import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { variation } from 'ember-launch-darkly';

export default class SupplierInvoicesRoute extends Route {
  @service abilities;
  @service homePage;

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'invoices');
    let canNavigate = this.abilities.can('navigate supplier-invoice');
    let canNavigateV2 = this.abilities.can('navigate v2 supplier-invoice');

    if (variation('feature--boolean-supplier-invoices-access')) {
      if (!canNavigateV2 || this.abilities.cannot('view v2 supplierInvoice')) {
        return this.homePage.replaceWithDefaultPage();
      }
    } else if (!canNavigate || this.abilities.cannot('view supplierInvoice')) {
      return this.homePage.replaceWithDefaultPage();
    }
  }
}
