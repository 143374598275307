import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { restartableTask } from 'ember-concurrency';

import { CARD_LEVELS } from 'qonto/constants/cards';

const DEFAULT_SORT_BY = 'processed_at:desc';

export default class RequestsPastIndexController extends Controller {
  @service intl;
  @service organizationManager;
  @service abilities;

  queryParams = ['highlight', 'page', 'per_page'];
  @tracked highlight = '';
  @tracked page = 1;
  @tracked per_page = 25;
  @tracked sort_by = DEFAULT_SORT_BY;

  transfer = null;

  CARD_LEVELS = CARD_LEVELS;

  get requests() {
    return this.model.fetchRequestsTask.lastSuccessful?.value || [];
  }

  get pendingTabTitle() {
    return this.organizationManager.membership.hasSpendLimits
      ? this.intl.t('requests.pending-manager-limits.tab_title')
      : this.intl.t('requests.pending.tab_title');
  }

  get createButtonLabel() {
    let canCreateCardRequest =
      this.abilities.can('create card request') && this.abilities.cannot('review card request');
    let canCreateTransferRequest =
      this.abilities.can('create transfer request') &&
      this.abilities.cannot('review transfer request');
    let canCreateMileageRequest =
      this.abilities.can('create mileage request') && this.abilities.can('review mileage request');

    if (canCreateTransferRequest && canCreateCardRequest) {
      return this.intl.t('btn.create-request');
    }

    if (canCreateTransferRequest) {
      return this.intl.t('btn.create-transfer-request');
    }

    if (canCreateMileageRequest) {
      return this.intl.t('requests.cta.submit-expense');
    }

    return this.intl.t('btn.create-card-request');
  }

  get localState() {
    let { isRunning, last } = this.model.fetchRequestsTask;
    let isEmpty = this.requests.length === 0;

    if (isRunning) {
      return {
        isLoading: true,
        error: false,
        empty: false,
      };
    }

    if (last.isError) {
      return {
        isLoading: false,
        error: true,
        empty: false,
      };
    }

    if (isEmpty) {
      return {
        isLoading: false,
        error: false,
        empty: true,
      };
    }
  }

  @action handlePerPageChange(value) {
    this.page = 1;
    this.per_page = value;
  }

  handleSelectRequestTask = restartableTask(async requestId => {
    let request = this.requests.find(({ id }) => id === requestId);

    if (request.requestType === 'multi_transfer') {
      return this.transitionToRoute('requests.past.multi-transfer-detail', request.id);
    }

    if (request.requestType === 'transfer') {
      await request.hasMany('attachments').load();
    }

    this.highlight = request.id;
  });

  @action clearHighlight() {
    this.highlight = '';
  }

  @action
  handleSortBy(sortDefinition) {
    this.highlight = '';
    this.page = 1;
    this.sort_by = sortDefinition;
  }
}
