import { getOwner, setOwner } from '@ember/owner';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

class SavingsAccountsInformation {
  @service intl;
  @service abilities;

  get shouldShowSavingsAccountSelector() {
    return this.abilities.can('create savings-account') && this.canManage;
  }

  get cantCreateReasonKeyValues() {
    return {
      too_many_accounts: {
        key: 'accounts.creation.select-account.savings.error-max-account-reached',
        content: this.intl.t('accounts.creation.select-account.savings.error-max-account-reached'),
      },
      not_enough_funds: {
        key: 'accounts.creation.select-account.savings.error-not-enough-funds',
        content: this.intl.t('accounts.creation.select-account.savings.error-not-enough-funds'),
      },
      max_amount_reached: {
        key: 'accounts.creation.select-account.savings.error-max-amount',
        content: this.intl.t('accounts.creation.select-account.savings.error-max-amount'),
      },
      kycb_not_updated: {
        key: 'accounts.creation.select-account.savings.error-kycb-not-updated.content',
        content: this.intl.t(
          'accounts.creation.select-account.savings.error-kycb-not-updated.content',
          {
            linkText: this.intl.t(
              'accounts.creation.select-account.savings.error-kycb-not-updated.link-text'
            ),
          }
        ),
        routePath: 'settings.company-profile',
      },
    };
  }

  get cantCreateReasonTranslation() {
    return this.cantCreateReasonKeyValues[this.cantCreateReason];
  }

  get maxRate() {
    return Math.max(...this.rates.map(rates => rates.nominalInterestRate));
  }

  constructor(owner, properties) {
    setOwner(this, owner);
    Object.assign(this, properties);
  }
}

class RemuneratedAccountsInformation {
  @service abilities;
  @service organizationManager;

  get shouldShowRemuneratedAccountSelector() {
    return this.abilities.can('create remunerated-account');
  }

  get maxMaturity() {
    return this.interestRates.at(-1).endDay;
  }

  get maxMaturityRate() {
    return parseFloat(this.interestRates.at(-1).rate) / 100;
  }

  get isMaxAccountNumberReached() {
    return this.organizationManager.organization.isMaxRemuneratedAccountNumberReached;
  }

  constructor(owner, properties) {
    setOwner(this, owner);
    Object.assign(this, properties);
  }
}

export default class AccountsNewRoute extends Route {
  @service store;
  @service abilities;

  async model() {
    let savingsAccountAbility = this.abilities.can('create savings-account');
    let remuneratedAccountAbility = this.abilities.can('create remunerated-account');
    let savingsInformationPromise,
      savingsRatesPromise,
      interestRatesPromise = null;

    if (savingsAccountAbility) {
      let savingsAdapter = this.store.adapterFor('savings-account');
      savingsInformationPromise = savingsAdapter.getInformation();
      savingsRatesPromise = savingsAdapter.getSavingsRates();
    }

    if (remuneratedAccountAbility) {
      let remunerationAdapter = this.store.adapterFor('remunerated-account');
      interestRatesPromise = remunerationAdapter.getInterestRates();
    }

    let [information, rates, interestRates] = await Promise.all([
      savingsInformationPromise,
      savingsRatesPromise,
      interestRatesPromise,
    ]);
    return {
      remuneratedInformation: remuneratedAccountAbility
        ? new RemuneratedAccountsInformation(getOwner(this), {
            interestRates,
          })
        : null,
      savingsInformation: savingsAccountAbility
        ? new SavingsAccountsInformation(getOwner(this), {
            ...information,
            rates,
          })
        : null,
    };
  }
}
