import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class CustomLabelsManageRoute extends Route {
  @service abilities;
  @service homePage;
  @service menu;
  @service organizationManager;
  @service router;

  activate() {
    this.menu.hide();
  }

  deactivate() {
    this.menu.show();
  }

  beforeModel() {
    if (this.abilities.cannot('view custom-label')) {
      return this.homePage.replaceWithDefaultPage();
    }

    if (this.abilities.cannot('access custom-label')) {
      return this.router.replaceWith('custom-labels.upgrade-plan');
    }
  }

  model() {
    let { organization } = this.organizationManager;
    let { labelLists } = organization;

    return labelLists;
  }
}
