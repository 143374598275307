import Controller from '@ember/controller';
import { service } from '@ember/service';

export default class Protected extends Controller {
  @service bannerFlashMessages;

  get hasTopBanner() {
    return !this.bannerFlashMessages.isEmpty;
  }
}
