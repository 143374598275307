import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class DirectDebitCollectionsIncreaseCollectionLimitController extends Controller {
  @service flowLinkManager;
  @service intl;
  @service router;
  @service segment;

  queryParams = ['origin'];
  @tracked origin = null;

  get points() {
    return [
      {
        title: this.intl.t('sdd-collections.collection-limit.increase-point-1.title'),
        content: this.intl.t('sdd-collections.collection-limit.increase-point-1.content'),
      },
      {
        title: this.intl.t('sdd-collections.collection-limit.increase-point-2.title'),
        content: this.intl.t('sdd-collections.collection-limit.increase-point-2.content'),
      },
    ];
  }

  @action
  onConnectBankAccountClick() {
    this.flowLinkManager.transitionTo({
      name: 'external-account-import',
      stepId: 'select',
      queryParams: {
        origin: this.origin || 'direct-debit-collections',
      },
    });

    this.segment.track('incoming-direct-debit_external-account-flow_started');
  }

  @action
  onCloseButtonClick() {
    this.router.transitionTo(this.origin || 'direct-debit-collections');
  }
}
