import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { dropTask, restartableTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { ErrorInfo } from 'qonto/utils/error-info';
import { ignore404 } from 'qonto/utils/ignore-error';

export default class QuotesShowRoute extends Route {
  @service toastFlashMessages;
  @service intl;
  @service modals;
  @service router;
  @service store;
  @service organizationManager;
  @service sentry;
  @service abilities;
  @service subscriptionManager;
  @service segment;

  beforeModel() {
    if (!this.abilities.can('create receivableInvoice')) {
      return this.router.replaceWith('receivable-invoices');
    }
  }

  async model({ id }) {
    let organizationId = this.organizationManager.organization.id;

    if (variation('feature--boolean-client-hub')) {
      this.fetchClientsTask.perform().catch(this.error);
    }

    this.fetchTask.perform(id).catch(this.error);
    this.fetchSettingsTask.perform(organizationId).catch(ignore404).catch(this.error);

    let [remainingInvoiceStats, remainingQuoteStats] = await Promise.all([
      this.store.modelFor('receivable-invoice').getStats(this.store),
      this.store.modelFor('quote').getStats(this.store),
    ]);

    return {
      fetchTask: this.fetchTask,
      fetchSettingsTask: this.fetchSettingsTask,
      remainingQuoteStats,
      remainingInvoiceStats,
    };
  }

  afterModel({ fetchTask, fetchSettingsTask, remainingQuoteStats, remainingInvoiceStats }) {
    this.modal = this.modals.open(
      'receivable-invoices/invoice-modal',
      {
        isFullScreenModal: true,
        hasSidebar: true,
        fetchTask,
        fetchSettingsTask,
        isDuplicateDisabled: remainingQuoteStats.quotasRemaining?.total === 0,
        isGenerateInvoiceDisabled: remainingInvoiceStats.quotasRemaining?.total === 0,
        isPdfPreviewWithNoToolbar: true,
        isQuote: true,
      },
      {
        focusTrapOptions: {
          initialFocus: false,
          onDeactivate: this.onCloseModal,
        },
      }
    );
  }

  fetchSettingsTask = restartableTask(async organizationId => {
    let settings = await this.store.findRecord('receivable-invoices-settings', organizationId);
    return settings;
  });

  fetchTask = dropTask(async id => {
    this.organizationManager.organization.getAvatar();
    let organization_id = this.organizationManager.organization.id;
    await this.store.query('customer', {
      filter: { organization_id },
    });

    let quote = await this.store.findRecord('quote', id);
    quote.contactEmail = quote.organizationSnapshot.contactEmail;

    await quote.hasMany('receivableInvoices').load();

    await quote.setPdfPreviewIframeUrl();

    return { quote };
  });

  fetchClientsTask = restartableTask(async () => {
    let clientsParams = {
      page: 1,
      per_page: 500,
      sort_by: 'name:asc',
    };
    let clients = await this.store.query('client-hub', clientsParams);
    let total = clients.meta.total_count;

    while (total > clients.length) {
      clientsParams.page++;
      clients = clients.concat(await this.store.query('client-hub', clientsParams));
    }
  });

  resetController(_, isExiting) {
    if (isExiting) {
      this.modal.close();
    }
  }

  @action error(error) {
    let errorInfo = ErrorInfo.for(error);
    if (errorInfo.shouldSendToSentry) {
      this.sentry.captureException(error);
    }
    this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    this.router.replaceWith('quotes.index');
  }

  @action onCloseModal() {
    this.router.transitionTo('quotes.index');
    this.segment.track('quote_options_escape');
  }
}
