import { ConflictError } from '@ember-data/adapter/error';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class CapitalReleaseContractClass extends Route {
  @service capitalIncreaseManager;
  @service toastFlashMessages;
  @service intl;

  model() {
    let handleContractSigned = error => {
      if (error instanceof ConflictError) {
        let message = this.intl.t('errors.conflict_error');
        this.toastFlashMessages.toastInfo(message);
        this.replaceWith('capital.finish');
      } else {
        return error;
      }
    };

    return this.get('capitalIncreaseManager')
      .requestCapitalReleaseSignature('deposit_release')
      .catch(handleContractSigned);
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.set('showContract', true);
    controller.set('signUrl', model.sign_url);
    controller.set('documentId', model.document_id);
  }
}
