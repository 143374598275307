export default {
  "accounts": "qva",
  "header-subtitle": "qvH title-4",
  "subtitle-container": "qvO",
  "subtitle": "qvm title-3 mb-16",
  "amount": "qvp body-2",
  "recommendations-section": "qvx",
  "dismissing": "qvw",
  "recommendations-divider": "qvN",
  "list--cashbeeSavings": "qvv",
  "connections-banner": "qvo",
  "list": "qvn",
  "icon": "qvi",
  "badge": "qvA",
  "discover-tile": "qvY"
};
