export default {
  "container": "qWV",
  "link-grey": "qWL",
  "slack-section-title": "qWT title-4 mb-8",
  "edit-channel-text": "qWz body-2 mb-16",
  "rule-section-title": "qWa title-4 mb-16",
  "rule-cards-container": "qWH",
  "edit-slack-container": "qWO",
  "rule-card": "qWm",
  "add-rule-container": "qWp",
  "add-rule-link-button": "qWx",
  "add-rule-text": "qWw",
  "empty-state": "qWN",
  "empty-state-textblock": "qWv",
  "empty-state-title": "qWo title-2",
  "empty-state-description": "qWn body-2",
  "empty-state-illustration": "qWi",
  "rules": "qWA"
};
