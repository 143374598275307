import Route from '@ember/routing/route';
import { service } from '@ember/service';

import dayjs from 'dayjs';

export default class OrganizationsShowIndexRoute extends Route {
  @service abilities;
  @service router;

  redirect(model) {
    if (model.underRegistration) {
      this.router.replaceWith('capital');
    } else if (
      model.depositCapitalStatus === null &&
      model.mainAccount?.balance === 0 &&
      this.abilities.can('access bank-account') &&
      dayjs().diff(model.createdAt, 'day') < 2
    ) {
      // The redirection to the accounts shall be removed completely when the get started
      // actions feature will be removed but for now we need to keep it.
      if (this.abilities.can('view get started actions onboarding')) {
        if (this.abilities.can('navigate overview')) {
          this.router.replaceWith('overview');
        } else {
          this.router.replaceWith('transactions');
        }
      } else {
        this.router.replaceWith('accounts');
      }
    } else {
      if (this.abilities.can('navigate overview')) {
        this.router.replaceWith('overview');
      } else {
        this.router.replaceWith('transactions');
      }
    }
  }
}
