export default {
  "container": "qrH",
  "content": "qrO",
  "logos": "qrm",
  "logo": "qrp",
  "animation": "qrx",
  "title": "qrw title-2 mb-32",
  "avatar": "qrN",
  "dropdown": "qrv",
  "disabled": "qro",
  "organization": "qrn",
  "actions": "qri"
};
