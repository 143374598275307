/* eslint-disable @qonto/no-import-roles-constants */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { didCancel, dropTask, restartableTask, timeout } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';
import { reads } from 'macro-decorators';

import { APPROVAL_WORKFLOW_STATUSES } from 'qonto/constants/approval-workflow';
import { CARD_LEVELS } from 'qonto/constants/cards';
import {
  getTrackingNameAndProperties,
  LAYOUT,
  TRACKING_ORIGINS,
  TYPES,
} from 'qonto/constants/empty-states/system';
import { getEmptyStateConfig } from 'qonto/constants/empty-states/tasks-and-requests';
import { apiBaseURL } from 'qonto/constants/hosts';
import { ROLES } from 'qonto/constants/membership';
import { REQUEST_TYPES } from 'qonto/constants/requests';
import { DEBOUNCE_MS } from 'qonto/constants/timers';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import cloneProperties from 'qonto/utils/clone-properties';
import { filterParams } from 'qonto/utils/compute-query-params';
import { ErrorInfo } from 'qonto/utils/error-info';
import parseConfirmResponse from 'qonto/utils/parse-confirm-response';

const DEFAULT_SORT_BY = 'created_at:desc';

export default class RequestsPendingIndexController extends Controller {
  @service abilities;
  @service errors;
  @service toastFlashMessages;
  @service intl;
  @service networkManager;
  @service organizationManager;
  @service store;
  @service segment;
  @service sentry;
  @service featuresManager;
  @service modals;
  @service notifierCounterManager;
  @service emptyStates;

  CARD_LEVELS = CARD_LEVELS;
  currentTransfer = null;
  beneficiaryWarning = null;
  spendLimitsWarning = null;
  spendLimits = null;
  /**
   * Update in route in model()
   * @type {boolean}
   */
  isRequester = false;

  @tracked showAllreadyProcessedModal = false;
  @tracked highlight = '';
  @tracked page = 1;
  @tracked per_page = 25;
  @tracked sort_by = DEFAULT_SORT_BY;
  @tracked allocatedBudget;
  @tracked selectedAccountHasInsufficientFunds = false;
  @tracked canShowInsufficientWarning = false;

  @reads('organizationManager.organization.hasMultipleActiveCurrentRemuneratedAccounts')
  hasMultipleActiveCurrentRemuneratedAccounts;

  get hasInsuficientFunds() {
    return this.selectedAccountHasInsufficientFunds;
  }

  get canShowAccountSelector() {
    return (
      this.abilities.can('review expense report request') &&
      this.hasMultipleActiveCurrentRemuneratedAccounts &&
      !this.spendLimitsWarning
    );
  }

  get pendingTabTitle() {
    return this.organizationManager.membership.hasSpendLimits
      ? this.intl.t('requests.pending-manager-limits.tab_title')
      : this.intl.t('requests.pending.tab_title');
  }

  get createButtonLabel() {
    let canCreateCardRequest =
      this.abilities.can('create card request') && this.abilities.cannot('review card request');
    let canCreateTransferRequest =
      this.abilities.can('create transfer request') &&
      this.abilities.cannot('review transfer request');
    let canCreateMileageRequest =
      this.abilities.can('create mileage request') && this.abilities.can('review mileage request');

    if (canCreateTransferRequest && canCreateCardRequest) {
      return this.intl.t('btn.create-request');
    }

    if (canCreateTransferRequest) {
      return this.intl.t('btn.create-transfer-request');
    }

    if (canCreateMileageRequest) {
      return this.intl.t('requests.cta.submit-expense');
    }

    return this.intl.t('btn.create-card-request');
  }

  get localState() {
    let { isRunning, last } = this.fetchDataTask;
    let isEmpty = this.requests.length === 0;

    if (isRunning) {
      return {
        isLoading: true,
        error: false,
        empty: false,
      };
    }

    if (last.isError) {
      return {
        isLoading: false,
        error: true,
        empty: false,
      };
    }

    if (isEmpty) {
      return {
        isLoading: false,
        error: false,
        empty: true,
      };
    }
  }

  get isEmpty() {
    return this.requests.length === 0;
  }

  get isEmptyStatePreviewLayout() {
    return this.emptyStateRevampOptions?.layout === LAYOUT.DISCOVER_PREVIEW;
  }

  get emptyStateRevampOptions() {
    if (this.fetchDataTask.isRunning || this.fetchDataTask.last?.isError) {
      return;
    }

    let canCreateCardOrExpenseReportOrMileageRequests =
      this.abilities.can('create card request') ||
      this.abilities.can('create expense-report request') ||
      this.abilities.can('create mileage request');
    let canCreateTransferRequests = this.abilities.can('create transfer request');

    let hasFlag = variation('feature--boolean-enable-reimbursements');
    return this.emptyStates.getEmptyStateOptions({
      isOrgEligibleForFeature: true,
      isEmptyGlobally: this.isEmpty,
      isEmptyLocally: this.isEmpty,
      hasActiveFilterOrSearch: false,
      config: getEmptyStateConfig(this.intl, hasFlag ? 'request_transfer' : undefined),
      abilities: {
        canCreateCardOrExpenseReportOrMileageRequests,
        canCreateTransferRequests,
      },
    });
  }

  @action
  trackCta(origin) {
    this.trackCtaEvent(origin);
  }

  @action
  trackCtaEvent(origin) {
    if (this.emptyStateRevampOptions) {
      this.emptyStates.trackCta(this.emptyStateRevampOptions, origin);
    } else {
      let trackingData = getTrackingNameAndProperties({
        type: TYPES.ACTIVATE,
        name: 'requests',
      })({
        isClickEvent: true,
        isEmptyState: false,
        origin: TRACKING_ORIGINS.HEADER,
      });
      if (trackingData?.name && trackingData.properties) {
        this.segment.track(trackingData.name, trackingData.properties);
      }
    }
  }

  @action
  handleSortBy(sortDefinition) {
    this.highlight = '';
    this.page = 1;
    this.sort_by = sortDefinition;
  }

  @action setAccount(request, account) {
    request.bankAccount = account;
    this.setProperties({ confirmWarnings: [], confirmErrors: [], beneficiaryWarning: [] });
    if (this.abilities.can('create transfer')) {
      return this.confirmRequestTask.perform(request);
    }
  }

  @action handlePerPageChange(value) {
    this.page = 1;
    this.per_page = value;
  }

  handleReloadRequestTask = dropTask(async () => {
    this.showAllreadyProcessedModal = false;
    await this.transitionToRoute('requests.past', {
      queryParams: { highlight: this.highlight },
    });
  });

  @action handleRefresh() {
    this.fetchDataTask
      .perform({})
      .then(() => {
        this.showAllreadyProcessedModal = false;
        this.resetQueryParams(['highlight']);
      })
      .catch(() => {
        // ignore error
      });
  }

  @action
  showAccountInsufficientFundsWarning() {
    this.selectedAccountHasInsufficientFunds = true;
  }

  @action
  toggleCanShowInsufficientWarning(value) {
    this.canShowInsufficientWarning = value ?? !this.canShowAccountSelector;
  }

  handleSelectRequestTask = restartableTask(async requestId => {
    let { role } = this.organizationManager.membership;

    let request = this.requests.find(({ id }) => id === requestId);

    if (!request.bankAccount) {
      this.toggleCanShowInsufficientWarning();
      this.setAccount(request, this.organizationManager.organization.mainAccount);
    }

    this.segment.track('request_details_opened', {
      request_type: request.requestType,
      request_id: request.id,
      request_status: request.status,
      origin: 'requests',
      role,
    });

    if (request.requestType === 'multi_transfer') {
      let routeName = this.isRequester
        ? 'requests.pending.requester-multi-transfer-detail'
        : 'requests.pending.multi-transfer-detail';

      return this.transitionToRoute(routeName, request.id);
    }

    if (request.requestType === 'transfer') {
      await request.hasMany('attachments').load();
    }

    await this.fetchAllocatedBudgetTask.perform(request);

    this.highlight = request.id;

    if (this.abilities.can('create transfer')) {
      return this.confirmRequestTask.perform(request);
    }
  });

  handleCancelTask = dropTask(async request => {
    try {
      return await request.cancelRequest();
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(
          new Error('Failed to cancel request at requests.pending.index', {
            cause: error,
          })
        );
      }
    }
  });

  @action handleDecline(request) {
    return request.declineRequest();
  }

  approveTask = dropTask(async request => {
    if (request.requestType === 'transfer') {
      await this.confirmRequestTask.perform(request);

      if (this.confirmErrors.length) {
        throw new Error('Validation failed');
      }
    }

    await request.approveRequest();
  });

  @action handleCloseSidePanel() {
    this.resetQueryParams(['highlight']);
    return this.fetchDataTask
      .perform({})
      .then(() => this.notifierCounterManager.updateCounter())
      .catch(() => {
        // ignore error
      });
  }

  fetchDataTask = restartableTask(async (params = {}) => {
    await timeout(DEBOUNCE_MS);

    let { organization } = this.organizationManager;
    let { page, per_page, sort_by } = filterParams(params);

    let requestModel = {
      includes: ['memberships'],
      organization_id: organization.id,
      page,
      per_page,
      sort_by,
    };

    if (!this.isRequester) {
      requestModel.status = ['pending'];
      if (this.featuresManager.isEnabled('approvalWorkflows')) {
        requestModel.approval_workflow_status = APPROVAL_WORKFLOW_STATUSES.AWAITS_MY_APPROVAL;
      }
    }

    let spendLimitsPromise = Promise.resolve();
    if (
      this.abilities.can('review transfer request') &&
      this.organizationManager.membership.role === ROLES.MANAGER
    ) {
      spendLimitsPromise = this.organizationManager.membership.getSpendLimits();
    }

    let [requests] = await Promise.all([
      this.store.query('request', requestModel),
      spendLimitsPromise,
    ]);
    return requests;
  });

  get requests() {
    return this.fetchDataTask.lastSuccessful?.value ?? [];
  }

  confirmRequestTask = dropTask(async request => {
    if (!request.lastStep) return;
    if (request.requestType === 'transfer') {
      await this.confirmRequestTransferTask.perform(request);
    } else if (request.requestType === 'expense_report' || request.requestType === 'mileage') {
      await this.confirmReimbursementTask.perform(request);
    }
  });

  confirmRequestTransferTask = dropTask(async request => {
    this.destroyCurrentTransfer();
    this.set('confirmErrors', []);
    this.set('spendLimitsWarning', null);
    this.set('spendLimits', null);

    let bankAccount = this.organizationManager.currentAccount;
    let transfer = this.store.createRecord('transfer', { bankAccount });

    await cloneProperties(request, transfer);
    transfer.email = transfer.beneficiaryEmail;
    transfer.iban = transfer.beneficiaryIban;
    transfer.name = transfer.beneficiaryName;
    transfer.bic = transfer.beneficiaryBic;
    this.currentTransfer = transfer;

    let transferNamespace = this.store.adapterFor('transfer').namespace;
    let url = `${apiBaseURL}/${transferNamespace}/transfers/confirm`;
    // Patch : End point confirm not support operation type's scheduled_later only scheduled
    let data = this.currentTransfer.serialize();
    data.operation_type =
      data.operation_type === 'scheduled_later' ? 'scheduled' : data.operationType;
    data = JSON.stringify({ transfer: data });

    try {
      let response = await this.networkManager.request(url, {
        method: 'POST',
        data,
      });

      this.setProperties(parseConfirmResponse(response, this.intl));
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(
          new Error('Failed to confirm a request transfer at requests.pending.index', {
            cause: error,
          })
        );
      }

      if (error.status === 422 && error?.errors?.iban?.length) {
        let hasSEPAError = error.errors.iban.some(error => error.includes('SEPA'));

        if (hasSEPAError) {
          this.set('confirmErrors', ['iban_sepa_error']);
        }
      }
      if (this.errors.shouldFlash(error)) {
        this.toastFlashMessages.toastError(this.errors.messageForStatus(error));
      }
    }
  });

  fetchAllocatedBudgetTask = dropTask(async request => {
    if (
      !request ||
      this.abilities.cannot('read budget') ||
      [REQUEST_TYPES.MILEAGE].includes(request.requestType) ||
      !request.pending
    ) {
      this.allocatedBudget = null;
      return;
    }

    let requestPeriodAmounts = false;
    let initiatorId = null;
    let scheduledDate = new Date();

    switch (request.requestType) {
      case REQUEST_TYPES.TRANSFER: {
        requestPeriodAmounts = this.abilities.can('review transfer request');
        initiatorId = request.initiator.get('id');
        scheduledDate = request.scheduledDate;
        break;
      }
      case REQUEST_TYPES.FLASH_CARD:
      case REQUEST_TYPES.VIRTUAL_CARD: {
        requestPeriodAmounts = this.abilities.can('review card request');
        initiatorId = request.holder.get('id');
        break;
      }
      case REQUEST_TYPES.EXPENSE_REPORT: {
        requestPeriodAmounts = this.abilities.can('review expense report request');
        initiatorId = request.initiator.get('id');
        break;
      }
    }

    requestPeriodAmounts &= this.organizationManager.membership.id !== initiatorId;

    try {
      let results = await this.store.adapterFor('budget').search({
        initiatorId,
        scheduledDate,
        includes: requestPeriodAmounts ? ['period_amounts'] : [],
      });
      this.allocatedBudget = results[0];
    } catch (error) {
      if (didCancel(error)) return;

      if (ErrorInfo.for(error).shouldSendToSentry) {
        return this.sentry.captureException(error);
      }
    }
  });

  confirmReimbursementTask = dropTask(async request => {
    this.destroyCurrentTransfer();
    this.set('confirmErrors', []);
    this.set('spendLimitsWarning', null);
    this.set('spendLimits', null);

    let bankAccount = this.organizationManager.currentAccount;
    let transfer = this.store.createRecord('transfer', { bankAccount });

    await cloneProperties(request, transfer);
    transfer.email = request.initiator.get('email');
    transfer.amount = request.amount.value;
    transfer.currency = request.amount.currency;
    this.currentTransfer = transfer;

    let transferNamespace = this.store.adapterFor('transfer').namespace;
    let url = `${apiBaseURL}/${transferNamespace}/transfers/confirm`;
    // Patch : End point confirm not support operation type's scheduled_later only scheduled
    let data = this.currentTransfer.serialize();
    data.operation_type =
      data.operation_type === 'scheduled_later' ? 'scheduled' : data.operationType;
    data.name = request.initiator.get('fullName');
    data.activity_tag = 'other_service';
    data = JSON.stringify({ transfer: data });

    try {
      let response = await this.networkManager.request(url, {
        method: 'POST',
        data,
      });

      this.setProperties(parseConfirmResponse(response, this.intl));
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      if (error.status === 422 && error?.errors?.iban?.length) {
        let hasSEPAError = error.errors.iban.some(error => error.includes('SEPA'));

        if (hasSEPAError) {
          this.set('confirmErrors', ['iban_sepa_error']);
        }
      }

      if (this.errors.shouldFlash(error)) {
        this.toastFlashMessages.toastError(this.errors.messageForStatus(error));
      }
    }
  });

  resetQueryParams() {
    this.highlight = '';
    this.page = 1;
    this.per_page = 25;
    this.sort_by = DEFAULT_SORT_BY;
  }

  destroyCurrentTransfer() {
    let transfer = this.currentTransfer;
    // Ember Data 4.7 does not like calling `destroy()` on a store
    // instance that is already in "destroying" mode. Since we can skip the
    // cleanup in this case anyway we just return early here.
    if (this.store.isDestroying || this.store.isDestroyed) return;
    if (transfer) {
      transfer.destroy();
    }
  }

  // websocket events callbacks
  reloadData() {
    this.fetchDataTask.perform({}).catch(() => {
      // ignore error
    });
  }

  employeeCanceled(payload) {
    let { id } = payload.object;
    if (!this._isRequestInList(id)) {
      return;
    }

    if (this.highlight === id) {
      this.showAllreadyProcessedModal = true;
    } else {
      this._displayToastRequestProcessed();
      this.reloadData();
    }
  }

  adminSettled(payload) {
    let { id } = payload.object;

    this.fetchDataTask.perform().catch(error => {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    });

    if (this.highlight === id) {
      this.showAllreadyProcessedModal = true;
    } else {
      this._displayToastRequestProcessed();
    }
  }

  _isRequestInList(itemId) {
    return this.requests.some(({ id }) => id === itemId);
  }

  _displayToastRequestProcessed() {
    this.toastFlashMessages.toastInfo(
      this.intl.t('toasts.request_processed_refresh'),
      'transfer_create'
    );
  }

  fetchRequestsAndHandleActivateLeversModalTask = dropTask(async () => {
    if (!this.isAbleToActivateLeversModal) {
      return;
    }

    try {
      let { organization } = this.organizationManager;
      let { requests } = await this.networkManager.request(
        `${apiBaseURL}/v3/requests?organization_id=${organization.id}&request_type[]=flash_card&request_type[]=virtual_card&per_page=1`
      );
      let hasNeverRequestedCard = !requests.length;

      if (hasNeverRequestedCard) {
        this.modals.open('requests/cards/popup/confirmation/activation-levers');
      }
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  get isAbleToActivateLeversModal() {
    let hasActivationShowModalFeature = Boolean(
      this.featuresManager.flags.cardRequestActivationShowModal
    );
    let canCreateCardRequests = this.abilities.can('create card requests');

    return (
      hasActivationShowModalFeature &&
      canCreateCardRequests &&
      !safeLocalStorage.getItem('saw-activation-levers-modal')
    );
  }
}
