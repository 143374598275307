import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class CardsSetPinRoute extends Route {
  @service organizationManager;

  beforeModel(transition) {
    super.beforeModel(...arguments);

    // redirection for backward compatibility with legacy deeplink
    // to remove once setpin deeplink is using the action QP
    let { card_id: id } = transition.to.params;
    let organizationSlug = this.organizationManager.organization.slug;

    this.replaceWith('flows', organizationSlug, 'card-set-pin', 'set-pin', {
      queryParams: { id },
    });
  }
}
