export default {
  "container": "qUI",
  "form-container": "qUt",
  "preview-container": "qUM",
  "pdf-preview-container": "qUP",
  "content": "qUl",
  "title": "qUX",
  "tabs-container": "qUC",
  "close-button": "qUk btn btn--icon-only btn--tertiary btn--large",
  "pdf-preview": "qUJ",
  "header": "qUs",
  "without-tabs": "qUF"
};
