import Controller from '@ember/controller';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

export default class ReceivableInvoicesCreditNotesNewController extends Controller {
  @service router;
  @service segment;
  @service organizationManager;
  @service intl;
  @service modals;

  queryParams = ['invoiceId'];

  @tracked invoiceId = null;

  get logo() {
    let { organization } = this.organizationManager;
    return organization.get('isDefaultAvatar') === false ? organization.picture : null;
  }

  onCloseTask = dropTask(async () => {
    await this.modals.open('receivable-invoices/exit-credit-note-form-modal', {
      title: this.intl.t('receivable-invoices.credit-note-creation.exit-modal.title'),
      description: this.intl.t('receivable-invoices.credit-note-creation.exit-modal.description'),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('btn.leave'),
      confirmTask: this.onConfirmCloseTask,
    });
  });

  onConfirmCloseTask = dropTask(async close => {
    this.segment.track('credit-note_creation_canceled');
    this.router.transitionTo('receivable-invoices.index');
    await close();
  });
}
