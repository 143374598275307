import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import ConnectionsController from '../controller';

export default class ConnectionsInvoicesController extends ConnectionsController {
  @service abilities;
  @service intl;
  @service modals;
  @service segment;
  @service toastFlashMessages;

  @tracked highlight;
  @tracked invoicesConnections = this.model.invoicesConnectionsTask?.lastSuccessful?.value || [];
  @tracked sortBy = 'status:asc';

  closeSidebarId = 'close-sidebar';
  queryParams = ['highlight', { sortBy: 'sort_by' }];

  get sortedInvoicesConnections() {
    let sorted = this.model.invoicesConnectionsTask?.lastSuccessful?.value || [];
    let [sortField, sortOrder] = this.sortBy.split(':');

    return [...sorted].sort((a, b) => {
      let order = 0;

      let fieldA = a.get(sortField);
      let fieldB = b.get(sortField);

      if (fieldA > fieldB) {
        order = 1;
      } else if (fieldA < fieldB) {
        order = -1;
      } else {
        order = 0;
      }

      return sortOrder === 'asc' ? order : -order;
    });
  }

  get canDisconnect() {
    return this.abilities.can('disconnect gmi-integration');
  }

  @action
  handleSortBy(sortField, sortOrder) {
    this.highlight = '';
    this.sortBy = `${sortField}:${sortOrder}`;
  }

  @action
  updateHighlightedItem(itemId) {
    this.highlight = itemId;
  }

  confirmDisconnectTask = dropTask(async connection => {
    let { name: connectionName } = connection;

    this.segment.track('active-connections_disconnect-cta_click', {
      'invoice-retrieval': connectionName,
    });

    await this.modals.open('popup/destructive', {
      title: this.intl.t('settings.connections.disconnect-modal.title', {
        connectionName,
      }),
      description: this.intl.t('settings.connections.disconnect-modal.description'),
      cancel: this.intl.t('settings.connections.disconnect-modal.cancel-cta'),
      confirm: this.intl.t('settings.connections.disconnect-modal.disconnect-cta'),
      confirmTask: this.disconnectTask,
      connection,
    });
  });

  disconnectTask = dropTask(async (closeModal, { connection }) => {
    let { name: connectionName } = connection;

    try {
      await connection.destroyRecord();

      this.invoicesConnections = this.invoicesConnections.filter(c => c.id !== connection.id);

      this.toastFlashMessages.toastSuccess(
        this.intl.t('settings.connections.disconnect-toast.success', { connectionName })
      );
    } catch {
      this.toastFlashMessages.toastError(
        this.intl.t('settings.connections.disconnect-toast.error', { connectionName })
      );
    } finally {
      closeModal();
    }
  });
}
