import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class TeamUpgradePlan extends Route {
  @service abilities;
  @service router;

  beforeModel() {
    if (this.abilities.can('access team')) {
      return this.router.transitionTo('teams');
    }
  }
}
