import Route from '@ember/routing/route';
import { service } from '@ember/service';

import {
  DEPOSIT_CAPITAL_STEPPER_STEP,
  FAST_TRACK_DEPOSIT_CAPITAL_STATUS,
} from 'qonto/constants/organization';

export default class CapitalStatusRoute extends Route {
  @service capitalIncreaseManager;
  @service featuresManager;

  activate() {
    let {
      DEPOSIT_REQUEST_SENT,
      DEPOSIT_REQUEST_SIGNED,
      DEPOSIT_CERTIFICATE_SIGNED,
      KBIS_SUBMITTED,
      DEPOSIT_RELEASE_REQUESTED,
    } = FAST_TRACK_DEPOSIT_CAPITAL_STATUS;

    let { depositCapitalStatus } = this.capitalIncreaseManager.organization;

    switch (depositCapitalStatus) {
      case DEPOSIT_REQUEST_SENT:
        if (this.isQontoNotaryEnabled) {
          return this.setStepperStep(DEPOSIT_CAPITAL_STEPPER_STEP.COMPANY_REGISTRATION);
        }
        return this.setStepperStep(DEPOSIT_CAPITAL_STEPPER_STEP.DEPOSIT_CERTIFICATE);
      case DEPOSIT_REQUEST_SIGNED:
        return this.setStepperStep(DEPOSIT_CAPITAL_STEPPER_STEP.DEPOSIT_CERTIFICATE);
      case DEPOSIT_CERTIFICATE_SIGNED:
      case KBIS_SUBMITTED:
        return this.setStepperStep(DEPOSIT_CAPITAL_STEPPER_STEP.COMPANY_REGISTRATION);
      case DEPOSIT_RELEASE_REQUESTED:
        return this.setStepperStep(DEPOSIT_CAPITAL_STEPPER_STEP.FUND_RELEASE);
    }

    return this.setStepperStep(DEPOSIT_CAPITAL_STEPPER_STEP.DOCUMENTS);
  }

  setStepperStep(stepperStepName) {
    this.capitalIncreaseManager.setCurrentStep(stepperStepName);
  }

  get isQontoNotaryEnabled() {
    return this.featuresManager.isEnabled('qontoNotary');
  }
}
