import Controller from '@ember/controller';
import { service } from '@ember/service';

import { reads } from 'macro-decorators';

export default class FinancingPartnersIndexController extends Controller {
  @service organizationManager;

  @reads('organizationManager.membership.id') membershipId;
}
