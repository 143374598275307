import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { Checkbox } from '@repo/design-system-kit';
import { task } from 'ember-concurrency';

import { addDocumentFileToModel, removeDocumentFileFromModel } from 'qonto/utils/handle-documents';

export default class CapitalUploadController extends Controller {
  checkbox = Checkbox;

  @service capitalIncreaseManager;
  @service toastFlashMessages;
  @service store;
  @service modals;
  @service intl;

  @action toggleCertify() {
    this.set('certify', !this.certify);
  }

  @action
  handleAddDocument(model, docType, file) {
    addDocumentFileToModel(this.store, model, docType, file);
  }

  @action
  handleRemove(model, docType, file) {
    removeDocumentFileFromModel(this.store, model, docType, file);
  }

  @action
  handleOpenFilePreview(file) {
    this.modals.open('file-preview-modal', {
      isFullScreenModal: true,
      selectedFile: file,
      deletable: true,
      model: this.organization,
      docType: 'kbis',
      remove: this.handleRemove,
    });
  }

  @action
  handleBack() {
    this.transitionToRoute('capital.kbis-info');
  }

  handleConfirmTask = task(async () => {
    let isFormValid = this.capitalIncreaseManager.validateOnly('kbis');

    if (!isFormValid) return;

    try {
      await this.capitalIncreaseManager.handleSaveDocuments('kbis');
      this.set('isKbisSubmitted', true);
    } catch {
      let message = this.intl.t('errors.internal_server_error');
      this.toastFlashMessages.toastError(message);
    }
  });
}
