import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { hash } from 'rsvp';

export default class RequestsPastMultiTransferDetailRoute extends Route {
  @service store;

  queryParams = {
    highlight: {
      refreshModel: false,
    },
  };

  model({ request_id }) {
    return hash({
      request: this.store.findRecord('request-multi-transfer', request_id),
      transfers: this.store.query('request-multi-transfer-transfer', {
        includes: ['attachments'],
        request_multi_transfer_id: request_id,
      }),
    });
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.highlight = '';
    }
  }
}
