import Controller from '@ember/controller';
import { service } from '@ember/service';

import { LottiePlayer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import { authBaseURL } from 'qonto/constants/hosts';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class InvitationsShowMemberCredentialsController extends Controller {
  lottiePlayer = LottiePlayer;

  @service errors;
  @service toastFlashMessages;
  @service intl;
  @service networkManager;
  @service router;
  @service sentry;
  @service sessionManager;
  @service userManager;

  animationReady = false;

  moveToNextStep() {
    let { kycRequired } = this.invite;

    if (kycRequired) {
      return this.router.transitionTo('invitations.show.member.info');
    }

    return this.router.transitionTo('invitations.show.member.address');
  }

  handleCreateUserTask = dropTask(async user => {
    if (this.sessionManager.isAuthenticated) {
      try {
        await this.networkManager.request(`${authBaseURL}/v2/users/me/phone_number`, {
          method: 'POST',
          data: JSON.stringify({
            user: {
              phone_number: user.phoneNumber,
            },
          }),
        });
      } catch (error) {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
        this.toastFlashMessages.toastError(
          this.errors.messageForStatus(error) || this.intl.t('toasts.errors.server_error')
        );
      }
      return this.moveToNextStep();
    }

    let { email, password } = user;
    let { loginToken } = this.invite;
    user.set('loginToken', loginToken);

    return user
      .save()
      .then(() => this.userManager.setup({ email, password }))
      .then(() => {
        this.moveToNextStep();
      });
  });
}
