import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class MembersUpgradeTeamsRoute extends Route {
  @service router;
  @service subscriptionManager;

  rootRouteName = 'members';

  beforeModel() {
    if (this.subscriptionManager.getLimitByFeatureCode('additional_users') !== 1) {
      return this.router.replaceWith(this.rootRouteName);
    }
  }
}
