import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { DEPOSIT_CAPITAL_STEPPER_STEP } from 'qonto/constants/organization';

export default class CapitalCompanyVerificationRoute extends Route {
  @service capitalIncreaseManager;

  activate() {
    this.capitalIncreaseManager.setCurrentStep(DEPOSIT_CAPITAL_STEPPER_STEP.DOCUMENTS);
  }
}
