import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';
import window from 'ember-window-mock';

import { apiBaseURL, oauthNamespace } from 'qonto/constants/hosts';
// eslint-disable-next-line @qonto/no-import-roles-constants
import { ROLES } from 'qonto/constants/membership';
import { IDENTITY_TYPES, LOCAL_STORAGE_ORGANIZATION_KEY } from 'qonto/constants/oauth';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class OauthIndexRoute extends Route {
  @service networkManager;
  @service organizationManager;
  @service sessionManager;
  @service sentry;
  @service store;
  @service userManager;

  async beforeModel(transition) {
    let { login_challenge: loginChallenge } = transition.to.queryParams;

    if (!loginChallenge) {
      return this.replaceWith('/404');
    }

    this.loginRequest = await this.networkManager.request(
      `${apiBaseURL}/${oauthNamespace}/oauth/login_requests?login_challenge=${loginChallenge}`
    );

    if (this.loginRequest.skip_login_prompt) {
      let redirectTo = await this.performSilentLogin(loginChallenge);

      window.location.replace(redirectTo);
      transition.abort();
    }

    this.sessionManager.requireAuthentication(transition, 'signin');
  }

  async model(params, transition) {
    await this.sessionManager.setupData();

    if (this.loginRequest.identity_type === IDENTITY_TYPES.USER) {
      let redirectTo = await this.performSilentLogin(params.login_challenge);

      window.location.replace(redirectTo);
      transition.abort();
    }

    if (this.loginRequest.organization_id) {
      let redirectTo = await this.acceptLoginRequestWithOrganization(
        params.login_challenge,
        this.loginRequest.organization_id
      );

      safeLocalStorage.setItem(LOCAL_STORAGE_ORGANIZATION_KEY, this.loginRequest.organization_id);
      window.location.replace(redirectTo);
      transition.abort();
    }

    if (variation('feature--boolean-login-improvement')) {
      let memberships = await this.getMembershipsTask.perform(
        this.organizationManager.organizations
      );

      return {
        login: this.loginRequest,
        organizations: memberships.map(membership => {
          let organization = membership.get('organization');

          organization.disabled =
            !membership.permissions?.oauth?.rules.some(rule => rule.action === 'login') ||
            membership.role === ROLES.MANAGER ||
            membership.role === ROLES.EMPLOYEE;

          return organization;
        }),
      };
    } else {
      let organizationsRecord = this.store
        .peekAll('organization')
        .filter(({ accessDisabled }) => !accessDisabled);

      let userMembershipsIds = this.userManager.currentUser.memberships.map(({ id }) => id);

      let organizations = await Promise.all(
        organizationsRecord.map(async organization => {
          let organizationMembership = organization.memberships.find(({ id }) =>
            userMembershipsIds.includes(id)
          );

          let membership = await this.store.findRecord('membership', organizationMembership.id);

          // We need to directly check the permissions on membership here
          // as we iterate our current membership through multiple organizations
          organization.disabled = !membership.permissions?.oauth?.rules.some(
            rule => rule.action === 'login'
          );

          return organization;
        })
      );

      return { login: this.loginRequest, organizations };
    }
  }

  async acceptLoginRequestWithOrganization(loginChallenge, organizationId) {
    try {
      let { redirect_to: redirectTo } = await this.networkManager.request(
        `${apiBaseURL}/${oauthNamespace}/oauth/login_requests/accept`,
        {
          method: 'POST',
          data: JSON.stringify({
            login_challenge: loginChallenge,
            organization_id: organizationId,
          }),
        }
      );

      return redirectTo;
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  }

  async performSilentLogin(loginChallenge) {
    try {
      if (this.sessionManager.isAuthenticated) {
        let { redirect_to: redirectTo } = await this.networkManager.request(
          `${apiBaseURL}/${oauthNamespace}/oauth/login_requests/accept`,
          {
            method: 'POST',
            data: JSON.stringify({ login_challenge: loginChallenge }),
          }
        );

        return redirectTo;
      } else {
        let { redirect_to: redirectTo } = await this.networkManager.request(
          `${apiBaseURL}/${oauthNamespace}/oauth/login_requests/reject`,
          {
            method: 'POST',
            data: JSON.stringify({
              login_challenge: loginChallenge,
              error: {
                code: 'user_not_logged_in',
                description: 'user was not logged in and login prompt was skipped',
              },
            }),
          }
        );

        return redirectTo;
      }
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    if (!variation('feature--boolean-login-improvement')) {
      let organizations = this.store
        .peekAll('organization')
        .filter(({ accessDisabled }) => !accessDisabled);

      let userMembershipsIds = this.userManager.currentUser.memberships.map(({ id }) => id);

      controller.organizations = organizations.map(organization => {
        let organizationMembership = organization.memberships.find(({ id }) =>
          userMembershipsIds.includes(id)
        );

        organization.disabled =
          organizationMembership.role === ROLES.MANAGER ||
          organizationMembership.role === ROLES.EMPLOYEE;

        return organization;
      });
    }

    if (this.organizationManager.organizations.length === 1) {
      controller.organization = this.organizationManager.organizations[0];
    }
  }

  getMembershipsTask = dropTask(async organizations => {
    return await Promise.all(
      organizations.map(async organization => {
        if (organization.memberships.length) {
          return organization.memberships[0];
        } else {
          return await this.store.adapterFor('membership').fetchMembershipMe({
            organizationId: organization.id,
          });
        }
      })
    );
  });
}
