import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { dropTask } from 'ember-concurrency';

import { FlowSetup } from 'qonto/routes/flows/setup/internals';

class PagopaFlowDataContext {
  payment = null;
  paymentStatus = null;

  constructor(payment) {
    this.payment = payment;
  }
}

export default class PagopaFlowSetup extends FlowSetup {
  @service abilities;
  @service homePage;
  @service intl;
  @service modals;
  @service router;
  @service segment;
  @service store;
  @service organizationManager;

  constructor() {
    super(...arguments);

    let payment = this.store.createRecord('pagopa-payment');

    this.dataContext = new PagopaFlowDataContext(payment);
  }

  beforeFlow() {
    let { organization, membership } = this.organizationManager;

    if (membership.shouldSubmitKyc && organization.kybPending) {
      return this.router.replaceWith('kyc.intro', {
        queryParams: {
          redirectRoute: 'pagopa',
        },
      });
    }

    Sentry.getCurrentScope().setTag('CFT', 'local-payments');
    if (this.abilities.cannot('create pagopa')) {
      return this.homePage.replaceWithDefaultPage();
    }
  }

  onComplete() {
    this.router.transitionTo('transactions');
  }

  onAbortTask = dropTask(async (_, { id: stepId }) => {
    if (stepId === 'notice-info') {
      this.segment.track('pagopa_payment-flow_abort', { step: stepId });
      this.router.transitionTo('pagopa');
      return;
    }

    let result = await this.openAbortModalTask.perform();
    let shouldContinueAbort = result === 'confirm';
    if (shouldContinueAbort) {
      this.segment.track('pagopa_payment-flow_abort', { step: stepId });
      this.router.transitionTo('pagopa');
    }
    return shouldContinueAbort;
  });

  openAbortModalTask = dropTask(async () => {
    return await this.modals.open('popup/destructive', {
      title: this.intl.t('pagopa.cancel-payment-modal.title'),
      description: this.intl.t('pagopa.cancel-payment-modal.subtitle'),
      cancel: this.intl.t('btn.back'),
      confirm: this.intl.t('pagopa.cancel-payment-modal.cta'),
    });
  });
}
