/* eslint-disable @qonto/no-import-roles-constants */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { dropTask, task } from 'ember-concurrency';

import { ROLES } from 'qonto/constants/membership';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class GuestsMemberController extends Controller {
  @service errors;
  @service toastFlashMessages;
  @service flowLinkManager;
  @service intl;
  @service modals;
  @service organizationManager;
  @service router;
  @service segment;
  @service subscriptionManager;

  @tracked editingTeam;
  @tracked team;

  get guest() {
    return this.model;
  }

  get organization() {
    return this.organizationManager.organization;
  }

  get currentPricePlan() {
    return this.subscriptionManager.currentPricePlan;
  }

  get memberPrice() {
    return this.subscriptionManager.options.additionalUser?.value;
  }

  get isManager() {
    return this.organizationManager.membership.role === ROLES.MANAGER;
  }

  sendInviteAgainTask = dropTask(async () => {
    this.segment.track('member_resend_invite', { role: this.guest.role });

    try {
      await this.guest.renewInvite();

      let message = this.intl.t('toasts.membership_invite_sent_again');
      this.toastFlashMessages.toastInfo(message);
    } catch (error) {
      this.errors.handleError(error);
    }
  });

  saveTeamTask = dropTask(async guest => {
    try {
      this.toggleEditingTeam();

      if (this.team.id !== guest.team.get('id')) {
        guest.team = this.team;

        await guest.save();

        this.segment.track('member_team_update_confirmed', {
          flow: 'update-team',
        });

        this.toastFlashMessages.toastSuccess(
          this.intl.t('accountant-access.change-team.success.toast')
        );
      }
    } catch {
      let message = this.intl.t('toasts.errors.server_error');
      this.toastFlashMessages.toastError(message);
    }
  });

  revokeTask = dropTask(async close => {
    try {
      await this.guest.revoke();
      await this.organization.reload();
      this.segment.track('member_disable_confirmed');

      this.toastFlashMessages.toastInfo(this.intl.t('accountant-access.disable.success.toast'));

      close();
    } catch (error) {
      this.errors.handleError(error);
    }
  });

  unRevokeTask = dropTask(async guest => {
    try {
      await guest.unrevoke();
      await this.organization.reload();

      let message = this.intl.t('toasts.members.invitation-success');
      this.toastFlashMessages.toastInfo(message);
    } catch (error) {
      this.errors.handleError(error);
    }
  });

  cancelInvitationTask = dropTask(async close => {
    await this.guest
      .revoke()
      .then(async () => {
        await this.organization.reload();

        this.segment.track('member_cancel_invite_confirmed');

        close();

        this.router.replaceWith('guests', this.organization.slug);

        this.toastFlashMessages.toastInfo(this.intl.t('toasts.cancel-invitation-success'));
      })
      .catch(ignoreCancelation);
  });

  openRevokeAccessModalTask = task(async () => {
    let { memberPrice, guest } = this;

    this.segment.track('member_disable_started', {
      membershipStatus: guest.status,
      role: guest.role,
    });

    await this.modals.open('guest/modal/confirm-revoke-accountant', {
      confirmTask: this.revokeTask,
      memberPrice,
      isRefundable: false,
    });
  });

  unRevokedGuestConfirmTask = dropTask(async () => {
    this.segment.track('member_re_enable', { role: ROLES.REPORTING });

    await this.modals.open('guest/modal/confirm-unrevoke-accountant', {
      shouldBeCharged: false,
      membership: this.guest,
      memberPrice: this.memberPrice,
      pricePlanCode: this.currentPricePlan.code,
      isUpgradePlanLinkVisible: false,
      confirmTask: this.unRevokeTask,
    });
  });

  @action
  openCancelInviteModal() {
    this.segment.track('member_cancel_invite_started');

    this.modals.open('popup/destructive', {
      title: this.intl.t('members.cancel-modal.title'),
      confirm: this.intl.t('members.cancel-modal.confirm-cta'),
      cancel: this.intl.t('btn.back'),
      description: this.intl.t('members.cancel-modal.subtitle'),
      confirmTask: this.cancelInvitationTask,
    });
  }

  @action
  toggleEditingTeam() {
    if (!this.editingTeam) {
      this.changeTeam(this.guest.team);
    }
    this.editingTeam = !this.editingTeam;
  }

  @action
  changeTeam(team) {
    this.team = team;
  }

  @action
  onEditTeamButtonClick() {
    this.segment.track('member_team_update_started', { flow: 'update-team' });
    this.toggleEditingTeam();
  }

  @action handleViewTransactions(membership) {
    this.router.transitionTo('transactions.index', this.organization.slug, {
      queryParams: {
        initiator: membership.id,
      },
    });
  }
}
