import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { TrackedArray } from 'tracked-built-ins';

export default class MembershipAddRoute extends Route {
  @service menu;
  @service store;
  @service organizationManager;

  activate() {
    if (this.menu.isVisible) {
      this.menu.hide();
    }
  }

  deactivate() {
    if (!this.menu.isVisible) {
      this.menu.show();
    }
  }

  async model() {
    let { updateProcess } = this.modelFor('settings.company-profile');
    let organization = await updateProcess.belongsTo('organization').load();

    let membershipChangeRequest = this.store.createRecord(
      'kyc-kyb-update-membership-change-request',
      { kycKybUpdateProcess: updateProcess }
    );

    let memberships = await organization.hasMany('memberships').load();

    let updateProcessKbis = await updateProcess.kycKybUpdateKbisFile;
    let kbis = new TrackedArray();
    if (updateProcessKbis) {
      kbis.push(updateProcessKbis);
    }

    let updateProcessPois = await membershipChangeRequest.kycKybUpdatePoiFile;
    let pois = new TrackedArray();
    if (updateProcessPois) {
      pois.push(updateProcessPois);
    }

    let updateProcessBylaws = await updateProcess.kycKybUpdateBylawFile;
    let bylaws = new TrackedArray();
    if (updateProcessBylaws) {
      bylaws.push(updateProcessBylaws);
    }
    membershipChangeRequest.corporateOfficer = null;
    membershipChangeRequest.legalRepresentative = null;
    membershipChangeRequest.ubo = null;

    return {
      memberships,
      membershipChangeRequest,
      organization,
      updateProcess,
      kbis,
      pois,
      bylaws,
    };
  }
}
