import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { restartableTask } from 'ember-concurrency';

import { REQUEST_TYPES, STATUS } from 'qonto/constants/requests';
import { filterParams } from 'qonto/utils/compute-query-params';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class DirectDebitCollectionsRequestsRoute extends Route {
  @service abilities;
  @service router;
  @service store;
  @service sentry;
  @service organizationManager;
  @service directDebitCollectionsManager;

  queryParams = {
    page: { refreshModel: true },
    perPage: { refreshModel: true },
    sortBy: { refreshModel: true },
  };

  beforeModel() {
    if (this.abilities.cannot('read direct debit collection request')) {
      return this.router.replaceWith('direct-debit-collections.processing', {
        queryParams: { sortBy: null },
      });
    }
  }

  model(params) {
    let {
      isActivatedNotEligible,
      collectionLimit,
      guardingDepositPercentage,
      hasGuarding,
      collectionsOnHoldData,
    } = this.modelFor('direct-debit-collections');

    params = filterParams(params);

    this.directDebitCollectionsManager.getTotalOnHoldMetaTask
      .perform(params.bankAccounts)
      .catch(ignoreCancelation)
      .catch(error => {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });

    this.fetchMultiRequestsTask
      .perform(params)
      .catch(ignoreCancelation)
      .catch(error => {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });

    return {
      fetchMultiRequestsTask: this.fetchMultiRequestsTask,
      getTotalOnHoldMetaTask: this.directDebitCollectionsManager.getTotalOnHoldMetaTask,
      isActivatedNotEligible,
      collectionLimit,
      guardingDepositPercentage,
      hasGuarding,
      collectionsOnHoldData,
    };
  }

  fetchMultiRequestsTask = restartableTask(async (params = {}) => {
    let { page, perPage, sortBy } = filterParams(params);

    return await this.store.query('request', {
      includes: ['memberships'],
      status: [STATUS.PENDING],
      organization_id: this.organizationManager.organization.id,
      request_type: [REQUEST_TYPES.MULTI_DIRECT_DEBIT_COLLECTION],
      page,
      per_page: perPage,
      sort_by: sortBy,
    });
  });
}
