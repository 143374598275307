import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { DEFAULT_ORIGIN_URL } from 'qonto/constants/bank-account';

export default class AccountsNewSavingsRoute extends Route {
  @service menu;
  @service abilities;
  @service router;
  @service store;

  origin;

  activate() {
    this.menu.hide();
  }

  deactivate() {
    this.menu.show();
  }

  async beforeModel(transition) {
    this.origin =
      this.router.currentRoute?.queryParams?.origin ||
      transition.to?.queryParams?.origin ||
      DEFAULT_ORIGIN_URL;
    if (this.abilities.cannot('create savings-account')) {
      this.router.replaceWith(this.origin);
    } else {
      let { savingsInformation } = await this.modelFor('accounts.new');

      if (!savingsInformation.canManage) {
        this.router.replaceWith(this.origin);
      }
    }
  }

  model() {
    let { savingsInformation } = this.modelFor('accounts.new');
    let { rates, maxRate, ...information } = savingsInformation;
    return {
      information,
      rates,
      maxRate,
      origin: this.origin,
    };
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.set('step', 'about');
    }
  }
}
