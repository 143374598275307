export const remuneratedAccountFunding = {
  steps: {
    'add-funds': {
      componentName: 'accounts/remunerated/funding/add-funds',
      nextStepId: 'review',
    },
    review: {
      componentName: 'accounts/remunerated/funding/review',
      nextStepId: 'success',
    },
    success: {
      componentName: 'accounts/remunerated/funding/success',
    },
  },
  options: {
    enablePersistence: false,
  },
};
