import Controller from '@ember/controller';
import { service } from '@ember/service';
import { capitalize } from '@ember/string';
import { tracked } from '@glimmer/tracking';

import { Disclaimer, LottiePlayer } from '@repo/design-system-kit';
import { dropTask, task } from 'ember-concurrency';
import window from 'ember-window-mock';

import { apiBaseURL, oauthNamespace } from 'qonto/constants/hosts';
import { ErrorInfo } from 'qonto/utils/error-info';

const SENTRY_IGNORE_HTTP_STATUS = 410;

export default class OauthConsentController extends Controller {
  disclaimer = Disclaimer.Block;

  lottiePlayer = LottiePlayer;

  queryParams = ['consent_challenge'];

  @service toastFlashMessages;
  @service intl;
  @service networkManager;
  @service organizationManager;
  @service sentry;

  @tracked consent_challenge = null;
  @tracked organization;

  get clientLogo() {
    return this.model.consent?.client?.['logo_uri'];
  }

  get clientName() {
    return capitalize(this.model.consent?.client?.['name'] || '');
  }

  get scopes() {
    return this.model.consent?.requested_scope?.split(' ') || [];
  }

  get showPaymentsDisclaimer() {
    return this.scopes.includes('payment.write');
  }

  acceptConsentTask = dropTask(async () => {
    try {
      let { redirect_to: redirectTo } = await this.networkManager.request(
        `${apiBaseURL}/${oauthNamespace}/oauth/consents/accept`,
        {
          method: 'POST',
          data: JSON.stringify({
            consent_challenge: this.consent_challenge,
            consent: {
              grant_scope: this.scopes,
              session: {
                ...(this.model.organizationId
                  ? { organization_id: this.model.organizationId }
                  : {}),
              },
            },
          }),
        }
      );

      window.location.replace(redirectTo);
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry && error.status !== SENTRY_IGNORE_HTTP_STATUS) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  });

  rejectConsentTask = task(async () => {
    try {
      let { redirect_to: redirectTo } = await this.networkManager.request(
        `${apiBaseURL}/${oauthNamespace}/oauth/consents/reject`,
        {
          method: 'POST',
          data: JSON.stringify({
            consent_challenge: this.consent_challenge,
          }),
        }
      );

      window.location.replace(redirectTo);
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry && error.status !== SENTRY_IGNORE_HTTP_STATUS) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  });
}
