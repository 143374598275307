import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class MultiRoute extends Route {
  @service organizationManager;
  @service store;

  model() {
    let file = null;
    let { organization, currentAccount } = this.organizationManager;

    return this.store.createRecord('request-multi-transfer', {
      file,
      organization,
      bankAccount: currentAccount,
    });
  }

  afterModel() {
    this.transitionTo('requests.transfers.multi.new');
  }
}
