export default {
  "page-wrapper": "qyj",
  "content-wrapper": "qyW",
  "content": "qyu",
  "period-field": "qUS",
  "format-options": "qUc",
  "format-option": "qUq",
  "format-radio": "qUZ",
  "format-description": "qUR body-2"
};
