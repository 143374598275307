import Controller from '@ember/controller';
import { service } from '@ember/service';

const ROUTES = {
  APPS: 'settings.connections.apps',
  BANKS: 'settings.connections.banks',
  INVOICES: 'settings.connections.invoices',
};

export default class ConnectionsController extends Controller {
  @service router;

  get canViewTabs() {
    return (
      [
        this.model.canViewAppsConnections,
        this.model.canViewBankConnections,
        this.model.canViewInvoicesConnections,
      ].filter(Boolean).length > 1
    );
  }

  get tabs() {
    return {
      apps: {
        route: ROUTES.APPS,
        active: this.router.currentRouteName === ROUTES.APPS,
        count: this.model.appsConnectionsTask?.lastSuccessful?.value.length || 0,
        display: this.model.canViewAppsConnections,
      },
      banks: {
        route: ROUTES.BANKS,
        active: this.router.currentRouteName === ROUTES.BANKS,
        count: this.model.banksConnectionsTask?.lastSuccessful?.value.length || 0,
        display: this.model.canViewBankConnections,
      },
      invoices: {
        route: ROUTES.INVOICES,
        active: this.router.currentRouteName === ROUTES.INVOICES,
        count: this.model.invoicesConnectionsTask?.lastSuccessful?.value.length || 0,
        display: this.model.canViewInvoicesConnections,
      },
    };
  }
}
