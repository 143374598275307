import { BENEFITS_LIST_WARNINGS } from 'qonto/constants/subscriptions';

function getNextStepId({ warnings = [] }) {
  let stepId = 'confirm';
  let hasWarnings = warnings.some(warning =>
    Object.values(BENEFITS_LIST_WARNINGS).includes(warning.code)
  );

  if (hasWarnings) {
    stepId = 'benefits-list';
  }
  return stepId;
}

export const subscription = {
  steps: {
    'discover-teams': {
      componentName: 'subscription-change/discover-teams',
      nextStepId: 'plans',
    },
    'discover-bookkeeping': {
      componentName: 'subscription-change/discover-bookkeeping',
      nextStepId: 'plans',
    },
    'discover-overview': {
      componentName: 'subscription-change/discover-overview',
      nextStepId: 'plans',
    },
    'discover-multi-accounts': {
      componentName: 'subscription-change/discover-multi-accounts',
      nextStepId: 'plans',
    },
    plans: {
      componentName: 'subscription-change/plans',
      nextStepId: ({ isFreeTrial, warnings = [] }) => {
        let stepId = getNextStepId({ warnings });
        if (isFreeTrial) stepId = 'confirm-trial';
        return stepId;
      },
    },
    'benefits-list': {
      componentName: 'subscription-change/benefits-list',
      nextStepId: 'confirm',
    },
    confirm: {
      componentName: 'subscription-change/confirm',
      nextStepId: ({ isInitialTrialUpsell, isItalyError }) => {
        let stepId = 'success';
        if (isItalyError) {
          stepId = 'success-italy';
        } else if (isInitialTrialUpsell) {
          stepId = 'success-trial';
        }
        return stepId;
      },
    },
    success: {
      componentName: 'subscription-change/success',
    },
    'success-italy': {
      componentName: 'addon-change/success-italy',
    },
    'confirm-trial': {
      componentName: 'subscription-change/confirm-trial',
      nextStepId: ({ isItalyError }) => {
        let stepId = 'success-trial';
        if (isItalyError) {
          stepId = 'success-italy';
        }

        return stepId;
      },
    },
    'success-trial': {
      componentName: 'subscription-change/success-trial',
    },
  },
  options: {
    enablePersistence: true,
  },
};
