import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import * as Sentry from '@sentry/ember';
import { task } from 'ember-concurrency';

import {
  LINKED_ATTACHMENTS_LIMIT,
  TRANSACTIONS_ATTACHMENTS_LIMIT_REACHED,
} from 'qonto/constants/transactions';
import { FlowSetup } from 'qonto/routes/flows/setup/internals';

class MatchInvoiceDataContext {
  @tracked hasMatchedTransaction;
  @tracked hasMarkedWithoutTransaction;
  @tracked hasBeenMatchedBefore;
  @tracked invoice;
  @tracked attachment;
  @tracked refererPage;
  @tracked abortOrigin;

  constructor({
    isPaidWithNoTransaction,
    hasBeenMatchedBefore,
    hasMarkedWithoutTransaction,
    hasMatchedTransaction,
    abortOrigin,
  }) {
    this.isPaidWithNoTransaction = isPaidWithNoTransaction;
    this.hasBeenMatchedBefore = hasBeenMatchedBefore;
    this.hasMarkedWithoutTransaction = hasMarkedWithoutTransaction;
    this.hasMatchedTransaction = hasMatchedTransaction;
    this.abortOrigin = abortOrigin;
  }
}

export default class MatchInvoiceFlowSetup extends FlowSetup {
  @service abilities;
  @service homePage;
  @service intl;
  @service organizationManager;
  @service router;
  @service segment;
  @service sentry;
  @service store;
  @service toastFlashMessages;

  dataContext = null;

  constructor() {
    super(...arguments);

    this.dataContext = new MatchInvoiceDataContext({
      isPaidWithNoTransaction: false,
      hasBeenMatchedBefore: false,
      hasMatchedTransaction: false,
      hasMarkedWithoutTransaction: false,
      abortOrigin: null,
    });
  }

  async beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'invoices');

    if (this.abilities.cannot('match receivableInvoice')) {
      return this.homePage.replaceWithDefaultPage();
    }
    let { invoiceId } = this.router.currentRoute.queryParams;
    let invoice = await this.store.findRecord('receivable-invoice', invoiceId);
    let attachment = await invoice.belongsTo('attachment').load();

    if (!attachment) {
      this.sentry.captureMessage(`Invoice ${invoiceId} is missing an attachment.`);
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      return await this.router.replaceWith('receivable-invoices.index');
    }

    let organizationSlug = this.organizationManager.organization.slug;
    let refererPage = `/organizations/${organizationSlug}/receivable-invoices/${invoiceId}`;

    Object.assign(this.dataContext, { invoice, attachment, refererPage });
  }

  @action
  onComplete({
    abortOrigin,
    isPaidWithNoTransaction,
    hasBeenMatchedBefore,
    hasMarkedWithoutTransaction,
    hasMatchedTransaction,
  }) {
    if (isPaidWithNoTransaction) {
      this.toastFlashMessages.toastSuccess(
        this.intl.t('receivable-invoices.invoice-modal.success.matched-paid-transaction')
      );
    } else if (hasMatchedTransaction && !hasBeenMatchedBefore) {
      this.toastFlashMessages.toastSuccess(
        this.intl.t('receivable-invoices.invoice-modal.success.matched-transaction')
      );
    } else if (hasMatchedTransaction && hasBeenMatchedBefore) {
      this.toastFlashMessages.toastSuccess(
        this.intl.t('receivable-invoices.invoice-modal.success.another-transaction')
      );
    } else if (hasMarkedWithoutTransaction) {
      this.toastFlashMessages.toastSuccess(
        this.intl.t('receivable-invoices.invoice-modal.success.without-transaction')
      );
    } else if (!abortOrigin) {
      this.segment.track('receivable_invoice_transaction_list_dropped', {
        step: 'payment_date_nav',
      });
    } else if (abortOrigin === 'error') {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
    return this._goBackToMainPage();
  }

  onAbortTask = task(this, { drop: true }, async ({ abortOrigin }, currentStep) => {
    if (abortOrigin === 'confirmation_modal') {
      this.segment.track('receivable_invoice_transaction_list_dropped', { origin: abortOrigin });
    }

    if (currentStep.id === 'select-transaction' && !abortOrigin) {
      this.segment.track('receivable_invoice_transaction_list_dropped', {
        step: 'transaction_list_nav',
      });
    }

    if (abortOrigin === TRANSACTIONS_ATTACHMENTS_LIMIT_REACHED) {
      this.toastFlashMessages.toastError(
        this.intl.t('receivable-invoices.match-transaction.error-toast', {
          invoiceLimit: LINKED_ATTACHMENTS_LIMIT,
        })
      );
    } else if (abortOrigin === 'error') {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }

    await this._goBackToMainPage();
  });

  _goBackToMainPage() {
    if (this.dataContext.refererPage) {
      return this.router.replaceWith(this.dataContext.refererPage);
    }
    return this.router.replaceWith('receivable-invoices.index');
  }
}
