import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class RequestUpgradePlan extends Route {
  @service abilities;
  @service router;

  beforeModel() {
    if (this.abilities.can('read request')) {
      return this.router.transitionTo('requests');
    }
  }
}
