import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class TransferIndexRoute extends Route {
  @service abilities;
  @service router;
  @service homePage;

  beforeModel() {
    super.beforeModel(...arguments);
    if (this.abilities.cannot('read transfers')) {
      return this.homePage.replaceWithDefaultPage();
    }
    this.router.transitionTo('transfers.pending');
  }
}
