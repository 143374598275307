/* eslint-disable ember/no-controller-access-in-routes */
import { NotFoundError } from '@ember-data/adapter/error';
import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';

import { MODULAR_TABLE_LOCAL_STORAGE_KEY } from 'qonto/constants/transactions';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import { deserializeArrayQP } from 'qonto/utils/query-params';
import transformKeys from 'qonto/utils/transform-keys';

export function queryParamsFilterGroup(
  { card, filter, initiator, mandate, settledAtFrom, _teamIds },
  { isPagoPaFilterEnabled }
) {
  let filterGroup = { conditional: 'and', expressions: [] };

  if (filter === 'missing-attachments') {
    let statuses = ['completed'];

    filterGroup.expressions.push(
      {
        values: ['missing'],
        operator: 'in',
        property: 'attachment_status',
      },
      {
        values: statuses,
        operator: 'in',
        property: 'status',
      }
    );
    if (isPagoPaFilterEnabled) {
      filterGroup.expressions.push({
        operator: 'not_in',
        property: 'operation_method',
        values: ['pagopa_payment'],
      });
    }

    filterGroup.expressions.push({
      operator: 'not_in',
      property: 'operation_method',
      values: ['biller'],
    });

    if (settledAtFrom) {
      filterGroup.expressions.push({
        values: [parseInt(settledAtFrom, 10)],
        operator: 'gte',
        property: 'settled_at',
      });
    }
  }

  if (filter === 'fees-subscriptions') {
    filterGroup.expressions.push(
      {
        values: ['fees', 'subscription'],
        operator: 'in',
        property: 'activity_tag',
      },
      {
        operator: 'interval',
        property: 'emitted_at',
        values: ['current_month'],
      }
    );
  }

  if (card) {
    filterGroup.expressions.push({
      property: 'card_id',
      operator: 'in',
      values: [card],
    });
  }

  if (initiator) {
    filterGroup.expressions.push({
      property: 'initiator_id',
      operator: 'in',
      values: [initiator],
    });
  }

  if (mandate) {
    filterGroup.expressions.push({
      property: 'mandate_id',
      operator: 'in',
      values: [mandate],
    });
  }

  if (_teamIds?.length) {
    filterGroup.expressions.push({
      property: 'team_id',
      operator: 'in',
      values: deserializeArrayQP(_teamIds),
    });
  }

  return filterGroup.expressions.length ? filterGroup : undefined;
}

const SORT_PROPERTIES = [
  'settled_at',
  'emitted_at',
  'display_at',
  'updated_at',
  'amount',
  'counterparty_name',
  'operation_method',
];

export default class TransactionsIndexRoute extends Route {
  @service toastFlashMessages;
  @service intl;
  @service notifierManager;
  @service store;
  @service abilities;
  @service router;
  @service organizationManager;
  @service sentry;
  @service associationKybPendingModal;
  @service periodicUpdate;
  @service productDiscovery;
  @service segment;

  queryParams = {
    _teamIds: {
      refreshModel: true,
    },
    bankAccounts: {
      refreshModel: true,
    },
    onlyMe: {
      refreshModel: true,
    },
    page: {
      refreshModel: true,
    },
    per_page: {
      refreshModel: true,
    },
    preset: {
      refreshModel: true,
    },
    query: {
      refreshModel: true,
    },
    settledAtFrom: {
      refreshModel: true,
    },
    sort_by: {
      refreshModel: true,
    },
  };

  beforeModel(transition) {
    let { highlight, showSuggestedAttachments } = transition.to.queryParams;

    if (
      (!highlight || this.abilities.cannot('access email forward attachment')) &&
      showSuggestedAttachments === 'true'
    ) {
      return this.router.replaceWith('transactions.index', {
        queryParams: {
          showSuggestedAttachments: false,
          highlight: null,
        },
      });
    }

    let { queryParams, name } = transition.from ?? {};
    if (name === 'protected-deeplinks' && queryParams?.action === 'transactions.show_comments') {
      safeLocalStorage.setItem(MODULAR_TABLE_LOCAL_STORAGE_KEY, 'true');
    }
  }

  async model(params) {
    let { highlight, onlyMe, preset, sort_by } = params;
    let shouldRedirect = false;
    let controller = this.controllerFor(this.routeName);
    let isPagoPaFilterEnabled = this.abilities.can('view filter pagopa');

    if (onlyMe === 'true') {
      params.initiator = this.organizationManager.membership.id;
    }

    let selectedPreset;

    if (preset) {
      try {
        let presets = await this.store.query('search-preset', {
          organization_id: this.organizationManager.organization.id,
        });
        selectedPreset = presets.find(({ id }) => id === params.preset);
      } catch (error) {
        let errorInfo = ErrorInfo.for(error);

        if (errorInfo.shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      }
    }

    if (sort_by && !SORT_PROPERTIES.includes(sort_by.split(':')[0])) {
      delete params.sort_by;
    }

    // Receipt reminders feature flag
    let filterGroup =
      queryParamsFilterGroup(params, { isPagoPaFilterEnabled }) || controller.filterGroup;
    let filters = selectedPreset?.query?.filter_group || filterGroup;

    controller.setProperties(params);

    // Modular table: ensure controller.filterGroup is set on load when "preset" QP is in the URL
    if (preset && selectedPreset) {
      controller.set('filterGroup', filters);
    }

    controller.fetchDataTask
      .perform(params, false, filters)
      .catch(ignoreCancelation)
      .catch(error => {
        let errorInfo = ErrorInfo.for(error);
        if (errorInfo.shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });

    controller.fetchCountDataTask
      .perform()
      .catch(ignoreCancelation)
      .catch(error => {
        let errorInfo = ErrorInfo.for(error);
        if (errorInfo.shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });

    if (highlight) {
      try {
        await this.store.findRecord('transaction', highlight);
      } catch (error) {
        let errorInfo = ErrorInfo.for(error);
        if (errorInfo.shouldSendToSentry && !(error instanceof NotFoundError)) {
          this.sentry.captureException(
            new Error(`Transaction fetch fails with status ${error.status}`, { cause: error })
          );
        }

        if (error instanceof NotFoundError) {
          let message = this.intl.t('errors.transaction_reversed');
          this.toastFlashMessages.toastError(message);
        } else if (error.status !== 401) {
          let message = this.intl.t('errors.unexpected_error_occured');
          this.toastFlashMessages.toastError(message);
        }
        shouldRedirect = true;
      }

      this.fetchHasNoQuoteAndInvoiceTask.perform().catch(ignoreCancelation);
    }

    let updateProcess = await this.periodicUpdate.fetchUpdateProcess();

    if (this.productDiscovery.isProductDiscoverySystemFeatureEnabled) {
      try {
        await this.productDiscovery.fetchUserActions({ updateProcess });
      } catch (error) {
        let errorInfo = ErrorInfo.for(error);
        if (errorInfo.shouldSendToSentry && errorInfo.httpStatus !== 404) {
          this.sentry.captureException(error);
        }
      }
    }

    return {
      shouldRedirect,
      selectedPreset,
      updateProcess,
      fetchHasNoQuoteAndInvoiceTask: this.fetchHasNoQuoteAndInvoiceTask,
    };
  }

  redirect(model) {
    if (model.shouldRedirect) {
      this.router.transitionTo('transactions.index', { queryParams: { highlight: null } });
    }
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.exportModal?.close?.();
      controller.fetchDataTask.cancelAll();
      controller.currentModal?.close();
      controller.currentModal = null;
      this.fetchHasNoQuoteAndInvoiceTask.cancelAll({ resetState: true });
      controller.setProperties({
        bankAccounts: '',
        highlight: null,
        sort_by: null,
        page: 1,
        per_page: 25,
        query: '',
        _items: '',
        filter: null,
        filterGroup: undefined,
        card: undefined,
        initiator: undefined,
        mandate: undefined,
        _teamIds: '',
        showSuggestedAttachments: false,
        preset: undefined,
      });
      safeLocalStorage.removeItem(
        `${this.organizationManager.organization.id}-transactions-filter-group`
      );
      controller.set('showPeriodicUpdateBanner', true);
    }
  }

  activate() {
    super.activate(...arguments);
    this.notifierManager.on('transaction.vat_status_updated', this, 'handleVATStatusUpdate');
    this.notifierManager.on('attachment.updated', this, 'updateAttachment');
    this.segment.track('transactions_page_loaded');
  }

  deactivate() {
    super.deactivate(...arguments);
    this.notifierManager.off('transaction.vat_status_updated', this, 'handleVATStatusUpdate');
    this.notifierManager.off('attachment.updated', this, 'updateAttachment');
  }

  handleVATStatusUpdate(payload) {
    let { id, vat } = payload.object;
    let transaction = this.store.peekRecord('transaction', id);

    transaction?.setProperties({ vat: transformKeys(vat) });
  }

  updateAttachment(payload) {
    let attachment = this.store.peekRecord('attachment', payload.object.id);
    let { data } = this.store.normalize('attachment', payload.object);

    if (attachment) {
      attachment.set('probativeAttachment', data.attributes.probativeAttachment);
    }
  }

  fetchHasNoQuoteAndInvoiceTask = dropTask(async () => {
    if (this.abilities.cannot('navigate receivable-invoice')) {
      return false;
    }

    let hasNoQuoteAndInvoice = false;

    try {
      let [quoteStats, receivableInvoiceStats] = await Promise.all([
        this.store.adapterFor('quote').getStats(),
        this.store.adapterFor('receivable-invoice').getStats(),
      ]);

      let hasQuote = quoteStats?.created.total > 0;
      let hasInvoice =
        receivableInvoiceStats?.created.paid > 0 || receivableInvoiceStats?.created.unpaid > 0;

      hasNoQuoteAndInvoice = !hasQuote && !hasInvoice;
    } catch {
      // ignore error
    }

    return hasNoQuoteAndInvoice;
  });

  @action
  willTransition() {
    this.controller.set('sidebarOpened', false);
    return true;
  }

  @action
  didTransition() {
    if (!this.productDiscovery.hasAction('kyb-association-action')) {
      this.associationKybPendingModal.show();
    }

    return true;
  }
}
