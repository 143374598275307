import Controller from '@ember/controller';
import { service } from '@ember/service';

import { LottiePlayer } from '@repo/design-system-kit';
import { task } from 'ember-concurrency';
import window from 'ember-window-mock';

import { SLACK_OAUTH_STATE_KEY } from 'qonto/constants/hub';

export default class HubApplicationDetailsSettingsController extends Controller {
  lottiePlayer = LottiePlayer;

  @service router;
  @service modals;
  @service toastFlashMessages;
  @service intl;

  queryParams = ['code', 'state'];
  code = '';

  settingsRouteName = 'settings.connect-hub.applications.hub-application.details.settings';

  get channelDetailsUrl() {
    let reg = /^(?:https?:\/\/)?(?:[^@/\n]+@)?(?:www\.)?([^:/?\n]+)/; // prettier-ignore
    let { configuration_url, channel } = this.model.hubConnection.params;

    let domainName = reg.exec(configuration_url)[0];
    let channelName = channel.replace('#', '');

    return `${domainName}/app_redirect?channel=${channelName}`;
  }

  get editChannelUrl() {
    let { organization, hubApplication } = this.model;
    let authenticationUrl = this.router.urlFor(
      this.settingsRouteName,
      organization.slug,
      hubApplication.slug
    );
    let redirectURI = `${window.location.origin}${authenticationUrl}`;
    let state = crypto.randomUUID();
    window.sessionStorage.setItem(SLACK_OAUTH_STATE_KEY, state);
    return `${hubApplication.params.auth_url}&state=${state}&redirect_uri=${encodeURIComponent(
      redirectURI
    )}`;
  }

  deleteRuleTask = task(async rule => {
    let result = await this.modals.open('popup/destructive', {
      title: this.intl.t('qonto-hub.connect.modals.delete-rule.title'),
      description: this.intl.t('qonto-hub.connect.modals.delete-rule.description'),
      cancel: this.intl.t('qonto-hub.connect.modals.delete-rule.cancel-btn'),
      confirm: this.intl.t('qonto-hub.connect.modals.delete-rule.delete-btn'),
    });

    if (result === 'confirm') {
      try {
        await rule.destroyRecord();
        this.toastFlashMessages.toastSuccess(
          this.intl.t('qonto-hub.connect.modals.delete-rule.delete-success-toast')
        );
      } catch {
        this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));
      }
    }
  });

  editRuleTask = task(async rule => {
    let result;

    if (rule.trigger === 'transaction') {
      result = await this.modals.open('connect/modals/edit-notification-rule', {
        hubNotificationRule: rule,
        title: this.intl.t('qonto-hub.connect.modals.edit-transaction-rule.title'),
        description: this.intl.t('qonto-hub.connect.modals.edit-transaction-rule.description'),
        cancel: this.intl.t('btn.cancel'),
        confirm: this.intl.t('qonto-hub.connect.modals.edit-transaction-rule.confirm'),
      });
    } else {
      result = await this.modals.open('connect/modals/edit-notification-rule', {
        hubNotificationRule: rule,
        title: this.intl.t('qonto-hub.connect.modals.edit-balance-rule.title'),
        description: this.intl.t('qonto-hub.connect.modals.edit-balance-rule.description'),
        cancel: this.intl.t('btn.cancel'),
        confirm: this.intl.t('qonto-hub.connect.modals.edit-balance-rule.confirm'),
      });
    }

    if (result === 'confirm') {
      this.router.replaceWith(this.router.currentRouteName);
    }
  });
}
