import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { DEPOSIT_CAPITAL_STEPPER_STEP } from 'qonto/constants/organization';

export default class CapitalUpload extends Route {
  @service capitalIncreaseManager;

  activate() {
    super.activate(...arguments);
    this.capitalIncreaseManager.setCurrentStep(DEPOSIT_CAPITAL_STEPPER_STEP.COMPANY_REGISTRATION);
  }

  model() {
    return this.capitalIncreaseManager.organization;
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    let { isKbisSubmitted, isDepositReleaseSent } = this.capitalIncreaseManager;

    controller.setProperties({
      isKbisSubmitted,
      isDepositReleaseSent,
      isUploading: false,
      organization: model,
      certify: false,
    });
  }
}
