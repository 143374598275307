import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { ParsingError, PrismicError } from '@prismicio/client';

export default class FinancingPartnersOfferRoute extends Route {
  @service router;
  @service prismic;
  @service segment;

  model({ uid }) {
    return this.getFinancingOffer(uid);
  }

  @action
  error(error) {
    this.segment.track('financing_detail_error');

    if (error.message === 'not-found') {
      this.router.replaceWith('/404');
    } else {
      return true;
    }
  }

  async getFinancingOffer(uid) {
    let financingOffer;

    try {
      financingOffer = await this.prismic.getByUID('financing_offer', uid, {
        fetchLinks:
          'financing_partner.name,financing_partner.description,financing_partner.about,financing_partner.about2,financing_partner.logo,financing_partner.resources_links,financing_type.name,financing_type.description,financing_type.learn_more_link',
      });

      return financingOffer.data;
    } catch (error) {
      if (
        error instanceof ParsingError ||
        error.message === 'An invalid API response was returned'
      ) {
        throw new Error('bad-request');
      }

      if (error instanceof PrismicError) {
        throw new Error('not-found');
      }
    }
  }
}
