import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { dropTask } from 'ember-concurrency';

import { CARD_LEVELS } from 'qonto/constants/cards';
import { FlowSetup } from 'qonto/routes/flows/setup/internals';

class RequestsCardsDataContext {
  cardLevel;
  entity;
  cardMaxLimit;

  constructor({ cardLevel, kycSubmitted }) {
    this.cardLevel = cardLevel;
    this.kycSubmitted = kycSubmitted;
  }
}

export default class RequestsCardsFlowSetup extends FlowSetup {
  @service router;
  @service cardsManager;
  @service store;
  @service capitalIncreaseManager;
  @service abilities;
  @service organizationManager;
  @service toastFlashMessages;
  @service flowLinkManager;
  @service intl;
  @service modals;

  constructor() {
    super(...arguments);
    let { cardLevel } = this.router.currentRoute.queryParams;

    let kycSubmitted = this.organizationManager.membership.kycSubmitted;

    this.dataContext = new RequestsCardsDataContext({ cardLevel, kycSubmitted });
  }

  async beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'spend-management');

    let cardMaxLimit, entity;

    if (this.abilities.cannot('create card requests')) {
      return this.router.replaceWith('requests');
    }

    if (!this.dataContext.cardLevel) {
      return this.router.transitionTo('requests.landing');
    }

    try {
      if (this.dataContext.cardLevel === CARD_LEVELS.FLASH) {
        ({ cardMaxLimit, entity } = await this._flashCardData());
      } else {
        ({ cardMaxLimit, entity } = await this._virtualCardData());
      }
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      return this.router.transitionTo('requests.landing');
    }

    this.dataContext.cardMaxLimit = cardMaxLimit;

    this.dataContext.entity = entity;
  }

  /**
   * @returns {Promise<{cardMaxLimit: *, entity: *}>}
   * @private
   */
  async _flashCardData() {
    let {
      flash: { payment_lifespan_limit_maximum: flashCardMaxLimit },
    } = await this.cardsManager.fetchCardsMaxLimitsTask.perform();

    let cardMaxLimit = flashCardMaxLimit;

    let entity = this.store.createRecord('request-flash-card', {
      organization: this.capitalIncreaseManager.organization,
    });

    return { cardMaxLimit, entity };
  }

  /**
   * @returns {Promise<{cardMaxLimit: *, entity: *}>}
   * @private
   */
  async _virtualCardData() {
    let {
      virtual: { payment_monthly_limit_maximum: virtualCardMaxLimit },
    } = await this.cardsManager.fetchCardsMaxLimitsTask.perform();

    let cardMaxLimit = virtualCardMaxLimit;

    let entity = this.store.createRecord('request-virtual-card', {
      organization: this.capitalIncreaseManager.organization,
    });

    return { cardMaxLimit, entity };
  }

  onComplete = () => {
    this.router.transitionTo('cards.requests', {
      queryParams: { highlight: this.dataContext.entity.id },
    });
  };

  onAbortTask = dropTask(async (context, flow) => {
    if (flow.id.includes('kyc-intro')) {
      this.router.transitionTo('requests', { queryParams: { highlight: context.entity.id } });

      return true;
    }
    let modalResult = await await this.modals.open('popup/destructive', {
      title: this.intl.t('flow-in-flow.modal.abort.title'),
      description: this.intl.t('flow-in-flow.modal.abort.subtitle'),
      cancel: this.intl.t('btn.back'),
      confirm: this.intl.t('btn.leave'),
    });

    if (modalResult === 'confirm') {
      this.dataContext.entity?.destroyRecord();

      this.router.transitionTo('requests');

      return true;
    }

    return false;
  });
}
