export default {
  "header": "qNh",
  "btn-preset": "qNf btn btn-filter mr-8 mb-8",
  "invoices-wrapper": "qNK",
  "statements-table": "qNG",
  "no-result": "qNr",
  "lottie-illustration": "qNb",
  "wrapper": "qNy",
  "access-denied": "qNU",
  "access-denied-illustration": "qNj",
  "application-card": "qNW",
  "presets-wrapper": "qNu",
  "error-wrapper": "qvS",
  "error-container": "qvc",
  "error-illustration": "qvq mb-32",
  "invoicing-info": "qvZ"
};
