import Controller from '@ember/controller';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { isTesting, macroCondition } from '@embroider/macros';
import { LottiePlayer } from '@repo/design-system-kit';
import { dropTask, task, timeout } from 'ember-concurrency';
import { alias } from 'macro-decorators';

import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import scrollIntoView from 'qonto/utils/scroll-into-view';

export default class PasswordResetController extends Controller {
  lottiePlayer = LottiePlayer;

  @service toastFlashMessages;
  @service intl;
  @service organizationManager;
  @service sensitiveActions;
  @service userManager;
  @service segment;
  @service deviceManager;
  @service router;
  @service sentry;
  @service errors;

  @tracked animationReady = false;
  @tracked email;

  @alias('model.hasPassword') hasPassword;

  scrollToErrorTask = task(async () => {
    await timeout(macroCondition(isTesting()) ? 0 : 500);

    scrollIntoView('.has-danger');
  });

  resetPasswordTask = dropTask(async userPassword => {
    try {
      let data = await userPassword.resetPassword();
      if (this.hasPassword) {
        this.segment.track('reset_password_completed');
      } else {
        this.segment.track('set_new_pwd_clicked');
      }
      this.email = data.user.email;
    } catch (error) {
      if (error.errors?.[0]?.status === '403') {
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
        this.scrollToErrorTask.perform().catch(ignoreCancelation);
      } else {
        throw error;
      }
    }
  });

  signInTask = dropTask(async userPassword => {
    try {
      await this.sensitiveActions.runTask.perform(this.authenticateTask, userPassword);
    } catch (error) {
      this.toastFlashMessages.toastError(
        this.errors.messageForStatus(error) || this.intl.t('toasts.errors.server_error')
      );
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  authenticateTask = dropTask(async userPassword => {
    await this.userManager.setup({ email: this.email, password: userPassword.password });
    await this.organizationManager.setupTask.perform();
    await this.organizationManager.loadRegisteringOrganizations();
    this.router.replaceWith('protected.index');
  });
}
