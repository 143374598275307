import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import * as Sentry from '@sentry/ember';
import dayjs from 'dayjs';

import { FlowSetup } from 'qonto/routes/flows/setup/internals';

export const ORIGIN = {
  PRICE_PLAN_DETAILS: 'settings.price-plan.details',
  ORGANIZATION_PROFILE: 'settings.organization-profile',
  COMPANY_PROFILE: 'settings.company-profile',
};

class SubscriptionCloseFlowDataContext {
  @tracked reasonList = [];
  @tracked deactivationDueDate = null;
  @tracked closingReasonCategory = null;
  @tracked closingReasonSubCategory = null;
  @tracked closingReasonDetails = null;
  @tracked closingReasonDetailsOther = null;
}

export default class SubscriptionCloseFlowSetup extends FlowSetup {
  @service abilities;
  @service intl;
  @service router;
  @service store;
  @service subscriptionManager;
  @service zendeskWidget;

  constructor() {
    super(...arguments);
    let dataContext = new SubscriptionCloseFlowDataContext();

    dataContext.nextBillingDate = dayjs(this.subscriptionManager.nextRecurrenceDate)
      .subtract(1, 'day')
      .toDate();
    this.dataContext = dataContext;
  }

  async beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'pricing');
    let { origin = ORIGIN.PRICE_PLAN_DETAILS } = this.router.currentRoute.queryParams;

    if (this.abilities.cannot('deactivate organization')) {
      return this.router.replaceWith(origin);
    }

    this.dataContext.origin = origin;
    this.dataContext.reasonList = await this.store
      .adapterFor('organization')
      .listDeactivationReasons();
  }

  onAbort() {
    this.router.transitionTo(this.dataContext.origin);
    this.zendeskWidget.show();
  }
  onComplete() {
    this.router.transitionTo(this.dataContext.origin);
    this.zendeskWidget.show();
  }
}
