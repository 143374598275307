import { action } from '@ember/object';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';
import { TrackedArray } from 'tracked-built-ins';

import { CARD_LEVELS } from 'qonto/constants/cards';
import { FlowSetup } from 'qonto/routes/flows/setup/internals';

class RequestsVirtualCardKYCDataContext {
  documentType = null;
  documents = new TrackedArray();
}

export default class RequestsVirtualCardKycFlowSetup extends FlowSetup {
  @service flowLinkManager;
  @service router;
  @service modals;
  @service intl;

  constructor() {
    super(...arguments);

    this.dataContext = new RequestsVirtualCardKYCDataContext();
  }

  @action
  onComplete() {
    this.flowLinkManager.transitionTo({
      name: 'requests-cards',
      stepId: 'budget',
      queryParams: { cardLevel: CARD_LEVELS.VIRTUAL },
    });
  }

  onAbortTask = dropTask(async () => {
    let modalResult = await this.modals.open('popup/destructive', {
      title: this.intl.t('flow-in-flow.modal.abort.title'),
      description: this.intl.t('flow-in-flow.modal.abort.subtitle'),
      cancel: this.intl.t('btn.back'),
      confirm: this.intl.t('btn.leave'),
    });

    if (modalResult === 'confirm') {
      this.router.transitionTo('requests.landing');

      return true;
    }

    return false;
  });
}
