import { action } from '@ember/object';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { TrackedObject } from 'tracked-built-ins';

import { FlowSetup } from 'qonto/routes/flows/setup/internals';

class InvoicesProductsImportFlowDataContext {
  nextStepId = null;
  uploadedFiles = [];
  xlsFile = null;
  mapping = new TrackedObject({});
  status = { isMapped: false };
}

export default class InvoicesProductsImportFlowSetup extends FlowSetup {
  @service router;
  @service flow;

  constructor() {
    super(...arguments);
    this.dataContext = new InvoicesProductsImportFlowDataContext();
  }

  beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'invoices');
  }

  @action
  onComplete() {
    this._goBackToMainPage();
  }

  @action
  onAbort() {
    this._goBackToMainPage();
  }

  _goBackToMainPage() {
    if (this.flow.refererPage && this.flow.refererPage !== '/') {
      return this.router.transitionTo(this.flow.refererPage);
    }
    return this.router.replaceWith('products.index');
  }
}
