import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class CardsShowRoute extends Route {
  @service organizationManager;
  @service router;

  redirect(_model, transition) {
    let organizationSlug = this.organizationManager.organization.slug;

    let highlight = transition.to.queryParams.card_id;
    if (highlight) {
      return this.router.replaceWith('cards.index', organizationSlug, {
        queryParams: { highlight },
      });
    }
    return this.router.replaceWith('cards.index', organizationSlug);
  }
}
