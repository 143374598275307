import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

export default class SettingsPricePlanRoute extends Route {
  @service abilities;
  @service router;

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'pricing');

    if (this.abilities.cannot('view subscription')) {
      this.router.replaceWith('settings');
    }
  }
}
