export const virtualCardKyc = {
  steps: {
    intro: {
      componentName: 'requests/cards/kyc-intro',
      nextStepId: 'document-selection',
    },
    'document-selection': {
      componentName: 'kyc/document-selection',
      nextStepId: 'upload-documents',
    },
    'upload-documents': {
      componentName: 'kyc/upload-documents',
      nextStepId: 'user-info',
    },
    'user-info': {
      componentName: 'kyc/user-info',
      nextStepId: 'success',
    },
    success: {
      componentName: 'requests/cards/kyc-success',
    },
  },
};
