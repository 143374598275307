export default {
  "mandates": "qGl",
  "mandates-empty": "qGX",
  "header": "qGC",
  "header-empty": "qGk",
  "header-content": "qGJ",
  "search": "qGs",
  "search-bar": "qGF",
  "body": "qGg",
  "isEmpty": "qGD",
  "left-section": "qGV",
  "mandates-list": "qGL",
  "mandate-suspended": "qGT",
  "list-title": "qGz caption-bold",
  "list-empty": "qGa",
  "empty-illustration": "qGH",
  "empty-title": "qGO title-3",
  "empty-description": "qGm body-2",
  "body-details": "qGp",
  "l-app-content__page-header": "qGx",
  "pagination-footer": "qGw"
};
