import Route from '@ember/routing/route';
import { schedule } from '@ember/runloop';
import { service } from '@ember/service';

import { reads } from 'macro-decorators';

import { ORIGIN } from 'qonto/constants/cards';
import { ORGA_STATUS } from 'qonto/constants/organization';

export default class PhysicalOrVirtualRoute extends Route {
  @service abilities;
  @service cardsManager;
  @service toastFlashMessages;
  @service intl;
  @service menu;
  @service organizationManager;
  @service router;

  @reads('organizationManager.organization') currentOrganization;

  beforeModel() {
    let canCreateCard = this.abilities.can('create card');
    let canAccessCreationFlow = this.abilities.can('access creation flow card');
    if (!canCreateCard || !canAccessCreationFlow) {
      this.router.replaceWith('cards');
    }

    if (this.currentOrganization.status === ORGA_STATUS.SUSPENDED) {
      this.router.replaceWith('cards');
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  }

  async model() {
    let { estimates } = await this.cardsManager.fetchCardsCostsTask.perform(
      this.organizationManager.organization.id
    );

    return estimates;
  }

  activate() {
    schedule('afterRender', () => {
      if (this.menu.isVisible) {
        this.menu.hide();
      }
    });
  }

  deactivate() {
    this.menu.show();
  }

  /**
   * Resets the inviteeMembershipId query param when entering the route from another origin than ORIGIN.MEMBER_INVITATION.
   *
   * @param {Controller} controller
   * @param {T | undefined} context
   * @param {Transition} _transition
   */
  setupController(controller, context, _transition) {
    super.setupController(controller, context, _transition);
    if (![ORIGIN.MEMBER_INVITATION].includes(controller.origin)) {
      controller.resetQueryParams();
    }
  }
}
