import Controller from '@ember/controller';

import { bool } from 'macro-decorators';

const thirdpartyApplicationIconsList = {
  slack: '/illustrations/qonto-hub/hub-application/slack-round.svg',
};

export default class ConnectHubHubApplicationAuthenticationController extends Controller {
  @bool('model.error') hasError;

  queryParams = ['code', 'error', 'state'];

  detailsRouteName = 'settings.connect-hub.applications.hub-application.details.index';
  hubApplicationRouteName = 'settings.connect-hub.applications.hub-application';

  get lottiePath() {
    if (this.hasError) {
      return '/lotties/qonto-hub/qontohub-slack-noconnection.json';
    } else {
      return '/lotties/qonto-hub/qontohub-slack-connection.json';
    }
  }

  get thirdpartyApplicationIcon() {
    let slug = this.model.hubApplication.slug;

    return thirdpartyApplicationIconsList[slug];
  }
}
