import Route from '@ember/routing/route';

import { TrackedArray } from 'tracked-built-ins';

export default class InvitationsShowMemberKycUploadRoute extends Route {
  queryParams = {
    documentType: {
      refreshModel: true,
    },
  };

  model({ documentType }) {
    let model = this.modelFor('invitations.show');

    if (model.documentType !== documentType) {
      model.documents = new TrackedArray();
      model.documentType = documentType;
    }

    return model;
  }
}
