import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

export default class ConfirmCompanyProfile extends Controller {
  @service deviceManager;
  @service sessionManager;

  @action
  handleInvalidateSession() {
    this.sessionManager.invalidate();
  }
}
