export default {
  "title": "ZSR title-3",
  "personal-profile-container": "ZSQ",
  "personal-profile-section": "ZSe",
  "member-info-title": "ZSB",
  "kyc-complete-profile": "ZSE",
  "info-fields": "ZSd",
  "field": "ZSI",
  "address-fields": "ZSt",
  "field-span": "ZSM",
  "input": "ZSP",
  "cancel": "ZSl"
};
