import { BANK_CONNECTION_OUTCOME } from 'qonto/constants/connect';

export const externalAccountImport = {
  steps: {
    select: {
      componentName: 'accounts/external/import/select',
      nextStepId: 'consent',
    },
    consent: {
      componentName: 'accounts/external/import/consent',
      nextStepId: ({ outcome }) => {
        return Object.values(BANK_CONNECTION_OUTCOME).includes(outcome) ? outcome : 'select';
      },
    },
    success: {
      componentName: 'accounts/external/import/confirmation/success',
    },
    error: {
      componentName: 'accounts/external/import/confirmation/error',
    },
  },
  options: {
    enablePersistence: true,
  },
};
