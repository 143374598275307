export default {
  "mt-16": "qjO",
  "title": "qjm",
  "avatar": "qjp",
  "edit-organization-form": "qjx",
  "dropdown-content": "qjw",
  "city-block": "qjN",
  "zip-code-input": "qjv",
  "cancel-button": "qjo",
  "error-message": "qjn",
  "nature-of-operations": "qji",
  "error": "qjA",
  "legal-code": "qjY",
  "activity-type": "qjh"
};
