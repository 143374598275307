export default {
  "container": "qUg",
  "form-container": "qUD",
  "title": "qUV",
  "title-border": "qUL",
  "tabs-container": "qUT",
  "preview-container": "qUz",
  "email-preview-container": "qUa",
  "close-button": "qUH btn btn--icon-only btn--tertiary btn--large"
};
