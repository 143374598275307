import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class CustomLabelsController extends Controller {
  @service organizationManager;
  @service store;
  @service intl;

  @tracked activeLabel = null;

  get availableLists() {
    return this.model.filter(list => !list.isDeleted);
  }

  get overviewEmptyTranslation() {
    return this.intl.t('empty-states.custom-labels.overview-body');
  }

  @action
  handleCreateLabel(list) {
    if (list.labels?.at(-1)?.isNew) {
      return;
    }

    let label = this.store.createRecord('label', {
      labelList: list,
      organization: this.organizationManager.organization,
    });

    list.labels.push(label);
    this.handleFocus(label);
    return label;
  }

  @action
  handleFocus(label) {
    if (this.activeLabel && !this.activeLabel.isDestroying && !this.activeLabel.isDestroyed) {
      this.activeLabel.rollbackAttributes();
    }
    this.activeLabel = label;
  }
}
