import Route from '@ember/routing/route';
import { service } from '@ember/service';

import window from 'ember-window-mock';

export default class TopupSuccessRoute extends Route {
  @service organizationManager;
  @service router;
  @service segment;
  @service payment;

  beforeModel() {
    window.sessionStorage.removeItem('topup_context');

    let { topupsStatus } = this.organizationManager.membership;

    if (topupsStatus === 'completed') {
      return this.router.transitionTo('onboarding.topup.completed');
    } else {
      this.segment.track('onboarding_top_up_3ds_success');
      this.payment.onboardingCardSkipped = true;
    }
  }
}
