import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { hash } from 'rsvp';

import { REQUEST_EVENTS } from 'qonto/constants/listeners';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class TransfersRequestsRequesterMultiTransferRoute extends Route {
  @service notifierManager;
  @service store;
  @service abilities;
  @service sentry;
  @service organizationManager;

  activate() {
    this.notifierManager.on(REQUEST_EVENTS.ADMIN_APPROVED, this, 'settled');
    this.notifierManager.on(REQUEST_EVENTS.ADMIN_DECLINED, this, 'settled');
  }

  queryParams = {
    highlight: {
      refreshModel: false,
    },
  };

  // websocket events callbacks
  settled(payload) {
    if (this.currentModel?.request.id === payload.object.id) {
      this.refresh();
    }
  }

  async model({ request_id }) {
    let [request, transfers] = await Promise.all([
      this.store.findRecord('request-multi-transfer', request_id),
      this.store.query('request-multi-transfer-transfer', {
        includes: ['attachments'],
        request_multi_transfer_id: request_id,
        per_page: 200,
      }),
    ]);

    let potentialBudgetAllocation = null;

    if (this.abilities.can('read budget') && request.pending) {
      try {
        let budgetResults = await this.store.adapterFor('budget').search({
          initiatorId: this.organizationManager.membership.id,
          scheduledDate: request.scheduledDate,
        });

        potentialBudgetAllocation = budgetResults[0];
      } catch (error) {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      }
    }

    return hash({
      request,
      transfers,
      potentialBudgetAllocation,
    });
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.highlight = '';
    }
  }

  deactivate() {
    this.notifierManager.off(REQUEST_EVENTS.ADMIN_DECLINED, this, 'settled');
    this.notifierManager.off(REQUEST_EVENTS.ADMIN_APPROVED, this, 'settled');
  }
}
