export default {
  "page-wrapper": "qiV",
  "container": "qiL",
  "spinner-container": "qiT",
  "left-panel": "qiz",
  "form": "qia",
  "left-content": "qiH",
  "form-footer": "qiO",
  "delete-button": "qim",
  "header": "qip",
  "preview-container": "qix",
  "email-preview": "qiw",
  "close-button": "qiN btn btn--icon-only btn--tertiary btn--large"
};
