import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { FlowSetup } from 'qonto/routes/flows/setup/internals';

class PayLaterApplicationFlow {
  constructor({ eligibilityData, signature, origin }) {
    this.eligibilityData = eligibilityData;
    this.signature = signature;
    this.origin = origin;
  }
}

export default class PayLaterApplicationFlowSetup extends FlowSetup {
  @service router;
  @service segment;
  @service abilities;
  @service userManager;
  @service financing;

  constructor() {
    super(...arguments);

    this.dataContext = new PayLaterApplicationFlow({ signature: { url: '', status: '' } });
    safeLocalStorage.setItem(`pay-later-intro-${this.userManager.currentUser.id}`, true);
  }

  async beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'financing');

    if (!this.financing.hasEligibilityData) {
      return this.router.replaceWith('financing.pay-later');
    }

    this.dataContext.eligibilityData = await this.financing.getLastPayLaterEligibility();

    this.dataContext.origin = this.router.currentRoute.queryParams.origin ?? 'navigation';

    this.segment.track('pay-later_intro_displayed', { origin: this.dataContext.origin });
  }

  onComplete() {
    this.router.transitionTo('financing.pay-later');
  }

  onAbort() {
    this.router.transitionTo('financing.pay-later');
  }
}
