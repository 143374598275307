import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';

import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { task } from 'ember-concurrency';

export default class CardsSettingsPaymentLimitsController extends Controller {
  @service intl;
  @service toastFlashMessages;
  @service modals;
  @service organizationManager;
  @service router;
  @service sentry;

  submitTask = task(
    { drop: true },
    waitFor(async shouldDisplayMetalSuccessModal => {
      let { card } = this.model;

      try {
        await card.changeLimits();

        if (shouldDisplayMetalSuccessModal) {
          this.modals.open('card/modals/metal-success', {
            isFullScreenModal: true,
            paymentMonthlyLimit: card.paymentMonthlyLimit,
            onClose: close => {
              this.goBack();
              close();
            },
          });
        } else {
          this.toastFlashMessages.toastInfo(this.intl.t('toasts.cards.limits-updated'));
          this.goBack();
        }
      } catch (error) {
        if (hasMFAError(error?.errors)) {
          throw error;
        }

        this.sentry.captureException(error);
        card.rollbackAttributes();
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      }
    })
  );

  @action goBack() {
    let { queryParams } = this.model;
    return this.router.transitionTo('cards.index', this.organizationManager.organization.slug, {
      queryParams,
    });
  }
}
