import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

export default class InvitationsShowMemberKycDetailsController extends Controller {
  @service router;

  @action goToAddressStep() {
    return this.router.transitionTo('invitations.show.member.address');
  }

  @action
  goBackToUploadStep() {
    let { documentType } = this.model;

    this.router.transitionTo('invitations.show.member.kyc.upload', {
      queryParams: { documentType },
    });
  }
}
