import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { hash } from 'rsvp';

import { DEPOSIT_CAPITAL_STEPPER_STEP } from 'qonto/constants/organization';

export default class CapitalShareholdersIndexRoute extends Route {
  @service capitalIncreaseManager;
  @service store;

  activate() {
    this.capitalIncreaseManager.setCurrentStep(DEPOSIT_CAPITAL_STEPPER_STEP.SHAREHOLDERS);
  }

  model() {
    let { id: organizationId } = this.capitalIncreaseManager.organization;

    let stakeholders = this.store.query('stakeholder', {
      organization_id: organizationId,
    });
    let legalEntities = this.store.query('legal-entity', {
      organization_id: organizationId,
    });

    return hash({
      stakeholders,
      legalEntities,
    });
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.resetErrors();
    }
  }
}
