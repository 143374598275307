import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';

import { ignoreCancelation } from 'qonto/utils/ignore-error';
export default class SettingsPricePlanPaymentMethodsRoute extends Route {
  @service abilities;
  @service featuresManager;
  @service organizationManager;
  @service router;
  @service segment;
  @service store;

  activate() {
    this.segment.track('payment-methods_page_displayed');
  }

  beforeModel() {
    if (
      !this.abilities.can('manage organization') ||
      !this.featuresManager.isEnabled('storedCards')
    ) {
      this.router.replaceWith('settings.price-plan');
    }
  }

  model() {
    this.fetchPaymentMethodsTask.perform().catch(ignoreCancelation);
    return { fetchPaymentMethodsTask: this.fetchPaymentMethodsTask };
  }

  fetchPaymentMethodsTask = dropTask(async () => {
    let paymentMethods = await this.store.query('payment-method', {
      organization_id: this.organizationManager.organization.id,
    });
    return { paymentMethods };
  });
}
