export default {
  "bank-accounts": "qjt l-app-content__wrapper",
  "header-wrapper": "qjM",
  "scrolled-top": "qjP",
  "header": "qjl l-app-content__page-header",
  "create-account-button": "qjX btn btn--primary",
  "main": "qjC",
  "accounts": "qjk",
  "subtitle-container": "qjJ",
  "subtitle": "qjs title-3 mb-16",
  "amount": "qjF body-2",
  "list--cashbeeSavings": "qjg"
};
