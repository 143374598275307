import Controller from '@ember/controller';
import { action } from '@ember/object';
import { getOwner, setOwner } from '@ember/owner';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

class WizardContext {
  submitOnStep = 'legalEntityInfos';

  constructor(owner, { legalEntity, wizardSuccess }) {
    setOwner(this, owner);

    this.legalEntity = legalEntity;
    this.wizardSuccess = wizardSuccess;
  }

  saveLegalEntityTask = dropTask(async ({ legalEntity }) => {
    await legalEntity.save();
  });
}

export default class CapitalLegalOwnerController extends Controller {
  @service intl;
  @service modals;
  @service router;

  queryParams = ['step'];

  @tracked step = 'info';

  get steps() {
    return [
      {
        id: 'info',
        name: this.intl.t('on-boarding.capital-deposit.shareholders.add-legal-shareholder.step'),
        componentName: 'capital-deposit/legal-entity/infos',
      },
      {
        id: 'success',
        name: this.intl.t('on-boarding.capital-deposit.success'),
        componentName: 'capital-deposit/wizard-success',
      },
    ];
  }

  get hideGoBack() {
    return this.step === 'success';
  }

  get wizardContext() {
    return new WizardContext(getOwner(this), {
      legalEntity: this.model,
      wizardSuccess: {
        title: this.intl.t(
          'on-boarding.capital-deposit.shareholders.owner-declaration.success.title'
        ),
        text: this.intl.t(
          'on-boarding.capital-deposit.shareholders.owner-declaration.success.sub-title'
        ),
      },
    });
  }

  onCloseTask = dropTask(async () => {
    await this.modals.open('popup/destructive', {
      title: this.intl.t('on-boarding.capital-deposit.shareholders.add-shareholder.warning.title'),
      description: this.intl.t(
        'on-boarding.capital-deposit.shareholders.add-shareholder.warning.description'
      ),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('btn.delete'),
      confirmTask: this.modalConfirmTask,
    });
  });

  modalConfirmTask = dropTask(async close => {
    this.wizardContext.legalEntity.deleteRecord();
    this.router.transitionTo('capital.shareholders.index');
    await close();
  });

  @action onWizardComplete() {
    this.router.transitionTo('capital.shareholders.index');
  }
}
