import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

export default class InvitationsShowMemberKycUploadController extends Controller {
  @service router;

  @action
  goToDetailsStep() {
    return this.router.transitionTo('invitations.show.member.kyc.details');
  }

  @action
  goBackToSelectStep() {
    this.router.transitionTo('invitations.show.member.kyc.select');
  }
}
