import Controller from '@ember/controller';
import { service } from '@ember/service';
import { capitalize } from '@ember/string';

export default class CapitalStatusController extends Controller {
  @service capitalIncreaseManager;

  get depositCapitalStatus() {
    return this.organization.depositCapitalStatus;
  }

  get onboardingPartner() {
    return capitalize(this.organization.onboardingPartner ?? '');
  }

  get organization() {
    return this.capitalIncreaseManager.organization;
  }

  get isOnboardingPartnerAccountant() {
    return this.organization.onboardingPartnerType === 'accountant';
  }
}
