import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { LottiePlayer } from '@repo/design-system-kit';

export default class TopupSuccessController extends Controller {
  lottiePlayer = LottiePlayer;

  @service homePage;
  @service intl;
  @service organizationManager;
  @service toastFlashMessages;

  @action
  handleOnClick() {
    this.toastFlashMessages.toastInfo(this.intl.t('on-boarding.top-up.toast.pending'));
    this.homePage.visitDefaultPage(this.organizationManager.organization.slug);
  }
}
