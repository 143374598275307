import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { RISKY_LOGIN_STATES } from '@qonto/qonto-sca/constants/risky-login';
import * as Sentry from '@sentry/ember';

import { authBaseURL } from 'qonto/constants/hosts';
import { ErrorInfo } from 'qonto/utils/error-info';

const RISKY_LOGIN_ERRORS = {
  EXPIRED_DEVICE_EMAIL_VERIFICATION_CODE: 'expired_device_email_verification_code',
  INVALID_DEVICE: 'invalid_device',
  INVALID_DEVICE_EMAIL_VERIFICATION_CODE: 'invalid_device_email_verification_code',
};

export default class EmailVerificationRoute extends Route {
  @service networkManager;
  @service sentry;

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'account-management');
  }

  deactivate() {
    Sentry.getCurrentScope().setTag('CFT', 'no-cft');
  }

  async model(params) {
    let { verification_code } = params;

    let stateToShow;

    try {
      let response = await this.networkManager.rawRequest(
        `${authBaseURL}/v5/device_verification_emails/${verification_code}/validate`,
        { method: 'POST' }
      );

      switch (response.status) {
        case 200:
        case 204:
          stateToShow = RISKY_LOGIN_STATES.DEVICE_CONFIRMED;
          break;
        default:
          stateToShow = RISKY_LOGIN_STATES.GENERIC_ERROR;
          break;
      }
    } catch (error) {
      if (error?.status === 422) {
        switch (error?.responseJSON?.errors?.[0]?.code) {
          case RISKY_LOGIN_ERRORS.EXPIRED_DEVICE_EMAIL_VERIFICATION_CODE:
            stateToShow = RISKY_LOGIN_STATES.TIMEOUT;
            break;
          case RISKY_LOGIN_ERRORS.INVALID_DEVICE:
          case RISKY_LOGIN_ERRORS.INVALID_DEVICE_EMAIL_VERIFICATION_CODE:
            stateToShow = RISKY_LOGIN_STATES.WRONG_DEVICE;
            break;
          default:
            stateToShow = RISKY_LOGIN_STATES.GENERIC_ERROR;
            break;
        }
      } else {
        let errorInfo = ErrorInfo.for(error);
        if (errorInfo.shouldSendToSentry) {
          this.sentry.captureException(error);
        }
        stateToShow = RISKY_LOGIN_STATES.GENERIC_ERROR;
      }
    }

    return { stateToShow };
  }
}
