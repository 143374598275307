import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class InsuranceHubIndexRoute extends Route {
  @service insuranceManager;
  @service segment;

  controllerName = 'insurance-hub';

  async model() {
    return await this.insuranceManager.getInsuranceProducts();
  }

  afterModel() {
    this.segment.track('insurance-hub_displayed');
  }
}
