import Route from '@ember/routing/route';
import { next } from '@ember/runloop';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

export default class SignInRoute extends Route {
  @service sessionManager;
  @service modals;
  @service intl;
  @service headData;

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'onboarding');
    this.sessionManager.prohibitAuthentication();
  }

  afterModel() {
    this.headData.title = this.intl.t('sign_in.page_title');
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.arrivedOnPageAt = Date.now();
    if (controller.isUpdateSessionModalShown) {
      this.modals.open('popup/security/update-email/update-session');
      next(() => (controller.isUpdateSessionModalShown = false));
    }
  }

  resetController() {
    super.resetController(...arguments);
    this.headData.title = null;
  }
}
