import { InvalidError } from '@ember-data/adapter/error';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { dropTask, task } from 'ember-concurrency';
import { reads } from 'macro-decorators';

export default class TransfersInternalEditController extends Controller {
  @service toastFlashMessages;
  @service intl;
  @service modals;
  @service organizationManager;
  @service router;

  @reads('organizationManager.organization') organization;

  @action handleGoBack() {
    if (this.model.hasDirtyAttributes) this.model.rollbackAttributes();

    this.router.transitionTo('transfers.pending', {
      queryParams: {
        highlight: this.model.id,
      },
    });
  }

  handleConfirmTask = task(async transfer => {
    if (transfer.beneficiary.get('creditBankAccountId') && !transfer.creditBankAccountId) {
      transfer.creditBankAccountId = transfer.beneficiary.get('creditBankAccountId');
    }

    let { errors, warnings } = await transfer.confirm();

    this.modals.open('transfers/internal/confirm-modal', {
      transfer,
      warnings,
      errors,
      disabledConfirm: Boolean(errors.length),
      confirmTransferTask: this.confirmTransferTask,
    });
  });

  confirmTransferTask = dropTask(async () => {
    let transfer = this.model;
    transfer.addIdempotencyHeader();
    try {
      await transfer.save();

      // destroy attachments,
      // indeed we send the attachments within the transfer
      // and get back attachmentsIds.
      let savedAttachements = transfer.get('attachments').filter(item => item.isNew);
      savedAttachements.forEach(a => a.unloadRecord());

      this.toastFlashMessages.toastInfo(this.intl.t('toasts.transfer_updated'), 'transfer_create');
      this.router.transitionTo('transfers.index');
    } catch (error) {
      if (error instanceof InvalidError) {
        if (this.modals.count) {
          this.modals.top.close();
        }
      }
      throw error;
    } finally {
      transfer.removeIdempotencyHeader();
    }
  });

  @action selectAccountToCredit({ id, name, iban }) {
    this.model.setProperties({
      name,
      iban,
      creditBankAccountId: id,
      activityTag: 'treasury_and_interco',
      reference: this.model.reference || this.intl.t('transfers.new.internal-transfer'),
    });
  }
}
