import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { CARD_LEVELS, CARDS_LIMITS_ROUTES } from 'qonto/constants/cards';

export default class CardsSettingsRoute extends Route {
  @service menu;
  @service store;
  @service cardsManager;
  @service router;
  @service organizationManager;
  @service toastFlashMessages;
  @service intl;
  @service abilities;

  activate() {
    this.menu.hide();
  }

  deactivate() {
    let { card } = this.currentModel;
    if (!this.store.isDestroyed) {
      card.rollbackAttributes();
    }

    this.menu.show();

    this.cardsManager.fetchCardsMaxLimitsTask.cancelAll();
  }

  async model({ id: cardId }, transition) {
    let card = await this.store.findRecord('card', cardId);
    let { statuses, card_levels, team_ids, ...rawQueryParams } = transition.from?.queryParams || {};
    let queryParams = { ...rawQueryParams };

    // Array query params need to be parsed from strings
    let arrayFilters = { statuses, card_levels, team_ids };
    Object.keys(arrayFilters).forEach(param => {
      let value = arrayFilters[param];
      if (value) {
        queryParams[param] = JSON.parse(value);
      }
    });

    let cardsMaxLimits = CARDS_LIMITS_ROUTES.includes(transition.to.localName)
      ? await this.cardsManager.fetchCardsMaxLimitsTask.perform(
          card.holder.get('id'),
          card.cardLevel === CARD_LEVELS.ADVERTISING ? cardId : null
        )
      : null;

    return {
      card,
      cardsMaxLimits,
      queryParams,
    };
  }

  afterModel(model, transition) {
    let { localName: routeName } = transition.to;
    let { canUpdateOperationalCardSettings, canUpdateNickname } = this.abilities.abilityFor(
      'card',
      model.card
    );

    if (
      (routeName !== 'nickname' && !canUpdateOperationalCardSettings) ||
      (routeName === 'nickname' && !canUpdateNickname)
    ) {
      this.router.replaceWith('cards');
    }
  }

  @action
  error(error, transition) {
    if (CARDS_LIMITS_ROUTES.includes(transition.to.localName)) {
      let cardId = transition.to.parent.params.id;
      let organizationSlug = this.organizationManager.organization.slug;

      this.router.transitionTo('cards.index', organizationSlug, {
        queryParams: { highlight: cardId },
      });

      let errorMessage = this.intl.t('toasts.errors.server_error');
      this.toastFlashMessages.toastError(errorMessage);
    } else {
      // Keep default behavior for other errors
      return true;
    }
  }
}
