import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { TrackedArray } from 'tracked-built-ins';

export default class MembershipEditRoute extends Route {
  @service menu;

  activate() {
    if (this.menu.isVisible) {
      this.menu.hide();
    }
  }

  deactivate() {
    if (!this.menu.isVisible) {
      this.menu.show();
    }
  }

  async model({ membership_id }) {
    let { updateProcess, ownerChangeRequest, representativeChangeRequests, organization } =
      this.modelFor('settings.company-profile');

    let changeRequests = [ownerChangeRequest, ...representativeChangeRequests];
    let changeRequest = changeRequests.find(
      changeRequest =>
        changeRequest.id === membership_id ||
        changeRequest.belongsTo('membership').id() === membership_id
    );

    let membership = await changeRequest.belongsTo('membership').load();

    let updateProcessKbis = await updateProcess.kycKybUpdateKbisFile;
    let kbis = new TrackedArray();
    if (updateProcessKbis) {
      kbis.push(updateProcessKbis);
    }

    let updateProcessPois = await changeRequest.kycKybUpdatePoiFile;
    let pois = new TrackedArray();
    if (updateProcessPois) {
      pois.push(updateProcessPois);
    }

    let updateProcessBylaws = await updateProcess.kycKybUpdateBylawFile;
    let bylaws = new TrackedArray();
    if (updateProcessBylaws) {
      bylaws.push(updateProcessBylaws);
    }

    return {
      membership,
      membershipChangeRequest: changeRequest,
      updateProcess,
      kbis,
      pois,
      bylaws,
      organization,
    };
  }
}
