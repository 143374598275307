import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

import { ON_BEHALF_STATUS } from 'qonto/constants/on-behalf-registration';

export default class UploadPowerOfAttorneyRoute extends Route {
  @service abilities;
  @service menu;
  @service router;
  @service onBehalfRegistration;
  @service organizationManager;
  @service segment;

  get currentMembershipId() {
    return this.organizationManager.membership.id;
  }

  async beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'onboarding');

    if (this.abilities.cannot('create documents')) {
      return this.redirectToDefault();
    }

    let onBehalfRegistration = await this.loadOnBehalfRegistration();

    if (
      !onBehalfRegistration ||
      !this.hasCorrectMembership(onBehalfRegistration) ||
      this.isRegistrationCompleted(onBehalfRegistration)
    ) {
      return this.redirectToDefault();
    }
  }

  model(params, transition) {
    return {
      fromRouteName: transition.from?.name,
    };
  }

  async loadOnBehalfRegistration() {
    let organizationId = this.organizationManager.organization.id;
    return await this.onBehalfRegistration.loadOnBehalfRegistration(organizationId);
  }

  hasCorrectMembership(onBehalfRegistration) {
    return onBehalfRegistration.poweredMemberships.some(
      membership => membership.id === this.currentMembershipId
    );
  }

  isRegistrationCompleted(onBehalfRegistration) {
    return onBehalfRegistration.onBehalfStatus === ON_BEHALF_STATUS.COMPLETED;
  }

  redirectToDefault() {
    return this.router.replaceWith('organizations.show');
  }

  activate(transition) {
    this.segment.track('power_modal_opened', {
      page: transition.from?.name,
    });
    this.menu.hide();
  }

  deactivate() {
    this.menu.show();
  }
}
