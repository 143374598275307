import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class CapitalShareholderEditRoute extends Route {
  @service capitalIncreaseManager;
  @service menu;
  @service store;

  queryParams = ['step'];

  activate() {
    this.capitalIncreaseManager.hideCapitalStepper();
    if (this.menu.isVisible) {
      this.menu.hide();
    }
  }

  model() {
    return this.store.createRecord('stakeholder', {
      address: this.store.createRecord('address'),
      organization: this.capitalIncreaseManager.organization,
      shareholder: true,
    });
  }

  deactivate() {
    this.capitalIncreaseManager.showCapitalStepper();
    this.menu.show();
  }
}
