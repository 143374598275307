export default {
  "multi-transfer": "ZZe",
  "container": "ZZB",
  "title": "ZZE",
  "subtitle": "ZZd",
  "form-wrapper": "ZZI",
  "form": "ZZt",
  "form-title": "ZZM",
  "files-buttons": "ZZP",
  "files-buttons-item": "ZZl",
  "selected": "ZZX"
};
