import ReimbursementsPendingRoute from 'qonto/routes/reimbursements/pending/route';

export default class ReimbursementsPendingToApproveRoute extends ReimbursementsPendingRoute {
  controllerName = 'reimbursements.pending';

  setupController(controller, model) {
    controller.set('tab', 'to-approve');
    controller.set('approvalWorkflowLastStep', false);
    super.setupController(controller, model);
  }
}
