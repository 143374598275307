import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { BadgeStatus } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import {
  CARD_FLOWS_TRACKING_ORIGINS,
  CARD_LEVELS,
  ORIGIN,
  TRACKING_ORIGIN,
} from 'qonto/constants/cards';

export default class PhysicalOrVirtual extends Controller {
  badgeStatus = BadgeStatus;

  @service organizationManager;
  @service segment;
  @service modals;
  @service intl;
  @service toastFlashMessages;
  @service flowLinkManager;
  @service subscriptionManager;
  @service router;

  @reads('model') cardEstimates;

  CARD_LEVELS = CARD_LEVELS;
  queryParams = ['origin', 'inviteeMembershipId'];

  @tracked origin;
  @tracked inviteeMembershipId;

  @action goBack() {
    this.flowLinkManager.transitionTo({
      name: 'card-new-invitee',
      queryParams: { inviteeMembershipId: this.inviteeMembershipId },
    });
  }

  get displayBackButton() {
    return this.origin === ORIGIN.MEMBER_INVITATION;
  }

  onAbortTask = dropTask(async () => {
    // We are showing modal only in case when origin is ORIGIN.MEMBER_INVITATION.
    if (![ORIGIN.MEMBER_INVITATION].includes(this.origin)) {
      this.router.transitionTo('cards.index');
      return;
    }

    let result = await this.openAbortModalTask.perform();
    if (result === 'confirm') {
      this.segment.track('cancel-modal_cancel_clicked', {
        origin: this.flowTrackingOrigin,
      });
      this.router.transitionTo('members');
    }
  });

  openAbortModalTask = dropTask(async () => {
    return await this.modals.open('popup/destructive', {
      title: this.intl.t('cards.modals.cancel-creation.title'),
      description: this.intl.t('cards.modals.cancel-creation.subtitle'),
      cancel: this.intl.t('cards.modals.cancel-creation.back-cta'),
      confirm: this.intl.t('cards.modals.cancel-creation.leave-cta'),
    });
  });

  get flowTrackingOrigin() {
    return TRACKING_ORIGIN[this.origin] || CARD_FLOWS_TRACKING_ORIGINS.CREATION_REGULAR;
  }

  get isMultiAccounts() {
    return this.organizationManager.organization.hasMultipleActiveAccounts;
  }

  get hasPhysicalCardDiscount() {
    let estimates = Object.values(this.cardEstimates);

    return estimates.some(
      estimate =>
        estimate.discount_finish_date && estimate.monthly_cost !== estimate.discounted_monthly_cost
    );
  }

  get trialInfo() {
    return this.subscriptionManager.currentSubscription?.findTrial('flash_card');
  }

  @action
  handleClose() {
    this.router.transitionTo('cards.index');
  }

  resetQueryParams() {
    if (this.inviteeMembershipId) {
      this.inviteeMembershipId = null;
    }
  }
}
