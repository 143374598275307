import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class FinancingPartnersOfferController extends Controller {
  @service segment;
  @service organizationManager;
  @service abilities;

  @tracked showHeader = false;

  get shouldDisplayMissingKycBanner() {
    let organization = this.organizationManager.organization;
    let membership = this.organizationManager.membership;

    return membership.shouldSubmitKyc && organization.kybPending;
  }

  get canRequestFinancing() {
    return this.abilities.can('request financing');
  }

  @action
  handleIntersect(entries) {
    this.showHeader = entries.some(entry => !entry.isIntersecting);
  }

  @action
  onChangeTab(value) {
    this.segment.track(value, {
      partner: this.model.financing_partner.data.name,
      type: this.model.financing_type.data.name,
    });
  }
}
