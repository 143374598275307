import { UnauthorizedError } from '@ember-data/adapter/error';
import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class InvitationsShowRoute extends Route {
  @service toastFlashMessages;
  @service router;
  @service intl;
  @service segment;
  @service store;
  @service deviceManager;

  async model(params) {
    let invite = await this.store.findRecord('invite', params.token);
    let isMobile = this.deviceManager.isMobile;

    return { invite, isMobile };
  }

  @action
  error(error) {
    if (error instanceof UnauthorizedError) {
      this.segment.track('join_team_invalid_link');
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.invalid-invite'));
      return this.router.replaceWith('signin');
    }
  }
}
