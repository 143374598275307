import Controller from '@ember/controller';
import { action } from '@ember/object';
import { getOwner } from '@ember/owner';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';
import { UploadFile } from 'ember-file-upload';

import CURRENCIES from 'qonto/constants/currencies';
import { DUMMY_FILE, SAVINGS_ACCOUNT_TRANSFER_OPTIONS } from 'qonto/constants/savings-account';
import pushPayload from 'qonto/utils/store-push-payload';

import WizardContext from './wizard-context';

export default class AccountsNewSavingsController extends Controller {
  @service intl;
  @service modals;
  @service router;
  @service uploaderManager;
  @service networkManager;
  @service organizationManager;
  @service sensitiveActions;
  @service store;

  queryParams = ['step'];

  @tracked step = 'about';

  get steps() {
    return [
      {
        id: 'about',
        componentName: 'savings-accounts/steps/about',
        name: this.intl.t('accounts.savings.creation.about.step-title'),
      },
      {
        id: 'requirements',
        componentName: 'savings-accounts/steps/requirements',
        name: this.intl.t('accounts.savings.creation.requirements.step-title'),
      },
      this.model.information.membershipIdsFiscalResidencesToFill?.length > 0
        ? {
            id: 'ubo',
            componentName: 'savings-accounts/steps/ubo',
            name: this.intl.t('accounts.savings.creation.fatca-crs.step-title'),
          }
        : null,
      {
        id: 'details',
        componentName: 'savings-accounts/steps/details',
        name: this.intl.t('accounts.savings.creation.details.step-title'),
      },
      {
        id: 'information',
        componentName: 'savings-accounts/steps/information',
        name: this.intl.t('accounts.savings.creation.information.step-title'),
      },
      {
        id: 'review',
        componentName: 'savings-accounts/steps/review',
        name: this.intl.t('accounts.savings.creation.review.step-title'),
      },
      {
        id: 'success',
        componentName: 'savings-accounts/steps/success',
        name: this.intl.t('accounts.savings.creation.success.step-title'),
      },
    ].filter(Boolean);
  }

  get wizardContext() {
    let context = new WizardContext(getOwner(this));
    context.information = this.model.information;
    context.rates = this.model.rates;
    context.maxRate = this.model.maxRate;
    context.signContractAndTransferTask = this.signContractAndTransferTask;
    context.origin = this.model.origin;
    return context;
  }

  get hideGoBack() {
    return this.step === 'success';
  }

  @action goBack() {
    if (this.step === 'about' && this.router?.currentRoute?.queryParams?.origin) {
      return this.router.transitionTo('accounts.new', {
        queryParams: { origin: this.model.origin },
      });
    }
  }

  /**
   * Creates a File, uploads it and returns it so it can be attached
   */
  _createAttachementTask = dropTask(async () => {
    let file = UploadFile.fromDataURL(DUMMY_FILE.fileUrl);
    file.fileName = DUMMY_FILE.fileUrl;
    file.fileUrl = DUMMY_FILE.fileUrl;

    try {
      let response = await this.uploaderManager.uploadTask.perform(file);
      let payload = await response.json();
      let attachment = pushPayload(this.store, 'attachment', {
        attachment: payload.attachment,
      });
      file.fileUrl = attachment.get('file.fileUrl');
      attachment.setProperties({ file });
      return attachment;
    } catch (error) {
      this.networkManager.handleNetworkError(error);
      throw error;
    }
  });

  _initiateTransferTask = dropTask(async amount => {
    let { organization } = this.organizationManager;
    let bankAccount = organization.mainAccount;

    let transfer = this.store.createRecord('transfer', {
      bankAccount,
      organization,
      fx: false,
      amountCurrency: CURRENCIES.default,
      amount,
      ...SAVINGS_ACCOUNT_TRANSFER_OPTIONS,
    });

    transfer.addIdempotencyKey();
    // Transfer requires to have an attachment to be valid
    let attachment = await this._createAttachementTask.perform();
    let attachments = await transfer.get('attachments');
    attachments.push(attachment);
    return transfer;
  });

  _confirmTransferTask = dropTask(async transfer => {
    await transfer.save();
    transfer.removeIdempotencyHeader();
    // destroy the attachment
    let savedAttachements = transfer.get('attachments').filter(item => item.isNew);
    savedAttachements.forEach(attachement => attachement.unloadRecord());
  });

  signContractAndTransferTask = dropTask(
    async (amount, procedureId, sourceOfFunds, membershipFiscalResidences) => {
      let transfer = await this._initiateTransferTask.perform(amount);
      let signSuccessful = false;
      await this.sensitiveActions.runTask.perform(this._confirmTransferTask, transfer);
      let transferSuccessful = this._confirmTransferTask.lastPerformed.isSuccessful;
      if (transferSuccessful) {
        await this.store.adapterFor('savings-account').signContract({
          procedureId,
          fundOrigin: sourceOfFunds,
          membershipFiscalResidences,
        });
        signSuccessful = true;
      }
      return { signSuccessful };
    }
  );

  onCloseTask = dropTask(async () => {
    await this.modals.open('popup/destructive', {
      title: this.intl.t('accounts.savings.creation.leave-popup.title'),
      description: this.intl.t('accounts.savings.creation.leave-popup.intro'),
      cancel: this.intl.t('accounts.savings.creation.leave-popup.secondary-cta'),
      confirm: this.intl.t('accounts.savings.creation.leave-popup.cta'),
      confirmTask: this.modalConfirmTask,
    });
  });

  modalConfirmTask = dropTask(async close => {
    this.router.transitionTo(this.model.origin);
    await close();
  });

  @action onWizardComplete() {
    this.router.transitionTo(this.model.origin);
  }
}
