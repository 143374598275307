import { action } from '@ember/object';
import { setOwner } from '@ember/owner';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

import { ErrorInfo } from 'qonto/utils/error-info';

export class CardReorderDataContext {}

export default class CardReorderSetup {
  @service abilities;
  @service homePage;
  @service intl;
  @service organizationManager;
  @service router;
  @service sentry;
  @service store;
  @service toastFlashMessages;

  constructor(owner) {
    setOwner(this, owner);
    let { cardId } = this.router.currentRoute.queryParams;

    this.cardId = cardId;
    this.dataContext = new CardReorderDataContext();
  }

  async beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'cards');

    if (this.abilities.cannot('read card')) {
      return this.homePage.replaceWithDefaultPage();
    }

    if (this.abilities.cannot('create card')) {
      return this.router.replaceWith('cards');
    }

    try {
      let parentCard = await this.store.findRecord('card', this.cardId);

      this.dataContext.parentCard = parentCard;
      this.dataContext.isUserCardHolder =
        this.organizationManager.membership.id === parentCard.holder.get('id');
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      return this.router.transitionTo('cards.index');
    }
  }

  @action
  onAbort({ parentCard }) {
    this.router.transitionTo('cards.index', { queryParams: { highlight: parentCard.id } });
  }

  @action
  onComplete({ card }) {
    this.router.transitionTo('cards.index', { queryParams: { highlight: card.id } });
  }
}
