import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

export default class AgenciaTributariaRoute extends Route {
  @service abilities;
  @service homePage;
  @service sentry;

  beforeModel() {
    if (this.abilities.cannot('view nrc')) {
      this.homePage.replaceWithDefaultPage();
    } else {
      Sentry.getCurrentScope().setTag('CFT', 'local-payments');
    }
  }

  deactivate() {
    Sentry.getCurrentScope().setTag('CFT', 'no-cft');
  }
}
