import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';

import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class SettingsPricePlanInvoicesRoute extends Route {
  @service organizationManager;
  @service store;

  queryParams = {
    per_page: {
      defaultValue: 25,
      refreshModel: true,
    },
    page: {
      defaultValue: 1,
      refreshModel: true,
    },
  };

  model(params) {
    this.fetchDataTask.perform(params).catch(ignoreCancelation);
    return { fetchInvoicesTask: this.fetchDataTask };
  }

  fetchDataTask = dropTask(async params => {
    let { page, per_page } = params;
    let query = {
      page,
      per_page,
      organization_id: this.organizationManager.organization.id,
    };
    return await this.store.query('invoice', query);
  });

  @action
  loading() {
    // Bypass the loading substate at subscription level to handle loading in table
    return false;
  }
}
