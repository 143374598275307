import Route from '@ember/routing/route';

export default class CashFlowCategoriesTypeRoute extends Route {
  templateName = 'cash-flow-categories/index';

  model(params) {
    let { type } = params;

    if (!['inflows', 'outflows'].includes(type)) {
      this.transitionTo('cash-flow-categories.type', 'inflows');
    }
  }
}
