import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { getDefaultClientValues } from 'qonto/models/client-hub';

export default class ClientsNewRoute extends Route {
  @service abilities;
  @service router;
  @service menu;
  @service store;
  @service modals;

  activate() {
    this.menu.hide();
  }

  deactivate() {
    this.menu.show();
  }

  beforeModel() {
    if (!this.abilities.can('write client-hubs')) {
      return this.router.replaceWith('clients');
    }
  }

  model() {
    let client = this.store.createRecord('client-hub', getDefaultClientValues(this));

    return {
      client,
    };
  }

  afterModel({ client }) {
    this.modal = this.modals.open(
      'receivable-invoices/client-form',
      { client, isFullScreenModal: true },
      {
        focusTrapOptions: {
          initialFocus: false,
          onDeactivate: this.onCloseModal,
        },
      }
    );
  }

  resetController(_, isExiting) {
    super.resetController(...arguments);
    if (isExiting) {
      this.modal.close();
    }
  }

  @action onCloseModal() {
    this.router.transitionTo('clients');
  }
}
