import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

export default class CapitalKbisInfoController extends Controller {
  @service router;

  @action
  handleNext() {
    this.router.transitionTo('capital.upload');
  }

  @action
  handleBack() {
    this.router.transitionTo('capital.certificate');
  }
}
