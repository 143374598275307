import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class TransactionsShowRoute extends Route {
  @service intl;
  @service organizationManager;
  @service store;
  @service router;
  @service abilities;

  beforeModel(transition) {
    let { organization } = this.organizationManager;
    this.router.transitionTo('transactions', organization.slug, {
      queryParams: { highlight: transition.to.params.transaction_id },
    });
  }
}
