import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import { CONNECTION_EXPIRATION_STATUS } from 'qonto/constants/connect';

import ConnectionsController from '../controller';

export default class ConnectionsAppsController extends ConnectionsController {
  @service toastFlashMessages;
  @service intl;
  @service modals;

  @tracked appsConnections = this.model.appsConnectionsTask?.lastSuccessful?.value || [];
  @tracked sortBy = 'status:asc';
  @tracked highlight = '';

  queryParams = ['highlight', { sortBy: 'sort_by' }];
  closeSidebarId = 'close-sidebar';

  get sortedAppsConnections() {
    let sorted = this.model.appsConnectionsTask?.lastSuccessful?.value || [];
    let [sortField, sortOrder] = this.sortBy.split(':');

    return [...sorted].sort((a, b) => {
      let order;

      if (sortField === 'status') {
        // Since apps do not have the concept of failing connections, their
        // status is only based on expiration date
        // Checking on a or b connectionsStatus depending on sortOrder is to
        // make sure we push integrations with no expiresAt at the bottom of the list,
        // no matter if we sort by asc or desc
        if (sortOrder === 'asc') {
          let aHasExpirationStatus = a.connectionStatus !== CONNECTION_EXPIRATION_STATUS.UNKNOWN;
          order = aHasExpirationStatus ? (a.expiresAt >= b.expiresAt ? 1 : -1) : 1;
        } else {
          let bHasExpirationStatus = b.connectionStatus !== CONNECTION_EXPIRATION_STATUS.UNKNOWN;
          order = bHasExpirationStatus ? (a.expiresAt >= b.expiresAt ? 1 : -1) : 1;
        }
      } else {
        order = a.get(sortField) >= b.get(sortField) ? 1 : -1;
      }

      return sortOrder === 'asc' ? order : -order;
    });
  }

  @action
  updateHighlightedItem(itemId) {
    this.highlight = itemId;
  }

  @action
  handleSortBy(sortField, sortOrder) {
    this.highlight = '';
    this.sortBy = `${sortField}:${sortOrder}`;
  }

  confirmDeleteTask = dropTask(async connection => {
    await this.modals.open('popup/destructive', {
      title: this.intl.t('settings.connections.disconnect-modal.title', {
        connectionName: connection.client.name,
      }),
      description: this.intl.t('settings.connections.disconnect-modal.description'),
      cancel: this.intl.t('settings.connections.disconnect-modal.cancel-cta'),
      confirm: this.intl.t('settings.connections.disconnect-modal.disconnect-cta'),
      confirmTask: this.disconnectTask,
      connection,
    });
  });

  disconnectTask = dropTask(async (closeModal, { connection }) => {
    let connectionName = connection.client.name;
    try {
      await connection.destroyRecord();
      this.appsConnections = this.appsConnections.filter(c => c.id !== connection.id);
      this.toastFlashMessages.toastSuccess(
        this.intl.t('settings.connections.disconnect-toast.success', { connectionName })
      );
    } catch {
      this.toastFlashMessages.toastError(
        this.intl.t('settings.connections.disconnect-toast.error', { connectionName })
      );
    } finally {
      closeModal();
    }
  });
}
