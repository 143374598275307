import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

export default class PasswordsRoute extends Route {
  @service sessionManager;

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'onboarding');
    this.sessionManager.prohibitAuthentication();
  }
}
