export default function periodOptions(allocatablePeriods, { allocatedPeriod, budget }) {
  let options = allocatablePeriods.map(({ budget, allocatablePeriod }) => {
    return {
      name: budget.name,
      id: allocatablePeriod.id,
      disabled: Boolean(budget.restricted || budget.archived),
    };
  });

  let selectedOption = options.find(({ id }) => id === allocatedPeriod?.id);
  if (!selectedOption && allocatedPeriod && budget) {
    selectedOption = {
      name: budget.name,
      id: allocatedPeriod?.id,
      disabled: Boolean(budget.restricted || budget.archived),
    };
    options.unshift(selectedOption);
  }
  return { options, selectedOption };
}
