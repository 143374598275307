import { action } from '@ember/object';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { dropTask } from 'ember-concurrency';

import {
  SEPA_TRACKING_SETTLEMENT_CTA,
  SEPA_TRACKING_SETTLEMENT_EVENTS,
  TRANSFER_FLOW_ORIGIN,
} from 'qonto/constants/transfers';
import { FlowSetup } from 'qonto/routes/flows/setup/internals';
import { createSepaSettlementTrackingEventPayload } from 'qonto/utils/transfers';

class EditSepaTransferDataContext {
  sepaTransfer = null;
  confirmationResult = {};
  settlementResult = {};
  origin = TRANSFER_FLOW_ORIGIN.EDIT;
}

export default class EditSepaTransferFlowSetup extends FlowSetup {
  @service abilities;
  @service attachmentsManager;
  @service homePage;
  @service intl;
  @service modals;
  @service router;
  @service segment;
  @service store;

  constructor() {
    super(...arguments);
    this.dataContext = new EditSepaTransferDataContext();
  }

  async beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'transfers');

    if (this.abilities.cannot('update transfer')) {
      return this.homePage.replaceWithDefaultPage();
    }

    let { transferId } = this.router.currentRoute.queryParams;
    let scheduledTransfer = await this.store.findRecord('transfer', transferId);

    await scheduledTransfer.beneficiary;

    let { attachments, beneficiary } = scheduledTransfer;

    let relationshipPromises = [];

    if (attachments) {
      relationshipPromises.push(
        this.attachmentsManager.loadAttachments(attachments.map(({ id }) => id))
      );
    }

    if (beneficiary) {
      relationshipPromises.push(
        this.store.findRecord('beneficiary', scheduledTransfer.get('beneficiary.id'))
      );
    }

    await Promise.all(relationshipPromises);

    scheduledTransfer.name = scheduledTransfer.get('beneficiary.name');
    scheduledTransfer.activityTag = scheduledTransfer.get('beneficiary.activityTag');

    Object.assign(this.dataContext, { sepaTransfer: scheduledTransfer });
  }

  @action
  onComplete({ origin, sepaTransfer, settlementResult }) {
    let { status } = settlementResult;

    let isQontoBeneficiary = sepaTransfer.get('beneficiary.qontoBankAccount');

    let payload = createSepaSettlementTrackingEventPayload({
      origin,
      sepaTransfer,
      settlementStatus: status,
      cta: SEPA_TRACKING_SETTLEMENT_CTA.EXIT,
      isQontoBeneficiary,
    });

    this.segment.track(SEPA_TRACKING_SETTLEMENT_EVENTS.SUCCESS_CLOSED, payload);
    this.router.transitionTo('transfers.pending', {
      queryParams: {
        highlight: sepaTransfer.id,
      },
    });
  }

  onAbortTask = dropTask(async ({ origin, sepaTransfer }) => {
    let result = await this.openAbortModalTask.perform();

    if (result !== 'confirm') {
      return false;
    }

    sepaTransfer.rollbackAttributes();

    this.segment.track('transfer_creation_exited', { origin });
    this.router.transitionTo('transfers.pending', {
      queryParams: {
        highlight: sepaTransfer.id,
      },
    });

    return true;
  });

  openAbortModalTask = dropTask(
    async () =>
      await this.modals.open('popup/destructive', {
        title: this.intl.t('transfers.exit-flow.title'),
        description: this.intl.t('transfers.exit-flow.subtitle'),
        cancel: this.intl.t('btn.cancel'),
        confirm: this.intl.t('transfers.exit-flow.confirm'),
      })
  );
}
