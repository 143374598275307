import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { reads } from 'macro-decorators';

export default class CapitalRoute extends Route {
  @service capitalIncreaseManager;
  @service featuresManager;
  @service router;
  @service segment;

  @reads('featuresManager.flags.qontoNotary') isQontoNotaryEnabled;

  handleDocumentRedirection(bylawUploadedAt) {
    if (bylawUploadedAt) {
      this.router.transitionTo('capital.choose-poa-type');
    } else {
      this.router.transitionTo('capital.by-laws', {
        queryParams: { step: 'upload' },
      });
    }
  }

  activate() {
    let notaryChosen = this.isQontoNotaryEnabled ? 'qonto' : 'external';
    this.segment.identify({ notary: notaryChosen });
  }

  redirect() {
    let { organization, membership } = this.capitalIncreaseManager;
    if (organization.isFastTrackDepositCapitalFlowType) {
      if (!membership.kycAccepted || organization.pendingReview) {
        return this.router.transitionTo('capital.company-verification');
      }

      if (organization.depositRequestSent) {
        return this.router.transitionTo('capital.reviewed');
      }

      if (organization.isFastTrackDepositCapitalStatus) {
        return this.router.transitionTo('capital.status');
      }
    }

    let {
      isDocumentsSubmitted,
      isWaitingDeposit,
      isDepositRequestSent,
      isDepositRequestSigned,
      isDepositCertificateSigned,
      isKbisSubmitted,
      isDepositReleaseSent,
      isDepositReleaseRequested,
    } = this.capitalIncreaseManager;

    if (isDepositReleaseRequested) {
      this.router.transitionTo('capital.finish');
    } else if (isDepositReleaseSent) {
      this.router.transitionTo('capital.release');
    } else if (isKbisSubmitted) {
      this.router.transitionTo('capital.upload');
    } else if (isDepositCertificateSigned) {
      this.router.transitionTo('capital.certificate');
    } else if (isDepositRequestSigned) {
      this.router.transitionTo('capital.reviewed');
    } else if (isDocumentsSubmitted) {
      this.router.transitionTo('capital.review');
    } else if (isWaitingDeposit || isDepositRequestSent) {
      this.router.transitionTo('capital.deposit');
    } else if (organization.depositCapitalStatus === 'awaiting_documents') {
      this.handleDocumentRedirection(organization.bylawUploadedAt);
    } else {
      this.router.transitionTo('capital.index');
    }
  }
}
