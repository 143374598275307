export const remuneratedAccountCreation = {
  steps: {
    create: {
      componentName: 'accounts/remunerated/creation/create',
      nextStepId: 'success',
    },
    success: {
      componentName: 'accounts/remunerated/creation/success',
    },
  },
  options: {
    enablePersistence: false,
  },
};
