import { CARD_LEVELS } from 'qonto/constants/cards';

export const cardNewInvitee = {
  steps: {
    choose: {
      componentName: 'cards/choose-card-invitee',
      nextStepId: ({ card }) => {
        if (card.cardLevel === CARD_LEVELS.STANDARD) {
          return 'success-physical-one';
        } else {
          return 'success-digital';
        }
      },
    },
    'success-physical-one': {
      componentName: 'cards/success-invitee-physical-one',
    },
    'success-digital': {
      componentName: 'cards/success-invitee-digital',
    },
  },
};
