import { action } from '@ember/object';
import { setOwner } from '@ember/owner';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

import { ORIGIN, TRACKING_ORIGIN } from 'qonto/constants/cards';
import CardNewAbstractFlowSetup from 'qonto/routes/flows/setup/cards/new/abstract';
import { ErrorInfo } from 'qonto/utils/error-info';

export class CardNewDigitalDataContext {
  constructor({ card, cardId, kycSubmitted, origin, shouldShowRestrictions }) {
    this.card = card;
    this.cardId = cardId;
    this.isCardUnderCreation = true;
    this.kycSubmitted = kycSubmitted;
    this.origin = origin;
    this.shouldShowRestrictions = shouldShowRestrictions;
    // context for subscription-addon used as secondary flow
    this.recurrence = null;
    this.code = null;
  }
}

export default class CardNewDigitalFlowSetup extends CardNewAbstractFlowSetup {
  @service abilities;
  @service cardsManager;
  @service homePage;
  @service intl;
  @service organizationManager;
  @service router;
  @service sentry;
  @service store;
  @service subscriptionManager;
  @service toastFlashMessages;

  constructor(owner, globalDataContext) {
    super();
    setOwner(this, owner);

    let card;
    let isUserCardHolder;
    let { inviteeMembershipId, origin } = this.router.currentRoute.queryParams;

    if (globalDataContext?.card) {
      card = globalDataContext.card;
      isUserCardHolder = globalDataContext?.isUserCardHolder;
    } else {
      card = this.store.createRecord('card', {
        bankAccount: this.organization.defaultAccount,
        organization: this.organization,
      });

      if (inviteeMembershipId) {
        card.holder = this.store.peekRecord('membership', inviteeMembershipId);
      }

      card.addIdempotencyKey();
    }

    this.dataContext = new CardNewDigitalDataContext({
      card,
      cardId: globalDataContext?.cardId, // required for QCP cards
      kycSubmitted: this.organizationManager.membership.kycSubmitted,
      isUserCardHolder,
      origin: origin || ORIGIN.CREATION_REGULAR,
      shouldShowRestrictions: this.abilities.can('view restrictions card'),
    });
  }

  get bankAccount() {
    let { hasMultipleActiveAccounts, defaultAccount } = this.organization;
    return hasMultipleActiveAccounts ? defaultAccount : this.organizationManager.currentAccount;
  }

  async beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'cards');

    if (this.abilities.cannot('read card')) {
      return this.homePage.replaceWithDefaultPage();
    }

    if (this.abilities.cannot('access creation flow card')) {
      this.router.replaceWith('cards');
      return true; // to avoid TransitionAborted error before redirection
    }

    try {
      let { hasMultipleActiveAccounts, id: organizationId, defaultAccount } = this.organization;

      let { card } = this.dataContext;

      if (card?.holder?.id) {
        this.dataContext.cardsLimits = await this.cardsManager.fetchCardsMaxLimitsTask.perform(
          card.holder.id
        );
      } else {
        this.dataContext.cardsLimits = await this.cardsManager.fetchCardsMaxLimitsTask.perform();
      }

      let { estimates, isEstimateLoaded } =
        await this.cardsManager.fetchCardsCostsTask.perform(organizationId);
      this.dataContext.estimates = estimates;
      this.dataContext.isEstimateLoaded = isEstimateLoaded;
      this.dataContext.cardOptionsPrices = await this.cardsManager.fetchCardOptionsPricesTask
        .perform()
        .catch(() => []);
      await this.subscriptionManager.refresh();
      this.dataContext.pricePlan = this.subscriptionManager.currentPricePlan;
      this.dataContext.bankAccount = this.bankAccount;
      this.dataContext.currentSubscription = this.subscriptionManager.currentSubscription;
      this.dataContext.hasMultipleActiveAccounts = hasMultipleActiveAccounts;
      this.dataContext.mainAccount = defaultAccount;
      this.dataContext.flowTrackingOrigin = TRACKING_ORIGIN[this.dataContext.origin];
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this._cleanupCard();

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      return this.router.transitionTo('cards.physical-or-virtual');
    }
  }

  @action
  onComplete({ card, cardId, origin }) {
    if (origin === ORIGIN.MEMBER_INVITATION) {
      return this.router.replaceWith('members.active');
    }

    this.router.transitionTo('cards.index', { queryParams: { highlight: card.id || cardId } });
  }

  _cleanupCard() {
    let { card } = this.dataContext;
    if (card.isNew) {
      card.unloadRecord();
    }

    this.cardsManager.fetchCardsMaxLimitsTask.cancelAll();
  }
}
