import Route from '@ember/routing/route';
import { service } from '@ember/service';

import CURRENCIES from 'qonto/constants/currencies';

export default class TransfersInternalNewRoute extends Route {
  @service abilities;
  @service intl;
  @service homePage;
  @service organizationManager;
  @service store;

  beforeModel(transition) {
    let canCreateInternalTransfer = this.abilities.can('create internal transfer');
    let { hasMultipleActiveCurrentRemuneratedAccounts } = this.organizationManager.organization;

    if (!canCreateInternalTransfer || !hasMultipleActiveCurrentRemuneratedAccounts) {
      let queryParams = transition.to.queryParams;
      delete queryParams.query;
      this.homePage.visitDefaultPage({ queryParams });
    }
  }

  model(params) {
    let { organization } = this.organizationManager;

    let transferOptions = {
      activityTag: 'other_expense',
    };

    if (params?.bankAccount) {
      let { id, name, iban } = organization.bankAccounts.find(
        bankAccount => bankAccount.id === params.bankAccount
      );
      transferOptions = {
        name,
        iban,
        creditBankAccountId: id,
        activityTag: 'treasury_and_interco',
        reference: this.intl.t('transfers.new.internal-transfer'),
      };
    }

    let transfer = this.store.createRecord('transfer', {
      bankAccount: organization.defaultAccount,
      organization,
      amountCurrency: CURRENCIES.default,
      ...transferOptions,
    });
    transfer.addIdempotencyKey();

    return transfer;
  }
  setupController(controller, model) {
    super.setupController(controller, model);
    controller.firstEditTracked = false;
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.set('bankAccount', null);
    }
  }
}
