import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { dropTask } from 'ember-concurrency';

import { FlowSetup } from 'qonto/routes/flows/setup/internals';

class F24ManualDeclarationFlowDataContext {
  constructor(f24Order) {
    this.f24Order = f24Order;
  }
}

export default class F24ManualDeclarationFlowSetup extends FlowSetup {
  @service router;
  @service abilities;
  @service homePage;
  @service organizationManager;
  @service store;
  @service modals;
  @service intl;

  constructor() {
    super(...arguments);

    let { organization, currentAccount } = this.organizationManager;

    let address = this.store.createRecord('f24/address');
    let payer = this.store.createRecord('f24/payer', { address });
    let payerAgent = this.store.createRecord('f24/payer-agent');
    let erarioTaxItem = this.store.createRecord('f24/erario-tax-item');
    let erario = this.store.createRecord('f24/erario', { taxList: [erarioTaxItem] });
    let inpsTaxItem = this.store.createRecord('f24/inps-tax-item');
    let inailTaxItem = this.store.createRecord('f24/inail-tax-item');
    let regioniTaxItem = this.store.createRecord('f24/regioni-tax-item');
    let imuTaxItem = this.store.createRecord('f24/imu-tax-item');
    let imu = this.store.createRecord('f24/imu', { taxList: [imuTaxItem] });
    let othersTaxItem = this.store.createRecord('f24/others-tax-item');
    let others = this.store.createRecord('f24/others-tax', { taxList: [othersTaxItem] });
    let taxes = this.store.createRecord('f24/tax-information', {
      erario,
      imu,
      others,
      inpsTaxList: [inpsTaxItem],
      inailTaxList: [inailTaxItem],
      regioniTaxList: [regioniTaxItem],
    });

    let f24Order = this.store.createRecord('f24-order', {
      documentAmount: '0',
      taxes,
      organization,
      bankAccount: currentAccount,
      payer,
      payerAgent,
    });

    this.dataContext = new F24ManualDeclarationFlowDataContext(f24Order);
    this.dataContext.singleBankAccount = this.getSingleBackAccount();

    let { legalForm } = this.organizationManager.organization;
    this.dataContext.legalForm = legalForm;

    f24Order.prefill();
  }

  getSingleBackAccount() {
    let { activeSortedAccounts } = this.organizationManager.organization;
    let activeAccounts = activeSortedAccounts.filter(({ isRemunerated }) => !isRemunerated);

    return activeAccounts.length === 1 ? activeAccounts[0] : false;
  }

  beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'local-payments');

    let { organization, membership } = this.organizationManager;

    if (membership.shouldSubmitKyc && organization.kybPending) {
      return this.router.replaceWith('kyc.intro', {
        queryParams: {
          redirectRoute: 'f24',
        },
      });
    }

    if (this.abilities.cannot('view f24')) {
      return this.homePage.replaceWithDefaultPage();
    }
  }

  onComplete() {
    this.router.transitionTo('f24');
  }

  onAbortTask = dropTask(async () => {
    let result = await this.openAbortModal();
    let shouldContinueAbort = result === 'confirm';
    if (shouldContinueAbort) {
      this.cleanupStore();
      this.router.transitionTo('f24');
    }
    return shouldContinueAbort;
  });

  openAbortModal = () => {
    return this.modals.open('popup/destructive', {
      title: this.intl.t('f24.exit-flow-dialog.title'),
      description: this.intl.t('f24.exit-flow-dialog.subtitle'),
      cancel: this.intl.t('f24.exit-flow-dialog.cancel'),
      confirm: this.intl.t('f24.exit-flow-dialog.confirm'),
    });
  };

  unloadListItems(list) {
    for (let element of list) {
      element.unloadRecord();
    }
  }

  cleanupStore() {
    if (this.store.isDestroying || this.store.isDestroyed) return;

    let { f24Order } = this.dataContext;

    // when the f24 order has not been saved, we cleanup the store
    if (f24Order.isNew) {
      let { taxes } = f24Order;
      this.unloadListItems(taxes.erario.taxList);
      this.unloadListItems(taxes.imu.taxList);
      this.unloadListItems(taxes.others.taxList);
      this.unloadListItems(taxes.inailTaxList);
      this.unloadListItems(taxes.inpsTaxList);
      this.unloadListItems(taxes.regioniTaxList);
      taxes.erario.unloadRecord();
      taxes.imu.unloadRecord();
      taxes.others.unloadRecord();
      taxes.unloadRecord();

      let { payer, payerAgent } = f24Order;
      payer.address.unloadRecord();
      payer.unloadRecord();
      payerAgent.unloadRecord();

      f24Order.unloadRecord();
    }
  }
}
