import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { hasMFAError } from '@qonto/qonto-sca/utils/mfa-error';
import { dropTask } from 'ember-concurrency';
import window from 'ember-window-mock';
import { reads } from 'macro-decorators';

export default class MandatesListMandateController extends Controller {
  @service errors;
  @service toastFlashMessages;
  @service intl;
  @service router;
  @service modals;
  @service organizationManager;
  @service sensitiveActions;

  @reads('model') mandate;
  @reads('organizationManager.organization.hasMultipleBankAccounts') showAccount;

  showSuspendConfirmationModal = false;
  showUnsuspendedConfirmationModal = false;

  suspendMandateTask = dropTask(async () => {
    try {
      await this.mandate.suspend();
    } catch (error) {
      if (!this.errors.handleError(error)) {
        this.toastFlashMessages.toastError(this.intl.t('mandates.details.toast.suspend_fail'));
      }

      return;
    }

    let message = this.intl.t('mandates.details.toast.suspend_success');
    this.toastFlashMessages.toastSuccess(message);
    this.set('showSuspendConfirmationModal', false);
  });

  confirmUnsuspendTask = dropTask(async () => {
    await this.sensitiveActions.runTask.perform(this.unsuspendMandateTask);
  });

  unsuspendMandateTask = dropTask(async () => {
    try {
      await this.mandate.unsuspend();

      let message = this.intl.t('mandates.details.toast.unsuspend_success');
      this.toastFlashMessages.toastSuccess(message);
      this.set('showUnsuspendedConfirmationModal', false);
    } catch (error) {
      if (hasMFAError(error?.errors)) {
        throw error;
      }

      if (!this.errors.handleError(error)) {
        this.toastFlashMessages.toastError(this.intl.t('mandates.details.toast.unsuspend_fail'));
      }
    }
  });

  updateActivityTagTask = dropTask(async tag => {
    try {
      await this.mandate.updateActivityTag(tag);

      this.toastFlashMessages.toastSuccess(this.intl.t('mandates.details.activity_tag.success'));
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('mandates.details.activity_tag.fail'));
    }
  });

  downloadAttachment(downloadUrl) {
    window.open(downloadUrl);
  }

  @action
  redirectTransactions() {
    this.router.transitionTo('transactions.index', {
      queryParams: { mandate: this.mandate.id },
    });
  }

  @action
  handleOpenFilePreviewModal(attachment) {
    let { fileName: title, fileUrl, fileContentType } = attachment.file;

    this.modals.open('fullscreen/preview', {
      title,
      isFullScreenModal: true,
      fileUrl,
      fileContentType,
      downloadUrl: attachment.downloadUrl,
      onDownload: this.downloadAttachment,
    });
  }
}
