import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { DEPOSIT_CAPITAL_STEPPER_STEP } from 'qonto/constants/organization';

export default class CapitalReviewedRoute extends Route {
  @service capitalIncreaseManager;
  @service organizationManager;

  activate() {
    this.capitalIncreaseManager.setCurrentStep(DEPOSIT_CAPITAL_STEPPER_STEP.DEPOSIT_CERTIFICATE);
  }

  setupController(controller) {
    super.setupController(controller);
    let { isDepositRequestSigned, hasCertificate } = this.capitalIncreaseManager;

    controller.set('isDepositRequestSigned', isDepositRequestSigned);
    controller.set('hasCertificate', hasCertificate);
    controller.set('currentOrganization', this.organizationManager.organization);
    controller.set('showLoading', false);
  }
}
