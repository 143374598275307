import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

export default class FinancingPartnersOfferErrorController extends Controller {
  @service router;

  @action
  retry() {
    let { uid } = this.router.currentRoute.params;
    this.router.transitionTo('financing.partners.offer', uid);
  }
}
