import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

class WizardContext {
  @tracked stakeholder;
  @tracked saveStakeholderTask;
  @tracked wizardSuccess;
}

export default class ShareholdersEditController extends Controller {
  @service intl;
  @service modals;

  queryParams = ['step'];

  @tracked step = 'contribution';

  get steps() {
    return [
      {
        id: 'info',
        name: this.intl.t('on-boarding.capital-deposit.shareholders.add-physical-shareholder.step'),
        componentName: 'capital-deposit/stakeholder/info',
      },
      {
        id: 'poi',
        name: this.intl.t('on-boarding.capital-deposit.shareholders.add-physical-shareholder.step'),
        componentName: 'capital-deposit/stakeholder/poi',
      },
      {
        id: 'success',
        name: this.intl.t('on-boarding.capital-deposit.success'),
        componentName: 'capital-deposit/wizard-success',
      },
    ];
  }

  get wizardContext() {
    let wizardContext = new WizardContext();
    wizardContext.stakeholder = this.model;
    wizardContext.saveStakeholderTask = this.saveStakeholderTask;
    wizardContext.wizardSuccess = {
      title: this.intl.t(
        'on-boarding.capital-deposit.shareholders.edit-physical-shareholder.success.title'
      ),
      text: this.intl.t(
        'on-boarding.capital-deposit.shareholders.edit-physical-shareholder.success.subtitle'
      ),
    };

    return wizardContext;
  }

  saveStakeholderTask = dropTask(async stakeholder => {
    await stakeholder.save();

    let { proofOfIdentity } = stakeholder;

    // We don't update already saved POI (backend constraint of document endpoint)
    if (proofOfIdentity.isNew) {
      proofOfIdentity.subjectId = stakeholder.id;
      proofOfIdentity.subjectType = 'Membership';
      await proofOfIdentity.save();
    }
  });

  onCloseTask = dropTask(async () => {
    if (this.wizardContext.stakeholder.hasDirtyAttributes) {
      await this.modals.open('popup/destructive', {
        title: this.intl.t(
          'on-boarding.capital-deposit.shareholders.add-shareholder.warning.title'
        ),
        description: this.intl.t(
          'on-boarding.capital-deposit.shareholders.add-shareholder.warning.description'
        ),
        cancel: this.intl.t('btn.cancel'),
        confirm: this.intl.t('btn.delete'),
        confirmTask: this.modalConfirmTask,
      });
    } else {
      this.rollbackStakeholderOwnerAttributes();
      await this.transitionToRoute('capital.shareholders.index');
    }
  });

  modalConfirmTask = dropTask(async close => {
    this.rollbackStakeholderOwnerAttributes();
    this.transitionToRoute('capital.shareholders.index');
    await close();
  });

  @action
  rollbackStakeholderOwnerAttributes() {
    this.wizardContext.stakeholder.rollbackAttributes();
  }

  @action onWizardComplete() {
    this.transitionToRoute('capital.shareholders.index');
  }
}
