import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const COLORS = [
  '#F95656',
  '#45B184',
  '#EC9B50',
  '#B0F0DA',
  '#F1F78E',
  '#FFBC90',
  '#88A2F2',
  '#91E89D',
  '#FFA3C3',
  '#FFE770',
];

export default class CustomLabelsManageController extends Controller {
  @service organizationManager;
  @service toastFlashMessages;
  @service intl;
  @service router;
  @service store;

  @tracked activeLabel = null;

  get isListValid() {
    return this.activeLabel === null || this.activeLabel?.validations?.errors.length === 0;
  }

  @action
  _availableColor() {
    let usedColors = this.model.map(l => {
      return l.color.toUpperCase();
    });

    let difference = COLORS.filter(x => !usedColors.includes(x));
    return difference[0];
  }

  @action
  handleCreateLabelCategory() {
    if (this.model.length === 10) {
      return this.toastFlashMessages.toastError(
        this.intl.t('organizations.analytics.custom-labels.manage.create-limit')
      );
    }
    if (this.model.length && this.model.at(-1).isNew) {
      return;
    }
    let label = this.store.createRecord('label-list', {
      color: this._availableColor(),
      name: '',
      organization: this.organizationManager.organization,
      rank: 1,
    });
    this.handleFocus(label);
    return label;
  }

  @action
  handleFocus(label) {
    if (this.activeLabel && !this.activeLabel.isDestroying && !this.activeLabel.isDestroyed) {
      this.activeLabel.rollbackAttributes();
    }
    this.activeLabel = label;
  }

  @action
  handleGoBack() {
    if (this.isListValid) {
      this.handleFocus(null);
      return this.router.transitionTo('custom-labels.index');
    }
  }
}
