import Controller from '@ember/controller';
import { service } from '@ember/service';
import { capitalize } from '@ember/string';

import { FAST_TRACK_DEPOSIT_CAPITAL_STATUS } from 'qonto/constants/organization';

export default class CapitalCompanyVerificationController extends Controller {
  @service capitalIncreaseManager;

  get status() {
    return FAST_TRACK_DEPOSIT_CAPITAL_STATUS.COMPANY_VERIFICATION;
  }

  get onboardingPartner() {
    return capitalize(this.capitalIncreaseManager.organization.onboardingPartner ?? '');
  }

  get isOnboardingPartnerAccountant() {
    return this.capitalIncreaseManager.organization.onboardingPartnerType === 'accountant';
  }
}
