import Controller from '@ember/controller';
import { service } from '@ember/service';

export default class F24V2ListController extends Controller {
  @service segment;
  @service flowLinkManager;
  @service router;

  get isUpcomingActive() {
    return this.router.currentRouteName === 'f24.list.upcoming';
  }

  get isPastActive() {
    return this.router.currentRouteName === 'f24.list.past';
  }

  f24Pay = () => {
    this.segment.track('f24_create-flow_start');

    this.flowLinkManager.transitionTo({
      name: 'f24-manual-declaration',
      stepId: 'form-ordinario',
    });
  };
}
