import Route from '@ember/routing/route';
import { service } from '@ember/service';

import window from 'ember-window-mock';

import { SLACK_OAUTH_STATE_KEY } from 'qonto/constants/hub';

export default class ConnectHubHubApplicationAuthenticationRoute extends Route {
  @service menu;
  @service segment;
  @service store;

  hubApplicationRouteName = 'settings.connect-hub.applications.hub-application';

  activate() {
    this.menu.hide();
  }

  deactivate() {
    this.menu.show();
  }

  async model(params) {
    let { hubApplication, organization } = this.modelFor(this.hubApplicationRouteName);
    let { code, error, state } = params;
    let originalState = window.sessionStorage.getItem(SLACK_OAUTH_STATE_KEY);
    let isStateVerified = originalState === state;

    window.sessionStorage.removeItem(SLACK_OAUTH_STATE_KEY);

    if (error) {
      return {
        error,
        hubApplication,
        organization,
      };
    }

    if (!isStateVerified) {
      return {
        error: 'State was not Verified',
        hubApplication,
        organization,
      };
    }

    let authenticationUrl = window.location.href.split('?')[0];
    let connection = this.store.createRecord('hub-connection', {
      organizationId: organization.id,
      externalService: hubApplication.slug,
      params: {
        code,
        redirect_uri: authenticationUrl,
      },
    });

    try {
      await connection.save();

      this.segment.track('connect_app_activation_flow_success_cta_clicked', {
        name: hubApplication.name,
        integrationType: 'internal_integration',
        legal_country: organization.legalCountry,
      });

      return { hubApplication, organization };
    } catch (requestError) {
      return {
        error: requestError,
        hubApplication,
        organization,
      };
    }
  }
}
