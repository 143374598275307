import Controller from '@ember/controller';
import { service } from '@ember/service';

import { LottiePlayer } from '@repo/design-system-kit';
import { reads } from 'macro-decorators';

import { PRODUCT_ROUTE } from 'qonto/constants/insurance-hub';
import { getCategoryTextKey, getCategoryTitle } from 'qonto/utils/insurance-hub/categories';

export default class InsuranceHubController extends Controller {
  lottiePlayer = LottiePlayer;

  @service intl;
  @service organizationManager;
  @service router;
  @service segment;
  @service zendeskLocalization;

  @reads('organizationManager.organization') organization;
  @reads('organizationManager.organization.isFrench') isFrench;

  get partnerships() {
    return this.isFrench
      ? [
          { logo: '/illustrations/insurance-hub/axa.svg', name: 'Axa' },
          { logo: '/illustrations/insurance-hub/stello.svg', name: 'Stello' },
        ]
      : [{ logo: '/illustrations/insurance-hub/surein.svg', name: 'SureIn' }];
  }

  get categorySlug() {
    return this.router.currentRoute.params.category_slug;
  }

  get title() {
    return getCategoryTitle(this.categorySlug, this.intl);
  }

  get displayCoachTile() {
    return this.isFrench;
  }

  get faqLink() {
    let key = this.isFrench ? 'insurance-hub-stello' : 'insurance-hub-surein';

    return this.zendeskLocalization.getLocalizedArticle(key);
  }

  get productRoute() {
    return PRODUCT_ROUTE;
  }

  get textKey() {
    if (this.categorySlug) {
      return getCategoryTextKey(this.categorySlug);
    } else {
      return this.isFrench
        ? 'insurance-hub.marketplace.header.description.all-insurance.fr-orgas'
        : 'insurance-hub.marketplace.header.description.all-insurance.de-orgas';
    }
  }

  get linkKey() {
    return this.isFrench
      ? 'insurance-hub.marketplace.header.description.link-text.fr-orgas'
      : 'insurance-hub.marketplace.header.description.link-text.de-orgas';
  }

  get url() {
    return this.isFrench
      ? 'https://quote.stello.eu/coach/quiz/0192293f-d070-70df-9c86-7a4f70363ccc/'
      : this.zendeskLocalization.getLocalizedArticle('insurance-hub');
  }
}
