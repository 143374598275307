import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { task } from 'ember-concurrency';

import { ACCOUNT_TYPE } from 'qonto/constants/bank-account';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class AccountsDetailsRoute extends Route {
  @service organizationManager;
  @service router;
  @service store;
  @service sentry;

  beforeModel() {
    let { organization, membership } = this.organizationManager;

    if (membership.shouldSubmitKyc && organization.kybPending) {
      return this.router.replaceWith('kyc.intro', {
        queryParams: {
          redirectRoute: 'accounts',
        },
      });
    }
  }

  async model(params) {
    let { organization } = this.organizationManager;
    let bankAccount = organization.bankAccounts.find(({ slug }) => slug === params.slug);
    let remunerationDetails;
    if (bankAccount?.accountType === ACCOUNT_TYPE.REMUNERATED && bankAccount?.status === 'active') {
      try {
        let remuneratedAccountDetails = await this.fetchRemuneratedAccountDetailsTask.perform(
          bankAccount.id
        );
        remunerationDetails = remuneratedAccountDetails?.remunerationDetails;
      } catch (error) {
        let errorInfo = ErrorInfo.for(error);
        if (errorInfo.shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      }
    }
    return { bankAccount, remunerationDetails, organizationSlug: organization.slug };
  }

  afterModel({ bankAccount, remunerationDetails, organizationSlug }) {
    if (
      !bankAccount ||
      (bankAccount.accountType === ACCOUNT_TYPE.REMUNERATED && bankAccount.status !== 'active') ||
      (bankAccount.accountType === ACCOUNT_TYPE.REMUNERATED &&
        bankAccount.status === 'active' &&
        !remunerationDetails)
    ) {
      this.router.replaceWith('accounts', organizationSlug);
    }
  }

  fetchRemuneratedAccountDetailsTask = task(async accountId => {
    let remunerationAdapter = this.store.adapterFor('remunerated-account');
    return await remunerationAdapter.getDetails(accountId);
  });

  setupController(controller, model, transition) {
    super.setupController(controller, model, transition);
    controller.set('origin', transition.from);
  }
}
