import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import { CATEGORIES } from 'qonto/constants/connect';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class ConnectHubCategoriesController extends Controller {
  @service connectManager;
  @service intl;
  @service segment;

  @tracked searchQuery;
  @tracked stakeholderSlug;
  @tracked integrations = [];
  @tracked highlightedIntegrations = [];

  page = 1;
  hasMorePages = false;
  queryParams = ['searchQuery', 'stakeholderSlug'];

  get selectedStakeholder() {
    return this.model.allStakeholders.find(({ slug }) => slug === this.stakeholderSlug);
  }

  get pageTitle() {
    let titles = {
      [CATEGORIES.ACCOUNTING]: this.intl.t('section-title.accounting'),
      [CATEGORIES.BANKS]: this.intl.t('section-title.banks'),
      [CATEGORIES.BUSINESS_PAYMENTS]: this.intl.t('section-title.business-and-payments'),
      [CATEGORIES.CLOUD_STORAGE]: this.intl.t('section-title.cloud-and-storage'),
      [CATEGORIES.FINANCE]: this.intl.t('section-title.finance'),
      [CATEGORIES.HR]: this.intl.t('section-title.hr'),
      [CATEGORIES.PRODUCTIVITY]: this.intl.t('section-title.productivity'),
      [CATEGORIES.INVOICE_IMPORT]: this.intl.t('section-title.invoice-import'),
    };

    return titles[this.model.categorySlug] || '';
  }

  get pageDescription() {
    let descriptions = {
      [CATEGORIES.BANKS]: this.intl.t('qonto-hub.connect.banks.subtitle'),
      [CATEGORIES.INVOICE_IMPORT]: this.intl.t('qonto-hub.connect.invoices.subtitle'),
    };

    return descriptions[this.model.categorySlug] || '';
  }

  get compactCards() {
    return (
      this.model.categorySlug === CATEGORIES.BANKS ||
      this.model.categorySlug === CATEGORIES.INVOICE_IMPORT
    );
  }

  get highlighedIntegrationsTitle() {
    let titles = {
      [CATEGORIES.BANKS]: this.intl.t('qonto-hub.connect.banks.popular-section.title'),
      [CATEGORIES.INVOICE_IMPORT]: this.intl.t('qonto-hub.connect.banks.popular-section.title'),
    };

    return titles[this.model.categorySlug] || '';
  }

  get filteredIntegrationsTitle() {
    let titles = {
      [CATEGORIES.BANKS]: this.intl.t('qonto-hub.connect.banks.all-section.title'),
      [CATEGORIES.INVOICE_IMPORT]: this.intl.t('qonto-hub.connect.invoices.all-section.title'),
    };

    return titles[this.model.categorySlug] || '';
  }

  get showFilters() {
    return this.model.categorySlug !== CATEGORIES.BANKS;
  }

  get searchResultsCount() {
    if ((this.searchQuery || this.stakeholderSlug) && this.integrations.length > 0) {
      if (this.searchQuery) {
        return this.connectManager.searchIntegrationsTask.lastSuccessful?.value.total_results_size;
      }
      return this.connectManager.getIntegrationsTask.lastSuccessful?.value.total_results_size;
    }
    return 0;
  }

  setData(model) {
    this.integrations = model.integrations.results;
    this.highlightedIntegrations = model.highlightedIntegrations?.results;
    this.hasMorePages = model.integrations.total_pages > this.page;
  }

  loadMoreTask = dropTask(async () => {
    if (!this.hasMorePages) return;

    this.page += 1;

    if (this.searchQuery) {
      let { total_pages: totalPages, results } = await this.connectManager.searchIntegrationsTask
        .perform(this.searchQuery, this.page)
        .catch(ignoreCancelation);

      this.hasMorePages = totalPages > this.page;
      this.integrations = [...this.integrations, ...results];
    } else {
      let { total_pages: totalPages, results } = await this.connectManager.getIntegrationsTask
        .perform({
          categoryId: this.model.categoryId,
          stakeholderId: this.selectedStakeholder?.id,
          page: this.page,
          pageSize: this.pageSize,
        })
        .catch(ignoreCancelation);

      this.hasMorePages = totalPages > this.page;
      this.integrations = [...this.integrations, ...results];
    }
  });

  @action onLoadMore() {
    this.loadMoreTask.linked().perform().catch(ignoreCancelation);
  }

  async queryFilters() {
    let { results } = await this.connectManager.getIntegrationsTask
      .perform({
        categoryId: this.model.categoryId,
        stakeholderId: this.selectedStakeholder?.id,
      })
      .catch(ignoreCancelation);

    this.integrations = results;

    let isBanksCategory = this.model.categorySlug === CATEGORIES.BANKS;
    let isInvoiceImportCategory = this.model.categorySlug === CATEGORIES.INVOICE_IMPORT;

    if (isBanksCategory) {
      let { results } = await this.connectManager.highlightBankIntegrationsTask
        .perform({
          categoryId: this.model.categoryId,
          stakeholderId: this.selectedStakeholder?.id,
        })
        .catch(ignoreCancelation);

      this.highlightedIntegrations = results;
    }

    if (isInvoiceImportCategory) {
      let { results } = await this.connectManager.highlightGetMyInvoicesTask
        .perform({
          categoryId: this.model.categoryId,
          stakeholderId: this.selectedStakeholder?.id,
        })
        .catch(ignoreCancelation);

      this.highlightedIntegrations = results;
    }
  }

  onSearchTask = dropTask(async query => {
    this.searchQuery = query || undefined;
    this.page = 1;

    if (query) {
      let { results } = await this.connectManager.searchIntegrationsTask
        .perform(query, this.page)
        .catch(ignoreCancelation);

      this.integrations = results;
    } else {
      this.queryFilters();
    }
  });

  @action
  handleStakeholderSelect(stakeholder) {
    let slug = stakeholder?.slug;

    this.stakeholderSlug = slug;
    this.queryFilters();

    if (slug) {
      this.segment.track('connect_filter', {
        filterName: 'DesignedFor',
        category: undefined,
        designedFor: this.stakeholderSlug,
      });
    }
  }

  @action
  handleFilterReset() {
    this.stakeholderSlug = undefined;
    this.page = 1;
    this.queryFilters();
  }
}
