import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

const DEFAULT_PAGE = 1;
const DEFAULT_PER_PAGE = 25;

export default class TasksPastMultiDirectDebitCollectionController extends Controller {
  @tracked page = DEFAULT_PAGE;
  @tracked perPage = DEFAULT_PER_PAGE;

  get requests() {
    return this.model.fetchRequestsTask.lastSuccessful?.value ?? [];
  }

  @action
  handlePerPageChange(perPage) {
    this.page = DEFAULT_PAGE;
    this.perPage = perPage;
  }
}
