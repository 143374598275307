import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

export default class SettingsPersonalSecurityRoute extends Route {
  @service userManager;

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'account-management');
  }

  async model() {
    let { userManager } = this;
    let devices = await userManager.getDevices();
    return { user: userManager.currentUser, devices };
  }
}
