export const cardSetPinKYC = {
  steps: {
    intro: {
      componentName: 'cards/kyc/set-pin-intro',
      nextStepId: 'document-selection',
    },
    'document-selection': {
      componentName: 'kyc/document-selection',
      nextStepId: 'upload-documents',
    },
    'upload-documents': {
      componentName: 'kyc/upload-documents',
      nextStepId: 'user-info',
    },
    'user-info': {
      componentName: 'kyc/user-info',
      nextStepId: 'set-pin-transition',
    },
    'set-pin-transition': {
      componentName: 'cards/kyc/set-pin-transition',
    },
  },
  options: {
    enablePersistence: true,
  },
};
