import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class SupplierInvoicesExportRoute extends Route {
  @service abilities;
  @service homePage;
  @service menu;

  beforeModel(transition) {
    if (this.abilities.cannot('export supplier-invoice')) {
      return this.homePage.replaceWithDefaultPage();
    }

    if (transition.from?.name === 'accounting-hub.details' && this.menu.isVisible) {
      this.menu.hide();
    }
  }

  setupController(controller, model, transition) {
    super.setupController(...arguments);

    if (['supplier-invoices.index', 'accounting-hub.details'].includes(transition.from?.name)) {
      controller.origin = transition.from;
    } else {
      controller.origin = {
        name: 'supplier-invoices.index',
        queryParams: {},
        paramNames: [],
      };
    }
  }
}
