import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { DEPOSIT_CAPITAL_STEPPER_STEP } from 'qonto/constants/organization';

export default class CapitalReleaseRoute extends Route {
  @service capitalIncreaseManager;

  activate() {
    this.capitalIncreaseManager.setCurrentStep(DEPOSIT_CAPITAL_STEPPER_STEP.FUND_RELEASE);
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.set('showLoading', false);
  }
}
