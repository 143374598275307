import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class SettingsReferralsShowRoute extends Route {
  @service organizationManager;

  redirect() {
    let organizationSlug = this.organizationManager.organization.slug;
    this.replaceWith('settings.referrals.index', organizationSlug);
  }
}
