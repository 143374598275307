import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class GuestsUpgradeTeamsRoute extends Route {
  @service router;
  @service abilities;

  rootRouteName = 'guests';

  beforeModel() {
    if (this.abilities.can('access accountant-access')) {
      return this.router.replaceWith(this.rootRouteName);
    }
  }
}
