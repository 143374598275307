import Controller from '@ember/controller';
import { service } from '@ember/service';

export default class PagopaIndexController extends Controller {
  @service flowLinkManager;
  @service intl;
  @service modals;
  @service organizationManager;
  @service segment;
  @service router;

  get emptyStateOptions() {
    let { organization, membership } = this.organizationManager;

    return {
      title: this.intl.t('pagopa.section.empty-state.title'),
      subtitle: this.intl.t('pagopa.section.empty-state.subtitle'),
      lottieSrc: `/lotties/pagopa/empty-state.json`,
      button: {
        label: this.intl.t('pagopa.section.empty-state.cta'),
        callback: () => {
          if (organization.kybAccepted || (membership.shouldSubmitKyc && organization.kybPending)) {
            this.segment.track('pagopa_section_start-button_clicked');
            this.flowLinkManager.transitionTo({
              name: 'pagopa',
              stepId: 'notice-info',
            });
          } else {
            this.modals.open('pagopa-kyb-pending-modal');
          }
        },
      },
    };
  }
}
