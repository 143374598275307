import Controller, { inject as controller } from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { isTesting, macroCondition } from '@embroider/macros';
import { dropTask, timeout, waitForProperty } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';
import scrollIntoView from 'qonto/utils/scroll-into-view';

export default class RequestsTransfersMultiReviewController extends Controller {
  @controller('requests.transfers.multi') requestsTransfersMultiController;

  @service errors;
  @service toastFlashMessages;
  @service intl;
  @service segment;
  @service sentry;

  @action
  handleGoBack() {
    this.transitionToRoute('requests.landing');
  }

  confirmFlightTask = dropTask(async () => {
    let invalidMultiTransfer = await this._validateMultiTransfer();
    let invalidTransfers = await this._validateTransfers();

    this.model.id = null;
    this.model.transfers = this.model.selectedTransfers;

    if (invalidTransfers.length > 0 || !invalidMultiTransfer) {
      // Scroll view to the first element with error state
      let delay = macroCondition(isTesting()) ? 0 : 500;
      await timeout(delay);

      scrollIntoView('[data-has-error]');
      return;
    }
    this.model.confirmWarnings = null;

    try {
      await this.model.check();
      this.segment.track('request_confirmation_started', {
        request_type: this.model.requestType,
      });
      this.transitionToRoute('requests.transfers.multi.review.confirm');
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      // Wait for the form validation to be done for new beneficiaries
      // before checking validation status
      await Promise.all(
        this.model.newBeneficiaries.map(beneficiary =>
          waitForProperty(beneficiary.validations, 'isValidating', false)
        )
      );

      let hasInvalidBeneficiaries = this.model.newBeneficiaries.some(
        beneficiary => beneficiary.validations.isInvalid
      );

      // Display server error feedback that can happens when
      // the beneficiaries validations are valid but the request fails
      if (!hasInvalidBeneficiaries) {
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      }
    }
  });

  async _validateTransfers() {
    let invalidTransfers = [];

    for (let i = 0; i < this.model.selectedTransfers.length; i++) {
      let transfer = this.model.selectedTransfers[i];
      transfer.set('didValidate', false);
      let { validations: transferValidation } = await transfer.validate();
      transfer.set('didValidate', true);

      if (!transferValidation.isValid) {
        invalidTransfers.push(transfer);
      }
    }

    return invalidTransfers;
  }

  async _validateMultiTransfer() {
    let { validations } = await this.model.validate();
    if (!validations.isValid) {
      this.model.set('didValidate', true);
    }

    return this.model.validations.attrs.note.isValid;
  }
}
