export default {
  "container": "qWY",
  "wrapper": "qWh",
  "grey-header-block": "qWf",
  "connect-app-header": "qWK",
  "connect-app-header-link": "qWG",
  "connect-disclaimer-wrapper": "qWr",
  "connect-app-header-content": "qWb",
  "connect-app-header-cta": "qWy",
  "connect-app-content": "qWU",
  "connect-app-description": "qWj",
  "connect-app-body": "qWW"
};
