import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import { CATEGORIES } from 'qonto/constants/connect';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class ConnectHubCollectionsController extends Controller {
  @service intl;
  @service segment;
  @service connectManager;

  @tracked searchQuery;
  @tracked categorySlug;
  @tracked stakeholderSlug;
  @tracked integrations = [];

  page = 1;
  hasMorePages = false;
  queryParams = ['searchQuery', 'categorySlug', 'stakeholderSlug'];

  get selectedCategory() {
    return this.model.allCategories.find(({ slug }) => slug === this.categorySlug);
  }

  get selectedStakeholder() {
    return this.model.allStakeholders.find(({ slug }) => slug === this.stakeholderSlug);
  }

  get pageTitle() {
    let titles = {
      integrations: this.intl.t('section-title.integrations'),
      partnerships: this.intl.t('section-title.partnerships'),
      ebics: this.intl.t('section-title.ebics'),
    };

    return titles[this.model.collectionSlug] || '';
  }

  get searchResultsCount() {
    if ((this.searchQuery || this.stakeholderSlug) && this.integrations.length > 0) {
      if (this.searchQuery) {
        return this.connectManager.searchIntegrationsTask.lastSuccessful?.value.total_results_size;
      }
      return this.connectManager.getIntegrationsTask.lastSuccessful?.value.total_results_size;
    }
    return 0;
  }

  get categoryOptions() {
    return this.model.allCategories.filter(({ slug }) => slug !== CATEGORIES.AR_CLIENTS_MIGRATION);
  }

  setData(model) {
    this.integrations = model.integrations.results;
    this.hasMorePages = model.integrations.total_pages > this.page;
  }

  loadMoreTask = dropTask(async () => {
    if (!this.hasMorePages) return;

    this.page += 1;

    if (this.searchQuery) {
      let { total_pages: totalPages, results } = await this.connectManager.searchIntegrationsTask
        .perform(this.searchQuery, this.page)
        .catch(ignoreCancelation);

      this.hasMorePages = totalPages > this.page;
      this.integrations = [...this.integrations, ...results];
    } else {
      let { total_pages: totalPages, results } = await this.connectManager.getIntegrationsTask
        .perform({
          categoryId: this.model.categoryId,
          stakeholderId: this.model.stakeholder?.id,
          collectionSlug: this.model.collectionSlug,
          page: this.page,
          pageSize: this.pageSize,
        })
        .catch(ignoreCancelation);

      this.hasMorePages = totalPages > this.page;
      this.integrations = [...this.integrations, ...results];
    }
  });

  @action onLoadMore() {
    this.loadMoreTask.linked().perform().catch(ignoreCancelation);
  }

  async queryFilters() {
    let { results } = await this.connectManager.getIntegrationsTask
      .perform({
        categoryId: this.selectedCategory?.id,
        stakeholderId: this.selectedStakeholder?.id,
        collectionSlug: this.model.collectionSlug,
      })
      .catch(ignoreCancelation);

    this.integrations = results;
  }

  onSearchTask = dropTask(async query => {
    this.searchQuery = query || undefined;
    this.page = 1;

    if (query) {
      let { results } = await this.connectManager.searchIntegrationsTask
        .perform(query, this.page)
        .catch(ignoreCancelation);

      this.integrations = results;
    } else {
      this.queryFilters();
    }
  });

  @action
  handleCategorySelect(category) {
    let slug = category?.slug;

    this.categorySlug = slug;
    this.queryFilters();

    if (slug) {
      this.segment.track('connect_filter', {
        filterName: 'Category',
        category: this.categorySlug,
        designedFor: this.stakeholderSlug,
      });
    }
  }

  @action
  handleStakeholderSelect(stakeholder) {
    let slug = stakeholder?.slug;

    this.stakeholderSlug = slug;
    this.queryFilters();

    if (slug) {
      this.segment.track('connect_filter', {
        filterName: 'DesignedFor',
        category: this.categorySlug,
        designedFor: this.stakeholderSlug,
      });
    }
  }

  @action
  handleFilterReset() {
    this.categorySlug = undefined;
    this.stakeholderSlug = undefined;
    this.page = 1;
    this.queryFilters();
  }
}
