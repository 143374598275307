import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class CustomLabelsSettingsRoute extends Route {
  @service abilities;
  @service homePage;
  @service organizationManager;
  @service router;

  beforeModel() {
    if (this.abilities.cannot('view custom-label')) {
      return this.homePage.replaceWithDefaultPage();
    }

    if (this.abilities.cannot('access custom-label')) {
      return this.router.replaceWith('custom-labels.upgrade-plan');
    }
  }

  model() {
    let { organization } = this.organizationManager;

    return organization.labelLists;
  }
}
