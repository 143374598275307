export const memberEditExpensePermissions = {
  steps: {
    'expense-permissions': {
      componentName: 'member/expense-permissions',
      nextStepId: () => {},
    },
  },
  options: {
    enablePersistence: true,
  },
};
