import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

export default class MandatesListMandateEditController extends Controller {
  @service router;

  @action
  handleClose() {
    this.router.transitionTo('mandates.list.mandate');
  }
}
