import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';
import window from 'ember-window-mock';

import { INVITE_DISCOVERABILITY_STORAGE_KEY } from 'qonto/constants/membership';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { InstructionalTooltip } from 'qonto/react/components/product-discovery/instructional-tooltip';

export default class TeamsIndexController extends Controller {
  @service router;
  @service segment;
  @service intl;

  @tracked isTooltipDismissed = false;

  InstructionalTooltip = InstructionalTooltip;

  membersRoute = 'members';

  get canDeleteTeams() {
    return this.model.length > 1;
  }

  refreshModelTask = dropTask(async () => {
    await this.router.refresh('teams');
  });
  get shouldDisplayTooltip() {
    if (!variation('feature--boolean-invitation-tooltip')) {
      return false;
    }

    let storageValue = safeLocalStorage.getItem(INVITE_DISCOVERABILITY_STORAGE_KEY);

    return storageValue !== 'true';
  }

  @action
  dismissTooltip() {
    this.segment.track('user-access_intro-tooltip_dismiss');
    safeLocalStorage.setItem(INVITE_DISCOVERABILITY_STORAGE_KEY, true);

    this.isTooltipDismissed = true;
  }

  get instructionalTooltipCta() {
    return {
      button: {
        variant: 'primary',
        size: 'small',
      },
      text: this.intl.t('member-invitation.tooltip.cta'),
      isExternal: true,
      onPress: () => {
        this.segment.track('user-access_intro-tooltip_book-a-call');
        window.open('https://calendly.com/vaska-vlahova-qonto/30min', '_blank');
      },
    };
  }
}
