import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { dropTask, task } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class InvoiceSubscriptionSddPaymentLinkRoute extends Route {
  @service store;
  @service sentry;
  @service toastFlashMessages;
  @service intl;
  @service router;
  @service modals;
  @service segment;

  model() {
    let invoiceSubscription = this.modelFor('invoice-subscriptions.sdd');

    this.loadMandateTask
      .perform(invoiceSubscription)
      .catch(ignoreCancelation)
      .catch(this.handleMandateLoadError);

    this.modal = this.modals.open(
      'invoice-subscriptions/payment-link-modal',
      {
        loadMandateTask: this.loadMandateTask,
        onSendEmail: this.onSendEmail,
        onClose: this.confirmExit,
        isFullScreenModal: true,
      },
      {
        focusTrapOptions: {
          clickOutsideDeactivates: false,
          allowOutsideClick: true,
          onDeactivate: this.onCloseModal,
        },
        className: 'epm-from-bottom',
      }
    );

    return {
      invoiceSubscription,
      mandateTask: this.loadMandateTask,
    };
  }

  @action onCloseModal() {
    this.router.transitionTo('invoice-subscriptions.index');
  }

  get mandate() {
    return this.loadMandateTask.lastSuccessful?.value;
  }

  @action
  confirmExit() {
    this.modals.open('popup/confirmation', {
      title: this.intl.t('flow-in-flow.modal.abort.title'),
      description: this.intl.t('flow-in-flow.modal.abort.subtitle'),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('btn.leave'),
      confirmTask: this.exitTask,
    });
  }

  @action onSendEmail() {
    this.router.transitionTo('invoice-subscriptions.index');
  }

  exitTask = task(async close => {
    await close();
    this.segment.track('recurring-invoices_payment-link_canceled');
    this.onCloseModal();
  });

  loadMandateTask = dropTask(async invoiceSubscription => {
    return await invoiceSubscription.directDebitCollectionMandate;
  });

  resetController(_, isExiting) {
    if (isExiting) {
      this.modal?.close();
    }
  }

  @action
  handleMandateLoadError(error) {
    if (ErrorInfo.for(error).shouldSendToSentry) {
      this.sentry.captureException(error);
    }

    this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    this.router.transitionTo('invoice-subscriptions.index');
  }
}
