import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

export default class CapitalShareholdersIndexController extends Controller {
  @service router;
  @service segment;

  @action
  handleClose() {
    this.router.transitionTo('capital.shareholders.index');
  }

  @action
  redirectToPhysicalShareholder() {
    this.router.transitionTo('capital.shareholders.stakeholders.new');
    this.trackingEvent('physical');
  }

  @action
  redirectToLegalShareholder() {
    this.trackingEvent('legal');
    this.router.transitionTo('capital.shareholders.legal-entities.new');
  }

  @action
  trackingEvent(type) {
    this.segment.track('kdeposit_shareholder_type_selected', {
      type,
    });
  }
}
