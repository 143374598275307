import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

export default class PagopaRoute extends Route {
  @service abilities;
  @service homePage;
  @service router;

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'local-payments');
    let canCreatePagopa = this.abilities.can('create pagopa');

    if (!canCreatePagopa) {
      return this.homePage.replaceWithDefaultPage();
    }
  }
}
