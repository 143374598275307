/* eslint-disable  ember/no-controller-access-in-routes */
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class TasksPastIndexRoute extends Route {
  @service sentry;

  queryParams = {
    highlight: {
      refreshModel: false,
    },
    page: { refreshModel: true },
    per_page: { refreshModel: true },
    sort_by: { refreshModel: true },
  };

  model(params) {
    let controller = this.controllerFor(this.routeName);

    controller.requests = [];

    controller.fetchDataTask
      .perform(params)
      .then(() => {
        if (params.highlight) {
          let highlightedRequest = controller.requests.find(req => req.id === params.highlight);
          if (highlightedRequest) {
            return highlightedRequest.belongsTo('approvalWorkflowState').reload();
          }
        }
      })
      .catch(error => {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.resetQueryParams();
    }
  }
}
