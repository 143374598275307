/* eslint-disable @qonto/use-safe-local-storage */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import window from 'ember-window-mock';

export default class FinancingPartnersController extends Controller {
  @service menu;
  @service userManager;
  @service segment;

  @tracked skipIntro = false;

  get showIntro() {
    let financingIntroItem = window.localStorage.getItem(
      `financing-intro-${this.userManager.currentUser.id}`
    );
    return !financingIntroItem && !this.skipIntro;
  }

  @action
  dismissIntro() {
    this.skipIntro = true;
    if (!this.menu.isVisible) {
      this.menu.show();
    }

    this.segment.track('financing_modal_cta');
    window.localStorage.setItem(`financing-intro-${this.userManager.currentUser.id}`, true);
  }
}
