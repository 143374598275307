import Controller from '@ember/controller';
import { service } from '@ember/service';

export default class InvitationsShowMemberInfoController extends Controller {
  @service userManager;

  get user() {
    return this.userManager.currentUser;
  }
}
