import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

export default class CapitalChoosePoaTypeController extends Controller {
  @service router;
  @service segment;

  @action
  handleChoosePoaType(poaType) {
    this.segment.track('kdeposit_poa_type_chosen', {
      poa_type: poaType,
    });
    this.router.transitionTo('capital.address', { queryParams: { poaType } });
  }

  @action
  goBack() {
    this.router.transitionTo('capital.by-laws', { queryParams: { step: 'upload' } });
  }
}
