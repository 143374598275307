import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class InvitationsShowMemberRoute extends Route {
  @service sessionManager;
  @service userManager;

  async redirect(_, transition) {
    if (!this.sessionManager.requireAuthentication(transition, 'invitations.show')) {
      return;
    }

    if (!this.userManager.currentUser) {
      await this.userManager.setup();
    }
  }
}
