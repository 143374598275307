export const matchInvoice = {
  steps: {
    'select-transaction': {
      componentName: 'match-invoice/select-transaction',
      nextStepId: 'other-transaction',
    },
    'other-transaction': {
      componentName: 'match-invoice/other-transaction',
    },
  },
  options: {
    enablePersistence: false,
  },
};
