import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class BeneficiaryMultiRoute extends Route {
  @service organizationManager;
  @service store;

  beforeModel() {
    this.store.unloadAll('bulk-beneficiary');
  }

  model() {
    let { organization, currentAccount } = this.organizationManager;
    return this.store.createRecord('multi-beneficiary', {
      bankAccount: currentAccount,
      organization,
      file: null,
    });
  }

  afterModel() {
    this.transitionTo('beneficiaries.multi.new');
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.set('multiBeneficiary', model);
    controller.set('organizationSlug', this.organizationManager.organization.slug);
  }
}
