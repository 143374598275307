/* eslint-disable @qonto/no-import-roles-constants */
import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';

import * as Sentry from '@sentry/ember';

import { MEMBER_STATUS, ROLES } from 'qonto/constants/membership';

export default class GuestsRoute extends Route {
  @service abilities;
  @service organizationManager;
  @service subscriptionManager;
  @service router;
  @service store;

  queryParams = {
    teamId: {
      refreshModel: true,
    },
  };

  beforeModel() {
    if (this.abilities.cannot('access accountant-access')) {
      return this.router.replaceWith('guests.upgrade-plan');
    }

    Sentry.getCurrentScope().setTag('CFT', 'spend-management');
  }

  async model({ teamId }) {
    let filters = {
      status: [MEMBER_STATUS.ACTIVE, MEMBER_STATUS.REVOKED, MEMBER_STATUS.INVITED],
      roles: [ROLES.REPORTING],
    };

    if (isPresent(teamId)) {
      filters.team_ids = [teamId];
    }

    return {
      guests: await this.store.query('membership', {
        organization_id: this.organizationManager.organization.id,
        filters,
      }),
    };
  }

  redirect({ guests }, transition) {
    if (transition.to.name === 'guests.index') {
      let firstActiveAccountant = guests.find(({ invitable, revoked }) => !invitable && !revoked);

      let firstAccountantInArray = guests?.[0];

      let accountantToShow = firstActiveAccountant || firstAccountantInArray || null;

      if (accountantToShow) {
        this.transitionTo(
          'guests.guest',
          this.organizationManager.organization.slug,
          accountantToShow.id
        );
      }
    }
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.setProperties({ teamId: null });
    }
  }
}
