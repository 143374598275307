import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { F24_V2_SORT_BYS } from 'qonto/constants/sort';

export default class F24V2UpcomingController extends Controller {
  @service store;

  @tracked highlight = null;
  @tracked page = 1;
  @tracked perPage = 25;
  @tracked sortBy = `${F24_V2_SORT_BYS.EXPIRATION_DATE}:asc`;

  queryParams = ['highlight', 'page', { perPage: 'per_page' }, { sortBy: 'sort_by' }];

  get sidebarElement() {
    return document.getElementById('f24-list-sidebar');
  }

  get highlightableItems() {
    let { highlight, model } = this;
    if (highlight) {
      let f24Order = this.store.peekRecord('f24-order', highlight);
      if (f24Order && !model.includes(f24Order)) {
        return [...model, f24Order];
      }
    }
    return model;
  }

  @action updateParam(param, value) {
    this[param] = value;
  }

  @action
  updateHighlightedItem(itemId) {
    this.highlight = itemId;
  }
}
