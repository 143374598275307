import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { task } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class SavingsRoute extends Route {
  @service abilities;
  @service router;
  @service sentry;
  @service store;

  async beforeModel() {
    if (!this.canAccessSavings) {
      this.router.replaceWith('accounts');
      return;
    }
    await this.organization.loadBankAccounts();
  }

  model() {
    let options = { organization: this.organization };

    if (this.shouldFetchRemunerationDetails) {
      this.fetchRemuneratedAccountsTask.perform(this.organization.id).catch(error => {
        let errorInfo = ErrorInfo.for(error);
        if (errorInfo.shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });
      options.remuneratedAccountsTask = this.fetchRemuneratedAccountsTask;
    }

    return options;
  }

  get canAccessSavings() {
    let savingsAccounts = [];
    return (
      ((this.abilities.can('access savingsAccount') && savingsAccounts?.length) ||
        this.abilities.can('access remunerated-account') ||
        this.organization.nonClosedRemuneratedAccounts.length) &&
      this.abilities.can('access bank-account')
    );
  }

  get shouldFetchRemunerationDetails() {
    return (
      this.abilities.can('read remunerated-account') &&
      this.organization.bankAccounts.some(({ isRemunerated }) => isRemunerated)
    );
  }

  get organization() {
    return this.modelFor('organizations');
  }

  fetchRemuneratedAccountsTask = task(async organizationId => {
    let remuneratedAccounts = await this.store.query('remunerated-account', {
      organization_id: organizationId,
    });
    return { remuneratedAccounts };
  });
}
