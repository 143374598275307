import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { dropTask, restartableTask, task, timeout } from 'ember-concurrency';

import { DEBOUNCE_MS } from 'qonto/constants/timers';
import { ERROR_ALREADY_CANCELED, ERROR_CANNOT_CANCEL } from 'qonto/constants/transfers';

export default class BaseTransfersController extends Controller {
  queryParams = [
    'highlight',
    'page',
    'per_page',
    'sort_by',
    'complete',
    { bankAccounts: 'bank-accounts' },
  ];
  @tracked highlight = null;
  complete = false;
  page = 1;
  per_page = 25;
  sort_by = 'next_recursion_date:desc';
  @tracked bankAccounts = '';

  get transfers() {
    return this.model.fetchTransfersTask.lastSuccessful?.value || [];
  }

  @service notifierCounterManager;
  @service subscriptionManager;
  @service router;
  @service organizationManager;
  @service toastFlashMessages;
  @service attachmentsManager;
  @service store;
  @service intl;
  @service modals;
  @service segment;
  @service flowLinkManager;

  get localState() {
    if (this.model.fetchTransfersTask.isRunning) {
      return {
        legend: '',
        illuPath: '',
        title: '',
        error: false,
        isLoading: true,
      };
    }

    if (this.model.fetchTransfersTask.last.isError) {
      return {
        illuPath: '',
        legend: '',
        title: '',
        error: true,
        isLoading: false,
      };
    }

    return {
      legend: '',
      illuPath: '',
      title: '',
      error: false,
      isLoading: false,
    };
  }

  saveTransferTask = restartableTask(async (model, attribute, value) => {
    if (model && attribute) {
      model.set(attribute, value);
    }

    await timeout(DEBOUNCE_MS);

    return model.save();
  });

  cancelTransferTask = task(async (transfer, close) => {
    try {
      await transfer.cancel();
      this.router.transitionTo('transfers.index');

      this.toastFlashMessages.toastSuccess(
        this.intl.t('transfers.cancel-transfer-feedback.success')
      );
    } catch (error) {
      let message;
      if (error.code === ERROR_CANNOT_CANCEL) {
        message = this.intl.t('transfers.cancel-transfer-feedback.cannot-cancel');
      } else if (error.code === ERROR_ALREADY_CANCELED) {
        message = this.intl.t('transfers.cancel-transfer-feedback.already-canceled');
      } else {
        message = this.intl.t('transfers.cancel-transfer-feedback.other-error');
      }

      this.toastFlashMessages.toastError(message);
    } finally {
      close();
    }
  });

  updateBeneficiaryTagTask = dropTask(async (beneficiaryPromise, tag) => {
    // the task is called with the relationship (tranfer.beneficiary)
    // this is just to make sure it's loaded and obtain the
    // actual model
    let beneficiary = await beneficiaryPromise;
    await beneficiary.updateActivityTag(tag);
  });

  get isEmptyGlobally() {
    let { transferRequestsCompleted = 0, transferRequests = 0 } =
      this.notifierCounterManager.counter || {};
    return transferRequestsCompleted + transferRequests + this.transfers.length === 0;
  }

  @action
  handlePerPageChange(value) {
    this.set('page', 1);
    this.set('per_page', value);
  }

  @action
  handleSortBy(sortDefinition) {
    this.setProperties({
      highlight: null,
      page: 1,
      sort_by: sortDefinition,
    });
  }

  @action
  handleOpenFilePreviewModal(selectedFile) {
    let requiredAttachmentDisclaimer;
    let highlightedItem = this.store.peekRecord('transfer', this.highlight);
    if (highlightedItem.shouldHaveAttachments) {
      requiredAttachmentDisclaimer = this.intl.t('transfers.sidebar.attachment.delete_tooltip');
    }
    this.attachmentModal = this.modals.open(
      'attachments/attachment-viewer-modal',
      {
        isFullScreenModal: true,
        transfer: highlightedItem,
        selectedFile,
        requiredAttachmentDisclaimer,
        hasSidebar: false,
        onDownload: this.attachmentsManager.downloadAttachment,
        // attachmnents can't be removed from the sidebar
        deletable: false,
      },
      {
        focusTrapOptions: {
          clickOutsideDeactivates: false,
        },
      }
    );
    this.segment.track('transfer_side_panel_view_attach');
  }

  @action
  handleTriggerTrackEvent() {
    this.segment.track('transfer_creation_clicked');
  }

  @action
  handleEditTransfer(transfer) {
    let organizationAccountsIbans = this.organizationManager.organization.bankAccounts.map(
      b => b.iban
    );
    let isInternalTransfer =
      organizationAccountsIbans.includes(transfer.iban) ||
      Boolean(transfer.beneficiary.get('creditBankAccountId'));

    if (isInternalTransfer) {
      this.router.transitionTo('transfers.internal.edit', transfer);
    } else {
      this.segment.track('transfer_list_open_side_panel_edit');
      return this.flowLinkManager.transitionTo({
        name: 'edit-sepa-transfer',
        stepId: 'details',
        queryParams: {
          transferId: transfer.id,
        },
      });
    }
  }

  @action
  updateHighlightedItem(itemId) {
    this.highlight = itemId;
  }
}
