import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { restartableTask, timeout } from 'ember-concurrency';

import {
  DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES,
  DIRECT_DEBIT_SUBSCRIPTION_STATUS,
} from 'qonto/constants/direct-debit-subscriptions';
import { DEBOUNCE_MS } from 'qonto/constants/timers';
import { filterParams } from 'qonto/utils/compute-query-params';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class DirectDebitCollectionsProcessingRoute extends Route {
  @service organizationManager;
  @service directDebitCollectionsManager;
  @service sentry;
  @service store;

  queryParams = {
    highlight: {
      refreshModel: false,
    },
    page: { refreshModel: true },
    perPage: { refreshModel: true },
    sortBy: { refreshModel: true },
    statusFilter: { refreshModel: true },
    scheduleTypeFilter: { refreshModel: true },
    search: { refreshModel: true },
    bankAccounts: { refreshModel: true },
  };

  model(params) {
    let { isActivatedNotEligible, collectionLimit, guardingDepositPercentage, hasGuarding } =
      this.modelFor('direct-debit-collections');

    params = filterParams(params);

    this.directDebitCollectionsManager.getTotalOnHoldMetaTask
      .perform(params.bankAccounts)
      .catch(ignoreCancelation)
      .catch(error => {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });

    this.fetchSubscriptionsTask
      .perform(params)
      .catch(ignoreCancelation)
      .catch(error => {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });

    return {
      fetchSubscriptionsTask: this.fetchSubscriptionsTask,
      getTotalOnHoldMetaTask: this.directDebitCollectionsManager.getTotalOnHoldMetaTask,
      isActivatedNotEligible,
      collectionLimit,
      guardingDepositPercentage,
      hasGuarding,
    };
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.resetQueryParams();
    }
  }

  fetchSubscriptionsTask = restartableTask(
    async ({
      page,
      perPage,
      sortBy,
      bankAccounts,
      statusFilter,
      scheduleTypeFilter,
      search,
    } = {}) => {
      if (search) {
        await timeout(DEBOUNCE_MS);
      }

      let filters = {
        bank_account_ids: [bankAccounts],
        search,
      };

      filters.status = [
        ...(statusFilter
          ? [statusFilter]
          : [
              DIRECT_DEBIT_SUBSCRIPTION_STATUS.PENDING_MANDATE_SIGNATURE,
              DIRECT_DEBIT_SUBSCRIPTION_STATUS.SCHEDULED,
              DIRECT_DEBIT_SUBSCRIPTION_STATUS.IN_PROGRESS,
            ]),
      ];

      filters.schedule_type = [
        ...(scheduleTypeFilter
          ? [scheduleTypeFilter]
          : Object.values(DIRECT_DEBIT_SUBSCRIPTION_SCHEDULE_TYPES)),
      ];

      return await this.store.query('direct-debit-subscription', {
        filters,
        page,
        per_page: perPage,
        sort_by: sortBy,
      });
    }
  );
}
