import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

export default class AppOnboardingCardsRoute extends Route {
  @service router;
  @service segment;

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'cards');
    this.segment.track('redirected_from_onboarding.cards.index', {
      message: 'User is redirected from old card onboarding url /onboarding/_id/cards',
    });
    // If the user is not onboarded yet, they will be redirected automatically to the new `/organizations/_slug/flows/card-onboarding/choose-card`
    this.router.transitionTo('application');
  }
}
