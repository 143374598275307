import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import * as Sentry from '@sentry/ember';
import dayjs from 'dayjs';
import { dropTask } from 'ember-concurrency';

import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';
import { MILEAGE_VEHICLE_DETAILS_STORAGE_KEY, REQUEST_ORIGINS } from 'qonto/constants/requests';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { FlowSetup } from 'qonto/routes/flows/setup/internals';
import { ErrorInfo } from 'qonto/utils/error-info';
import { getVehicleTypes } from 'qonto/utils/mileage/vehicle-types';

class MileageFlowDataContext {
  @tracked requestMileage = null;
  @tracked skipVehicleDetails = false;
  @tracked firstTime = true;
  @tracked ibanExists = false;
  @tracked shouldGoToVehicleDetailsStep = false;
  vehicles = {};

  constructor(requestMileage, vehicles) {
    this.requestMileage = requestMileage;
    this.vehicles = vehicles;
  }
}

export default class RequestMileageFlowSetup extends FlowSetup {
  @service abilities;
  @service intl;
  @service homePage;
  @service modals;
  @service organizationManager;
  @service router;
  @service segment;
  @service sentry;
  @service store;

  @tracked dataContext = null;

  constructor() {
    super(...arguments);

    let { organization } = this.organizationManager;
    let { legalCountry } = organization;

    let defaultVehicle = { type: 'car' };

    let requestMileage = this.store.createRecord('request-mileage', {
      organization,
      tripDate: dayjs().format(DATE_PICKER_FIELD_FORMAT),
      ...(!organization.isSpanish && { vehicle: defaultVehicle }),
    });

    let vehicles = getVehicleTypes({
      intl: this.intl,
      legalCountry,
    });

    let vehicleDetails = this.storedVehicleDetails;

    this.dataContext = new MileageFlowDataContext(requestMileage, vehicles);

    let checkVehicleForCountry = (countryCode, savedVehicle) => {
      let vehicleTypes = getVehicleTypes({ intl: this.intl, legalCountry: countryCode });
      if (countryCode === 'DE' && savedVehicle.power !== undefined) return true;
      return !Object.keys(vehicleTypes).includes(savedVehicle.type);
    };

    if (vehicleDetails && !organization.isSpanish) {
      requestMileage.vehicle = vehicleDetails;
      this.dataContext.skipVehicleDetails = Boolean(vehicleDetails);

      if (checkVehicleForCountry(organization.legalCountry, vehicleDetails)) {
        this.dataContext.requestMileage.vehicle = defaultVehicle;
        this.dataContext.skipVehicleDetails = false;
      }
    }

    if (organization.isSpanish) {
      this.dataContext.requestMileage.vehicle = null;
      this.dataContext.skipVehicleDetails = true;
    }
  }

  get initialStepId() {
    return 'journey';
  }

  async beforeFlow() {
    let origin = this.router.currentRoute.queryParams.origin;
    this.segment.track('request_mileage_started', {
      ...(origin && { origin }),
    });

    Sentry.getCurrentScope().setTag('CFT', 'spend-management');

    let cannotUseRequestMileage = this.abilities.cannot('use mileages request');
    let cannotCreateRequestMileage = this.abilities.cannot('create mileage request');

    if (cannotUseRequestMileage || cannotCreateRequestMileage) {
      return this.homePage.replaceWithDefaultPage();
    }

    let { membership } = this.organizationManager;

    try {
      await membership.getIban();
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }

    Object.assign(this.dataContext.requestMileage, {
      iban: membership?.iban,
    });
    this.dataContext.ibanExists = Boolean(this.dataContext.requestMileage.iban);
  }

  onComplete() {
    let isReimbursementsOrigin =
      this.router.currentRoute.queryParams.origin === REQUEST_ORIGINS.reimbursements ||
      this.router.currentRoute.queryParams.origin === REQUEST_ORIGINS.REIMBURSEMENTS_EMPTY_STATE;

    if (isReimbursementsOrigin) {
      this.router.transitionTo('reimbursements.requests');
      return;
    }
    this.router.transitionTo('requests.pending');
  }

  onAbortTask = dropTask(async (_, { id: stepId }) => {
    let transitionToReimbursements =
      this.router.currentRoute.queryParams.origin === REQUEST_ORIGINS.reimbursements ||
      this.router.currentRoute.queryParams.origin === REQUEST_ORIGINS.REIMBURSEMENTS_EMPTY_STATE;

    if (transitionToReimbursements) {
      return await this.handleAbort.perform('reimbursements');
    }

    if (stepId === 'intro') {
      this.router.transitionTo('requests.pending');
      return true;
    }

    return await this.handleAbort.perform('requests.pending');
  });

  handleAbort = dropTask(async transitionTo => {
    let result = await this.modals.open('popup/destructive', {
      title: this.intl.t('requests.mileage.close.title'),
      description: this.intl.t('requests.mileage.close.subtitle'),
      cancel: this.intl.t('requests.mileage.close.cancel'),
      confirm: this.intl.t('requests.mileage.close.cta'),
    });

    if (result === 'confirm') {
      if (this.dataContext.requestMileage.isNew) {
        this.dataContext.requestMileage.destroyRecord();
      }

      this.router.transitionTo(transitionTo);

      return true;
    }

    return false;
  });

  get storedVehicleDetails() {
    let mileageVehicleDetailsStored = safeLocalStorage.getItem(MILEAGE_VEHICLE_DETAILS_STORAGE_KEY);

    if (mileageVehicleDetailsStored) {
      return JSON.parse(mileageVehicleDetailsStored);
    }

    return null;
  }
}
