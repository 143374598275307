import Controller, { inject as controller } from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';

export default class RequestsTransfersMultiConfirmController extends Controller {
  @service organizationManager;
  @service intl;
  @service toastFlashMessages;
  @service segment;
  @service router;

  model = null;

  @controller('requests.transfers.multi') parentController;

  @action
  handleCloseConfirm(closeModal) {
    if (closeModal !== 'submit') {
      this.router.transitionTo('requests.transfers.multi.review');
    }
  }

  handleSubmitTransfersTask = dropTask(async closeModal => {
    let mt = await this.model.save();

    let bulkTransfers = mt.transfers;
    bulkTransfers.forEach(bt => {
      // destroy attachments,
      // indeed we send the attachments within the transfer
      // and get back attachmentsIds.
      let savedAttachements = bt.attachments.filter(item => item.isNew);
      savedAttachements.forEach(a => a.unloadRecord());
    });

    this.segment.track('request_created', { request_type: this.model.requestType });

    let message = this.intl.t('toasts.request_created');
    this.toastFlashMessages.toastInfo(message, 'transfer_create');
    closeModal('submit');
    this.router.transitionTo(
      this.parentController.origin === 'transfers' ? 'transfers.requests' : 'requests.pending'
    );
  });
}
