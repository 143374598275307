export default {
  "guests": "qhz",
  "guests--upsell-screen": "qha",
  "no-members": "qhH",
  "teams-header": "qhO",
  "empty": "qhm",
  "invite-text": "qhp",
  "body": "qhx",
  "members": "qhw",
  "members-list": "qhN",
  "members-title": "qhv caption-bold",
  "member": "qho",
  "details": "qhn"
};
