import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';

import { dropTask } from 'ember-concurrency';

export default class CardsSettingsOptionsController extends Controller {
  @service abilities;
  @service intl;
  @service toastFlashMessages;
  @service router;
  @service organizationManager;
  @service segment;
  @service sentry;

  submitTask = dropTask(
    waitFor(async () => {
      let { card } = this.model;

      try {
        await card.changeOptions();
        this.segment.track('cards_side_panel_settings_edit_confirmed', {
          card_type: card.cardLevel,
        });
        this.toastFlashMessages.toastInfo(this.intl.t('toasts.cards.settings-updated'));
        this.goBack();
      } catch (error) {
        this.sentry.captureException(error);
        card.rollbackAttributes();
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      }
    })
  );

  @action goBack() {
    let { queryParams } = this.model;
    return this.router.transitionTo('cards.index', this.organizationManager.organization.slug, {
      queryParams,
    });
  }
}
