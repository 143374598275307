import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class CapitalShareholderOwnerChooseTypeRoute extends Route {
  @service capitalIncreaseManager;
  @service menu;

  activate() {
    this.capitalIncreaseManager.hideCapitalStepper();
    if (this.menu.isVisible) {
      this.menu.hide();
    }
  }

  deactivate() {
    this.capitalIncreaseManager.showCapitalStepper();
    this.menu.show();
  }
}
