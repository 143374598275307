import { action } from '@ember/object';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { dropTask } from 'ember-concurrency';

import { FlowSetup } from 'qonto/routes/flows/setup/internals';

class ConnectApplicationsActivationDataContext {
  hubApplication = null;
  hubConnection = null;
  hubNotificationRule = null;
  organization = null;
  applicationSlug = null;

  constructor({ organization, applicationSlug }) {
    this.organization = organization;
    this.applicationSlug = applicationSlug;
  }
}

export default class ConnectApplicationsActivationFlowSetup extends FlowSetup {
  @service abilities;
  @service homePage;
  @service intl;
  @service modals;
  @service organizationManager;
  @service segment;
  @service store;
  @service router;

  constructor() {
    super(...arguments);

    let { organization } = this.organizationManager;
    let { queryParams } = this.router.currentRoute;
    let { applicationSlug } = queryParams;
    this.dataContext = new ConnectApplicationsActivationDataContext({
      organization,
      applicationSlug,
    });
  }

  async beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'connect');

    if (this.abilities.cannot('view connect')) {
      return this.homePage.replaceWithDefaultPage();
    }

    try {
      let hubConnections = await this.store.query('hub-connection', {
        organization_id: this.dataContext.organization.id,
        includes: ['rules'],
        filters: {
          external_service: this.dataContext.applicationSlug,
        },
      });
      let hubConnection = hubConnections[0];
      let applications = await this.store.query('hub-application', {
        organization_id: this.dataContext.organization.id,
        filters: { applicationSlug: this.dataContext.applicationSlug },
      });
      let application = applications[0];
      let hubNotificationRule = this.store.createRecord('hub-notification-rule', {
        ibans: [],
        channelId: hubConnection.params.channel_id,
        channelName: hubConnection.params.channel,
        connection: hubConnection,
      });

      this.dataContext = {
        ...this.dataContext,
        hubConnection,
        hubNotificationRule,
        hubApplication: application,
      };
    } catch {
      return this.homePage.replaceWithDefaultPage();
    }
  }

  @action
  onComplete() {
    this.segment.track('connect_slack_app_activation_flow_success_cross_button_clicked');

    this.router.replaceWith(
      'settings.connect-hub.applications.hub-application',
      this.dataContext.applicationSlug
    );
  }

  onAbortTask = dropTask(async ({ hubNotificationRule, applicationSlug }, { id: stepId }) => {
    if (stepId !== 'choose-alert') {
      let result = await this.modals.open('popup/destructive', {
        title: this.intl.t('qonto-hub.activation.exit-modal.title'),
        description: this.intl.t('qonto-hub.activation.exit-modal.subtitle'),
        cancel: this.intl.t('qonto-hub.activation.exit-modal.cancel-btn'),
        confirm: this.intl.t('qonto-hub.activation.exit-modal.confirm-btn'),
      });
      if (result !== 'confirm') {
        return false;
      }
    }

    hubNotificationRule.unloadRecord();

    this.router.replaceWith('settings.connect-hub.applications.hub-application', applicationSlug);
    return true;
  });
}
