/* eslint-disable require-await */
import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { DEBUG } from '@glimmer/env';

import { didCancel, restartableTask } from 'ember-concurrency';

import { DEFAULT_FLOW_QUERY_PARAMS } from 'qonto/constants/query-params/flow';
import { flowDescription } from 'qonto/routes/flows/descriptions';
import { flowSetupClasses } from 'qonto/routes/flows/setup';
import { ErrorInfo } from 'qonto/utils/error-info';
import urlFromRouteInfo from 'qonto/utils/url-from-route-info';

export default class FlowsRoute extends Route {
  @service flow;
  @service menu;
  @service router;
  @service sentry;

  constructor() {
    super(...arguments);

    this.on('activate', transition => {
      this.menu.hide();
      // Transitioning to the flows route from any settings route
      // triggers the activate event twice, to avoid redifining the
      // the referer page we check if the name is different to flows.
      let notFromFlow = transition.from?.name !== 'flows';
      let notFromDeeplinks = transition.from?.name !== 'protected-deeplinks';
      if (notFromFlow && notFromDeeplinks) {
        this.flow.refererPage = urlFromRouteInfo(this.router, transition.from);
      }
    });

    this.on('deactivate', () => {
      this.menu.show();
      this.flow.reset();
    });
  }

  model(params) {
    return { params, initializeFlowTask: this.initializeFlowTask };
  }

  initializeFlowTask = restartableTask(async (transition, name, stepId) => {
    await transition.promise;

    if (!this.flow.isInitialised) {
      await this.flow.setup({ flowDescription, flowSetupClasses, flowName: name, stepId });
    } else {
      await this.flow.updateFromUrlChange({ name, stepId });
    }
  });

  async afterModel({ params }, transition) {
    this.initializeFlowTask.perform(transition, params?.name, params?.step_id).catch(error => {
      if (didCancel(error)) return;
      // eslint-disable-next-line no-console
      if (DEBUG) console.error(error); // to avoid swallowing assertions made for developers

      if (ErrorInfo.for(error).shouldSendToSentry) {
        return this.sentry.captureException(error);
      }
    });
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      this._resetQueryParams(controller);
      this.initializeFlowTask.cancelAll();
    }
  }

  _resetQueryParams(controller) {
    let [queryParamsList] = controller.queryParams;

    let getQueryParamDefaultValue = queryParamKey => {
      if (!DEFAULT_FLOW_QUERY_PARAMS.hasOwnProperty(queryParamKey)) return;
      return DEFAULT_FLOW_QUERY_PARAMS[queryParamKey];
    };

    for (let [queryParamKey] of Object.entries(queryParamsList)) {
      controller.set(`${queryParamKey}`, getQueryParamDefaultValue(queryParamKey));
    }
  }
}
