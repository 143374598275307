import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

export default class BudgetsRoute extends Route {
  @service abilities;
  @service homePage;
  @service router;

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'spend-management');
    if (this.abilities.cannot('access budget')) {
      this.homePage.visitDefaultPage();
    }
  }
}
