import Route from '@ember/routing/route';
import { service } from '@ember/service';

/**
 * Deprecated route that we keep to redirect user coming from old bookmarks
 * We use replacewith to update the history entry
 */
export default class TransfersNewRoute extends Route {
  @service router;

  beforeModel() {
    this.router.replaceWith('transfers.landing');
  }
}
