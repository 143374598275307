import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class ConnectionsInvoicesRoute extends Route {
  @service abilities;
  @service router;

  beforeModel() {
    if (this.abilities.cannot('view gmi-integration')) {
      this.router.transitionTo('settings.connections.apps');
    }
  }

  model() {
    return {
      appsConnectionsTask: this.modelFor('settings.connections').connections.appsConnectionsTask,
      banksConnectionsTask: this.modelFor('settings.connections').connections.banksConnectionsTask,
      invoicesConnectionsTask:
        this.modelFor('settings.connections').connections.invoicesConnectionsTask,
      canViewAppsConnections: this.modelFor('settings.connections').canViewAppsConnections,
      canViewBankConnections: this.modelFor('settings.connections').canViewBankConnections,
      canViewInvoicesConnections: this.modelFor('settings.connections').canViewInvoicesConnections,
    };
  }
}
