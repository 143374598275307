export default {
  "page-wrapper": "qni",
  "back-button": "qnA",
  "abort-button": "qnY",
  "container": "qnh",
  "subtitle": "qnf",
  "button": "qnK",
  "panel-list": "qnG",
  "panel-list-item": "qnr",
  "card-illustration": "qnb"
};
