import { action } from '@ember/object';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';
import { TrackedArray } from 'tracked-built-ins';

import { FlowSetup } from 'qonto/routes/flows/setup/internals';

class ProfileKYCDataContext {
  documentType = null;
  documents = new TrackedArray();
}

export default class ProfileKYCFlowSetup extends FlowSetup {
  @service router;
  @service modals;
  @service intl;

  constructor() {
    super(...arguments);

    this.dataContext = new ProfileKYCDataContext();
  }

  @action
  onComplete() {
    this.transitionToProfile();
  }

  transitionToProfile() {
    return this.router.transitionTo('settings.personal.profile');
  }

  onAbortTask = dropTask(async (_, { id: stepId }) => {
    if (stepId === 'document-selection') {
      this.transitionToProfile();
      return true;
    }

    let modalResult = await await this.modals.open('popup/destructive', {
      title: this.intl.t('flow-in-flow.modal.abort.title'),
      description: this.intl.t('flow-in-flow.modal.abort.subtitle'),
      cancel: this.intl.t('btn.back'),
      confirm: this.intl.t('btn.leave'),
    });

    if (modalResult === 'confirm') {
      this.transitionToProfile();
      return true;
    }

    return false;
  });
}
