import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import { DEPOSIT_CAPITAL_STEPPER_STEP } from 'qonto/constants/organization';
import { sortByKey } from 'qonto/utils/sort-by-keys';

export default class CapitalDepositRoute extends Route {
  @service capitalIncreaseManager;
  @service featuresManager;
  @service notifierManager;
  @service router;
  @service store;

  @reads('featuresManager.flags.qontoNotary') isQontoNotaryEnabled;

  activate() {
    this.capitalIncreaseManager.setCurrentStep(DEPOSIT_CAPITAL_STEPPER_STEP.CAPITAL_DEPOSIT);

    if (this.isStandardDepositCapitalFlowType && this.isQontoNotaryEnabled) {
      this.notifierManager.on(
        'organization.deposit_certificate_signed',
        this,
        'redirectToNextStep'
      );
    }
  }

  async model() {
    let { id: organizationId } = this.capitalIncreaseManager.organization;

    let stakeholders = await this.store.query('stakeholder', {
      organization_id: organizationId,
    });

    let legalEntities = await this.store.query('legal-entity', {
      organization_id: organizationId,
    });

    let physicalShareholderOwner = stakeholders.find(
      stakeholder => stakeholder.isCurrentUser && stakeholder.shareholder
    );

    let physicalShareholders = stakeholders
      .filter(stakeholder => stakeholder.shareholder && !stakeholder.isCurrentUser)
      .sort(sortByKey('createdAt'));

    let legalShareholderOwner = physicalShareholderOwner
      ? null
      : legalEntities.find(legalEntity => legalEntity.stakeholder.isCurrentUser);

    let legalShareholders = legalEntities.filter(
      legalEntity => legalEntity.id !== legalShareholderOwner?.id
    );

    return {
      physicalShareholders,
      legalShareholders,
      physicalShareholderOwner,
      legalShareholderOwner,
    };
  }

  deactivate() {
    if (this.isStandardDepositCapitalFlowType && this.isQontoNotaryEnabled) {
      this.notifierManager.off(
        'organization.deposit_certificate_signed',
        this,
        'redirectToNextStep'
      );

      this.redirectToNextStepTask.cancelAll();
    }
  }

  async redirectToNextStep() {
    try {
      await this.redirectToNextStepTask.perform();
    } catch (error) {
      if (error.name !== 'TaskCancelation') {
        throw error;
      }
    }
  }

  get isStandardDepositCapitalFlowType() {
    return !this.capitalIncreaseManager.organization.isFastTrackDepositCapitalFlowType;
  }

  redirectToNextStepTask = dropTask(async () => {
    let { id: organizationId } = this.capitalIncreaseManager.organization;
    let organization = await this.store.findRecord('organization', organizationId);

    if (organization.depositCertificateSigned) {
      this.router.transitionTo('capital.certificate');
    }
  });
}
