import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { dropTask } from 'ember-concurrency';

import { BUDGET_ORIGIN } from 'qonto/constants/budget';
import { FlowSetup } from 'qonto/routes/flows/setup/internals';
import { ErrorInfo } from 'qonto/utils/error-info';

export class EditBudgetFlowDataContext {
  exerciseId;
  type;
  budget;
}

export default class EditBudgetFlowSetup extends FlowSetup {
  @service router;
  @service segment;
  @service abilities;
  @service intl;
  @service store;
  @service sentry;
  @service toastFlashMessages;
  @service modals;

  constructor() {
    super(...arguments);

    this.dataContext = new EditBudgetFlowDataContext();
    this.dataContext.id = this.router.currentRoute.queryParams.id;
    this.dataContext.origin = this.router.currentRoute.queryParams.origin;
  }

  async beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'spend-management');

    let { id } = this.dataContext;

    if (this.abilities.cannot('update budget') || this.abilities.cannot('read budget') || !id) {
      return this._goBackToMainPage();
    }

    try {
      let budget = await this.store.findRecord('budget', id);
      this.dataContext.budget = budget;
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      return this._goBackToMainPage();
    }
  }

  onComplete() {
    if (this.dataContext.origin === BUDGET_ORIGIN.show) {
      this.router.replaceWith('budgets.show', this.dataContext.id);
    } else {
      this.router.transitionTo('budgets.list');
    }
  }

  onAbortTask = dropTask(async (_, { id: stepId }) => {
    if (stepId === 'select-exercise') {
      return this._goBackToMainPage();
    }

    this.segment.track('edit-budget_close_clicked');

    let result = await this.modals.open('popup/destructive', {
      title: this.intl.t('team-budgets.edit.edit-exercise.close-edit.modal.title'),
      description: this.intl.t('team-budgets.edit.edit-exercise.close-edit.modal.description'),
      cancel: this.intl.t('btn.back'),
      confirm: this.intl.t('team-budgets.edit.edit-exercise.close-edit.modal.button'),
    });

    if (result === 'confirm') {
      this.segment.track('edit-budget_cancel-changes-confirmation_clicked');
      return this._goBackToMainPage();
    }

    this.segment.track('edit-budget_cancel-changes-confirmation-back_clicked');
    return false;
  });

  beforeRestoreTask = dropTask(async ({ budget }) => {
    // if the user decide to create a new exercise without saving it, the new exercise will be saved in
    // the dataContext. Then, after a page refresh, the flow service tries to retrieve the exercise.
    // As the exercise as id=null, it may generates an error. To avoid this problem we clean the exercises
    // array, by removing the exercises not saved.
    budget.relationships.exercises = await budget.relationships.exercises.filter(({ id }) =>
      Boolean(id)
    );
  });

  _goBackToMainPage() {
    // If the user exit the flow without saving the edited Periods,
    // the changes will be reflected in the component, even if the Periods are not saved.
    // To avoid this issue, we unload all dirty periods from the store, so the next API
    // call will retrieve them again.
    this._unloadDirtyPeriods();

    if (this.dataContext.origin === BUDGET_ORIGIN.show) {
      this.router.replaceWith('budgets.show', this.dataContext.id);
    } else {
      this.router.replaceWith('budgets.list');
    }
  }

  _unloadDirtyPeriods() {
    this.dataContext.budget?.exercises.forEach(exercise => {
      exercise.rollbackAttributes();
      for (let i = exercise.periods.length - 1; i >= 0; i--) {
        exercise.periods[i].rollbackAttributes();
      }
    });
  }
}
