import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { restartableTask } from 'ember-concurrency';

// eslint-disable-next-line @qonto/no-import-roles-constants
import { MEMBER_STATUS, ROLES } from 'qonto/constants/membership';
import { STATUS } from 'qonto/constants/receivable-invoice';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

const SENTRY_IGNORE_HTTP_STATUSES = [404, 401];

export default class ReceivableInvoicesIndexRoute extends Route {
  @service abilities;
  @service organizationManager;
  @service router;
  @service store;
  @service sentry;
  @service toastFlashMessages;
  @service intl;
  @service subscriptionManager;
  @service receivableInvoicesUploadManager;

  queryParams = {
    status: { refreshModel: true },
    page: { refreshModel: true },
    perPage: { refreshModel: true },
    sortBy: { refreshModel: true },
  };

  activate() {
    this.router.on('routeWillChange', this.storeTransition);
  }

  deactivate() {
    this.router.off('routeWillChange', this.storeTransition);
    this.fetchCustomersAndInvoicesTask.lastSuccessful = null;
  }

  beforeModel({ to }) {
    if (!to.queryParams.status) {
      return this.router.replaceWith({ queryParams: { status: STATUS.DRAFT } });
    }
    safeLocalStorage.setItem('receivable-invoices-page-seen', true);
  }

  async model(params) {
    this.params = params;
    let canReadInvoices = this.abilities.can('read receivableInvoice');

    this.fetchCustomerAndInvoices();
    let model = {
      canReadInvoices,
      invoicesTask: this.fetchCustomersAndInvoicesTask,
    };

    this.fetchSettingsTask
      .perform(params)
      .catch(ignoreCancelation)
      .catch(error => this.ignoreNotFoundAndHandleError(error));

    model.settingsTask = this.fetchSettingsTask;

    this.receivableInvoicesUploadManager.registerCallback({
      onUploadFinished: this.fetchCustomerAndInvoices,
    });

    if (this.abilities.can('read client-hubs')) {
      let clients = await this.store.query('client-hub', {
        page: 1,
        per_page: 1,
        sort_by: 'name:asc',
      });

      model.totalClientsCount = clients.meta.total_count;
    }

    if (this.abilities.can('read products')) {
      let products = await this.store.query('product', {
        page: 1,
        per_page: 1,
        sort_by: 'title',
      });

      model.totalProductsCount = products.meta.total_count;
    }

    if (this.abilities.can('read accountsReceivableOnboarding')) {
      let organizationId = this.organizationManager.organization.id;
      let onboardingState = await this.store
        .findRecord('accounts-receivable-onboarding', organizationId)
        .catch(() => null);

      let alreadyInvitedAccountants = await this.store.query('membership', {
        organization_id: organizationId,
        filters: {
          status: [MEMBER_STATUS.INVITED],
          roles: [ROLES.REPORTING],
        },
      });

      await this.organizationManager.organization.getAvatar();

      model.isAlreadyInvitedAccountant = alreadyInvitedAccountants.length > 0;
      model.onboardingState = onboardingState;
    }

    return model;
  }

  @action
  fetchCustomerAndInvoices() {
    this.fetchCustomersAndInvoicesTask
      .perform(this.params)
      .catch(ignoreCancelation)
      .catch(error => this.ignoreNotFoundAndHandleError(error));
  }

  combinedSortParam(sortBy, defaultSortBy) {
    //If the sorting param is on the same field as the default param,
    //we don't need to add a secondary sort
    if (sortBy.replace(/^-/, '') === defaultSortBy.replace(/^-/, '')) {
      return sortBy;
    }
    return [sortBy, defaultSortBy].join(',');
  }

  getParamsWithOrganizationId(params) {
    let organizationId = this.organizationManager.organization.id;

    let { status, page, perPage, sortBy } = params;
    let filter = status ? { status } : {};
    let defaultSortBy = status === 'unpaid' ? 'due_date,-number' : '-issue_date,-number';
    return {
      filter: { organization_id: organizationId, ...filter },
      page: { number: page, size: perPage },
      sort: this.combinedSortParam(sortBy, defaultSortBy),
    };
  }

  fetchCustomersAndInvoicesTask = restartableTask(async params => {
    let paramsWithOrganizationId = this.getParamsWithOrganizationId(params);
    let { organization_id } = paramsWithOrganizationId.filter;
    let receivableInvoices;

    if (this.abilities.can('create receivable-invoice')) {
      await this.store.query('customer', {
        filter: { organization_id },
      });
    }

    receivableInvoices = await this.store.query('receivable-invoice', paramsWithOrganizationId);
    let invoiceStats = await this.store.modelFor('receivable-invoice').getStats(this.store);

    return {
      receivableInvoices: receivableInvoices ?? [],
      invoiceStats,
      meta: {
        total_count: receivableInvoices?.meta.total ?? 0,
        total_pages: receivableInvoices?.meta.total
          ? receivableInvoices?.meta.total / paramsWithOrganizationId.page.size
          : 1,
        per_page: paramsWithOrganizationId.page.size,
        current_page: paramsWithOrganizationId.page.number,
      },
      invoicesCreatedCount: receivableInvoices?.meta.total_for_organization ?? 0,
    };
  });

  fetchSettingsTask = restartableTask(async params => {
    let paramsWithOrganizationId = this.getParamsWithOrganizationId(params);
    let { organization_id } = paramsWithOrganizationId.filter;
    let settings = await this.store.findRecord('receivable-invoices-settings', organization_id);
    if (!settings.contactEmail) {
      settings.contactEmail = this.organizationManager.membership.email;
    }

    return settings;
  });

  ignoreNotFoundAndHandleError(exception) {
    let { errors } = exception;
    if (errors?.some(error => error?.source?.pointer.includes('/invoicefilters'))) {
      return this.router.replaceWith({ queryParams: { status: STATUS.DRAFT } });
    }

    if (!SENTRY_IGNORE_HTTP_STATUSES.includes(exception.status)) {
      this.handleError(exception);
    }
  }

  storeTransition(transition) {
    this.transition = transition;
  }

  resetController(controller, isExiting) {
    if (
      !isExiting ||
      this.router.transition.targetName === 'receivable-invoices.show' ||
      this.router.transition.targetName === 'invoicing-settings'
    )
      return;

    controller.status = STATUS.DRAFT;
    controller.page = 1;
    controller.perPage = 25;
    controller.sortBy = '-issue_date,-number';

    if (!this.receivableInvoicesUploadManager.hasErrors) {
      this.receivableInvoicesUploadManager.resetState();
    }
  }

  handleError(error) {
    let errorInfo = ErrorInfo.for(error);
    if (errorInfo.shouldSendToSentry) {
      this.sentry.captureException(error);
    }
    this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
  }
}
