/* eslint-disable @qonto/no-async-action */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { restartableTask, timeout } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import { DEBOUNCE_MS } from 'qonto/constants/timers';
import { DEFAULT_SORT_BY } from 'qonto/constants/transactions';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { TransactionsTable } from 'qonto/react/components/transactions/table';
import { queryParamsFilterGroup } from 'qonto/routes/transactions/index/route';

const defaultPagination = { page: 1, per_page: 25 };
const defaultQuery = {
  sort: { property: 'emitted_at', direction: 'desc' },
  search: '',
  pagination: defaultPagination,
};

export default class TransactionsV2IndexController extends Controller {
  reactTransactionsTable = TransactionsTable;
  queryParams = [
    { bankAccounts: 'bank-accounts', page: 'page', perPage: 'per_page' },
    'query',
    'initiator',
  ];

  queryParamsFilter = ['initiator'];

  @tracked page = 1;
  @tracked perPage = 25;
  @tracked bankAccounts = '';
  @tracked initiator = null;
  @tracked query = '';
  @tracked _filterGroup = null;
  @tracked meta = null;
  @tracked currentModal = null;

  @service abilities;
  @service modals;
  @service organizationManager;
  @service segment;
  @service store;
  @tracked sort_by = DEFAULT_SORT_BY;

  @reads('organizationManager.organization') organization;

  get selectedAccount() {
    return this.organizationManager.currentAccount;
  }

  get organizationId() {
    return this.organization.id;
  }

  get canAccessAccountDetails() {
    return (
      this.abilities.can('access bank-account') &&
      this.selectedAccount &&
      !this.selectedAccount.isExternalAccount
    );
  }

  get queryOptions() {
    let { query, sort_by } = this;
    let [property, direction] = (sort_by || DEFAULT_SORT_BY).split(':');

    return {
      sort: { property, direction },
      search: query,
      pagination: defaultPagination,
    };
  }

  get filterGroup() {
    let savedFilterGroup = safeLocalStorage.getItem('transactions-filter-group');
    savedFilterGroup = savedFilterGroup ? JSON.parse(savedFilterGroup) : undefined;

    return this._filterGroup === undefined ? savedFilterGroup : this._filterGroup;
  }

  getRedirectionQueryParams() {
    let queryParams = {};

    for (let param of this.queryParamsFilter) {
      if (this[param] !== undefined) {
        queryParams[param] = this[param];
      }
    }

    return queryParams;
  }

  get filterGroupQuery() {
    return queryParamsFilterGroup(this.getRedirectionQueryParams(), {
      isPagoPaFilterEnabled: false,
    });
  }

  set filterGroup(filterGroup) {
    this._filterGroup = filterGroup;
  }

  searchTask = restartableTask(async query => {
    await timeout(DEBOUNCE_MS);

    this.query = query;
    this.page = 1;
  });

  @action
  openModal(modalName, modalOptions) {
    if (this.currentModal) {
      this.currentModal.close();
    }

    this.currentModal = this.modals.open(modalName, modalOptions);
  }

  get isPagoPaFilterEnabled() {
    return this.abilities.can('view filter pagopa');
  }

  @action
  updateMeta(meta) {
    this.meta = meta;
  }

  @action
  updatePage(page) {
    this.page = page;
  }

  @action
  applyFilters({ sort, filter_group, search, pagination } = defaultQuery) {
    let { property, direction } = sort;
    let page = pagination?.page;
    let perPage = pagination?.per_page;
    let sortBy = `${property}:${direction}`;

    this.page = page;
    this.perPage = perPage;
    this.query = search;
    this.sortBy = sortBy;
    this.filterGroup = filter_group || null;
    this.initiator = null;
  }

  @action
  updatePerPage(perPage) {
    this.updatePage(1);
    this.perPage = perPage;
  }

  @action
  async openExportModal() {
    this.segment.track('export_transactions_clicked');

    if (this.queryParams.preset) {
      let presets = await this.store.query('search-preset', {
        organization_id: this.organizationManager.organization.id,
      });

      let selectedPreset = presets.find(({ id }) => id === this.queryParams.preset);
      this.filterGroup = selectedPreset?.query?.filter_group;
    }

    let [property, direction] = (this.sort_by || DEFAULT_SORT_BY).split(':');
    let filters = {
      ...this.bankAccountFilter,
      organization_id: this.organization.id,
      search: this.query,
      filter_group:
        this.filterGroup ||
        queryParamsFilterGroup(this.queryParams, {
          isPagoPaFilterEnabled: this.isPagoPaFilterEnabled,
        }),
      sort: { property, direction },
    };

    this.exportModal = this.modals.open('transactions/custom-exports/modal', {
      isFullScreenModal: true,
      filters,
    });
  }
}
