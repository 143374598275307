import { action } from '@ember/object';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';
import { TrackedArray } from 'tracked-built-ins';

import { FlowSetup } from 'qonto/routes/flows/setup/internals';

class CardSetPinKYCDataContext {
  documentType = null;
  documents = new TrackedArray();
}

export default class CardSetPinKYCFlowSetup extends FlowSetup {
  @service router;
  @service flowLinkManager;
  @service modals;
  @service intl;

  constructor() {
    super(...arguments);

    this.dataContext = new CardSetPinKYCDataContext();
    this.dataContext.id = this.router.currentRoute.queryParams.id;
  }

  @action
  onComplete(dataContext) {
    this.flowLinkManager.transitionTo({
      name: 'card-set-pin',
      stepId: 'set-pin',
      queryParams: { id: dataContext.id },
    });
  }

  onAbortTask = dropTask(async (dataContext, { id: stepId }) => {
    if (stepId === 'intro') {
      this.router.transitionTo('cards.index', {
        queryParams: { highlight: dataContext.id },
      });

      return true;
    }

    let modalResult = await this.modals.open('popup/destructive', {
      title: this.intl.t('flow-in-flow.modal.abort.title'),
      description: this.intl.t('flow-in-flow.modal.abort.subtitle'),
      cancel: this.intl.t('btn.back'),
      confirm: this.intl.t('btn.leave'),
    });

    if (modalResult === 'confirm') {
      this.router.transitionTo('cards.index', {
        queryParams: { highlight: dataContext.id },
      });

      return true;
    }

    return false;
  });
}
