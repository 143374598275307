export default {
  "vat-number": "quN body-2",
  "banner": "quv",
  "container": "quo",
  "organization-profile": "qun",
  "input": "qui",
  "medium-input": "quA medium",
  "small-input": "quY small",
  "actions": "quh",
  "company-info": "quf",
  "company-info-section": "quK",
  "company-address-section": "quG",
  "company-country": "qur",
  "company-billing-email": "qub",
  "company-address": "quy"
};
