import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { Badge, SearchFieldWithDebounce } from '@repo/design-system-kit';

import { UNSORTED_INTEGRATION } from 'qonto/constants/connect';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
import scrollIntoView from 'qonto/utils/scroll-into-view';

export default class ConnectHubFeaturedController extends Controller {
  searchField = SearchFieldWithDebounce;

  badge = Badge;

  @service userManager;
  @service intl;
  @service organizationManager;
  @service segment;
  @service abilities;
  @service connectManager;

  @tracked searchQuery;
  @tracked categorySlug;
  @tracked stakeholderSlug;
  @tracked scrolledTop = true;

  queryParams = ['searchQuery'];
  detailsRouteName = 'settings.connect-hub.applications.hub-application.details.index';

  onSearch = query => {
    this.searchQuery = query || undefined;
    this.connectManager.searchIntegrationsTask.perform(query).catch(ignoreCancelation);
    this.segment.track('connect_app_filtered', { keyword: query });
    this._scrollListToTop();
  };

  get showBanksSection() {
    return this.abilities.can('import external-account');
  }

  get landingSections() {
    let landingIndex = `landing_section_index_${this.organizationManager.organization.legalCountry.toLowerCase()}`;
    let sections = [];

    this.model.featuredIntegrations.results.forEach(integration => {
      let { landing_section_sort_index } = integration.data;
      let sectionIndex = integration.data[landingIndex];

      if (!sections[sectionIndex]) {
        sections[sectionIndex] = [];
      }

      if (landing_section_sort_index && landing_section_sort_index !== UNSORTED_INTEGRATION) {
        sections[sectionIndex][landing_section_sort_index] = integration;
      } else {
        sections[sectionIndex].push(integration);
      }
    });

    return sections;
  }

  get filteredIntegrations() {
    if (this.searchQuery) {
      return this.connectManager.searchIntegrationsTask.lastSuccessful?.value.results || [];
    }

    return [];
  }

  get landingSectionTitles() {
    return [
      {
        title: this.intl.t('qonto-hub.connect.apps-list.section-1.title'),
        subtitle: this.intl.t('qonto-hub.connect.apps-list.section-1.subtitle'),
      },
      {
        title: this.intl.t('qonto-hub.connect.apps-list.section-2.title'),
        subtitle: this.intl.t('qonto-hub.connect.apps-list.section-2.subtitle'),
      },
      {
        title: this.intl.t('qonto-hub.connect.apps-list.section-3.title'),
        subtitle: this.intl.t('qonto-hub.connect.apps-list.section-3.subtitle'),
      },
      {
        title: this.intl.t('qonto-hub.connect.apps-list.section-4.title'),
        subtitle: this.intl.t('qonto-hub.connect.apps-list.section-4.subtitle'),
      },
    ];
  }

  get showSearchResultsCount() {
    return (
      (this.searchQuery || this.categorySlug || this.stakeholderSlug) &&
      this.filteredIntegrations.length > 0
    );
  }

  get showFooter() {
    return !this.searchQuery || this.filteredIntegrations.length;
  }

  get isLoading() {
    return this.connectManager.searchIntegrationsTask.isRunning;
  }

  @action
  updateScrolledTop(value) {
    this.scrolledTop = value;
  }

  _scrollListToTop() {
    let element = document.getElementById('integrations-list');

    scrollIntoView(element, { offsetTop: 32 });
  }
}
