import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class SettingsIndexRoute extends Route {
  @service router;

  redirect() {
    this.router.replaceWith('settings.personal');
  }
}
