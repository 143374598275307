export default {
  "team": "qGb",
  "no-members": "qGy",
  "teams-header": "qGU",
  "header-search": "qGj",
  "search-bar": "qGW",
  "invite": "qGu body-2",
  "invite-text": "qrS",
  "empty-illustration": "qrc",
  "empty-title": "qrq title-3",
  "empty-description": "qrZ body-2",
  "pagination-footer": "qrR",
  "filter-search": "qrQ",
  "fullsize": "qre",
  "tabs-container": "qrB",
  "tabs-separator": "qrE",
  "tooltip-wrapper": "qrd",
  "invite-cta": "qrI"
};
