import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class GuestsMemberRoute extends Route {
  @service router;
  @service store;
  @service segment;
  @service organizationManager;

  activate() {
    this.segment.track('accountant-access_details_displayed');
  }

  async model({ membership_id }) {
    return await this.store.findRecord('membership', membership_id);
  }

  @action
  error() {
    this.router.replaceWith('guests');
  }

  setupController(controller, membership) {
    controller.model = membership;
    controller.editingTeam = false;
  }
}
