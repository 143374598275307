import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import formatFileSize from '@qonto/ui-kit/utils/format-bytes';
import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import TEMPLATE_VALUES from 'qonto/constants/env-helper';
import { ORGA_STATUS } from 'qonto/constants/organization';
import { ORIGIN } from 'qonto/routes/flows/setup/subscription/close';
import { isValidEmail } from 'qonto/utils/email';

export default class OrgProfileController extends Controller {
  @service intl;
  @service toastFlashMessages;
  @service modals;
  @service router;

  @tracked emailInputValidity = null;
  @tracked emailInputError = null;

  @reads('model.organization') organization;
  @reads('organization.hasDirtyAttributes') organizationChanged;

  origin = ORIGIN.ORGANIZATION_PROFILE;

  @action
  onDeactivationCanceled() {
    this.router.refresh('settings.organization-profile');
  }

  get isBannerVisible() {
    return (
      this.organization.status !== ORGA_STATUS.SUSPENDED && this.model.deactivationData?.ongoing
    );
  }

  get disableSave() {
    return (
      [ORGA_STATUS.DEACTIVATED, ORGA_STATUS.SUSPENDED].includes(this.organization.status) ||
      !(this.organizationChanged && this.emailInputValidity)
    );
  }

  get formattedMaxSize() {
    return formatFileSize(this.intl, TEMPLATE_VALUES.avatarMaxSize);
  }

  @action handleUpdate(value) {
    let isValid = value && isValidEmail(value);
    this.emailInputValidity = isValid;
    this.emailInputError = isValid ? null : this.intl.t('validations.errors.email');
    this.set('organization.contactEmail', value);
  }

  @action handleDropAvatar() {
    this.organization.set('avatar', null);
    this.organization.set('isDefaultAvatar', true);
    this.organization.save();
  }

  @action handleAddAvatar(avatar) {
    this.modals.open('avatar-confirm-dialog', {
      confirmTask: this.handleAvatarModalConfirmTask,
      avatarCandidate: avatar,
      hideCropper: true,
    });
  }

  handleAvatarModalConfirmTask = dropTask(async (close, file) => {
    this.organization.set('avatar', file);
    this.organization.set('isDefaultAvatar', false);
    try {
      await this.saveOrganizationTask.perform(this.organization);
    } catch (error) {
      if (error.status === 422 && error.errors[0].detail.code === 'invalid_file') {
        this.toastFlashMessages.toastError(
          this.intl.t('users.profile.photo-too-large-error', { maxSize: '5 MB' })
        );
      }
    }
    close();
  });

  saveOrganizationTask = dropTask(async organization => {
    await organization.save();
    let message = this.intl.t('toasts.settings_updated');
    this.toastFlashMessages.toastSuccess(message);
  });
}
