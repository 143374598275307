import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { restartableTask } from 'ember-concurrency';

import { SUPPLIER_STATUSES } from 'qonto/constants/supplier';
import { INVOICE_STATUSES } from 'qonto/constants/supplier-invoice';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class SuppliersIndexRoute extends Route {
  @service store;
  @service toastFlashMessages;
  @service intl;
  @service sentry;
  @service router;

  queryParams = {
    page: { refreshModel: true },
    perPage: { refreshModel: true },
    sortBy: { refreshModel: true },
    status: { refreshModel: true },
    search: { refreshModel: true },
    highlighted: { refreshModel: false },
  };

  beforeModel({ to }) {
    if (!Object.values(SUPPLIER_STATUSES).includes(to.queryParams.status)) {
      return this.router.replaceWith({
        queryParams: { status: SUPPLIER_STATUSES.UNARCHIVED },
      });
    }
  }

  model(params) {
    this.fetchSuppliersTask
      .perform(params)
      .catch(ignoreCancelation)
      .catch(error => this._handleError(error));

    if (params.highlighted) {
      this.fetchRelatedInvoices(params.highlighted);
    }

    return {
      suppliersTask: this.fetchSuppliersTask,
      fetchRelatedInvoicesTask: this.fetchRelatedInvoicesTask,
      fetchRelatedInvoices: this.fetchRelatedInvoices,
    };
  }

  fetchSuppliersTask = restartableTask(async params => {
    let { page, perPage, sortBy, search } = params;

    let results = await Promise.all([
      this.store.query('supplier', {
        page,
        per_page: perPage,
        sort: sortBy,
        filter: { status: SUPPLIER_STATUSES.UNARCHIVED },
        search,
      }),
      this.store.query('supplier', {
        page,
        per_page: perPage,
        sort: sortBy,
        filter: { status: SUPPLIER_STATUSES.ARCHIVED },
        search,
      }),
    ]);

    return {
      [SUPPLIER_STATUSES.UNARCHIVED]: results[0],
      [SUPPLIER_STATUSES.ARCHIVED]: results[1],
    };
  });

  @action
  fetchRelatedInvoices(id) {
    this.fetchRelatedInvoicesTask
      .perform(id)
      .catch(ignoreCancelation)
      .catch(error => {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
        this.toastFlashMessages.toastError(
          this.intl.t('supplier-list.sidebar.supplier-information.related-invoices.error-toast')
        );
      });
  }

  fetchRelatedInvoicesTask = restartableTask(async id => {
    return await this.store.query('supplier-invoice', {
      filter: {
        supplier_id: id,
        status: [
          INVOICE_STATUSES.toReview,
          INVOICE_STATUSES.toPay,
          INVOICE_STATUSES.scheduled,
          INVOICE_STATUSES.pending,
        ],
      },
      per_page: 5,
    });
  });

  resetController(controller) {
    controller.page = 1;
    controller.perPage = 25;
    controller.sortBy = 'name:asc';
  }

  _handleError(exception) {
    if (ErrorInfo.for(exception).shouldSendToSentry) {
      this.sentry.captureException(exception);
    }
    this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
  }
}
