import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { DEPOSIT_CAPITAL_STEPPER_STEP } from 'qonto/constants/organization';

const allowedQueryParams = ['offices', 'hosted', 'commercial', 'agency'];

export default class CapitalBylawRoute extends Route {
  @service capitalIncreaseManager;
  @service router;

  activate() {
    this.capitalIncreaseManager.setCurrentStep(DEPOSIT_CAPITAL_STEPPER_STEP.DOCUMENTS);
  }

  beforeModel(transition) {
    let { poaType = null } = transition.to.queryParams;

    if (!allowedQueryParams.includes(poaType)) {
      this.router.transitionTo('capital.choose-poa-type');
    }
  }

  resetController(controller, isExisting) {
    if (isExisting) {
      controller.enableOrganizationValidations = false;
    }
  }
}
