import { ConflictError } from '@ember-data/adapter/error';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class CapitalReviewedContractRoute extends Route {
  @service capitalIncreaseManager;
  @service toastFlashMessages;
  @service intl;

  async model() {
    try {
      return await this.capitalIncreaseManager.requestCapitalDepositSignature('deposit_request');
    } catch (error) {
      if (error instanceof ConflictError) {
        let message = this.intl.t('errors.conflict_error');
        this.toastFlashMessages.toastInfo(message);
        this.replaceWith('capital.reviewed');
      } else {
        throw error;
      }
    }
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.set('showContract', true);
    controller.set('signUrl', model.sign_url);
    controller.set('documentId', model.document_id);
  }
}
