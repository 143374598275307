import { ORIGIN } from 'qonto/constants/cards';

export const cardNewFlash = {
  steps: {
    'bank-account': {
      componentName: 'cards/bank-account',
      nextStepId: ({ origin }) =>
        origin === ORIGIN.MEMBER_INVITATION ? 'payment-lifespan-limit' : 'holder',
    },
    holder: {
      componentName: 'cards/holder',
      nextStepId: 'payment-lifespan-limit',
    },
    'payment-lifespan-limit': {
      componentName: 'cards/payment-lifespan-limit',
      nextStepId: 'validity-period',
    },
    'validity-period': {
      componentName: 'cards/validity-period',
      nextStepId: 'review',
    },
    review: {
      componentName: 'cards/review',
      nextStepId: ({ isUserCardHolder, kycSubmitted }) => {
        if (isUserCardHolder && !kycSubmitted) {
          return 'kyc-intro';
        }
        return 'success';
      },
    },
    'kyc-intro': {
      componentName: 'cards/kyc/digital-card-kyc-intro',
      nextStepId: 'user-info',
    },
    'user-info': {
      componentName: 'kyc/user-info',
      nextStepId: 'success',
    },
    // secondary flows do not display a success step, but all flows require a success step
    // so the success step must be described although it is ignored
    success: {
      componentName: 'cards/success-new-digital',
    },
  },
  options: {
    enablePersistence: false,
  },
};
