/* eslint-disable @qonto/no-import-roles-constants */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

import { LottiePlayer, SearchField } from '@repo/design-system-kit';
import { variation } from 'ember-launch-darkly';

import { ROLES } from 'qonto/constants/membership';
import { RegatePopup } from 'qonto/react/components/regate-popup';
import normalize from 'qonto/utils/normalize-string';

export default class AccountingHubController extends Controller {
  lottiePlayer = LottiePlayer;
  regatePopup = RegatePopup;
  searchField = SearchField;

  @tracked searchQuery = '';
  @tracked filteredItems = variation('feature--boolean-login-improvement')
    ? this.model.organizations
    : this.model.organizationsToRoleMap;

  @action
  filterItems(query) {
    this.searchQuery = query;

    let normalizedQuery = normalize(query);

    if (variation('feature--boolean-login-improvement')) {
      this.filteredItems = this.model.organizations.filter(({ name }) =>
        normalize(name).includes(normalizedQuery)
      );
    } else {
      this.filteredItems = this.model.organizationsToRoleMap.filter(({ organization }) =>
        normalize(organization.name).includes(normalizedQuery)
      );
    }
  }

  get businessItems() {
    if (variation('feature--boolean-login-improvement')) {
      return this.model.organizations.filter(
        ({ membershipRole }) => membershipRole !== ROLES.REPORTING
      );
    } else {
      return this.model.organizationsToRoleMap.filter(({ role }) => role !== ROLES.REPORTING);
    }
  }

  get clientItems() {
    if (variation('feature--boolean-login-improvement')) {
      return this.model.organizations.filter(
        ({ membershipRole }) => membershipRole === ROLES.REPORTING
      );
    } else {
      return this.model.organizationsToRoleMap.filter(({ role }) => role === ROLES.REPORTING);
    }
  }
}
