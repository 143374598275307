import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

export default class ReceivableInvoicesRoute extends Route {
  @service abilities;
  @service homePage;

  beforeModel(transition) {
    Sentry.getCurrentScope().setTag('CFT', 'invoices');

    let canNavigate =
      this.abilities.can('navigate receivable-invoice') ||
      (transition.to.name.includes('receivable-invoices.export') &&
        this.abilities.can('export receivable-invoice'));

    if (!canNavigate) {
      return this.homePage.replaceWithDefaultPage();
    }
  }
}
