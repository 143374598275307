/* eslint-disable @qonto/no-async-action, @qonto/no-import-roles-constants */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { parseDate } from '@internationalized/date';
import formatFileSize from '@qonto/ui-kit/utils/format-bytes';
import { BadgeStatus, DateField, Disclaimer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
import window from 'ember-window-mock';
import { reads } from 'macro-decorators';

import TEMPLATE_VALUES from 'qonto/constants/env-helper';
import { authBaseURL } from 'qonto/constants/hosts';
import { ROLES } from 'qonto/constants/membership';
import { ERRORS } from 'qonto/constants/sca';
import { ErrorInfo } from 'qonto/utils/error-info';
import { formatIban, maskInput } from 'qonto/utils/format-input';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class SettingsUserProfileController extends Controller {
  dateField = DateField;
  disclaimerBlock = Disclaimer.Block;
  disclaimerInline = Disclaimer.Inline;

  badgeStatus = BadgeStatus;

  @service intl;
  @service toastFlashMessages;
  @service modals;
  @service networkManager;
  @service userManager;
  @service sentry;
  @service segment;
  @service store;
  @service abilities;
  @service flowLinkManager;
  @service organizationManager;
  @service router;

  @tracked emailEdition = false;
  @tracked pendingEmail = null;
  @tracked pendingEmailError = null;
  @tracked iban = null;
  @tracked ibanEdition = false;
  @tracked pendingIban = null;
  @tracked pendingIbanError = null;
  @tracked pendingPhoneNumber = null;
  @tracked phoneNumberEdition = false;
  @tracked phoneNumberError = null;

  formattedMaxSize = formatFileSize(this.intl, TEMPLATE_VALUES.avatarMaxSize);

  @reads('model.membership') membership;
  @reads('userManager.currentUser') currentUser;

  get roleName() {
    let labels = {
      [ROLES.OWNER]: this.intl.t('roles.owner'),
      [ROLES.ADMIN]: this.intl.t('roles.admin'),
      [ROLES.MANAGER]: this.intl.t('roles.manager'),
      [ROLES.REPORTING]: this.intl.t('roles.reporting'),
      [ROLES.EMPLOYEE]: this.intl.t('roles.employee'),
    };

    return labels[this.membership.role];
  }

  get canAccessIban() {
    return (
      this.abilities.can('use iban membership') &&
      (this.abilities.can('create expense report request') ||
        this.abilities.can('create mileage request'))
    );
  }

  get ibanPlaceholder() {
    return this.ibanEdition
      ? this.intl.t('users.profile.bank-account.iban.placeholder-input')
      : this.intl.t('users.profile.bank-account.iban.placeholder-empty');
  }

  get ibanConfirmButtonLabel() {
    return this.iban
      ? this.intl.t('btn.change')
      : this.intl.t('users.profile.bank-account.iban.add.cta');
  }

  get maskedIban() {
    let iban = this.ibanEdition ? this.membership.pendingIban : this.iban;
    return formatIban(maskInput(iban));
  }

  get userBirthCountry() {
    if (this.membership.birthCountry) {
      try {
        return this.intl.formatCountry(this.membership.birthCountry);
      } catch {
        // We have cases where the country has been entered manually and does not match with translation value
        // While this is still possible we need this fallback to avoid to display the empty value placeholder to the user.
        return this.membership.birthCountry;
      }
    }
  }

  get userBirthday() {
    return parseDate(this.membership.birthdate);
  }

  @action
  redirectToFourthline() {
    let { personalInfoProvided } = this.organizationManager.membership;
    if (personalInfoProvided) {
      this.router.transitionTo('kyc.start', {
        queryParams: {
          redirectRoute: this.router.currentURL,
          trackingEventOrigin: 'profile_page',
        },
      });
    } else {
      this.segment.track('submit_kyc_information_start', {
        origin: 'profile_page',
      });
      this.flowLinkManager.transitionTo({
        name: 'management-kyc',
        stepId: 'user-info',
      });
    }
  }

  get KYCBadgeStatus() {
    if (this.membership.kycPending) {
      return { level: 'in-progress', text: this.intl.t('kyc.status.pending') };
    }
    if (this.membership.kycAccepted) {
      return { level: 'validated', text: this.intl.t('kyc.status.accepted') };
    }
    if (this.membership.kycRefused) {
      return { level: 'error', text: this.intl.t('kyc.status.refused') };
    }
  }

  get showKYCBadge() {
    let { kycPending, kycAccepted, kycRefused, kycSubmitted, role } = this.membership;
    return role !== ROLES.REPORTING && (kycPending || kycAccepted || kycRefused) && kycSubmitted;
  }

  get showKYCDisclaimer() {
    let { shouldSubmitKyc, role } = this.membership;
    return role !== ROLES.REPORTING && shouldSubmitKyc;
  }

  @action
  handleEmailEdition() {
    this.emailEdition = !this.emailEdition;
    this.pendingEmail = null;
    this.pendingEmailError = null;
    this.segment.track('profile_email-update_started');
  }

  @action updateEmail(value) {
    this.pendingEmail = value;
    this.pendingEmailError = null;
  }

  @action
  handleIbanEdition() {
    this.ibanEdition = !this.ibanEdition;
    this.pendingIban = null;
    this.pendingIbanError = null;
  }

  @action
  updateIban(value) {
    this.pendingIban = value;
    this.pendingIbanError = null;
  }

  @action
  handlePhoneNumberEdition() {
    this.segment.track('profile_phone-update_started');
    this.phoneNumberEdition = !this.phoneNumberEdition;
  }
  @action
  updatePhoneNumber(value) {
    this.pendingPhoneNumber = value;
    this.phoneNumberError = null;
  }

  @action
  handleAddOrUpdateIban() {
    if (this.iban) {
      this.segment.track('reimbursement_iban-update_started', {
        origin: 'profile_screen',
      });
    } else {
      this.segment.track('reimbursement_iban-add_started', {
        origin: 'profile_screen',
      });
    }

    this.handleIbanEdition();
  }

  @action
  handleCancelIbanEdition() {
    if (this.iban) {
      this.segment.track('reimbursement_iban-update_cancelled', {
        origin: 'profile_screen',
      });
    } else {
      this.segment.track('reimbursement_iban-add_cancelled', {
        origin: 'profile_screen',
      });
    }

    this.handleIbanEdition();
  }

  @action
  handleAddAvatar(avatar) {
    this.modals.open('avatar-confirm-dialog', {
      confirmTask: this.handleAvatarModalConfirmTask,
      avatarCandidate: avatar,
      title: this.intl.t('users.profile.modals.crop_photo.title'),
      description: this.intl.t('users.profile.modals.crop_photo.desc'),
    });
  }

  @action
  async handleDropAvatar() {
    try {
      await this.currentUser.removeAvatar();
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);

      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      let message = this.intl.t('errors.internal_server_error');
      this.toastFlashMessages.toastError(message);
    }
  }

  @action
  showDidomiCookiesConsentModal() {
    window.Didomi?.preferences?.show();
  }

  @action
  handleSubmitDeleteIbanClick() {
    this.segment.track('reimbursement_iban-delete_started');
    this.deleteIbanTask.perform().catch(ignoreCancelation);
  }

  handleAvatarModalConfirmTask = dropTask(async (close, file) => {
    this.currentUser.avatar = file;
    try {
      await this.saveUserTask.perform(this.currentUser);
    } catch (error) {
      if (error.status === 422 && error.errors[0].detail.code === 'invalid_file') {
        this.toastFlashMessages.toastError(
          this.intl.t('users.profile.photo-too-large-error', { maxSize: '5 MB' })
        );
      }
    }
    close();
  });

  saveUserTask = dropTask(async user => {
    await user.save();
  });

  updateEmailTask = dropTask(async pendingEmail => {
    this.currentUser.pendingEmail = pendingEmail;
    let { email } = this.currentUser;

    let { validations } = await this.currentUser.validate({
      on: ['pendingEmail'],
    });

    let isUnexpectedError = false;

    if (!validations.isValid || email === pendingEmail) {
      this.pendingEmailError = this.intl.t('validations.errors.email');
      return;
    }

    let memberships = await this.store.query('membership', {
      user_id: this.currentUser.id,
    });

    try {
      await this.requestOTPTask.perform(pendingEmail, false);
    } catch (error) {
      let code = error?.errors?.[0]?.code;
      if (code === ERRORS.MIGRATION) {
        if (memberships.length > 1) {
          return await this.modals.open('popup/security/update-email/warning-membership', {
            userHasPassword: this.currentUser.hasPassword,
            requestOTPTask: this.requestOTPTask,
          });
        } else {
          try {
            await this.requestOTPTask.perform(pendingEmail, true);
          } catch {
            isUnexpectedError = true;
          }
        }
      } else if (code === ERRORS.NOT_ENROLLED) {
        return await this.modals.open('popup/security/update-email/secure-account');
      } else {
        isUnexpectedError = true;

        if (ErrorInfo.for(error).shouldSendToSentry && error.status !== 422) {
          this.sentry.captureException(error);
        }
      }
    }
    if (isUnexpectedError) {
      await this.modals.open('popup/security/sca/generic-error');
    } else {
      await this.modals.open('popup/security/email-verification', {
        newEmailAddress: pendingEmail,
        userHasPassword: this.currentUser.hasPassword,
        isNewEmail: this.isNewEmail,
      });
    }
  });

  requestOTPTask = dropTask(async (pendingEmail, confirmMigration) => {
    let endpoint = '/v5/user/email_update/send_otp';
    let method = 'POST';
    await this.networkManager.request(`${authBaseURL}${endpoint}`, {
      method,
      data: JSON.stringify({
        pending_email: pendingEmail,
        confirm_migration: confirmMigration,
      }),
    });
  });

  updatePhoneNumberTask = dropTask(async pendingPhoneNumber => {
    try {
      this.currentUser.pendingPhoneNumber = pendingPhoneNumber;
      await this._updatePhoneNumber(pendingPhoneNumber);

      let isPhoneNumberCreated = Boolean(this.currentUser.phoneNumber);
      let reason = 'success';
      if (isPhoneNumberCreated) {
        reason = await this.modals.open('popup/security/phone-number-verification');
      }

      if (reason === 'success') {
        this.phoneNumberEdition = false;
        this.pendingPhoneNumber = null;
        this.segment.track('profile_phone-update_success');
        this.toastFlashMessages.toastSuccess(this.intl.t('toasts.phone-number-confirm-update'));
        await this.userManager.setup();
      }

      this.currentUser.pendingPhoneNumber = null;
      this.phoneNumberError = null;
    } catch (error) {
      if (
        Array.isArray(error.errors) &&
        error.errors.some(({ code }) =>
          ['invalid_user_phone_number', 'missing_phone_number'].includes(code)
        )
      ) {
        this.phoneNumberError = this.intl.t('validations.errors.phone-number');
      } else {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      }
    }
  });

  saveIbanTask = dropTask(async pendingIban => {
    if (this.iban) {
      return await this.updateIbanTask.perform(pendingIban);
    }

    return await this.createIbanTask.perform(pendingIban);
  });

  updateIbanTask = dropTask(async pendingIban => {
    if (this.abilities.cannot('update iban membership')) {
      return;
    }

    try {
      this.membership.iban = pendingIban;

      await this.model.membership.updateIban();
      this.toastFlashMessages.toastSuccess(
        this.intl.t('users.profile.bank-account.iban.edit.success')
      );
      this.segment.track('reimbursement_iban-update_confirmed', {
        origin: 'profile_screen',
      });

      this.iban = formatIban(maskInput(pendingIban));
      this.handleIbanEdition();
    } catch (error) {
      this._handleIbanError(error);
    }
  });

  createIbanTask = dropTask(async pendingIban => {
    if (this.abilities.cannot('create iban membership')) {
      return;
    }

    try {
      this.membership.iban = pendingIban;

      await this.model.membership.createIban();
      this.toastFlashMessages.toastSuccess(this.intl.t('users.profile.bank-account.iban.success'));
      this.segment.track('reimbursement_iban-add_confirmed', {
        origin: 'profile_screen',
      });

      this.iban = formatIban(maskInput(pendingIban));
      this.handleIbanEdition();
    } catch (error) {
      this._handleIbanError(error);
    }
  });

  deleteIbanTask = dropTask(async () => {
    if (this.abilities.cannot('delete iban membership')) {
      return;
    }

    let result = await this.modals.open('popup/destructive', {
      title: this.intl.t('users.profile.bank-account.iban.delete.title'),
      description: this.intl.t('users.profile.bank-account.iban.delete.disclaimer'),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('users.profile.bank-account.iban.delete.cta'),
    });

    if (result === 'confirm') {
      try {
        await this.model.membership.deleteIban();
        this.toastFlashMessages.toastSuccess(
          this.intl.t('users.profile.bank-account.iban.delete.success')
        );
        this.segment.track('reimbursement_iban-delete-confirmed');

        this.iban = null;
      } catch (error) {
        this.sentry.captureException(error);
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      }
    } else {
      this.segment.track('reimbursement_iban-delete-cancelled');
    }
  });

  _handleIbanError(error) {
    if (
      Array.isArray(error.errors) &&
      error.errors.some(({ detail }) =>
        ['not_valid_iban', 'not_a_string', 'blank'].includes(detail.code)
      )
    ) {
      this.pendingIbanError = this.intl.t('users.profile.bank-account.iban.invalid');
      this.segment.track('reimbursement_iban_incorrect', {
        origin: 'profile_screen',
      });
    } else {
      this.sentry.captureException(error);
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  }

  /**
   * Update the phone_number.
   * FYI: We can end up with some users that have empty phone number after the registration process.
   * For them, when the phone number is empty, we set up one.
   * For users with a valid phone number, though, we shouldn't set pendingPhoneNumber as current number.
   * https://getqonto.atlassian.net/browse/BUG-4809
   * https://getqonto.atlassian.net/browse/BUG-7063
   * https://gitlab.qonto.co/qonto/qonto-auth/-/blob/master/app/service_controllers/user_service_controller.rb#L38
   *
   * @param {string} pendingPhoneNumber
   */
  async _updatePhoneNumber(pendingPhoneNumber) {
    let isPhoneNumberCreated = Boolean(this.currentUser.phoneNumber);
    let endpoint;
    endpoint = isPhoneNumberCreated ? '/v5/user/phone_number' : '/v2/users/me/phone_number';
    let method = isPhoneNumberCreated ? 'PUT' : 'POST';
    await this.networkManager.request(`${authBaseURL}${endpoint}`, {
      method,
      data: JSON.stringify({
        user: {
          phone_number: pendingPhoneNumber,
        },
      }),
    });
  }
}
