import { action } from '@ember/object';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

import { FlowSetup } from 'qonto/routes/flows/setup/internals';

class InternationalOutBeneficiaryFlowDataContext {
  beneficiary = null;
}

export default class InternationalOutBeneficiaryFlowSetup extends FlowSetup {
  @service abilities;
  @service homePage;
  @service internationalOutManager;
  @service intl;
  @service router;
  @service toastFlashMessages;

  constructor() {
    super(...arguments);
    this.dataContext = new InternationalOutBeneficiaryFlowDataContext();
  }

  async beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'transfers');

    let canAccess =
      this.abilities.can('create beneficiary') && (await this.internationalOutManager.isEligible());

    if (!canAccess) {
      this.homePage.replaceWithDefaultPage();
    }
  }

  onComplete() {}

  @action
  onSuccessToast() {
    this.toastFlashMessages.toastSuccess(
      this.intl.t('international-out.beneficiaries.create.success')
    );
  }

  @action
  onAbort() {
    this.router.transitionTo('transfers.landing');
  }
}
