import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class SettingsAnalyticsUpgradePlanRoute extends Route {
  @service abilities;
  @service homePage;
  @service router;

  beforeModel() {
    if (this.abilities.cannot('view custom-label')) {
      return this.homePage.replaceWithDefaultPage();
    }
    if (this.abilities.can('access custom-label')) {
      return this.router.replaceWith('custom-labels');
    }
  }
}
