import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import * as Sentry from '@sentry/ember';
import dayjs from 'dayjs';
import { dropTask } from 'ember-concurrency';

import CURRENCIES from 'qonto/constants/currencies';
import { DATE_PICKER_FIELD_FORMAT } from 'qonto/constants/dates';
import { FlowSetup } from 'qonto/routes/flows/setup/internals';

class DirectDebitSubscriptionFlowDataContext {
  directDebitSubscription = null;
  clientToEdit = null;
  guardingDepositPercentage = null;

  @tracked
  useExistingMandate = true;

  constructor({ directDebitSubscription }) {
    this.directDebitSubscription = directDebitSubscription;
  }
}

export default class DirectDebitSubscriptionFlowSetup extends FlowSetup {
  @service router;
  @service modals;
  @service segment;
  @service intl;
  @service organizationManager;
  @service store;

  dataContext = null;

  constructor(_owner, previousDataContext = {}) {
    super(...arguments);

    let { directDebitSubscription } = previousDataContext;

    let tomorrow = new Date().setDate(new Date().getDate() + 1);
    let tomorrowString = dayjs(tomorrow).format(DATE_PICKER_FIELD_FORMAT);

    this.dataContext = new DirectDebitSubscriptionFlowDataContext({
      directDebitSubscription:
        directDebitSubscription ||
        this.store.createRecord('direct-debit-subscription', {
          initialCollectionDate: tomorrowString,
          bankAccount: this.organizationManager.organization.mainAccount,
          amount: {
            value: undefined,
            currency: CURRENCIES.default,
          },
        }),
    });
  }

  async beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'transfers');

    await this.organizationManager.organization.getAvatar();

    // important to clean up store
    this.store.unloadAll('direct-debit-collection-activation');

    let { guardingDepositPercentage } = await this.store
      .createRecord('direct-debit-collection-activation')
      .fetchData();

    this.dataContext.guardingDepositPercentage = guardingDepositPercentage;
  }

  @action
  onComplete() {
    this.router.transitionTo('direct-debit-collections.processing');
  }

  onAbortTask = dropTask(async (_, { id: stepId }) => {
    if (stepId !== 'type-selection' && stepId !== 'clients') {
      let result = await this.openAbortModalTask.perform();

      if (result !== 'confirm') {
        return false;
      }
    }

    this.router.transitionTo('direct-debit-collections');

    this.segment.track('incoming-direct-debit_flow_exited');

    return true;
  });

  openAbortModalTask = dropTask(async () => {
    return await this.modals.open('popup/confirmation', {
      title: this.intl.t('flow-in-flow.modal.abort.title'),
      description: this.intl.t('flow-in-flow.modal.abort.subtitle'),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('btn.leave'),
    });
  });
}
