import Route from '@ember/routing/route';

export default class HubApplicationDetailsIndexRoute extends Route {
  settingsRouteName = 'settings.connect-hub.applications.hub-application.details.settings';
  descriptionRouteName = 'settings.connect-hub.applications.hub-application.details.description';
  detailsRouteName = 'settings.connect-hub.applications.hub-application.details';

  model() {
    let hubApplicationModel = this.modelFor(this.detailsRouteName);

    if (hubApplicationModel.hubConnection) {
      this.replaceWith(this.settingsRouteName);
    } else {
      this.replaceWith(this.descriptionRouteName);
    }
  }
}
