import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { all, restartableTask } from 'ember-concurrency';

import { apiBaseURL, apiNamespace } from 'qonto/constants/hosts';
import { REWARDS_STATUS } from 'qonto/constants/referral';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class SettingsReferralsIndexRoute extends Route {
  @service abilities;
  @service homePage;
  @service networkManager;
  @service organizationManager;
  @service store;
  @service sentry;

  referrals = [];
  hasReferrals = false;
  queryParams = {
    rewardStatus: {
      reload: false,
    },
    status: {
      reload: false,
    },
    page: {
      reload: false,
    },
  };

  fetchFilteredReferralsTask = restartableTask(async params => await this.queryReferrals(params));

  fetchFilteredTieredReferralsTask = restartableTask(
    async params => await this.queryRewards(params)
  );

  get isTieredReferral() {
    return this.abilities.can('tiered referral referral');
  }

  async queryReferrals(params) {
    let { id: organization_id } = this.organizationManager.organization;

    return await this.store.query('referral', {
      organization_id,
      ...params,
    });
  }

  async queryRewards(params) {
    let { id: organization_id } = this.organizationManager.organization;

    return await this.networkManager.request(`${apiBaseURL}/${apiNamespace}/referrals/rewards`, {
      data: {
        organization_id,
        page: params.page,
        status: params.rewardStatus,
      },
    });
  }

  beforeModel() {
    if (this.abilities.cannot('read referrals')) {
      this.homePage.replaceWithDefaultPage();
    }
    Sentry.getCurrentScope().setTag('CFT', 'pricing');
  }

  deactivate() {
    this.fetchFilteredReferralsTask.cancelAll();
    this.fetchFilteredTieredReferralsTask.cancelAll();
  }

  async #setReferrals(params) {
    let [registered, rewarded] = await all([
      await this.fetchFilteredReferralsTask.perform({
        ...params,
        status: 'registered',
      }),
      await this.fetchFilteredReferralsTask.perform({
        ...params,
        status: 'rewarded',
      }),
    ]);

    this.hasReferrals = Boolean(registered.length || rewarded.length);

    return {
      registered,
      rewarded,
      hasReferrals: this.hasReferrals,
    };
  }

  async #setTieredReferrals(params) {
    let { id: organization_id } = this.organizationManager.organization;
    let hasRewardsError = false;

    let {
      eligibilities,
      max_reward_amount: maxRewardAmount,
      has_referrals: hasReferrals,
    } = await this.networkManager.request(`${apiBaseURL}/${apiNamespace}/referrals/eligibilities`, {
      data: {
        organization_id,
      },
    });

    if (hasReferrals) {
      await this.fetchFilteredTieredReferralsTask
        .perform(params)
        .catch(ignoreCancelation)
        .catch(error => {
          hasRewardsError = true;
          if (ErrorInfo.for(error).shouldSendToSentry) {
            this.sentry.captureException(error);
          }
        });
    }

    return {
      eligibilities,
      maxRewardAmount,
      hasReferrals,
      hasRewardsError,
      fetchFilteredTieredReferralsTask: this.fetchFilteredTieredReferralsTask,
    };
  }

  async model(params) {
    if (this.isTieredReferral) {
      return await this.#setTieredReferrals(params);
    } else {
      return await this.#setReferrals(params);
    }
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    // by default, if no referral has been awarded yet
    // display existing pending invites
    if (this.isTieredReferral) {
      if (
        !model.fetchFilteredTieredReferralsTask.last?.value?.rewards.length &&
        controller.rewardStatus === REWARDS_STATUS.EARNED &&
        !model.hasRewardsError
      ) {
        controller.rewardStatus = REWARDS_STATUS.PENDING;
      }

      controller.hasRewardsError = model.hasRewardsError;
    } else {
      if (model.rewarded.length === 0 && model.registered.length > 0) {
        controller.status = 'registered';
      }
    }

    controller.fetchData();
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.page = 1;
      controller.status = 'rewarded';
      controller.rewardStatus = REWARDS_STATUS.EARNED;
    }
  }
}
