import { action } from '@ember/object';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

import { FlowSetup } from 'qonto/routes/flows/setup/internals';

class SepaTransferBeneficiaryFlowDataContext {
  beneficiary = null;
}

export default class SepaTransferBeneficiaryFlowSetup extends FlowSetup {
  @service abilities;
  @service router;
  @service toastFlashMessages;
  @service segment;
  @service intl;
  @service store;
  @service attachmentsManager;

  dataContext = null;

  constructor() {
    super(...arguments);
    this.dataContext = new SepaTransferBeneficiaryFlowDataContext();
  }

  beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'transfers');

    if (this.abilities.cannot('create beneficiary')) {
      return this.router.replaceWith('transfers.landing');
    }
  }

  onComplete() {}

  @action
  onSuccessToast(dataContext) {
    this._trackBeneficiarySelection(dataContext);

    if (dataContext.beneficiaryToEdit) {
      this.toastFlashMessages.toastSuccess(
        this.intl.t('transfers.beneficiaries.edit-modal.success')
      );
    } else {
      this.toastFlashMessages.toastSuccess(
        this.intl.t('transfers.beneficiaries.add-modal.success')
      );
    }
  }

  _trackBeneficiarySelection({ beneficiaryToEdit, invoice, origin }) {
    let isBeneficiaryEdited = Boolean(beneficiaryToEdit);

    this.segment.track('transfer-sepa_beneficiary_selected', {
      ...(invoice?.id && { flow: 'byinvoice' }),
      ...(!isBeneficiaryEdited && { new_beneficiary: !isBeneficiaryEdited }),
      ...(isBeneficiaryEdited && { edited_beneficiary: isBeneficiaryEdited }),
      ...(origin && { origin }),
    });
  }

  /**
   * In most cases, primary flow abortTask will take over
   * but on some convoluted navigation cases users managed to get it executed
   */
  onAbort() {
    this.router.transitionTo('transfers.landing');
    return true;
  }
}
