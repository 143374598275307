import { service } from '@ember/service';

import CardsIndexController from '../index/controller';

export default class CardsMyCardsController extends CardsIndexController {
  @service abilities;
  @service organizationManager;

  get activeTab() {
    return 'my-cards';
  }

  get isAccountSelectorShown() {
    let availableBankAccounts =
      this.organizationManager.organization.activeSortedRemuneratedAndCurrentAccounts;

    return this.abilities.can('read bankAccount') && availableBankAccounts.length > 1;
  }

  get emptyStateOptions() {
    if (!this.shouldDisplayEmptyState) {
      return null;
    }

    if (this.abilities.can('create card')) {
      return {
        title: this.intl.t('empty-states.cards.tabs.my-cards.approver-title'),
        subtitle: this.intl.t('empty-states.cards.tabs.my-cards.approver-body'),
        lottieSrc: '/lotties/empty-state/no-card.json',
      };
    } else if (this.abilities.can('create card request')) {
      return {
        title: this.intl.t('empty-states.cards.tabs.my-cards.requester-title'),
        subtitle: this.intl.t('empty-states.cards.tabs.my-cards.requester-body'),
        lottieSrc: '/lotties/empty-state/no-card.json',
      };
    }
    return {
      title: this.intl.t('empty-states.cards.tabs.my-cards.essential-title'),
      subtitle: this.intl.t('empty-states.cards.tabs.my-cards.essential-body'),
      lottieSrc: '/lotties/empty-state/no-card.json',
    };
  }
}
