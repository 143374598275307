import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { IS_PERIODIC_WARNING_REVIEWED } from 'qonto/constants/kyc-kyb-update-process';
import { getSessionStorageItem } from 'qonto/utils/session-storage';

export default class PeriodicReviewWarningRoute extends Route {
  @service router;
  @service organizationManager;
  @service periodicUpdate;
  @service homePage;
  @service menu;

  updateProcess = null;

  getRedirectRoute(transition) {
    return transition.to.queryParams.redirectRoute || this.homePage.defaultRouteName;
  }

  async beforeModel() {
    if (getSessionStorageItem(IS_PERIODIC_WARNING_REVIEWED)) {
      return this.router.replaceWith(this.homePage.defaultRouteName);
    }

    this.updateProcess = await this.periodicUpdate.fetchUpdateProcess();

    if (
      !this.periodicUpdate.shouldDisplayPeriodReviewWarning({ updateProcess: this.updateProcess })
    ) {
      return this.router.replaceWith(this.homePage.defaultRouteName);
    }
  }

  async model(params, transition) {
    let { organization } = this.organizationManager;
    let updateProcess = this.updateProcess || (await this.periodicUpdate.fetchUpdateProcess());

    let redirectRoute = this.getRedirectRoute(transition);

    return {
      organization,
      updateProcess,
      redirectRoute,
    };
  }

  activate() {
    this.menu.hide();
  }

  deactivate() {
    this.menu.show();
  }
}
