import { InvalidError } from '@ember-data/adapter/error';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { dropTask, task } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import { SCHEDULE_OPERATION_TYPES } from 'qonto/constants/standing-orders';
import { SEPA_MODAL_CLOSE_REASONS } from 'qonto/constants/transfers';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class TransfersInternalNewController extends Controller {
  @service errors;
  @service intl;
  @service modals;
  @service organizationManager;
  @service router;
  @service segment;
  @service store;

  @tracked confirmModal;
  @tracked kycModal;

  queryParams = ['bankAccount'];

  get operationTypes() {
    return Object.values(SCHEDULE_OPERATION_TYPES);
  }

  @reads('organizationManager.organization') organization;
  @reads('organization.hasMultipleActiveCurrentRemuneratedAccounts') showAccount;

  @action handleGoBack() {
    this.router.transitionTo('transfers.landing');
  }

  handleConfirmTask = dropTask(async transfer => {
    let { warnings, errors } = await transfer.confirm();

    let hasErrors = errors.length > 0;

    this.confirmModal = this.modals.open('transfers/internal/confirm-modal', {
      warnings,
      errors,
      role: this.organizationManager.membership.role,
      disabledConfirm: hasErrors,
      transfer: this.model,
      confirmTransferTask: this.confirmTransferTask,
    });
  });

  confirmTransferTask = dropTask(async () => {
    let transfer = this.model;

    transfer.addIdempotencyHeader();
    try {
      await transfer.save();

      if (this.confirmModal) {
        this.confirmModal.close();
      }

      if (!this.organizationManager.membership.kycSubmitted) {
        this.kycModal = this.modals.open('transfers/kyc/transition-modal', {
          transfer,
        });
      } else {
        await this.onTransferCreate(transfer);
      }
    } catch (error) {
      if (error instanceof InvalidError) {
        if (this.confirmModal) {
          this.confirmModal.close();
        }
        if (this.kycModal) {
          this.kycModal.close();
        }
      } else if (this.errors.shouldFlash(error)) {
        this.errors.handleError(error);
        this.router.transitionTo();
      }
      throw error;
    } finally {
      transfer.removeIdempotencyHeader();
    }
  });

  @action selectAccountToCredit({ id, name, iban }) {
    this.model.setProperties({
      name,
      iban,
      creditBankAccountId: id,
      activityTag: 'treasury_and_interco',
      reference: this.model.reference || this.intl.t('transfers.new.internal-transfer'),
    });
  }

  onTransferCreate(transfer) {
    this.displaySuccessModalTask.perform(transfer).catch(ignoreCancelation);
  }

  displaySuccessModalTask = task(async transfer => {
    let closeReason = await this.modals.open('transfers/internal/success-modal', {
      transfer,
    });
    if (closeReason === SEPA_MODAL_CLOSE_REASONS.MAKE_NEW_TRANSFER) {
      this._createNewTransfer();
    } else {
      this.router.transitionTo('transfers.pending');
    }
  });

  _createNewTransfer() {
    this.segment.track('transfer_create_modal_success_create_new');
    this.router.transitionTo('transfers.landing');
  }
}
