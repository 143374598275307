import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class SettingsAllowedEmailUpgradePlanRoute extends Route {
  @service abilities;
  @service router;

  rootRouteName = 'settings.receipts-forward';

  beforeModel() {
    if (this.abilities.can('access email forward attachment')) {
      return this.router.replaceWith(this.rootRouteName);
    }
  }
}
