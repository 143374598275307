import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { dropTask } from 'ember-concurrency';

import {
  DEFAULT_CHECKS_CURRENCY,
  MAX_CHECK_DEPOSIT_AMOUNT,
  MAX_CHECK_DEPOSIT_AMOUNT_ON_PERIOD,
} from 'qonto/constants/checks';
import { FlowSetup } from 'qonto/routes/flows/setup/internals';

const STEPS_WITH_ABORT_MODALS = ['emitter', 'details', 'account', 'confirm'];

class CheckFlowDataContext {
  check = null;

  constructor(check) {
    this.check = check;
  }
}

export default class CheckFlowSetup extends FlowSetup {
  @service abilities;
  @service featuresManager;
  @service intl;
  @service modals;
  @service organizationManager;
  @service router;
  @service store;

  constructor() {
    super(...arguments);

    let { organization } = this.organizationManager;
    let { defaultAccount } = organization;

    let check = this.store.createRecord('check', {
      bankAccount: defaultAccount,
      organization,
      amountCurrency: DEFAULT_CHECKS_CURRENCY,
    });

    let dataContext = new CheckFlowDataContext(check);

    dataContext.hasMultipleActiveAccounts = organization.hasMultipleActiveAccounts;
    dataContext.maxCheckDepositAmount = MAX_CHECK_DEPOSIT_AMOUNT;
    dataContext.maxCheckDepositAmountOnPeriod = MAX_CHECK_DEPOSIT_AMOUNT_ON_PERIOD;
    dataContext.organizationMaxCreditDelayDays = this.organizationMaxCreditDelayDays;
    dataContext.organizationSlug = organization.slug;
    dataContext.isTessiFNCIenabled = true;
    dataContext.hasValidMandate = false;
    dataContext.isFNCIgenericError = false;

    this.dataContext = dataContext;
  }

  get organizationMaxCreditDelayDays() {
    return this.organizationManager.organization.checkSettings.max_credit_delay_days || 0;
  }

  shouldDisplayAbortModal(stepId) {
    let { emitterName, emittedDate } = this.dataContext.check;
    return STEPS_WITH_ABORT_MODALS.includes(stepId) && (emitterName || emittedDate);
  }

  beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'transfers');

    if (this.abilities.cannot('create check')) {
      return this.router.transitionTo('checks');
    }
  }

  onComplete() {
    return this.router.transitionTo('checks');
  }

  onAbortTask = dropTask(async (_, { id: stepId }) => {
    let isAbortConfirmed = true;

    if (this.shouldDisplayAbortModal(stepId)) {
      let result = await this.openAbortModalTask.perform();
      isAbortConfirmed = result === 'confirm';
      if (isAbortConfirmed) {
        this.router.transitionTo('checks');
      }
    } else {
      this.router.transitionTo('checks');
    }

    return isAbortConfirmed;
  });

  openAbortModalTask = dropTask(async () => {
    return await this.modals.open('popup/destructive', {
      title: this.intl.t('checks.modal.title'),
      description: this.intl.t('checks.modal.description'),
      cancel: this.intl.t('btn.back'),
      confirm: this.intl.t('btn.cancel-deposit'),
    });
  });
}
