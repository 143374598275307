import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

export default class KycFailController extends Controller {
  @service router;
  @service homePage;
  queryParams = ['redirectRoute'];

  @action
  handleOnClose() {
    return this.router.transitionTo(this.homePage.defaultRouteName);
  }
}
