import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { EmptyStatesDivider } from '@repo/domain-kit/pricing';
import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { getEmptyStateConfig } from 'qonto/constants/empty-states/receivable-invoice';
import {
  getTrackingNameAndProperties,
  LAYOUT,
  TRACKING_ORIGINS,
  TYPES,
} from 'qonto/constants/empty-states/system';
import { INVITATION_TYPES } from 'qonto/constants/membership';
import {
  AR_ONBOARDING_USER_ACTIONS,
  FULLPAGE_EMPTY_STATE_FF,
  ONBOARDING_EMPTY_STATE_FF,
  ONBOARDING_STATUS,
  SETTINGS_DEFAULT_VALUES,
  STATUS,
} from 'qonto/constants/receivable-invoice';
import {
  USER_ACTIONS_CTA_TYPE,
  USER_ACTIONS_ILLUSTRATION_TYPE,
  USER_ACTIONS_STATUS,
  USER_ACTIONS_TYPE,
} from 'qonto/constants/user-actions';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { TrackRender } from 'qonto/react/components/track-render';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

const DISCOVERY_ACTIONS_CACHE_KEY = 'AR_PRODUCT_DISCOVERY_ACTIONS';

export default class ReceivableInvoicesIndexController extends Controller {
  emptyStatesDivider = EmptyStatesDivider;

  @service intl;
  @service router;
  @service segment;
  @service subscriptionManager;
  @service abilities;
  @service organizationManager;
  @service modals;
  @service emptyStates;
  @service receivableInvoicesUploadManager;
  @service flowLinkManager;
  @service sentry;
  @service toastFlashMessages;
  @service errors;

  queryParams = ['status', 'page', 'perPage', 'sortBy'];
  @tracked status = STATUS.DRAFT;
  @tracked page = 1;
  @tracked perPage = 25;
  @tracked sortBy = '-issue_date,-number';

  @tracked cachedProductDiscoveryActions = JSON.parse(
    safeLocalStorage.getItem(DISCOVERY_ACTIONS_CACHE_KEY)
  );

  get legalCountry() {
    return this.organizationManager.organization.legalCountry;
  }

  get organization() {
    return this.organizationManager.organization;
  }

  get showProductDiscoveryStack() {
    return (
      variation('feature-invoices-ar-onboarding') &&
      ![ONBOARDING_STATUS.NOT_STARTED, ONBOARDING_STATUS.NOT_ELIGIBLE].includes(
        this.model.onboardingState?.status
      ) &&
      this.userActions.length > 0
    );
  }

  #showUserAction(name) {
    switch (name) {
      case AR_ONBOARDING_USER_ACTIONS.RESUME_INVOICE_ONBOARDING:
        return (
          this.abilities.can('complete accountsReceivableOnboarding') &&
          this.model.onboardingState?.status === ONBOARDING_STATUS.STARTED &&
          this.totalCount.unpaid + this.totalCount.completed === 0
        );
      case AR_ONBOARDING_USER_ACTIONS.SETUP_NUMBERING:
        return (
          [ONBOARDING_STATUS.COMPLETED, ONBOARDING_STATUS.SKIPPED].includes(
            this.model.onboardingState?.status
          ) &&
          this.settings?.numberingMode !== 'manual' &&
          this.totalCount.unpaid + this.totalCount.completed === 0 &&
          this.settings?.invoiceNextNumber === '001'
        );

      case AR_ONBOARDING_USER_ACTIONS.COMPLETE_COMPANY_DETAILS:
        if (
          ![ONBOARDING_STATUS.COMPLETED, ONBOARDING_STATUS.SKIPPED].includes(
            this.model.onboardingState?.status
          )
        ) {
          return false;
        }

        if (this.legalCountry === 'FR') {
          return !this.settings?.commercialRegisterNumber || !this.settings?.legalCapitalShare;
        }

        if (this.legalCountry === 'DE') {
          return !this.settings?.districtCourt || !this.settings?.companyLeadership;
        }
        return false;

      case AR_ONBOARDING_USER_ACTIONS.TEMPLATE_CUSTOMIZATION:
        if (
          ![ONBOARDING_STATUS.COMPLETED, ONBOARDING_STATUS.SKIPPED].includes(
            this.model.onboardingState?.status
          )
        ) {
          return false;
        }

        if (this.legalCountry === 'DE') {
          return (
            this.organization.isDefaultAvatar &&
            this.settings?.colorText === SETTINGS_DEFAULT_VALUES.colorText &&
            this.settings?.colorTextAlt === SETTINGS_DEFAULT_VALUES.colorTextAlt &&
            this.settings?.colorTheme === SETTINGS_DEFAULT_VALUES.colorTheme &&
            this.settings?.invoiceHeader === SETTINGS_DEFAULT_VALUES.invoiceHeader &&
            this.settings?.invoiceFooter === SETTINGS_DEFAULT_VALUES.invoiceFooter
          );
        } else {
          return (
            this.organization.isDefaultAvatar &&
            this.settings?.colorText === SETTINGS_DEFAULT_VALUES.colorText &&
            this.settings?.colorTextAlt === SETTINGS_DEFAULT_VALUES.colorTextAlt &&
            this.settings?.colorTheme === SETTINGS_DEFAULT_VALUES.colorTheme
          );
        }

      case AR_ONBOARDING_USER_ACTIONS.CLIENTS_IMPORT:
        return (
          variation('feature--boolean-ar-import-clients-and-items') &&
          (this.model.totalClientsCount ?? 0) < 5
        );

      case AR_ONBOARDING_USER_ACTIONS.ITEMS_IMPORT:
        return (
          variation('feature--boolean-ar-import-clients-and-items') &&
          (this.model.totalProductsCount ?? 0) < 5
        );

      case AR_ONBOARDING_USER_ACTIONS.ACCOUNTANT_INVITE:
        return (
          [ONBOARDING_STATUS.COMPLETED, ONBOARDING_STATUS.SKIPPED].includes(
            this.model.onboardingState?.status
          ) &&
          !this.model.isAlreadyInvitedAccountant &&
          this.abilities.can('access accountant-access')
        );

      case AR_ONBOARDING_USER_ACTIONS.IMPORT_INVOICES:
        return (
          [ONBOARDING_STATUS.COMPLETED, ONBOARDING_STATUS.SKIPPED].includes(
            this.model.onboardingState?.status
          ) &&
          this.abilities.can('import receivableInvoice') &&
          !this.model.invoicesTask.lastSuccessful?.value.receivableInvoices.filter(
            invoice => invoice.imported
          ).length
        );

      case AR_ONBOARDING_USER_ACTIONS.COMPLETE_COMPANY_PROFILE:
        return (
          this.legalCountry === 'FR' &&
          [ONBOARDING_STATUS.COMPLETED, ONBOARDING_STATUS.SKIPPED].includes(
            this.model.onboardingState?.status
          ) &&
          (!this.settings?.contactEmail || !this.settings?.vatNumber)
        );
      default:
        return false;
    }
  }

  @action
  goToImportInvoices() {
    let element = document.getElementById('import-invoices-button');
    // Clicking the element to open the file browser is not allowed by the browser so this is a workaround
    element.dispatchEvent(new KeyboardEvent('keypress', { key: 'Enter' }));
  }

  get userActions() {
    let actions = [
      this.#showUserAction(AR_ONBOARDING_USER_ACTIONS.RESUME_INVOICE_ONBOARDING) && {
        name: AR_ONBOARDING_USER_ACTIONS.RESUME_INVOICE_ONBOARDING,
        status: USER_ACTIONS_STATUS.ENABLED,
        type: USER_ACTIONS_TYPE.DISCOVERY,
        heading: this.intl.t('receivable-invoices.pds.finish-invoice-details'),
        cta: {
          type: USER_ACTIONS_CTA_TYPE.LINK_TO_FLOW,
          name: 'account-receivable-onboarding',
          stepId: 'summary',
          model: this.organizationManager.organization.slug,
          buttonType: 'tertiary',
          text: this.intl.t('user-actions.list.cta'),
        },
        illustration: {
          name: 'invoices-settings',
          type: USER_ACTIONS_ILLUSTRATION_TYPE.SVG,
        },
      },
      this.#showUserAction(AR_ONBOARDING_USER_ACTIONS.SETUP_NUMBERING) && {
        name: AR_ONBOARDING_USER_ACTIONS.SETUP_NUMBERING,
        status: USER_ACTIONS_STATUS.ENABLED,
        type: USER_ACTIONS_TYPE.DISCOVERY,
        heading: this.intl.t('receivable-invoices.pds.pick-invoice-number'),
        cta: {
          type: USER_ACTIONS_CTA_TYPE.LINK_TO,
          route: 'invoicing-settings',
          query: { target: 'numbering' },
          model: this.organizationManager.organization,
          buttonType: 'tertiary',
          text: this.intl.t('user-actions.list.cta'),
        },
        illustration: {
          name: 'supplier-invoice-flat',
          type: USER_ACTIONS_ILLUSTRATION_TYPE.SVG,
        },
      },
      this.#showUserAction(AR_ONBOARDING_USER_ACTIONS.COMPLETE_COMPANY_DETAILS) && {
        name: AR_ONBOARDING_USER_ACTIONS.COMPLETE_COMPANY_DETAILS,
        status: USER_ACTIONS_STATUS.ENABLED,
        type: USER_ACTIONS_TYPE.DISCOVERY,
        heading: this.organization.isAssociation
          ? this.intl.t('receivable-invoices.pds.add-association-details')
          : this.organization.isBusiness
            ? this.intl.t('receivable-invoices.pds.add-business-details')
            : this.intl.t('receivable-invoices.pds.add-company-details'),
        cta: {
          type: USER_ACTIONS_CTA_TYPE.LINK_TO,
          route: 'invoicing-settings',
          query: { target: 'company-details' },
          model: this.organizationManager.organization,
          buttonType: 'tertiary',
          text: this.intl.t('user-actions.list.cta'),
        },
        illustration: {
          name: 'general-residence',
          type: USER_ACTIONS_ILLUSTRATION_TYPE.SVG,
        },
      },
      this.#showUserAction(AR_ONBOARDING_USER_ACTIONS.TEMPLATE_CUSTOMIZATION) && {
        name: AR_ONBOARDING_USER_ACTIONS.TEMPLATE_CUSTOMIZATION,
        status: USER_ACTIONS_STATUS.ENABLED,
        type: USER_ACTIONS_TYPE.DISCOVERY,
        heading: this.intl.t('receivable-invoices.pds.customize'),
        cta: {
          type: USER_ACTIONS_CTA_TYPE.LINK_TO,
          route: 'invoicing-settings',
          query: { target: 'customization' },
          model: this.organizationManager.organization,
          buttonType: 'tertiary',
          text: this.intl.t('user-actions.list.cta'),
        },
        illustration: {
          name: 'invoices-customization',
          type: USER_ACTIONS_ILLUSTRATION_TYPE.SVG,
        },
      },
      this.#showUserAction(AR_ONBOARDING_USER_ACTIONS.CLIENTS_IMPORT) && {
        name: AR_ONBOARDING_USER_ACTIONS.CLIENTS_IMPORT,
        status: USER_ACTIONS_STATUS.ENABLED,
        type: USER_ACTIONS_TYPE.DISCOVERY,
        heading: this.intl.t('receivable-invoices.pds.import-clients'),
        cta: {
          type: USER_ACTIONS_CTA_TYPE.LINK_TO_FLOW,
          name: 'invoice-clients-import',
          stepId: 'initial',
          model: this.organizationManager.organization.slug,
          buttonType: 'tertiary',
          text: this.intl.t('user-actions.list.cta'),
        },
        illustration: {
          name: 'import-clients',
          type: USER_ACTIONS_ILLUSTRATION_TYPE.SVG,
        },
      },
      this.#showUserAction(AR_ONBOARDING_USER_ACTIONS.ITEMS_IMPORT) && {
        name: AR_ONBOARDING_USER_ACTIONS.ITEMS_IMPORT,
        status: USER_ACTIONS_STATUS.ENABLED,
        type: USER_ACTIONS_TYPE.DISCOVERY,
        heading: this.intl.t('receivable-invoices.pds.import-products'),
        cta: {
          type: USER_ACTIONS_CTA_TYPE.LINK_TO_FLOW,
          name: 'invoice-products-import',
          stepId: 'upload',
          model: this.organizationManager.organization.slug,
          buttonType: 'tertiary',
          text: this.intl.t('user-actions.list.cta'),
        },
        illustration: {
          name: 'import-products',
          type: USER_ACTIONS_ILLUSTRATION_TYPE.SVG,
        },
      },
      this.#showUserAction(AR_ONBOARDING_USER_ACTIONS.ACCOUNTANT_INVITE) && {
        name: AR_ONBOARDING_USER_ACTIONS.ACCOUNTANT_INVITE,
        status: USER_ACTIONS_STATUS.ENABLED,
        type: USER_ACTIONS_TYPE.DISCOVERY,
        heading: this.intl.t('receivable-invoices.pds.invite-accountant'),
        cta: {
          type: USER_ACTIONS_CTA_TYPE.LINK_TO_FLOW,
          name: 'member-invite',
          stepId: 'personal-info',
          query: { invitationType: INVITATION_TYPES.GUEST },
          model: this.organizationManager.organization.slug,
          buttonType: 'tertiary',
          text: this.intl.t('user-actions.list.cta'),
        },
        illustration: {
          name: 'cards-calculator',
          type: USER_ACTIONS_ILLUSTRATION_TYPE.SVG,
        },
      },
      this.#showUserAction(AR_ONBOARDING_USER_ACTIONS.IMPORT_INVOICES) && {
        name: AR_ONBOARDING_USER_ACTIONS.IMPORT_INVOICES,
        status: USER_ACTIONS_STATUS.ENABLED,
        type: USER_ACTIONS_TYPE.DISCOVERY,
        heading: this.intl.t('receivable-invoices.pds.import-invoices'),
        cta: {
          type: USER_ACTIONS_CTA_TYPE.BUTTON,
          buttonType: 'tertiary',
          onClick: this.goToImportInvoices,
          text: this.intl.t('user-actions.list.cta'),
        },
        illustration: {
          name: 'files-import-invoice',
          type: USER_ACTIONS_ILLUSTRATION_TYPE.SVG,
        },
      },
      this.#showUserAction(AR_ONBOARDING_USER_ACTIONS.COMPLETE_COMPANY_PROFILE) && {
        name: AR_ONBOARDING_USER_ACTIONS.COMPLETE_COMPANY_PROFILE,
        status: USER_ACTIONS_STATUS.ENABLED,
        type: USER_ACTIONS_TYPE.DISCOVERY,
        heading: this.intl.t('receivable-invoices.pds.additional-business-details'),
        cta: {
          type: USER_ACTIONS_CTA_TYPE.LINK_TO,
          route: 'invoicing-settings',
          query: { target: 'company-details' },
          model: this.organizationManager.organization,
          buttonType: 'tertiary',
          text: this.intl.t('user-actions.list.cta'),
        },
        illustration: {
          name: 'roles-and-permissions-company-access',
          type: USER_ACTIONS_ILLUSTRATION_TYPE.SVG,
        },
      },
    ];

    actions = actions.filter(Boolean);
    return this.cachedProductDiscoveryActions
      ? actions.filter(
          ({ name }) => this.cachedProductDiscoveryActions?.[name] !== USER_ACTIONS_STATUS.DISMISSED
        )
      : actions;
  }

  onDismissCardTask = dropTask(async ({ name }) => {
    this.cachedProductDiscoveryActions = {
      ...this.cachedProductDiscoveryActions,
      [name]: USER_ACTIONS_STATUS.DISMISSED,
    };
    await safeLocalStorage.setItem(
      'AR_PRODUCT_DISCOVERY_ACTIONS',
      JSON.stringify(this.cachedProductDiscoveryActions)
    );
  });

  get currentParams() {
    return {
      status: this.status,
      sortBy: this.sortBy,
      page: this.page,
      perPage: this.perPage,
    };
  }

  get sideDrawerId() {
    return this.abilities.can('request financing')
      ? 'receivable-invoices-with-financing'
      : 'receivable-invoices';
  }

  get invoiceStats() {
    return this.model.invoicesTask.lastSuccessful?.value.invoiceStats;
  }

  get showGlobalEmptyState() {
    if (
      this.abilities.can('complete accountsReceivableOnboarding') &&
      ![ONBOARDING_STATUS.NOT_STARTED, ONBOARDING_STATUS.NOT_ELIGIBLE].includes(
        this.model.onboardingState?.status
      ) &&
      this.goToOnboardingTask.isIdle
    ) {
      return false;
    }
    return this.invoiceStats?.created?.total === 0;
  }

  get isEmptyLocally() {
    let createdInvoices = this.invoiceStats?.created;
    switch (this.status) {
      case STATUS.DRAFT:
        return createdInvoices?.draft === 0;
      case STATUS.UNPAID:
        return createdInvoices?.unpaid === 0;
      case STATUS.CANCELED:
        return createdInvoices?.canceled === 0;
      case STATUS.PAID:
        return createdInvoices?.paid === 0;
    }

    if (this.isCompletedTabActive) {
      return createdInvoices?.paid === 0 && createdInvoices?.canceled === 0;
    }
  }

  get tabName() {
    switch (this.status) {
      case STATUS.DRAFT:
        return 'draft';
      case STATUS.UNPAID:
        return 'pending';
      default:
        return 'completed';
    }
  }

  get isExportButtonEnabled() {
    if (this.model.invoicesTask.isRunning) {
      return true;
    }

    let createdInvoices = this.invoiceStats?.created;
    if (!createdInvoices) {
      return false;
    }

    return [
      createdInvoices[STATUS.UNPAID],
      createdInvoices[STATUS.CANCELED],
      createdInvoices[STATUS.PAID],
    ].some(status => status > 0);
  }

  trackCtaEvent(origin) {
    if (this.emptyStateRevampOptions) {
      this.emptyStates.trackCta(this.emptyStateRevampOptions, origin);
    } else {
      let trackingData = getTrackingNameAndProperties({
        type: TYPES.ACTIVATE,
        name: 'client-invoices',
      })({
        isClickEvent: true,
        isEmptyState: false,
        origin: TRACKING_ORIGINS.HEADER,
      });

      if (trackingData?.name && trackingData.properties) {
        this.segment.track(trackingData.name, trackingData.properties);
      }
    }
  }

  get isInformEmptyState() {
    return this.emptyStateRevampOptions?.layout === LAYOUT.INFORM;
  }

  get displayOnboardingEmptyState() {
    return (
      this.abilities.can('complete accountsReceivableOnboarding') &&
      (this.model.onboardingState?.status === ONBOARDING_STATUS.NOT_STARTED ||
        this.goToOnboardingTask.isRunning)
    );
  }

  get emptyStateRevampOptions() {
    if (!this.model.invoicesTask.isRunning) {
      return this.emptyStates.getEmptyStateOptions({
        isOrgEligibleForFeature: true,
        isEmptyGlobally: this.showGlobalEmptyState,
        isEmptyLocally: this.isEmptyLocally,
        hasActiveFilterOrSearch: Boolean(this.filterValue),
        config: getEmptyStateConfig(this.intl),
        customInputs: {
          tab: this.tabName,
          showOnboarding: this.displayOnboardingEmptyState,
        },
      });
    }
  }

  get hasReachedLimitOfInvoices() {
    return this.invoiceStats?.quotasRemaining?.total === 0;
  }

  get totalCount() {
    return {
      draft: this.invoiceStats?.created?.draft || 0,
      unpaid: this.invoiceStats?.created?.unpaid || 0,
      completed: this.invoiceStats?.created?.paid + this.invoiceStats?.created?.canceled || 0,
    };
  }

  get isCompletedTabActive() {
    let statuses = this.status?.split(',') || [];

    return statuses.includes(STATUS.PAID) || statuses.includes(STATUS.CANCELED);
  }

  get filterValue() {
    if (this.status?.split(',').length > 1) {
      return;
    }

    return this.filterOptions.find(el => el.code === this.status);
  }

  get filterOptions() {
    return [
      {
        code: STATUS.PAID,
        value: this.intl.t('receivable-invoices.status.paid'),
      },
      {
        code: STATUS.CANCELED,
        value: this.intl.t('receivable-invoices.status.canceled'),
      },
    ];
  }

  @action
  updateFilter(selected) {
    if (!selected) {
      this.status = this.filterOptions.map(el => el.code).join(',');
      return;
    }
    this.status = selected.code;
  }

  get canFullyAccess() {
    return this.abilities.can('fully access receivableInvoice');
  }

  get isCreateInvoiceCTADisplayed() {
    return !this.displayEmptyState && !this.hasReachedLimitOfInvoices;
  }

  @action
  trackHeaderClick() {
    this.trackCtaEvent(TRACKING_ORIGINS.HEADER);
  }

  @action
  trackPrimaryClick() {
    this.trackCtaEvent(TRACKING_ORIGINS.PRIMARY);
  }

  @action
  trackSecondaryClick() {
    // specific tracker for import ar feature
    this.segment.track('invoice_imported_get-started_clicked');

    //tracker for empty states feature
    this.trackCtaEvent(TRACKING_ORIGINS.SECONDARY);
  }

  get isFilterActive() {
    return this.status !== null;
  }

  get canDisplayBanner() {
    if (this.model.invoicesTask.isRunning) {
      return false;
    }
    return this.hasReachedLimitOfInvoices;
  }

  get displayEmptyState() {
    let { invoicesTask, canReadInvoices } = this.model;
    let hasReceivableInvoices = false;
    if (invoicesTask.last?.isSuccessful) {
      hasReceivableInvoices = invoicesTask.lastSuccessful.value.receivableInvoices.length > 0;
    }
    return (
      !canReadInvoices ||
      (!invoicesTask.isRunning && !hasReceivableInvoices && !this.isFilterActive)
    );
  }

  get emptyStateOptions() {
    let {
      organization: { legalCountry },
    } = this.organizationManager;

    let emptyStates = {
      title: this.intl.t('receivable-invoices.empty-state-basic-upsell.title', { legalCountry }),
      description: this.intl.t('receivable-invoices.empty-state-basic-upsell.description'),
      cta: this.intl.t('receivable-invoices.empty-state-basic-upsell.cta'),
    };

    if (this.canFullyAccess) {
      emptyStates = {
        title: this.intl.t('receivable-invoices.empty-state.title', { legalCountry }),
        description: this.intl.t('receivable-invoices.empty-state.description', { legalCountry }),
        cta: this.intl.t('receivable-invoices.empty-state.cta'),
      };
    }

    return this.getEmptyStateOptionsFromPath(emptyStates);
  }

  getEmptyStateOptionsFromPath({ title, description, cta }) {
    return {
      title,
      subtitle: description,
      lottieSrc: `/lotties/receivable-invoices/empty-state.json`,
      button: {
        label: cta,
        callback: () => {
          this.segment.track('invoice_creation_started', {
            origin: 'empty_state',
            price_plan: this.subscriptionManager.currentPricePlan.code,
          });
          this.router.transitionTo('receivable-invoices.new');
        },
      },
    };
  }

  get localState() {
    let localState = {
      isEmpty: false,
      isError: false,
      isLoading: false,
    };

    if (this.model.invoicesTask.isRunning || this.model.settingsTask.isRunning) {
      localState.isLoading = true;
    } else if (this.model.invoicesTask.last.isError || this.model.settingsTask.last.isError) {
      localState.isError = true;
    } else if (this.model.invoicesTask.lastSuccessful?.value.meta.total_count === 0) {
      if ([STATUS.PAID, STATUS.CANCELED].includes(this.status)) {
        return;
      }
      localState.isEmpty = true;
    }

    return localState;
  }

  get receivableInvoices() {
    return this.model.invoicesTask.lastSuccessful?.value.receivableInvoices || [];
  }

  get settings() {
    return this.model.settingsTask.lastSuccessful?.value;
  }

  get showStatusFilter() {
    return (
      this.isCompletedTabActive &&
      (this.model.invoicesTask.lastSuccessful?.value.meta.total_count > 0 ||
        [STATUS.CANCELED, STATUS.PAID].includes(this.status))
    );
  }

  get tabEmptyStateOptions() {
    if (!this.status) {
      return;
    }

    switch (this.status) {
      case STATUS.DRAFT:
        return {
          title: !this.canFullyAccess
            ? this.intl.t('receivable-invoices.empty-state-basic-upsell.title')
            : this.intl.t('receivable-invoices.invoices-list.tabs.drafts.empty-state.title'),
          subtitle: !this.canFullyAccess
            ? this.intl.t('receivable-invoices.empty-state-basic-upsell.solo-plan.description')
            : this.intl.t('receivable-invoices.invoices-list.tabs.drafts.empty-state.body'),
          lottieSrc: `/lotties/receivable-invoices/empty-state-draft.json`,
        };
      case STATUS.CANCELED:
        return {
          title: this.intl.t('receivable-invoices.invoices-list.tab_canceled.empty_state.title'),
          subtitle: this.intl.t('receivable-invoices.invoices-list.tab_canceled.empty_state.text', {
            htmlSafe: true,
          }),
          lottieSrc: `/lotties/receivable-invoices/empty-state-canceled.json`,
        };
      case STATUS.PAID:
        return {
          title: this.intl.t('receivable-invoices.invoices-list.tab_paid.empty_state.title'),
          subtitle: this.intl.t('receivable-invoices.invoices-list.tab_paid.empty_state.text', {
            htmlSafe: true,
          }),
          lottieSrc: `/lotties/receivable-invoices/empty-state-paid.json`,
        };
      case STATUS.UNPAID:
        return {
          title: this.intl.t('receivable-invoices.invoices-list.tabs.pending.empty-state.title'),
          subtitle: this.intl.t('receivable-invoices.invoices-list.tabs.pending.empty-state.body'),
          lottieSrc: `/lotties/receivable-invoices/empty-state-unpaid.json`,
        };
      case STATUS.COMPLETED:
        return {
          title: this.intl.t('receivable-invoices.invoices-list.tabs.completed.empty-state.title'),
          subtitle: this.intl.t(
            'receivable-invoices.invoices-list.tabs.completed.empty-state.body'
          ),
          lottieSrc: `/lotties/receivable-invoices/empty-state-paid.json`,
        };
    }
  }

  get columnsToShow() {
    return {
      customer: true,
      issueDate: true,
      dueDate: true,
      status: this.status !== STATUS.DRAFT && this.status !== STATUS.UNPAID,
      amountDue: true,
    };
  }

  get tableCaption() {
    switch (this.status) {
      case 'draft':
        return this.intl.t('receivable-invoices.invoices-list.tabs.drafts.title');
      case 'canceled':
        return this.intl.t('receivable-invoices.invoices-list.tabs.canceled');
      case 'paid':
        return this.intl.t('receivable-invoices.invoices-list.tabs.paid');
      case 'unpaid':
        return this.intl.t('receivable-invoices.invoices-list.tabs.unpaid');
      default:
        return this.intl.t('receivable-invoices.invoices-list.tabs.all');
    }
  }

  @action changePage(page) {
    this.page = page;
  }

  @action changePerPage(perPage) {
    this.perPage = perPage;
  }

  @action handleSortBy(sortDefinition) {
    this.sortBy = sortDefinition;
  }

  @action
  onUploadPopoverClose() {
    this.receivableInvoicesUploadManager.resetState();
    this.segment.track('invoice_imported_upload-component_close-button_clicked');
  }

  @action
  onPreviewFile(file) {
    this.segment.track('invoice_imported_upload-component_invoice-item_clicked');
    this.router.transitionTo('receivable-invoices.show', file.invoiceId);
  }

  @action
  onPrimaryGetStartedClick() {
    let experiment = variation('experiment--ar-onboarding-optimization');

    switch (experiment) {
      case 'creation':
        this.segment.track('client-invoices_cta_clicked', {
          cta_type: 'create',
          empty_state: true,
          origin: 'empty_state_primary',
          redirection: 'invoice_editor',
        });
        this.goToInvoiceCreationTask.perform().catch(ignoreCancelation);
        break;
      case 'onboarding':
        this.segment.track('client-invoices_cta_clicked', {
          cta_type: 'create',
          empty_state: true,
          origin: 'empty_state_primary',
          redirection: 'onboarding',
        });
        this.goToOnboardingTask.perform('initial').catch(ignoreCancelation);
        break;
      default:
        this.trackPrimaryClick();
        this.goToOnboardingTask.perform().catch(ignoreCancelation);
        break;
    }
  }

  @action
  onHeaderGetStartedClick() {
    this.goToOnboardingTask.perform().catch(ignoreCancelation);
    this.trackHeaderClick();
  }

  @action
  onEducationDemoClick() {
    this.segment.track('client-invoices_demo-cta_clicked');
  }

  get importingPopoverCopies() {
    return {
      'in-progress': 'invoicing.importing-modal.title.in-progress',
      errors: 'invoicing.importing-modal.title.errors',
      complete: 'invoicing.importing-modal.title.complete',
    };
  }

  get shouldShowEducationalEmptyState() {
    return (
      this.displayOnboardingEmptyState &&
      variation('feature--string-receivable-invoices-onboarding') ===
        ONBOARDING_EMPTY_STATE_FF.EDUCATION
    );
  }

  get shouldShowFullpageEmptyState() {
    let { NEW_DESIGN_NEW_COPIES, NEW_DESIGN_OLD_COPIES, OFF, OLD_DESIGN } = FULLPAGE_EMPTY_STATE_FF;
    let fullpageEmptyStateVariation = variation('feature--string-receivable-invoices-es-revamp');

    if (!fullpageEmptyStateVariation) {
      return false;
    }

    this.segment.track('empty-state-variation', {
      variation: fullpageEmptyStateVariation,
      legalCountry: this.organizationManager.organization.legalCountry,
    });

    switch (fullpageEmptyStateVariation) {
      case NEW_DESIGN_OLD_COPIES:
      case NEW_DESIGN_NEW_COPIES:
        this.segment.track('empty-state_shown', {
          'empty-state_feature': 'client-invoices',
          'invoicing-test_variation': fullpageEmptyStateVariation,
        });

        return true;
      case OFF:
      case OLD_DESIGN:
      default:
        return false;
    }
  }

  goToOnboardingTask = dropTask(async stepId => {
    try {
      // The onboarding state is guaranteed to be non-nullish otherwise we won't display the CTA
      this.model.onboardingState.status = ONBOARDING_STATUS.STARTED;
      await this.model.onboardingState.save();

      this.flowLinkManager.transitionTo({
        name: 'account-receivable-onboarding',
        stepId: stepId ?? 'summary',
      });
    } catch (error) {
      if (this.errors.shouldFlash(error)) {
        this.toastFlashMessages.toastError(this.errors.messageForStatus(error));
      }

      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  goToInvoiceCreationTask = dropTask(async () => {
    try {
      // The onboarding state is guaranteed to be non-nullish otherwise we won't display the CTA
      this.model.onboardingState.status = ONBOARDING_STATUS.SKIPPED;
      await this.model.onboardingState.save();

      this.router.transitionTo('receivable-invoices.new');
    } catch (error) {
      if (this.errors.shouldFlash(error)) {
        this.toastFlashMessages.toastError(this.errors.messageForStatus(error));
      }

      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  trackRender = TrackRender;
}
