export default {
  "container": "qfD",
  "compact-header": "qfV",
  "visible": "qfL",
  "page-container": "qfT",
  "main": "qfz",
  "tabs": "qfa",
  "tabs-nav": "qfH",
  "tabs-panels": "qfO",
  "tabs-panel": "qfm body-2",
  "sidebar": "qfp",
  "prismic-content": "qfx"
};
