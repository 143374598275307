import EmberObject from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { variation } from 'ember-launch-darkly';

export default class AccountsNewCurrentRoute extends Route {
  @service abilities;
  @service toastFlashMessages;
  @service flowLinkManager;
  @service intl;
  @service menu;
  @service modals;
  @service organizationManager;
  @service segment;
  @service store;
  @service subscriptionManager;

  activate() {
    if (this.menu.isVisible) {
      this.menu.hide();
    }
  }

  model() {
    let { organization } = this.organizationManager;

    return {
      wizardContext: EmberObject.create({
        account: this.store.createRecord('bank-account', { organization }),
      }),
    };
  }

  get plan() {
    let { code } = this.subscriptionManager.currentPricePlan;
    let hasMultiAccounts = this.subscriptionManager.hasFeature('multi_accounts');

    return { code, hasMultiAccounts };
  }

  get organization() {
    return this.organizationManager.organization;
  }

  get hasMultiLocalIban() {
    let { legalCountry, mainAccount, bankAccounts } = this.organization;
    return (
      ['DE', 'IT', 'ES'].includes(legalCountry) &&
      mainAccount.iban.includes('FR') &&
      bankAccounts.length === 1
    );
  }

  get shouldBlockAccountCreation() {
    return (
      this.organization.isItalian &&
      variation('operational--boolean-disable-account-creation-for-italy')
    );
  }

  beforeModel(transition) {
    let { id: organizationId } = this.organization;
    let { code, hasMultiAccounts } = this.plan;
    let cannotCreate = this.abilities.cannot('create bankAccount');

    if (cannotCreate) {
      this.replaceWith('accounts');
    }

    if (this.shouldBlockAccountCreation) {
      this.modals.open('account-creation-blocked-italy-popup', {
        isFullScreenModal: false,
      });
      transition.abort();
    }

    if (this.hasMultiLocalIban) {
      this.segment.track('account_creation_blocked_multilocal_IBAN', { organizationId });
      this.modals.open('account-creation-blocked-popup', {
        isFullScreenModal: false,
      });
      transition.abort();
    }

    if (this.subscriptionManager.hasReachedBankAccountLimit) {
      let message = hasMultiAccounts
        ? this.intl.t('bank_accounts.create.errors.limit')
        : this.intl.t('bank_accounts.create.errors.not-in-plan');

      this.toastFlashMessages.toastError(message);

      this.segment.track('account_creation_limit_reached', {
        reason: hasMultiAccounts ? `limit_reached` : `not_included`,
        plan: code,
        account_count: this.organization.activeOrPendingBankAccounts.length,
      });

      if (transition.from) {
        return this.flowLinkManager.transitionTo({
          name: 'subscription-change',
          stepId: 'plans',
          queryParams: { upsellTrigger: 'bank_account' },
        });
      } else {
        this.replaceWith('accounts');
      }
    }
  }

  deactivate() {
    this.menu.show();
  }
}
