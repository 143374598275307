export const mandate = {
  steps: {
    landing: {
      componentName: 'mandate/landing',
      nextStepId({ hasReceivedMandate }) {
        if (hasReceivedMandate) return 'upload';
        return 'no-mandate';
      },
    },
    'no-mandate': {
      componentName: 'mandate/no-mandate',
      nextStepId: 'test',
    },
    upload: {
      componentName: 'mandate/upload',
      nextStepId: 'creditor',
    },
    creditor: {
      componentName: 'mandate/creditor-info',
      nextStepId: 'mandate-info',
    },
    'mandate-info': {
      componentName: 'mandate/mandate-info',
      nextStepId({ hasMultipleActiveAccounts }) {
        if (hasMultipleActiveAccounts) return 'account';
        return 'review';
      },
    },
    account: {
      componentName: 'mandate/account-selection',
      nextStepId: 'review',
    },
    review: {
      componentName: 'mandate/review',
      nextStepId: 'success',
    },
    success: {
      componentName: 'mandate/success',
    },
  },
  options: {
    enablePersistence: true,
  },
};
