import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import * as Sentry from '@sentry/ember';
import { dropTask } from 'ember-concurrency';
import { bool } from 'macro-decorators';

import { INVITATION_TYPES } from 'qonto/constants/membership';
import { FlowSetup } from 'qonto/routes/flows/setup/internals';
import { ErrorInfo } from 'qonto/utils/error-info';

export class EditMemberDataContext {
  isEditingMember = true;
  invitationType = INVITATION_TYPES.STAFF;

  @tracked member;
  @tracked role;
  @tracked currentUserRole;
  @tracked currentUserCustomPermissions;
}

export default class MemberEditFlowSetup extends FlowSetup {
  @service abilities;
  @service intl;
  @service homePage;
  @service modals;
  @service organizationManager;
  @service router;
  @service store;
  @service subscriptionManager;
  @service toastFlashMessages;
  @service sentry;

  @tracked dataContext;

  @bool('organizationManager.organization.isGbR') isGbR;

  constructor() {
    super(...arguments);

    this.dataContext = new EditMemberDataContext();
  }

  loadMember() {
    let { memberId } = this.router.currentRoute.queryParams;

    if (memberId) {
      return this.store.findRecord('membership', memberId);
    }
  }

  abortEditMember() {
    this.dataContext.member.rollbackAttributes();
    this.router.transitionTo('members.active.member', this.dataContext.member.id);
  }

  get upsellTrigger() {
    if (this.abilities.cannot('access manager role feature members')) {
      return 'managerRole';
    }
  }

  async beforeFlow() {
    let member = await this.loadMember();

    if (this.abilities.cannot('update role', member)) {
      return this.homePage.replaceWithDefaultPage();
    }

    // Checking if the user has the permission to see and update the allowed bank accounts
    if (
      this.abilities.cannot('update allowed-bank-account', member) ||
      this.abilities.cannot('view allowed-bank-account')
    ) {
      return this.homePage.replaceWithDefaultPage();
    }

    // Checking if the edited user has custom permissions (it means they are a manager)
    if (member.customPermissions !== null) {
      try {
        await member.getAllowedBankAccounts();
      } catch (error) {
        let errorInfo = ErrorInfo.for(error);
        if (errorInfo.shouldSendToSentry) {
          this.sentry.captureException(error);
        }

        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
        return this.router.transitionTo('members.active');
      }

      member.allowedBankAccountsIds = member.allowedBankAccounts.map(
        allowedBankAccount => allowedBankAccount.id
      );
    } else {
      member.allowedBankAccountsIds = null;
    }

    this.dataContext.member = member;
    this.dataContext.currentUserRole = member.role;
    this.dataContext.currentUserSIpermission =
      member?.customPermissions?.groups.manage_supplier_invoices;
    this.dataContext.upsellTrigger = this.upsellTrigger;

    Sentry.getCurrentScope().setTag('CFT', 'spend-management');
  }

  @action
  onComplete() {
    this.router.transitionTo('members.active.member', this.dataContext.member.id);
  }

  @action
  onSuccessToast(_, [firstStep]) {
    if (
      [
        'expense-permissions',
        'other-permissions',
        'account-management-team',
        'account-management-organization',
      ].includes(firstStep.id)
    ) {
      this.toastFlashMessages.toastSuccess(this.intl.t('toasts.members.permissions-updated'));
    } else {
      this.toastFlashMessages.toastSuccess(this.intl.t('toasts.membership_role_updated'));
    }
  }

  onAbortTask = dropTask(async (_, { id: currentStepId }) => {
    if (currentStepId !== 'role') {
      let result = await this.openAbortModalTask.perform();
      return result === 'confirm';
    }

    this.abortEditMember();
  });

  openAbortModalTask = dropTask(async () => {
    return await this.modals.open('popup/destructive', {
      title: this.intl.t('members.modals.cancel-changes.title'),
      description: this.intl.t('members.modals.cancel-changes.description'),
      cancel: this.intl.t('btn.back'),
      confirm: this.intl.t('btn.confirm'),
      confirmTask: this.modalConfirmTask,
    });
  });

  modalConfirmTask = dropTask(async close => {
    this.abortEditMember();

    await close();
  });

  beforeRestoreTask = dropTask(async ({ member }) => {
    let { id: memberId } = member;

    if (memberId) {
      await this.store.findRecord('membership', memberId);
    }
  });
}
