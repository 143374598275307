import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { variation } from 'ember-launch-darkly';

export default class TransactionsV2Route extends Route {
  @service router;

  beforeModel() {
    Sentry.configureScope(scope => scope.setTag('CFT', 'bookkeeping'));

    if (!variation('feature--boolean-redesigned-transactions-table')) {
      this.router.transitionTo('transactions');
    }
  }
}
