export default {
  "page-wrapper": "Zcv",
  "content-wrapper": "Zco",
  "content": "Zcn",
  "period-field": "Zci",
  "format-options": "ZcA",
  "format-option": "ZcY",
  "format-radio": "Zch",
  "format-description": "Zcf body-2"
};
