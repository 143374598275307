import Route from '@ember/routing/route';

export default class HubApplicationDetailsDescriptionRoute extends Route {
  detailsRouteName = 'settings.connect-hub.applications.hub-application.details';

  model() {
    return this.modelFor(this.detailsRouteName);
  }

  resetController(controller) {
    controller.readMoreIsOpen = false;
  }
}
