export const requestMileage = {
  steps: {
    intro: {
      componentName: 'request-mileage/intro',
      nextStepId: 'journey',
    },
    journey: {
      componentName: 'request-mileage/journey',
      nextStepId: ({ skipVehicleDetails }) =>
        skipVehicleDetails ? 'request-details' : 'vehicle-details',
    },
    'vehicle-details': {
      componentName: 'request-mileage/vehicle-details',
      nextStepId: 'request-details',
    },
    'request-details': {
      componentName: 'request-mileage/request-details',
      nextStepId: ({ shouldGoToVehicleDetailsStep }) =>
        shouldGoToVehicleDetailsStep ? 'vehicle-details' : 'success',
    },
    success: {
      componentName: 'request-mileage/success',
    },
  },
  options: {
    enablePersistence: true,
  },
};
