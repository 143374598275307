export default {
  "page-wrapper": "qvR",
  "container": "qvQ",
  "sidebar": "qve",
  "search-input": "qvB",
  "organizations-list": "qvE",
  "content": "qvd",
  "no-result": "qvI",
  "lottie-illustration": "qvt",
  "accounting-header": "qvM",
  "logo": "qvP",
  "accounting-title": "qvl"
};
