import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { task } from 'ember-concurrency';

import { REQUEST_EVENTS } from 'qonto/constants/listeners';
import { ErrorInfo } from 'qonto/utils/error-info';
import parseConfirmResponse from 'qonto/utils/parse-confirm-response';

export default class TasksMultiTransferDetailRoute extends Route {
  @service abilities;
  @service notifierManager;
  @service store;
  @service organizationManager;
  @service intl;
  @service modals;
  @service router;
  @service sentry;

  queryParams = {
    highlight: {
      refreshModel: false,
    },
  };

  async model({ request_id }) {
    let [request, transfers] = await Promise.all([
      this.store.findRecord('request-multi-transfer', request_id),
      this.store.query('request-multi-transfer-transfer', {
        includes: ['attachments'],
        request_multi_transfer_id: request_id,
        per_page: 200,
      }),
    ]);

    let { membership } = this.organizationManager;

    let initiator = await request.get('initiator');

    let potentialBudgetAllocation = null;

    if (this.abilities.can('read budget') && request.pending) {
      try {
        let budgetResults = await this.store.adapterFor('budget').search({
          initiatorId: initiator.get('id'),
          scheduledDate: request.scheduledDate,
          includes: this.abilities.can('review transfer request') ? ['period_amounts'] : [],
        });

        potentialBudgetAllocation = budgetResults[0];
      } catch (error) {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      }
    }

    if (this.abilities.can('review transfer request') && membership.manager) {
      await membership.getSpendLimits();

      let { spendLimits, spendLimitsWarning } = await this.confirmRequestTransferTask.perform(
        request,
        transfers
      );

      return { request, transfers, spendLimits, spendLimitsWarning, potentialBudgetAllocation };
    }

    return {
      request,
      transfers,
      potentialBudgetAllocation,
    };
  }

  confirmRequestTransferTask = task(async (multiTransferRequest, requestMultiTransferTransfer) => {
    let transfersList = [];
    requestMultiTransferTransfer.forEach(request => {
      let params = {
        activityTag: request.activityTag,
        amount: request.amount,
        amountCurrency: request.amountCurrency,
        iban: request.beneficiaryIban,
        name: request.beneficiaryName,
        operationType: request.operationType,
        reference: request.reference,
        scheduledDate: request.scheduledDate,
      };
      let transfer = this.store.createRecord('bulk-transfer', params);
      transfersList.push(transfer);
    });

    let multiTransfer = this.store.peekRecord('multi-transfer', multiTransferRequest.id);

    if (!multiTransfer) {
      multiTransfer = this.store.createRecord('multi-transfer', {
        bankAccount: this.organizationManager.currentAccount,
        id: multiTransferRequest.id,
        transfers: transfersList,
        organization: multiTransferRequest.organization,
      });
    }

    let response = await multiTransfer.confirm();
    return parseConfirmResponse(response, this.intl);
  });

  activate() {
    let isApprover = this.abilities.can('review transfer request');
    if (isApprover) {
      this.notifierManager.on(REQUEST_EVENTS.EMPLOYEE_CANCELED, this, 'settled');
    } else {
      this.notifierManager.on(REQUEST_EVENTS.ADMIN_APPROVED, this, 'settled');
      this.notifierManager.on(REQUEST_EVENTS.ADMIN_DECLINED, this, 'settled');
    }
  }

  deactivate() {
    this.notifierManager.off(REQUEST_EVENTS.EMPLOYEE_CANCELED, this, 'settled');
    this.notifierManager.off(REQUEST_EVENTS.ADMIN_DECLINED, this, 'settled');
    this.notifierManager.off(REQUEST_EVENTS.ADMIN_APPROVED, this, 'settled');
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.highlight = '';
    }
  }

  transitionToPastRequestsTask = task(async close => {
    close();
    await this.router.transitionTo('tasks.past.multi-transfer-detail', this.currentModel.request);
  });

  // websocket events callbacks
  settled(payload) {
    if (this.currentModel.request.id === payload.object.id) {
      this.modals.open('popup/confirmation', {
        title: this.intl.t('requests.transfers.modals.invalid_transition.title'),
        description: this.intl.t('requests.transfers.modals.invalid_transition.desc', {
          htmlSafe: true,
        }),
        cancel: this.intl.t('btn.close'),
        confirm: this.intl.t('btn.view_request'),
        confirmTask: this.transitionToPastRequestsTask,
      });
    }
  }
}
