import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

export default class TransfersRequestsMultiTransferController extends Controller {
  @service intl;
  @service segment;
  @service organizationManager;
  @service router;

  @reads('organizationManager.membership') membership;

  @tracked showAllreadyProcessedModal = false;

  @tracked highlight = '';

  get newBeneficiaries() {
    return this.get('model.request.newBeneficiaries') || [];
  }

  get existingBeneficiaries() {
    return this.get('model.request.existingBeneficiaries') || [];
  }

  get existingBeneficiariesListTitle() {
    return this.newBeneficiaries.length ? this.intl.t('labels.existing_beneficiaries') : '';
  }

  get localState() {
    return {
      empty: !this.get('model.transfers.length'),
    };
  }

  get emptyStateOptions() {
    let isEmpty = this.localState.empty;
    let lottieSrc = '/lotties/empty-state/no-request-pending.json';
    let { organization } = this.membership;

    if (isEmpty) {
      return {
        lottieSrc,
        title: this.intl.t('empty-states.requests.pending.reporting.title'),
        subtitle: this.intl.t('empty-states.requests.pending.reporting.subtitle'),
        button: {
          label: this.intl.t('empty-states.requests.pending.reporting.button'),
          callback: () => {
            this.router.transitionTo('requests.landing', organization.get('slug'));
          },
        },
      };
    }
  }

  @action setAccount(request, account) {
    request.set('bankAccount', account);
  }

  approveTask = dropTask(async request => {
    await request.approveRequest();
    this.trackRequestReview(request);
    this.router.transitionTo('transfers.requests');
  });

  @action handleCancel() {
    this.router.transitionTo('transfers.requests');
  }

  @action handleDecline(request) {
    return request.declineRequest().then(() => {
      this.trackRequestReview(request);
      this.router.transitionTo('transfers.requests');
    });
  }

  @action updateHighlightedItem(itemId) {
    this.highlight = itemId;
  }

  trackRequestReview(request) {
    this.segment.track('request_reviewed', {
      request_type: request.requestType,
      request_id: request.id,
    });
  }

  get warnings() {
    return this.model.spendLimitsWarning ? [this.model.spendLimitsWarning] : [];
  }
}
