import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { didCancel, dropTask, restartableTask, timeout } from 'ember-concurrency';

import { APPROVAL_WORKFLOW_STATUSES } from 'qonto/constants/approval-workflow';
import { getEmptyStateConfig } from 'qonto/constants/empty-states/reimbursements';
import { LAYOUT } from 'qonto/constants/empty-states/system';
import { REQUEST_TYPES, STATUS } from 'qonto/constants/requests';
import { DEBOUNCE_MS } from 'qonto/constants/timers';
import { filterParams } from 'qonto/utils/compute-query-params';
import { ErrorInfo } from 'qonto/utils/error-info';

const DEFAULT_SORT_BY = 'created_at:desc';

export default class ReimbursementsCompletedController extends Controller {
  @service('intl') intl;
  @service abilities;
  @service notifierCounterManager;
  @service featuresManager;
  @service organizationManager;
  @service store;
  @service segment;
  @service sentry;
  @service emptyStates;
  @service flowLinkManager;
  @service subscriptionManager;

  @tracked spendLimitsWarning = null;
  @tracked spendLimits = null;
  @tracked shouldPreserveHighlight = false;
  @tracked highlight = '';
  @tracked page = 1;
  @tracked per_page = 25;
  @tracked requests = [];
  @tracked meta = null;
  @tracked sort_by = DEFAULT_SORT_BY;
  @tracked allocatedBudget;

  get toReviewRequestsCount() {
    let { expenseReportRequestsToReview = 0, mileageRequestsToReview = 0 } =
      this.notifierCounterManager.counter || {};

    return expenseReportRequestsToReview + mileageRequestsToReview;
  }

  get toPayRequestsCount() {
    let { expenseReportRequestsToPay = 0, mileageRequestsToPay = 0 } =
      this.notifierCounterManager.counter || {};

    return expenseReportRequestsToPay + mileageRequestsToPay;
  }

  get pendingRequestsCount() {
    let { expenseReportRequests = 0, mileageRequests = 0 } =
      this.notifierCounterManager.counter || {};
    return expenseReportRequests + mileageRequests;
  }

  get isEmptyGlobally() {
    let { mileageCompleted = 0, expenseReportCompleted = 0 } =
      this.notifierCounterManager.counter || {};
    return this.pendingRequestsCount + expenseReportCompleted + mileageCompleted === 0;
  }

  get isEmptyStatePreviewLayout() {
    return this.emptyStateOptions?.layout === LAYOUT.DISCOVER_PREVIEW;
  }

  get emptyStateOptions() {
    if (this.fetchDataTask.isRunning || this.fetchDataTask.last?.isError) {
      return;
    }

    return this.emptyStates.getEmptyStateOptions({
      isOrgEligibleForFeature: true,
      isEmptyGlobally: this.isEmptyGlobally,
      isEmptyLocally: this.meta?.total_count === 0,
      hasActiveFilterOrSearch: false,
      config: getEmptyStateConfig(this.intl, { changePlanCtaCallback: this.changePlanCtaCallback }),
      customInputs: {
        tab: 'completed',
        hasTeamManagement: this.subscriptionManager.hasFeature('team_management'),
      },
      abilities: {
        canReviewExpenseReport: this.abilities.can('review expense report request'),
        canCreateExpenseReport: this.abilities.can('create expense report request'),
      },
    });
  }

  get isSingleAccountApprover() {
    return (
      this.isApprover &&
      this.organizationManager.organization.activeSortedRemuneratedAndCurrentAccounts === 1
    );
  }

  get localState() {
    let { isRunning, last } = this.fetchDataTask;
    let isEmpty = this.requests.length === 0;

    if (isRunning) {
      return {
        isLoading: true,
        error: false,
        empty: false,
      };
    }

    if (last?.isError) {
      return {
        isLoading: false,
        error: true,
        empty: false,
      };
    }

    if (isEmpty) {
      return {
        isLoading: false,
        error: false,
        empty: true,
      };
    }
  }
  @action
  trackCTA(origin) {
    if (this.emptyStateOptions) {
      this.emptyStates.trackCta(this.emptyStateOptions, origin);
    }
  }

  @action
  handleSortBy(sortDefinition) {
    this.highlight = '';
    this.page = 1;
    this.sort_by = sortDefinition;
  }

  @action setAccount(request, account) {
    request.bankAccount = account;
    this.setProperties({ confirmWarnings: [], confirmErrors: [] });
  }

  @action handlePerPageChange(value) {
    this.page = 1;
    this.per_page = value;
  }

  handleSelectRequestTask = restartableTask(async requestId => {
    let { role } = this.organizationManager.membership;

    let request = this.requests.find(({ id }) => id === requestId);

    this.segment.track('request_details_opened', {
      request_type: request.requestType,
      request_id: request.id,
      request_status: request.status,
      origin: 'reimbursements',
      role,
    });

    await request.belongsTo('approvalWorkflowState').reload();

    await this.fetchAllocatedBudgetTask.perform(request);

    this.highlight = request.id;
  });

  @action handleCloseSidePanel() {
    if (!this.shouldPreserveHighlight) {
      this.resetQueryParams(['highlight']);
    }
    this.shouldPreserveHighlight = false;

    return this.fetchDataTask
      .perform({})
      .then(() => this.notifierCounterManager.updateCounter())
      .catch(() => {
        // ignore error
      });
  }

  fetchDataTask = restartableTask(async (params = {}) => {
    await timeout(DEBOUNCE_MS);

    let { organization } = this.organizationManager;
    let { page, per_page, sort_by, initiator_ids } = filterParams(params);

    let requestModel = {
      includes: ['memberships'],
      organization_id: organization.id,
      page,
      per_page,
      sort_by,
      status: [STATUS.APPROVED, STATUS.CANCELED, STATUS.DECLINED],
      request_type: ['expense_report', 'mileage'],
      initiator_ids,
    };

    if (this.featuresManager.isEnabled('approvalWorkflows')) {
      requestModel.approval_workflow_status = APPROVAL_WORKFLOW_STATUSES.COMPLETED;
      requestModel.status = undefined;
    }

    let response = await this.store.query('request', requestModel);

    this.requests = response;
    this.meta = response.meta;
  });

  fetchAllocatedBudgetTask = dropTask(async request => {
    if (
      !request ||
      this.abilities.cannot('read budget') ||
      [REQUEST_TYPES.MILEAGE].includes(request.requestType) ||
      !request.pending
    ) {
      this.allocatedBudget = null;
      return;
    }

    let requestPeriodAmounts = this.abilities.can('review expense report request');
    let initiatorId = request.initiator.get('id');
    let scheduledDate = new Date();

    requestPeriodAmounts &= this.organizationManager.membership.id !== initiatorId;

    try {
      let results = await this.store.adapterFor('budget').search({
        initiatorId,
        scheduledDate,
        includes: requestPeriodAmounts ? ['period_amounts'] : [],
      });
      this.allocatedBudget = results[0];
    } catch (error) {
      if (didCancel(error)) return;

      if (ErrorInfo.for(error).shouldSendToSentry) {
        return this.sentry.captureException(error);
      }
    }
  });

  @action
  changePlanCtaCallback(origin) {
    this.trackCTA(origin);
    this.flowLinkManager.transitionTo({ name: 'subscription-change', stepId: 'plans' });
  }

  resetQueryParams() {
    this.highlight = '';
    this.page = 1;
    this.per_page = 25;
    this.sort_by = DEFAULT_SORT_BY;
  }
}
