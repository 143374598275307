import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class CardsSettingsRestrictionsRoute extends Route {
  @service abilities;
  @service router;

  beforeModel() {
    if (this.abilities.cannot('view restrictions card')) {
      this.router.replaceWith('cards.index');
    }
  }
}
