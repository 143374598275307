import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

export default class QuotesRoute extends Route {
  @service abilities;
  @service homePage;

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'invoices');
    let canNavigate =
      this.abilities.can('navigate receivable-invoice') &&
      this.abilities.can('create receivable-invoice');

    if (!canNavigate) {
      return this.homePage.replaceWithDefaultPage();
    }
  }
}
