import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class GuestsInvitesShowRoute extends Route {
  @service router;
  @service organizationManager;

  rootRouteName = 'guests';

  redirect() {
    let organizationSlug = this.organizationManager.organization.slug;
    return this.router.replaceWith(this.rootRouteName, organizationSlug);
  }
}
