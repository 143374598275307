import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import dayjs from 'dayjs';
import { dropTask } from 'ember-concurrency';

import { BUDGET_ORIGIN, DEFAULT_SORT_BY } from 'qonto/constants/budget';
import { AmountsCardPlaceholder } from 'qonto/react/components/budget/details/amounts-card/placeholder';
import { BudgetDetailsError } from 'qonto/react/components/budget/details/error';
import { ManagersCardPlaceholder } from 'qonto/react/components/budget/details/managers-card/placeholder';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class BudgetsShowController extends Controller {
  @service toastFlashMessages;
  @service segment;
  @service budgetManager;
  @service router;
  @service flowLinkManager;
  @service intl;
  @service store;
  @service modals;
  @service sentry;

  queryParams = [{ periodId: 'period_id' }, { sortBy: 'sort_by' }, 'page', { perPage: 'per_page' }];

  @tracked periodId = null;
  @tracked sortBy = DEFAULT_SORT_BY;
  @tracked page = 1;
  @tracked perPage = 25;

  budgetDetailsErrorComponent = BudgetDetailsError;
  amountsCardPlaceholder = AmountsCardPlaceholder;
  managersCardPlaceholder = ManagersCardPlaceholder;

  onArchiveBudget = dropTask(async budget => {
    await this.budgetManager.archiveBudgetTask.perform({
      budget,
      trackingEvent: 'budget-details_archive-button_clicked',
    });

    if (budget.archived) {
      this.router.replaceWith('budgets.list');
    }
  });

  get budget() {
    return this.model.budget;
  }

  get managers() {
    return this.model.fetchTeamManagersTask.value;
  }

  get budgetTransactions() {
    return this.model.fetchBudgetTransactionsTask.value;
  }

  get teamId() {
    return this.budget.belongsTo('assignedTo').id();
  }

  get currentPeriodId() {
    return this.model.currentPeriodId;
  }

  get currentPeriod() {
    let { currentPeriodId, budget } = this;

    return budget.exercises
      .reduce((periods, exercise) => [...periods, ...exercise.periods], [])
      .find(period => period.id === currentPeriodId);
  }

  get emptyState() {
    let { startDate } = this.currentPeriod;

    let isFuturePeriod = dayjs().isBefore(startDate);

    if (isFuturePeriod) {
      return {
        lottieSrc: '/lotties/empty-state/search-no-result.json',
        title: this.intl.t('team-budgets.detail.empty-state.future-period.title'),
        subtitle: this.intl.t('team-budgets.detail.empty-state.future-period.subtitle'),
      };
    }

    return {
      lottieSrc: '/lotties/empty-state/search-no-result.json',
      title: this.intl.t('team-budgets.detail.empty-state.current-period.title'),
      subtitle: this.intl.t('team-budgets.detail.empty-state.current-period.subtitle'),
      button: {
        label: this.intl.t('team-budgets.detail.empty-state.current-period.cta'),
        callback: () => {
          this.segment.track('budget-details_empty-state-button_clicked');
          this.router.transitionTo('transactions');
        },
      },
    };
  }

  @action
  onUpdateBudget(budget) {
    this.segment.track('budget-details_edit-button_clicked');

    this.flowLinkManager.transitionTo({
      name: 'edit-budget',
      stepId: 'select-exercise',
      queryParams: { id: budget.id, origin: BUDGET_ORIGIN.show },
    });
  }

  @action
  onSelectPeriod(period) {
    this.periodId = period.id;
  }

  @action
  refreshModel() {
    /**
     * Using the shouldRefetchPeriod flag to force the period to be refetched in the route.
     * "data" property is a custom state that can be stored on a Transition's object.
     * @link https://github.com/tildeio/router.js/blob/55a4d59c237e330f01afec57872f510e4be5851e/lib/router/transition.js#L135-L145
     */
    this.router.refresh('budgets.show').data = {
      shouldRefetchPeriod: true,
    };
  }

  @action
  handleSortBy(sortDefinition) {
    this.sortBy = sortDefinition;
  }

  @action
  handlePerPageChange(value) {
    this.page = 1;
    this.perPage = value;
  }

  removeTransactionTask = dropTask(async transactionId => {
    this.segment.track('budget-details_transaction-removal-button_clicked');

    let result = await this.modals.open('popup/destructive', {
      title: this.intl.t('team-budgets.detail.remove-transaction.modal.title'),
      description: this.intl.t('team-budgets.detail.remove-transaction.modal.subtitle'),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('team-budgets.detail.remove-transaction.modal.confirm'),
    });

    if (result !== 'confirm') {
      if (result === 'cancel') {
        this.segment.track('budget-details_remove-cancel_clicked');
      } else {
        this.segment.track('budget-details-remove-transaction-closed');
      }

      return;
    }

    this.segment.track('budget-details-remove-transaction-confirmation_clicked');

    return this.store
      .adapterFor('budget')
      .allocateTransactionToPeriod({ transactionId, periodId: null })
      .then(() => {
        this.toastFlashMessages.toastSuccess(
          this.intl.t('team-budgets.detail.remove-transaction.toast.success')
        );

        this.refreshModel();
      })
      .catch(error => {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }

        this.toastFlashMessages.toastError(
          this.intl.t('team-budgets.detail.remove-transaction.toast.failure')
        );
      });
  });
}
