import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class FinancingIndexRoute extends Route {
  @service router;

  beforeModel() {
    // The financing.index route has moved to financing.partners
    // Redirect to the new route
    this.router.replaceWith('financing.partners');
  }
}
