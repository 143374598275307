import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { task } from 'ember-concurrency';

import { IT_FISCAL_REGIME } from 'qonto/constants/self-billing';
import { SOURCE_TYPES } from 'qonto/constants/supplier-invoice';
import { defaultValues } from 'qonto/models/receivable-invoice/item';
import { ErrorInfo } from 'qonto/utils/error-info';

const SENTRY_IGNORE_HTTP_STATUSES = [401];

export default class SelfBillingNewRoute extends Route {
  @service abilities;
  @service menu;
  @service organizationManager;
  @service router;
  @service store;
  @service modals;
  @service localeManager;
  @service intl;
  @service sentry;
  @service toastFlashMessages;
  @service errors;
  @service flowLinkManager;

  @tracked modal;

  queryParams = {
    supplierInvoiceId: {
      refreshModel: true,
    },
  };

  activate() {
    this.menu.hide();
  }

  deactivate() {
    this.menu.show();
    this.modal.close();
  }

  async beforeModel(transition) {
    let { supplierInvoiceId = null } = transition.to.queryParams;

    if (this.abilities.cannot('create self-invoice') || !supplierInvoiceId) {
      this.redirectToSupplierInvoices();
    }

    try {
      let eInvoiceActivation = await this.store.findRecord(
        'e-invoice-activation',
        this.organizationManager.organization.id
      );

      if (!eInvoiceActivation.eInvoicingActivated) {
        this.flowLinkManager.replaceWith({
          name: 'invoice-onboarding',
          stepId: 'einvoice-activation',
          queryParams: { supplierInvoiceId },
        });
      }
    } catch (error) {
      this.handleError(error);
    }
  }

  async model(_, transition) {
    let organization = this.organizationManager.organization;

    let [supplierInvoice, settings] = await Promise.all([
      this.retrieveSupplierInvoiceTask.perform(transition.to.queryParams.supplierInvoiceId),
      this.fetchSettingsTask.perform(organization.id),
    ]);

    let supplierInvoicePreview =
      await this.retrieveSupplierInvoiceAttachmentTask.perform(supplierInvoice);

    let selfInvoice = this.store.createRecord('self-invoice', {
      number: settings.selfInvoiceNextNumberFormatted,
      organization,
      supplierInvoiceSnapshot: {
        id: supplierInvoice.id,
      },
      supplierSnapshot: {
        taxRegime: IT_FISCAL_REGIME,
        name: supplierInvoice.supplierName,
      },
      items: [this.store.createRecord('receivable-invoice/item', defaultValues(this))],
      locale: this.localeManager.locale,
    });

    this.modal = this.modals.open(
      'self-billing/create-invoice-modal',
      {
        isFullScreenModal: true,
        settings,
        supplierInvoice,
        supplierInvoicePreview,
        selfInvoice,
      },
      {
        focusTrapOptions: {
          clickOutsideDeactivates: false,
        },
      }
    );
  }

  retrieveSupplierInvoiceTask = task(async supplierInvoiceId => {
    try {
      let supplierInvoice = await this.store.findRecord('supplier-invoice', supplierInvoiceId);
      if (supplierInvoice.source === SOURCE_TYPES.E_INVOICING) {
        this.router.replaceWith('supplier-invoices.index');
      } else {
        return supplierInvoice;
      }
    } catch (error) {
      this.handleError(error);
    }
  });

  fetchSettingsTask = task(async organizationId => {
    try {
      return await this.store.findRecord('receivable-invoices-settings', organizationId);
    } catch (error) {
      this.handleError(error, [401, 404]);
    }
  });

  retrieveSupplierInvoiceAttachmentTask = task(async supplierInvoice => {
    try {
      return await this.store.findRecord(
        'attachment',
        supplierInvoice.belongsTo('attachment').id(),
        {}
      );
    } catch (error) {
      this.handleError(error);
    }
  });

  handleError(error, sentryIgnoreStatuses = SENTRY_IGNORE_HTTP_STATUSES) {
    if (ErrorInfo.for(error).shouldSendToSentry && !sentryIgnoreStatuses.includes(error.status)) {
      this.sentry.captureException(error);
    }

    this.toastFlashMessages.toastError(
      this.errors.messageForStatus(error) || this.intl.t('toasts.errors.server_error')
    );

    this.redirectToSupplierInvoices();
  }

  redirectToSupplierInvoices() {
    this.router.replaceWith('supplier-invoices', {
      queryParams: { supplierInvoiceId: undefined },
    });
    this.modal.close();
  }
}
