/* eslint-disable @qonto/no-async-action */
import { action } from '@ember/object';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { timeout } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import {
  ACCOUNT_ID_LOCAL_STORAGE,
  ACCOUNT_STATUS,
  ACCOUNT_TYPE,
  FUNDING_DEBOUNCE_MS,
  SAVINGS_DEFAULT_URL,
} from 'qonto/constants/bank-account';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { FlowSetup } from 'qonto/routes/flows/setup/internals';

class FundingDataContext {
  constructor({ accountToCredit, accountToDebit, origin }) {
    this.accountToDebit = accountToDebit;
    this.amount = null;
    this.accountToCredit = accountToCredit;
    this.origin = origin;
  }
}
export default class RemuneratedAccountFundingFlowSetup extends FlowSetup {
  @service abilities;
  @service organizationManager;
  @service router;

  @reads('organizationManager.organization') organization;
  @reads('organization.remuneratedAccounts') remuneratedAccounts;

  dataContext = null;
  origin = this.router.currentRoute.queryParams.origin || SAVINGS_DEFAULT_URL;

  constructor() {
    super(...arguments);

    let accountToCreditId = this.router.currentRoute.queryParams?.accountId;

    if (accountToCreditId) {
      // To prevent losing accountId on page reload during the flow (or page bookmark)
      safeLocalStorage.setItem(ACCOUNT_ID_LOCAL_STORAGE, JSON.stringify(accountToCreditId));
    } else {
      // When transitioning to this flow using transitionToFlow() (and not using router + queryParam) the accountId is persisted in the local-storage
      accountToCreditId = JSON.parse(safeLocalStorage.getItem(ACCOUNT_ID_LOCAL_STORAGE));
    }

    let accountToCredit = this.remuneratedAccounts.find(({ id }) => id === accountToCreditId);

    this.dataContext = new FundingDataContext({
      accountToDebit: this.organization.mainAccount,
      accountToCredit,
      origin: this.origin,
    });
  }

  _redirect() {
    return this.router.replaceWith(this.origin);
  }

  beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'transfers');

    if (this.abilities.cannot('create remunerated-account')) {
      return this._redirect();
    }

    if (
      this.dataContext.accountToCredit?.accountType !== ACCOUNT_TYPE.REMUNERATED ||
      this.dataContext.accountToCredit?.status !== ACCOUNT_STATUS.WAITING
    ) {
      return this._redirect();
    }
  }

  @action
  onAbort() {
    this._redirect();
  }

  @action
  async onComplete() {
    await timeout(FUNDING_DEBOUNCE_MS);
    this._redirect();
  }
}
