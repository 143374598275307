import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { dropTask, restartableTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';
import { reads } from 'macro-decorators';

import { ES_FREELANCER_LEGAL_FORM, STATUS } from 'qonto/constants/receivable-invoice';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class BaseReceivableInvoicesRoute extends Route {
  @service abilities;
  @service organizationManager;
  @service sentry;
  @service store;

  @reads('organizationManager.organization') organization;

  get isSpanishFreelancer() {
    return (
      variation('improvement--boolean-invoicing-es-irpf') &&
      this.organization.isSpanish &&
      this.organization.legalForm === ES_FREELANCER_LEGAL_FORM
    );
  }

  fetchFrEinvoicingSettingsTask = dropTask(async () => {
    let canRead = this.abilities.can('read einvoicingSetting');

    if (!(this.organization.legalCountry === 'FR' && canRead)) {
      return false;
    }
    try {
      let frEinvoicingSettings = await this.store.findRecord(
        'einvoicing-settings',
        this.organization.id
      );
      return frEinvoicingSettings?.einvoicingOnboarded;
    } catch {
      return false;
    }
  });

  @action
  fetchFirstEInvoice() {
    return this.fetchFirstEInvoiceTask.perform().catch(ignoreCancelation);
  }

  fetchFirstEInvoiceTask = restartableTask(async () => {
    let paramsWithOrganizationId = {
      filter: {
        organization_id: this.organization.id,
        status: `${STATUS.PAID},${STATUS.UNPAID},${STATUS.CANCELED}`,
        is_einvoice: true,
      },
      page: { number: 1, size: 25 },
    };

    try {
      let einvoicesArray = await this.store.query('receivable-invoice', paramsWithOrganizationId);

      return einvoicesArray;
    } catch (error) {
      if (error.status === 422) {
        return;
      }
      this.handleError(error);
    }
  });

  @action
  handleError(error) {
    let errorInfo = ErrorInfo.for(error);
    if (errorInfo.shouldSendToSentry) {
      this.sentry.captureException(error);
    }
  }
}
