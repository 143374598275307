import Route from '@ember/routing/route';

export default class RequestsTransfersMultiConfirmRoute extends Route {
  model() {
    return this.modelFor('requests.transfers.multi');
  }

  setupController(controller, model) {
    let request = model;
    super.setupController(controller, request);
  }
}
