import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class FinancingPartnersIndexRoute extends Route {
  @service prismic;
  @service segment;
  @service organizationManager;

  async model() {
    return await this.prismic.get({
      predicates: [
        this.prismic.prismicLibrary.predicate.at('document.type', 'financing_offer'),
        this.prismic.prismicLibrary.predicate.at('document.tags', [
          `market_${this.organizationManager.organization.legalCountry.toLowerCase()}`,
        ]),
      ],
      orderings: {
        field: 'my.financing_offer.uid',
      },
      fetchLinks: 'financing_partner.name,financing_partner.logo,financing_type.name',
    });
  }

  @action
  error() {
    this.segment.track('financing_main_error');
    return true;
  }
}
