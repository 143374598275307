import { ForbiddenError } from '@ember-data/adapter/error';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

export default class SettingsPersonalProfileRoute extends Route {
  @service segment;
  @service organizationManager;
  @service sentry;

  activate() {
    this.segment.track('profile');
  }

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'account-management');
  }

  async model() {
    let { membership } = this.organizationManager;

    try {
      await membership.getIban();
    } catch (error) {
      if (!(error instanceof ForbiddenError)) {
        this.sentry.captureException(error);
      }
    }

    return {
      membership,
    };
  }

  setupController(controller, model, transition) {
    super.setupController(controller, model, transition);
    controller.set('iban', model.membership.iban);
  }

  resetController(controller) {
    controller.emailEdition = false;
    controller.pendingEmail = null;
    controller.pendingEmailError = null;
    controller.pendingPhoneNumber = null;
    controller.phoneNumberEdition = false;
    controller.phoneNumberError = null;
    controller.ibanEdition = false;
    controller.pendingIban = null;
    controller.pendingIbanError = null;
  }
}
