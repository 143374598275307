export default {
  "signin": "Zcg",
  "form": "ZcD",
  "illustration": "ZcV",
  "purple": "ZcL",
  "mint": "ZcT",
  "mustard": "Zcz",
  "peach": "Zca",
  "form-wrapper": "ZcH",
  "header-logo": "ZcO",
  "links": "Zcm",
  "no-account-label": "Zcp",
  "spinner-container": "Zcx",
  "main-image-container": "Zcw",
  "main-image": "ZcN"
};
