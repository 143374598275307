import { action } from '@ember/object';
import { setOwner } from '@ember/owner';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

import { ErrorInfo } from 'qonto/utils/error-info';

export class CardSetPinDataContext {
  constructor({ id, kycSubmitted }) {
    this.id = id;
    this.kycSubmitted = kycSubmitted;
  }
}

export default class CardSetPinFlowSetup {
  @service abilities;
  @service flow;
  @service homePage;
  @service intl;
  @service router;
  @service sentry;
  @service store;
  @service toastFlashMessages;
  @service userManager;
  @service organizationManager;
  @service flowLinkManager;

  dataContext = null;

  constructor(owner) {
    setOwner(this, owner);

    let { id } = this.router.currentRoute.queryParams;

    let kycSubmitted = this.organizationManager.membership.kycSubmitted;

    this.dataContext = new CardSetPinDataContext({
      id,
      kycSubmitted,
    });
  }

  async beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'cards');

    if (this.abilities.cannot('read card')) {
      return this.homePage.replaceWithDefaultPage();
    }

    let { id } = this.dataContext;

    try {
      let card = await this.store.findRecord('card', id);

      this.dataContext.card = card;

      if (this.abilities.cannot('set PIN card', card)) {
        return this.router.replaceWith('cards', card.organization, {
          queryParams: { highlight: id },
        });
      }
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      // when the user cannot see the card we get an error from API
      // e.g. when an employee try to see the card of someone else (/!\ this is not related to permissions)
      // e.g. when the card cannot be found
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      return this.router.transitionTo('cards.index');
    }
  }

  @action
  onAbort() {
    let { refererPage } = this.flow;
    // the refererPage is undefined when directly coming from a deeplink route
    let isFromCardsRoute = refererPage?.includes('/cards');

    if (isFromCardsRoute) {
      // to ensure all the query params previously set in the cards list will remain when coming back
      this.router.transitionTo(refererPage);
    } else {
      this._transitionToCards();
    }
  }

  @action
  onComplete() {
    this._transitionToCards();
  }

  _transitionToCards() {
    let { id } = this.dataContext.card;
    this.router.transitionTo('cards.index', { queryParams: { highlight: id } });
  }
}
