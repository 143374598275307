export const concierge = {
  steps: {
    message: {
      componentName: 'concierge/message',
      nextStepId: 'contact',
    },
    contact: {
      componentName: 'concierge/contact',
      nextStepId: 'success',
    },
    success: {
      componentName: 'concierge/success',
    },
  },
  options: {
    enablePersistence: false,
  },
};
