export const invoicesClientsImport = {
  steps: {
    initial: {
      componentName: 'invoices/clients-import/initial',
      nextStepId: ({ nextStepId }) => nextStepId,
    },
    integrations: {
      componentName: 'invoices/clients-import/integrations',
      nextStepId: 'noop',
    },
    upload: {
      componentName: 'invoices/clients-import/upload',
      nextStepId: 'mapping',
    },
    mapping: {
      componentName: 'invoices/clients-import/mapping',
      nextStepId: 'success',
    },
    success: {
      componentName: 'invoices/clients-import/success',
    },
  },
  options: {
    enablePersistence: false,
  },
};
