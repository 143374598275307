import { ORIGIN } from 'qonto/constants/cards';

export const cardNewDigital = {
  steps: {
    'choose-card': {
      componentName: 'cards/choose-card-digital',
      nextStepId: ({ origin }) =>
        origin === ORIGIN.MEMBER_INVITATION ? 'success-invitee' : 'success',
    },
    success: {
      componentName: 'cards/success-new-digital',
    },
    'success-invitee': {
      componentName: 'cards/success-invitee-digital',
    },
  },
  options: {
    enablePersistence: false,
  },
};
