export default {
  "member-details": "qYU",
  "header": "qYj",
  "header-img": "qYW",
  "invited-persona": "qYu",
  "revoked-persona": "qhS",
  "invited-icon": "qhc",
  "revoked-icon": "qhq",
  "dropdown": "qhZ",
  "header-body": "qhR",
  "name": "qhQ title-3",
  "email": "qhe caption-bold",
  "actions": "qhB body-2",
  "actions-cards": "qhE",
  "actions-transactions": "qhd",
  "body": "qhI",
  "expense-permissions-section": "qht",
  "body-title": "qhM title-4",
  "body-attr": "qhP",
  "body-label": "qhl",
  "attr-buttons": "qhX",
  "body-role": "qhC small",
  "details-actions": "qhk",
  "actions-item": "qhJ",
  "disclaimer": "qhs",
  "permissions": "qhF",
  "permission": "qhg",
  "permission-label": "qhD",
  "permission-check": "qhV",
  "permission-missing": "qhL",
  "editable-section": "qhT"
};
