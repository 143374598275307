import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class ReceivableInvoicesDuplicateLoadingRoute extends Route {
  @service menu;

  activate() {
    this.menu.hide();
  }

  deactivate() {
    this.menu.show();
  }
}
