import { action } from '@ember/object';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';
import { TrackedArray } from 'tracked-built-ins';

import { FlowSetup } from 'qonto/routes/flows/setup/internals';

class VirtualCardKYCDataContext {
  documentType = null;
  documents = new TrackedArray();
}

export default class VirtualCardProvideKYCFlowSetup extends FlowSetup {
  @service router;
  @service organizationManager;
  @service toastFlashMessages;
  @service modals;
  @service intl;

  constructor() {
    super(...arguments);
    let cardId = this.router.currentRoute.queryParams.cardId;

    if (this.organizationManager.membership.kycSubmitted) {
      this.router.transitionTo('cards.index', {
        queryParams: { highlight: cardId },
      });
      this.toastFlashMessages.toastError(this.intl.t('kyc.id-already-provided.toast.error'));
      return;
    }
    this.dataContext = new VirtualCardKYCDataContext();
    this.dataContext.cardId = cardId;
  }

  @action
  onComplete(dataContext) {
    return this.router.transitionTo('cards.index', {
      queryParams: { highlight: dataContext.cardId },
    });
  }

  onAbortTask = dropTask(async (dataContext, { id: stepId }) => {
    if (stepId === 'intro') {
      this.router.transitionTo('cards.index', {
        queryParams: { highlight: dataContext.cardId },
      });

      return true;
    }

    let modalResult = await this.modals.open('popup/destructive', {
      title: this.intl.t('flow-in-flow.modal.abort.title'),
      description: this.intl.t('flow-in-flow.modal.abort.subtitle'),
      cancel: this.intl.t('btn.back'),
      confirm: this.intl.t('btn.leave'),
    });

    if (modalResult === 'confirm') {
      this.router.transitionTo('cards.index', {
        queryParams: { highlight: dataContext.cardId },
      });

      return true;
    }

    return false;
  });
}
