import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { sortByKey } from 'qonto/utils/sort-by-keys';

export default class TeamsIndexRoute extends Route {
  @service abilities;
  @service router;
  @service organizationManager;
  @service store;
  @service sentry;

  membershipsPerPage = 250;
  upgradePlanRoute = 'teams.upgrade-plan';

  beforeModel() {
    if (this.abilities.cannot('access teams')) {
      return this.router.replaceWith(this.upgradePlanRoute);
    }
  }

  async model() {
    let { organization } = this.organizationManager;
    await this.organizationManager.organization.loadTeams();
    let teams = this.organizationManager.organization.teams;
    let teamIds = teams.map(team => team.id);
    let memberships = await this.store.query('membership', {
      organization_id: organization.id,
      filters: { team_ids: teamIds },
      per_page: this.membershipsPerPage,
    });

    if (memberships.meta?.total_pages > 1) {
      this.sentry.captureMessage('Not all memberships are being displayed in teams page!');
    }

    let sortedTeams = teams.sort(sortByKey('name'));

    return sortedTeams.map(team => ({
      team,
      memberships: memberships.filter(membership => membership.belongsTo('team').id() === team.id),
    }));
  }
}
