export const managementKyc = {
  steps: {
    'document-selection': {
      componentName: 'kyc/document-selection',
      nextStepId: 'upload-documents',
    },
    'upload-documents': {
      componentName: 'kyc/upload-documents',
      nextStepId: 'user-info',
    },
    'user-info': {
      componentName: 'kyc/user-info',
      nextStepId: 'success',
    },
    success: {
      componentName: 'management-kyc/success',
    },
  },
};
