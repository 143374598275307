import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

import { CONCIERGE_CHANNELS } from 'qonto/constants/concierge';
import { FlowSetup } from 'qonto/routes/flows/setup/internals';

class ConciergeFlowDataContext {
  conciergeRequest = null;

  constructor(conciergeRequest) {
    this.conciergeRequest = conciergeRequest;
  }
}

export default class ConciergeFlowSetup extends FlowSetup {
  @service router;
  @service abilities;
  @service store;

  constructor() {
    super(...arguments);

    let conciergeRequest = this.store.createRecord('concierge-request', {
      inquiry: '',
      channel: CONCIERGE_CHANNELS.SMS,
    });

    this.dataContext = new ConciergeFlowDataContext(conciergeRequest);
  }

  beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'cards');
    if (this.abilities.cannot('access concierge-and-lounge')) {
      return this.router.replaceWith('cards.index');
    }
  }

  onComplete() {
    this.router.transitionTo('cards.concierge-and-lounge');
  }

  onAbort() {
    this.router.transitionTo('cards.concierge-and-lounge');
  }
}
