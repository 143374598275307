export const nrcPayment = {
  steps: {
    'modelo-select': {
      componentName: 'nrc-payment/modelo-select',
      nextStepId: 'modelo-form',
    },
    'modelo-form': {
      componentName: 'nrc-payment/modelo-form',
      nextStepId: 'payer-details',
    },
    'payer-details': {
      componentName: 'nrc-payment/payer-details-form',
      nextStepId: 'preview',
    },
    preview: {
      componentName: 'nrc-payment/preview',
      nextStepId: 'result',
    },
    result: {
      componentName: 'nrc-payment/result',
    },
  },
  options: {
    enablePersistence: true,
  },
};
