import { CARD_LEVELS } from 'qonto/constants/cards';

export const requestsCards = {
  steps: {
    budget: {
      componentName: 'requests/cards/budget',
      nextStepId: ({ cardLevel }) => {
        return cardLevel === CARD_LEVELS.FLASH ? 'validity-period' : 'note';
      },
    },
    'validity-period': {
      componentName: 'requests/cards/validity-period',
      nextStepId: 'note',
    },
    note: {
      componentName: 'requests/cards/note',
      nextStepId: ({ kycSubmitted, cardLevel }) => {
        if (!kycSubmitted) {
          if (cardLevel === CARD_LEVELS.VIRTUAL) {
            return 'kyc-intro-virtual';
          }
          if (cardLevel === CARD_LEVELS.FLASH) {
            return 'kyc-intro-flash';
          }
        }

        return 'success';
      },
    },
    'kyc-intro-virtual': {
      componentName: 'requests/cards/request-virtual-card-kyc-intro',
    },
    'kyc-intro-flash': {
      componentName: 'requests/cards/request-flash-card-kyc-intro',
    },
    success: {
      componentName: 'requests/cards/success',
    },
  },
  options: {
    enablePersistence: true,
  },
};
