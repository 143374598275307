import Controller from '@ember/controller';
import { later } from '@ember/runloop';
import { service } from '@ember/service';

import { isTesting, macroCondition } from '@embroider/macros';
import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import {
  ERROR_TOAST_IGNORE_HTTP_STATUSES,
  SENTRY_IGNORE_HTTP_STATUSES,
} from 'qonto/constants/receivable-invoice';
import { ErrorInfo } from 'qonto/utils/error-info';
import scrollIntoView from 'qonto/utils/scroll-into-view';

export default class ReceivableInvoicesNewController extends Controller {
  @service intl;
  @service router;
  @service modals;
  @service segment;
  @service organizationManager;
  @service toastFlashMessages;
  @service sentry;

  @reads('organizationManager.organization') organization;

  get logo() {
    return this.organization.get('isDefaultAvatar') === false ? this.organization.picture : null;
  }

  get duplicateInvoiceOrigin() {
    let { currentRoute } = this.router;
    return { origin: 'receivable-invoices.duplicate', params: currentRoute?.params?.id };
  }

  onCloseTask = dropTask(async () => {
    await this.modals.open(
      'receivable-invoices/cancel-invoice-form-modal',
      {
        title: this.intl.t('receivable-invoices.invoice-creation.exit-modal.title'),
        description: this.intl.t('receivable-invoices.invoice-creation.exit-modal.description'),
        closeWithoutSaving: this.intl.t(
          'receivable-invoices.invoice-creation.exit-modal.cta.discard'
        ),
        saveAsDraft: this.intl.t('receivable-invoices.invoice-creation.exit-modal.cta.save-draft'),
        closeWithoutSavingTask: this.modalConfirmTask,
        saveAsDraftTask: this.saveAsDraftModalTask,
      },
      {
        className: 'epm-popup-modal',
      }
    );
  });

  modalConfirmTask = dropTask(async close => {
    this.segment.track('invoice_creation_canceled');
    this.router.transitionTo('receivable-invoices.index');
    await close();
  });

  saveTask = dropTask(async closeModal => {
    try {
      if (this.model.settings?.numberingMode === 'automatic') {
        this.model.invoice.number = null;
      }
      await this.model.invoice.save();
      this.segment.track('invoice-duplicate_create_confirm');
      // Remove locally stored items because backend return copies of those items with new ids
      this.model.invoice.clearItemsWithNoId();

      let origin = this.router.currentRouteName;

      this.router.transitionTo('receivable-invoices.share', this.model.invoice.id, {
        queryParams: { origin },
      });

      this.toastFlashMessages.toastSuccess(this.intl.t('receivable-invoices.issue-modal.toaster'));
    } catch (error) {
      this.handleError(error);
    } finally {
      closeModal?.();
    }
  });

  saveAsDraftTask = dropTask(async () => {
    try {
      if (this.model.settings?.numberingMode === 'automatic') {
        this.model.invoice.number = null;
      }
      await this.model.invoice.saveAsDraft();
      this.segment.track('invoice_draft_saved');
      // Remove locally stored items because backend return copies of those items with new ids
      this.model.invoice.clearItemsWithNoId();
      this.router.transitionTo('receivable-invoices.index');
      this.toastFlashMessages.toastSuccess(
        this.intl.t('receivable-invoices.invoices-list.draft-saved.toast-success')
      );
    } catch (error) {
      this.router.transitionTo('receivable-invoices.duplicate');
      this.handleError(error);
    }
  });

  saveAsDraftModalTask = dropTask(async close => {
    this.segment.track('invoice-draft_exit_save-as-draft');
    await this.saveAsDraftTask.perform();
    await close();
  });

  handleError(error) {
    let errorInfo = ErrorInfo.for(error);
    if (errorInfo.shouldSendToSentry && !SENTRY_IGNORE_HTTP_STATUSES.includes(error.status)) {
      this.sentry.captureException(error);
    }
    if (!ERROR_TOAST_IGNORE_HTTP_STATUSES.includes(error.status)) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
    later(this, this.scrollIntoError, macroCondition(isTesting()) ? 0 : 200);
  }

  scrollIntoError() {
    scrollIntoView('[data-has-error]');
  }
}
