/* eslint-disable @qonto/no-redirect-in-unrelated-hooks */
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class ReceivableInvoicesCreditNotesShareRoute extends Route {
  @service menu;
  @service store;
  @service organizationManager;
  @service router;
  @service sentry;
  @service toastFlashMessages;
  @service intl;

  activate() {
    this.menu.hide();
  }

  deactivate() {
    this.menu.show();
  }

  async model({ id }) {
    await this.fetchTask.perform(id).catch(error => {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      this.router.replaceWith('receivable-invoices.index');
    });

    return {
      fetchTask: this.fetchTask,
    };
  }

  fetchTask = dropTask(async id => {
    let { organization } = this.organizationManager;
    this.organizationManager.organization.getAvatar();
    let creditNote = await this.store.findRecord('receivable-credit-note', id);
    let receivableInvoice = await creditNote.receivableInvoice;

    let settings = await this.store.findRecord('receivable-invoices-settings', organization.id);

    return { creditNote, receivableInvoice, settings };
  });

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.setDefaultParams();
  }
}
