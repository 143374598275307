import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class TransfersInternalRoute extends Route {
  @service abilities;
  @service homePage;

  beforeModel(transition) {
    let canReadTransfers = this.abilities.can('read transfer');

    let queryParams = transition.to.queryParams;
    if (!canReadTransfers) {
      delete queryParams.query;
      this.homePage.visitDefaultPage({ queryParams });
    }
  }
}
