import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class RequestLandingRoute extends Route {
  @service segment;
  @service abilities;
  @service router;
  @service menu;

  activate() {
    this.menu.hide();
  }
  beforeModel(transition) {
    if (!this.abilities.can('create request') && !this.abilities.can('review mileage request')) {
      this.router.replaceWith('requests.pending');
    }

    let trackOriginEvent = transition.to.queryParams.origin;

    this.segment.track('request_creation_started', {
      origin: trackOriginEvent ?? 'requests',
    });
  }

  deactivate() {
    this.menu.show();
  }
}
