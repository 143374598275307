import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import CURRENCIES from 'qonto/constants/currencies';

export default class RequestsTransfersRoute extends Route {
  @service organizationManager;
  @service abilities;
  @service segment;
  @service store;

  beforeModel(transition) {
    if (this.abilities.cannot('read request')) {
      this.replaceWith('requests.upgrade-plan');
    }

    this.segment.track('request_started', {
      request_type: 'transfer',
      page_url: transition?.from?.name,
      team: this.organizationManager.membership.team.get('name'),
      origin: transition?.to.queryParams.origin,
    });
  }

  async model(params) {
    let { organization, currentAccount } = this.organizationManager;

    let requestOptions = await this.getRequestOptions(organization.id, params.supplierInvoiceId);

    return this.store.createRecord('requestTransfer', {
      bankAccount: currentAccount,
      organization,
      amountCurrency: CURRENCIES.default,
      requestType: 'transfer',
      ...requestOptions,
    });
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    // Errors already catched in the task
    // eslint-disable-next-line ember-concurrency/no-perform-without-catch
    controller.searchBeneficiaryTask.perform();
  }

  resetController(controller) {
    controller.confirmationModal?.close();
  }

  @action
  willTransition() {
    this.set('controller.searchQuery', null);
  }

  async getRequestOptions(organizationId, supplierInvoiceId) {
    if (supplierInvoiceId) {
      let beneficiary;
      let supplierInvoice = await this.store.findRecord('supplier-invoice', supplierInvoiceId);
      let attachmentId = supplierInvoice.belongsTo('attachment').id();
      let attachment = await this.store.findRecord('attachment', attachmentId);
      let { beneficiaryId, dueDate, description, iban, invoiceNumber, supplierName, totalAmount } =
        supplierInvoice || {};

      if (beneficiaryId) {
        beneficiary = await this.store.findRecord('beneficiary', beneficiaryId);
      } else if (iban) {
        beneficiary = await this.store
          .query('beneficiary', { organization_id: organizationId, iban })
          .then(beneficiaries => beneficiaries[0]);
      }

      let { activityTag, email, id, name } = beneficiary || {};

      return {
        name: supplierName || name,
        reference: invoiceNumber,
        amount: totalAmount?.value || null,
        attachments: attachment ? [attachment] : [],
        activityTag: activityTag ?? 'other_expense',
        iban: beneficiary?.iban || iban,
        notifyByEmail: Boolean(email),
        email,
        beneficiary,
        beneficiaryId: id,
        beneficiaryName: name,
        operationType: 'scheduled_later',
        scheduledDate: dueDate,
        note: description,
      };
    } else {
      return { activityTag: 'other_expense' };
    }
  }
}
