import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { LottiePlayer } from '@repo/design-system-kit';

export default class TopupFailController extends Controller {
  lottiePlayer = LottiePlayer;

  @service homePage;
  @service organizationManager;

  @action
  handleClose() {
    this.homePage.visitDefaultPage(this.organizationManager.organization.slug);
  }
}
