import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { DEPOSIT_CAPITAL_STEPPER_STEP } from 'qonto/constants/organization';

export default class CapitalChoosePoaTypeRoute extends Route {
  @service capitalIncreaseManager;
  @service segment;

  activate() {
    this.capitalIncreaseManager.setCurrentStep(DEPOSIT_CAPITAL_STEPPER_STEP.DOCUMENTS);

    this.segment.track('kdeposit_poa_type');
  }
}
