import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

export default class AccountsRoute extends Route {
  @service abilities;

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'ledger');
    if (this.abilities.cannot('access bank-account')) {
      this.replaceWith('organizations.show');
    }
  }
}
