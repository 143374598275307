import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class OverviewNewRoute extends Route {
  @service router;
  @service organizationManager;
  @service subscriptionManager;
  @service productDiscovery;
  @service sentry;
  @service periodicUpdate;

  beforeModel() {
    if (!this.productDiscovery.isProductDiscoverySystemFeatureEnabled) {
      this.router.transitionTo('overview.index');
    }
  }

  model() {
    let { transactionsTask, updateProcess, bankAccounts } = this.modelFor('overview');
    return {
      updateProcess,
      transactionsTask,
      bankAccounts,
      currentPricePlanCode: this.subscriptionManager.currentPricePlan?.code,
    };
  }

  afterModel({ updateProcess }) {
    this.periodicUpdate.setupPeriodicPopup({ updateProcess });
  }

  setupController(controller) {
    super.setupController(...arguments);

    let {
      organization: { isSuspended, isDeactivated },
    } = this.organizationManager;

    if (!isSuspended && !isDeactivated) {
      controller.fetchUserActionsTask.perform().catch(ignoreCancelation);
    }
  }

  resetController(controller) {
    super.resetController(...arguments);

    controller.fetchUserActionsTask.cancelAll();
    controller.hasNotFoundError = false;
  }
}
