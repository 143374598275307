export const sddActivation = {
  steps: {
    intro: {
      componentName: 'sdd-collection/activation/intro',
      nextStepId: 'creditor-identifier',
    },
    'creditor-identifier': {
      componentName: 'sdd-collection/activation/creditor-identifier',
      nextStepId: 'success',
    },
    success: {
      componentName: 'sdd-collection/activation/success',
    },
  },
};
