/* eslint-disable @qonto/no-import-roles-constants */
import { ROLES } from 'qonto/constants/membership';

export const memberEditRole = {
  steps: {
    role: {
      componentName: 'member/role-selection',
      nextStepId: ({ role, showComparison }) => {
        let isManager = role?.key === ROLES.MANAGER || role?.key === ROLES.ADVANCED_MANAGER;

        if (showComparison) {
          return 'compare';
        }

        if (isManager) {
          return 'scope';
        }

        return 'success';
      },
    },
    compare: {
      componentName: 'member/compare-roles',
      nextStepId: ({ role, showComparison }) => {
        let isManager = role?.key === ROLES.MANAGER || role?.key === ROLES.ADVANCED_MANAGER;

        if (showComparison) {
          return 'compare';
        }

        if (isManager) {
          return 'scope';
        }

        return 'success';
      },
    },
    scope: {
      componentName: 'member/scope-selection',
      nextStepId: ({ member }) => {
        let { scope } = member.customPermissions;
        return scope === 'team' ? 'account-management-team' : 'account-management-organization';
      },
    },
    'account-management-team': {
      componentName: 'member/account-management/team-scope',
      nextStepId: ({ hasAllowedBankAccountsError }) => {
        if (hasAllowedBankAccountsError) {
          return 'edit-allowed-bank-accounts-error';
        }
        return 'expense-permissions';
      },
    },
    'account-management-organization': {
      componentName: 'member/account-management/organization-scope',
      nextStepId: ({ hasAllowedBankAccountsError }) => {
        if (hasAllowedBankAccountsError) {
          return 'edit-allowed-bank-accounts-error';
        }

        return 'expense-permissions';
      },
    },
    'expense-permissions': {
      componentName: 'member/expense-permissions',
      nextStepId: () => {
        return 'other-permissions';
      },
    },
    'other-permissions': {
      componentName: 'member/other-permissions',
      nextStepId: ({ hasAllowedBankAccountsError }) => {
        if (hasAllowedBankAccountsError) {
          return 'edit-allowed-bank-accounts-error';
        }

        return 'success';
      },
    },
    success: {
      componentName: 'member/success/edit-role',
    },
    'edit-allowed-bank-accounts-error': {
      componentName: 'member/error/allowed-bank-accounts',
    },
  },
  options: {
    enablePersistence: true,
  },
};
