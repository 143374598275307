import Route from '@ember/routing/route';
import { service } from '@ember/service';

import window from 'ember-window-mock';

export default class TopupPaymentRoute extends Route {
  @service organizationManager;
  @service router;
  @service payment;

  beforeModel() {
    if (window.sessionStorage.getItem('topup_context')) {
      let { amount, provider } = JSON.parse(window.sessionStorage.getItem('topup_context'));
      this.payment.amount = amount;
      this.payment.provider = provider;
      window.sessionStorage.removeItem('topup_context');
    }

    let { topupsStatus } = this.organizationManager.membership;
    if (topupsStatus === 'completed') {
      return this.router.transitionTo('onboarding.topup.completed');
    }

    if (!this.payment.amount) {
      return this.router.transitionTo('onboarding.topup.amount');
    }
  }

  setupController(controller) {
    if (controller.ckoSessionId) {
      this.payment.onboardingCardSkipped = true;
    }
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.threeDSAuthenticationError = null;
      controller.googlePayError = null;
      controller.ckoSessionId = null;
      controller.checkoutFramesLibraryReady = false;
      controller.gpayLibraryLoaded = false;
    }
  }
}
