import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import dayjs from 'dayjs';
import { dropTask, task } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class PricePlanPaymentMethodsController extends Controller {
  @service toastFlashMessages;
  @service intl;
  @service modals;
  @service sentry;
  @service store;

  get items() {
    return this.model.fetchPaymentMethodsTask.lastSuccessful?.value.paymentMethods || [];
  }

  @action
  paymentType(type) {
    switch (type) {
      case 'apple_pay':
        return this.intl.t('subscription.payment-methods.card-types.apple-pay');
      case 'google_pay':
        return this.intl.t('subscription.payment-methods.card-types.google-pay');
      default:
        return this.intl.t('subscription.payment-methods.card-types.card');
    }
  }

  @action
  paymentTypeIcon(type) {
    switch (type) {
      case 'apple_pay':
        return 'apple_pay';
      case 'google_pay':
        return 'google_pay';
      default:
        return 'icon_card_outlined';
    }
  }

  @action
  expirationDate(date) {
    return dayjs(new Date(date)).format('MM/YY');
  }

  deletePaymentMethodTask = dropTask(async paymentMethodId => {
    try {
      let payment = this.store.peekRecord('payment-method', paymentMethodId);
      await payment.destroyRecord();
      this.toastFlashMessages.toastSuccess(
        this.intl.t('subscription.payment-methods.delete-success')
      );
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('subscription.payment-methods.delete-error'));

      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  deletePaymentMethodModalTask = task(async paymentMethodId => {
    await this.modals.open('payment-methods/modals/delete', {
      paymentMethodId,
      confirmTask: this.deletePaymentMethodTask,
    });
  });
}
