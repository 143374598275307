export default {
  "page-container": "qKI",
  "form": "qKt",
  "close-button": "qKM btn btn--icon-only btn--tertiary btn--large",
  "preview": "qKP",
  "preview-padding": "qKl",
  "title": "qKX",
  "header": "qKC",
  "without-tabs": "qKk",
  "form-section": "qKJ",
  "form-footer": "qKs",
  "disclaimer-text": "qKF"
};
