import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import formatFileSize from '@qonto/ui-kit/utils/format-bytes';
import { Disclaimer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import { addDocumentFileToModel, removeDocumentFileFromModel } from 'qonto/utils/handle-documents';

export default class CapitalAddressController extends Controller {
  disclaimer = Disclaimer.Block;

  @service capitalIncreaseManager;
  @service toastFlashMessages;
  @service modals;
  @service intl;
  @service store;

  @reads('capitalIncreaseManager.organization') organization;
  @tracked enableOrganizationValidations = false;

  queryParams = ['poaType'];
  @tracked poaType;

  confirmTask = dropTask(async () => {
    this.enableOrganizationValidations = false;
    let { validations: organizationValidation } =
      await this.capitalIncreaseManager.validateOnly('proofOfAddress');

    this.enableOrganizationValidations = true;

    if (organizationValidation.isValid) {
      await this.capitalIncreaseManager
        .handleSaveDocuments('poa')
        .then(organization => organization.save())
        .then(() => {
          this.transitionToRoute('capital.review');
        })
        .catch(error => {
          // TODO: to clarify
          // a string should be passed (instead of an object)
          this.toastFlashMessages.toastError(error);
        });
    }
  });

  @action
  handleAddDocument(model, docType, file) {
    addDocumentFileToModel(this.store, model, docType, file);
  }

  @action
  handleRemove(model, docType, file) {
    removeDocumentFileFromModel(this.store, model, docType, file);
  }

  @action
  handleOpenFilePreview(file) {
    this.modals.open('file-preview-modal', {
      isFullScreenModal: true,
      deletable: true,
      selectedFile: file,
      model: this.organization,
      docType: 'poa',
      remove: this.handleRemove,
    });
  }

  @action
  handleBack() {
    this.transitionToRoute('capital.choose-poa-type');
  }

  @action
  formatFileSize(val) {
    return formatFileSize(this.intl, val);
  }
}
