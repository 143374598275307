import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';
import window from 'ember-window-mock';
import { reads } from 'macro-decorators';

import { billerBaseURL, billerNamespace } from 'qonto/constants/hosts';

export default class PricePlanInvoicesController extends Controller {
  @service errors;
  @service segment;
  @service organizationManager;
  @service subscriptionManager;
  @service intl;
  @service store;
  @service networkManager;
  @service toastFlashMessages;

  @reads('organizationManager.membership.role') role;
  @reads('organizationManager.organization') organization;
  @reads('subscriptionManager.currentPricePlan.code') pricePlanCode;

  queryParams = ['page', 'per_page'];

  @tracked page = 1;
  @tracked per_page = 25;

  get invoices() {
    return this.model.fetchInvoicesTask.lastSuccessful?.value || [];
  }

  get localState() {
    if (this.model.fetchInvoicesTask.isRunning) {
      return {
        empty: false,
        isLoading: true,
      };
    }
    return {
      empty: this.invoices?.length === 0,
      isLoading: false,
    };
  }

  get currentMonth() {
    return this.intl.formatDate(new Date(), { month: 'long' });
  }

  get nextRecurrenceDateFormatted() {
    return this.intl.formatDate(this.subscriptionManager.nextInvoicingDate, {
      weekday: 'short',
      month: 'short',
      day: '2-digit',
    });
  }

  @action trackEvent(name) {
    this.segment.track(name, {
      price_plan: this.pricePlanCode,
      country: this.organization.legalCountry,
      role: this.role,
    });
  }

  @action
  downloadInvoice(itemId) {
    if (!itemId) return;

    this.trackEvent('subscription_invoice_clicked');
    let invoice = this.store.peekRecord('invoice', itemId);
    window.open(invoice.pdfUrl);
  }

  @action
  changePerPage(value) {
    this.page = 1;
    this.per_page = value;
  }

  exportSelectedInvoicesTask = dropTask(async selectedInvoices => {
    try {
      await this.networkManager.request(`${billerBaseURL}/${billerNamespace}/invoices/export`, {
        data: {
          invoice_ids: selectedInvoices,
          organization_id: this.organizationManager.organization.id,
        },
        method: 'POST',
      });
      this.trackEvent('invoices_bulk-download_clicked');
      this.toastFlashMessages.toastInfo(
        this.intl.t('subscription.invoice.export_toast', { count: selectedInvoices.length })
      );
    } catch (error) {
      if (this.errors.shouldFlash(error) || error.status === 403) {
        this.toastFlashMessages.toastError(this.errors.messageForStatus(error));
      }
      throw error;
    }
  });
}
