import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class F24V2Route extends Route {
  @service abilities;
  @service homePage;
  @service router;

  beforeModel() {
    if (this.abilities.cannot('view f24')) {
      this.homePage.replaceWithDefaultPage();
    }
  }
}
