export const cardOnboarding = {
  steps: {
    'choose-card': {
      componentName: 'cards/choose-card-onboarding',
      nextStepId: ({ showCardCustomization }) => {
        return showCardCustomization ? 'customization' : 'delivery-address';
      },
    },
    customization: {
      componentName: 'cards/customization-new',
      nextStepId: 'delivery-address',
    },
    'delivery-address': {
      componentName: 'cards/delivery-address',
      nextStepId: 'set-pin',
    },
    'set-pin': {
      componentName: 'cards/set-pin',
      nextStepId: 'confirm-pin',
    },
    'confirm-pin': {
      componentName: 'cards/confirm-pin',
      nextStepId: 'success',
    },
    success: {
      componentName: 'cards/success-onboarding',
    },
  },
  options: {
    enablePersistence: false,
  },
};
