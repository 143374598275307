import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { Disclaimer } from '@repo/design-system-kit';
import * as Sentry from '@sentry/ember';
import { dropTask } from 'ember-concurrency';
import window from 'ember-window-mock';

export default class CapitalCertificateController extends Controller {
  disclaimer = Disclaimer.Block;

  @service capitalIncreaseManager;
  @service toastFlashMessages;
  @service intl;
  @service segment;

  handleDownloadCertificateTask = dropTask(async () => {
    try {
      let downloadUrl = await this.capitalIncreaseManager.downloadDepositCertificate();
      this.segment.track('kdeposit_certificate_downloaded');

      let url = downloadUrl.download_url;
      window.location.href = url;
    } catch {
      Sentry.captureMessage('capital certificate: deposit-certificate download failed');

      let message = this.intl.t('errors.internal_server_error');
      this.toastFlashMessages.toastError(message);
    }
  });

  handleDownloadUpdatedBylawsTask = dropTask(async () => {
    try {
      let downloadUrl = await this.capitalIncreaseManager.downloadUpdatedBylaws();

      window.location.href = downloadUrl.download_url;
    } catch {
      Sentry.captureMessage('capital certificate: updated-bylaws download failed');

      let message = this.intl.t('errors.internal_server_error');
      this.toastFlashMessages.toastError(message);
    }
  });

  @action
  handleNext() {
    this.segment.track('kdeposit_certificate_confirmed');
    this.transitionToRoute('capital.kbis-info');
  }
}
