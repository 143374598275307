import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';

export default class SettingsHistoricalDataController extends Controller {
  @service organizationManager;
  @service intl;
  @service toastFlashMessages;
  @service modals;

  exportTransactionsTask = dropTask(async options => {
    let { organization } = this.organizationManager;

    try {
      await organization.exportOldTransactions(options);

      let toastMessage = this.intl.t('transactions.modals.export.toast_success');
      this.toastFlashMessages.toastInfo(toastMessage);
    } catch {
      let toastMessage = this.intl.t('errors.internal_server_error');
      this.toastFlashMessages.toastError(toastMessage);
    }
  });

  @action
  openStatementsModal() {
    this.modals.open('settings/historical-data/modal', {
      items: this.model.statements,
      title: this.intl.t('settings.historical-data.statements.title'),
      description: this.intl.t('organizations.subscription.invoice_download_desc'),
    });
  }

  @action
  openInvoicesModal() {
    this.modals.open('settings/historical-data/modal', {
      items: this.model.invoices,
      title: this.intl.t('settings.historical-data.statements.title'),
      description: this.intl.t('organizations.subscription.invoice_download_desc'),
    });
  }
}
