import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { variation } from 'ember-launch-darkly';

export default class DirectDebitCollectionsIncreaseCollectionLimitRoute extends Route {
  @service homePage;
  @service menu;

  queryParams = {
    origin: {
      refreshModel: true,
    },
  };

  beforeModel() {
    if (!variation('feature-boolean-sdd-increase-collection-limit')) {
      return this.homePage.replaceWithDefaultPage();
    }
  }

  model(params) {
    let { origin } = params;

    return {
      origin,
    };
  }

  activate() {
    if (this.menu.isVisible) {
      this.menu.hide();
    }
  }

  deactivate() {
    this.menu.show();
  }
}
