export default {
  "container": "quq",
  "content": "quZ",
  "setup-box": "quR",
  "setup-wizard": "quQ",
  "setup-success": "que",
  "setup-success-lottie": "quB",
  "setup-close-btn": "quE",
  "tray-connection": "qud",
  "close-button": "quI"
};
