import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

export default class AgenciaTributariaListErrorController extends Controller {
  @service router;

  @action
  retry() {
    this.router.replaceWith(this.model.retryRouteName);
  }

  get isCompleted() {
    return this.model.retryRouteName === 'agencia-tributaria.list.completed';
  }

  get isProcessing() {
    return this.model.retryRouteName === 'agencia-tributaria.list.processing';
  }
}
