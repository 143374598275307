/* eslint-disable @qonto/no-redirect-in-unrelated-hooks */
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class ReceivableInvoicesIndexShowRoute extends Route {
  @service toastFlashMessages;
  @service intl;
  @service router;
  @service store;
  @service organizationManager;
  @service sentry;
  @service abilities;

  model({ id }) {
    if (variation('feature--boolean-client-hub') && this.abilities.can('read client-hubs')) {
      this.fetchClientsTask.perform().catch(error => {
        let errorInfo = ErrorInfo.for(error);
        if (errorInfo.shouldSendToSentry) {
          this.sentry.captureException(error);
        }
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
        this.router.replaceWith('receivable-invoices.index');
      });
    }

    this.fetchTask.perform(id).catch(error => {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      this.router.replaceWith('receivable-invoices.index');
    });

    return {
      fetchTask: this.fetchTask,
    };
  }

  fetchTask = dropTask(async id => {
    this.organizationManager.organization.getAvatar();

    if (this.abilities.can('create receivable-invoice')) {
      await this.store.query('customer', {
        filter: { organization_id: this.organizationManager.organization.id },
      });
    }

    let creditNote = await this.store.findRecord('receivable-credit-note', id);
    let receivableInvoice = await creditNote.receivableInvoice;

    await creditNote.setPdfPreviewIframeUrl();

    return { creditNote, receivableInvoice };
  });

  fetchClientsTask = dropTask(async () => {
    let clientsParams = {
      page: 1,
      per_page: 500,
      sort_by: 'name:asc',
    };
    let clients = await this.store.query('client-hub', clientsParams);
    let total = clients.meta.total_count;

    while (total > clients.length) {
      clientsParams.page++;
      clients = clients.concat(await this.store.query('client-hub', clientsParams));
    }
  });
}
