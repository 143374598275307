import Route from '@ember/routing/route';
import { service } from '@ember/service';

import dayjs from 'dayjs';
import { dropTask } from 'ember-concurrency';

import { DEFAULT_SORT_BY, NUM_OF_NAMES_TO_DISPLAY } from 'qonto/constants/budget';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class BudgetsShowRoute extends Route {
  @service router;
  @service store;
  @service abilities;
  @service sentry;
  @service organizationManager;

  queryParams = {
    periodId: { refreshModel: true },
    sortBy: { refreshModel: true },
    page: { refreshModel: true },
    perPage: { refreshModel: true },
  };

  currentPeriodId = null;

  beforeModel() {
    if (this.abilities.cannot('read details budget')) {
      this.router.replaceWith('budgets.list');
    }
  }

  async model(params, transition) {
    let { budget_id: budgetId, periodId, sortBy, page, perPage } = params;
    let {
      data: { shouldRefetchPeriod },
    } = transition;

    let budget = this.store.peekRecord('budget', budgetId);

    if (!budget) {
      budget = await this.store.findRecord('budget', budgetId, {
        include: ['active_period'],
      });
    }

    let selectedPeriodId = periodId ?? this.#getActivePeriodId(budget);
    let userHasChangedPeriod = this.currentPeriodId !== selectedPeriodId;

    /**
     * Refetching budget period when user navigates from one period to another
     * or when user wants to refetch period.
     */
    if (userHasChangedPeriod || shouldRefetchPeriod) {
      this.currentPeriodId = selectedPeriodId;

      this.fetchBudgetPeriodTask
        .perform(this.currentPeriodId)
        .catch(ignoreCancelation)
        .catch(error => this.#handleError(error));

      this.fetchTeamManagersTask
        .perform(budget)
        .catch(ignoreCancelation)
        .catch(error => this.#handleError(error));
    }

    let filterParams = { sortBy, page, perPage };

    this.fetchBudgetTransactionsTask
      .perform({ periodId: this.currentPeriodId, budget, filterParams })
      .catch(ignoreCancelation)
      .catch(error => this.#handleError(error));

    return {
      budget,
      currentPeriodId: this.currentPeriodId,
      shouldWaitForTransactions: userHasChangedPeriod || shouldRefetchPeriod,
      fetchBudgetPeriodTask: this.fetchBudgetPeriodTask.last,
      fetchTeamManagersTask: this.fetchTeamManagersTask.last,
      fetchBudgetTransactionsTask: this.fetchBudgetTransactionsTask.last,
    };
  }

  fetchBudgetPeriodTask = dropTask(async periodId => {
    return await this.store.findRecord('period-computed-amount', periodId);
  });

  fetchBudgetTransactionsTask = dropTask(async ({ periodId, budget, filterParams }) => {
    let transactions = await this.store.query('budget-transaction', {
      periodIds: [periodId],
      budgetId: budget.id,
      ...filterParams,
    });

    if (transactions.length) {
      let membershipsIds = transactions.map(transaction => transaction.belongsTo('initiator').id());

      await this.store.query('membership', {
        organization_id: this.organizationManager.organization.id,
        filters: { ids: membershipsIds },
        per_page: membershipsIds.length,
      });
    }

    return transactions;
  });

  fetchTeamManagersTask = dropTask(async budget => {
    let teamManagersIdsToDisplay = budget.supervisorIds.slice(0, NUM_OF_NAMES_TO_DISPLAY);
    let count = budget.supervisorIds.length;

    if (!teamManagersIdsToDisplay.length) {
      return { memberships: [], count: 0 };
    }

    let memberships = this.store
      .peekAll('membership')
      .filter(({ id }) => teamManagersIdsToDisplay.includes(id));

    // If we don't have all the memberships in the store, we need to fetch them.
    if (memberships.length !== teamManagersIdsToDisplay.length) {
      memberships = await this.store.query('membership', {
        organization_id: this.organizationManager.organization.id,
        filters: { ids: teamManagersIdsToDisplay },
        per_page: teamManagersIdsToDisplay.length,
      });
    }

    return { memberships, count };
  });

  /**
   * When the user opens details of a budget, we want to display the active period by default.
   * We'll rely on this method to find the right period to display.
   */
  #getActivePeriodId(budget) {
    if (budget.activePeriod?.id) {
      return budget.activePeriod.id;
    }

    let periods = budget.exercises.reduce(
      (periods, exercise) => [...periods, ...exercise.periods],
      []
    );

    let activePeriod = periods.find(({ startDate, endDate }) =>
      dayjs().isBetween(startDate, endDate, 'day')
    );

    /**
     * If there is an active period, we want to display it by default.
     */
    if (activePeriod) {
      return activePeriod.id;
    }

    /**
     * If there is a next period, we'll display it.
     */
    let nextPeriod = periods.find(period => dayjs(period.startDate).isAfter(dayjs()));

    if (nextPeriod) {
      return nextPeriod.id;
    }

    /**
     * If there is no next period, we'll display the last period of the last exercise.
     */
    let previousPeriod = periods.reduce(
      (savedPeriod, currentPeriod) =>
        dayjs(currentPeriod.startDate).isAfter(savedPeriod.startDate) ? currentPeriod : savedPeriod,
      periods[0]
    );

    return previousPeriod.id;
  }

  #handleError(error) {
    if (ErrorInfo.for(error).shouldSendToSentry) {
      this.sentry.captureException(error);
    }
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.setProperties({ periodId: null });
      controller.setProperties({ sortBy: DEFAULT_SORT_BY });
      controller.setProperties({ page: 1 });
      controller.setProperties({ perPage: 25 });

      this.currentPeriodId = null;
    }
  }
}
