import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import dayjs from 'dayjs';
import { dropTask } from 'ember-concurrency';

import { REQUEST_EVENT_METHOD, REQUEST_ORIGINS } from 'qonto/constants/requests';

const DEFAULT_PAGE = 1;
const DEFAULT_PER_PAGE = 25;

export default class DirectDebitCollectionsRequestController extends Controller {
  @service organizationManager;
  @service sensitiveActions;
  @service router;
  @service modals;
  @service segment;
  @service requestsManager;

  @tracked page = DEFAULT_PAGE;
  @tracked perPage = DEFAULT_PER_PAGE;

  get bankAccounts() {
    return this.organizationManager.organization.activeSortedRemuneratedAndCurrentAccounts;
  }

  get requests() {
    return this.model.fetchRequestsTask.lastSuccessful?.value ?? [];
  }

  get hasRequestInThePast() {
    return this.requests.some(request =>
      dayjs().startOf('day').isAfter(dayjs(request.scheduledDate))
    );
  }

  onApproveClickTask = dropTask(async multiRequest => {
    this.#track('request_approved_clicked', multiRequest);

    let result = await this.sensitiveActions.runTaskNoDrop.perform(
      this.requestsManager.approveMultiRequestDirectDebitCollectionTask,
      multiRequest
    );

    if (result === 'success') {
      this.router.transitionTo('direct-debit-collections.requests.index');
    }
  });

  onRejectClickTask = dropTask(async multiRequest => {
    this.#track('request_declined_clicked', multiRequest);

    await this.modals.open('request/sidebar/modals/decline-request', {
      request: multiRequest,
      confirmTask: this.confirmRejectionTask,
    });
  });

  confirmRejectionTask = dropTask(async (closeRejectModal, multiRequest) => {
    let result =
      await this.requestsManager.rejectMultiRequestDirectDebitCollectionTask.perform(multiRequest);

    closeRejectModal();

    if (result === 'success') {
      this.router.transitionTo('direct-debit-collections.requests.index');
    }
  });

  @action
  handlePerPageChange(perPage) {
    this.page = DEFAULT_PAGE;
    this.perPage = perPage;
  }

  @action
  onBankAccountSelect(multiRequest, bankAccount) {
    multiRequest.bankAccount = bankAccount;

    this.#track('request_account_selected', multiRequest);
  }

  #track(eventName, { id, totalCount, requestType }) {
    this.segment.track(eventName, {
      role: this.organizationManager.membership.role,
      request_id: id,
      request_type: requestType,
      direct_debits_count: totalCount,
      origin: REQUEST_ORIGINS.INCOMING_DIRECT_DEBIT,
      method: REQUEST_EVENT_METHOD.BULK_DETAILS,
    });
  }
}
