import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { billerBaseURL, billerNamespace } from 'qonto/constants/hosts';
import { ErrorInfo } from 'qonto/utils/error-info';
import transformKeys from 'qonto/utils/transform-keys';

export default class SettingsPricePlanDetailsRoute extends Route {
  @service intl;
  @service networkManager;
  @service organizationManager;
  @service sentry;
  @service store;

  async getDeactivationData() {
    let { organization } = this.organizationManager;
    let deactivationData = await organization.getDeactivation().catch(error => {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      // Rethrow the error
      throw error;
    });

    let deactivated = deactivationData?.deactivated;
    let deactivationDate = deactivationData?.deactivationDate;
    let deactivationDueDate = deactivationData?.dueDate;
    let deactivationRequester = deactivationData?.requester;

    return { deactivationDueDate, deactivationRequester, deactivated, deactivationDate };
  }

  async getMonthlyConsumption() {
    let { organization } = this.organizationManager;
    let organizationId = organization.id;

    try {
      let { monthly_consumptions } = await this.networkManager.request(
        `${billerBaseURL}/${billerNamespace}/monthly_consumptions?organization_id=${organizationId}`
      );
      return monthly_consumptions;
    } catch (error) {
      // Ignore 403 errors this is an expected status code when the organization is deactivated
      if (ErrorInfo.for(error).shouldSendToSentry && error.status !== 403) {
        this.sentry.captureException(error);
      }
    }
  }

  async model() {
    let [deactivationData, monthlyConsumptions] = await Promise.all([
      this.getDeactivationData(),
      this.getMonthlyConsumption(),
    ]);

    let {
      additionalFeesTotalAmount: additionalFeesTotal,
      allFeesTotalAmount: allFeesTotal,
      allFeesTotalAmountVatIncluded: allFeesTotalVatIncluded,
      coreSubscriptionFeesTotalAmount,
      subscriptionFees: subscriptionsList,
      additionalFees,
      refunds,
      addons,
    } = transformKeys(monthlyConsumptions) || {};

    return {
      showMonthlyConsumptions: Boolean(monthlyConsumptions),
      subscriptionsList,
      coreSubscriptionFeesTotalAmount,
      additionalFeesTotal,
      refunds,
      allFeesTotal,
      allFeesTotalVatIncluded,
      additionalFees,
      addons,
      ...deactivationData,
    };
  }
}
