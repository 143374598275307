import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class ConnectHubHubApplicationRoute extends Route {
  @service organizationManager;
  @service connectManager;
  @service store;

  rootRouteName = 'settings.connect-hub.applications';

  async model(params) {
    let applicationSlug = params['application_slug'];
    let currentIntegration;
    let { organization } = this.organizationManager;
    let parentModel = this.modelFor(this.rootRouteName);

    currentIntegration =
      parentModel?.allIntegrations?.find(it => it.uid === applicationSlug.toLowerCase()) ||
      (await this.connectManager.getIntegration(applicationSlug.toLowerCase()));

    if (!currentIntegration) {
      this.replaceWith('/404');
    }

    let applications = await this.store.query('hub-application', {
      organization_id: organization.id,
      filters: { slug: applicationSlug },
    });

    return {
      hubApplication: applications[0],
      organization,
      prismicData: currentIntegration?.data,
    };
  }
}
