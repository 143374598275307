export const internationalOutBeneficiary = {
  steps: {
    create: {
      componentName: 'transfers/international-out/beneficiary/create',
    },
  },
  options: {
    enablePersistence: false,
  },
};
