import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class TopupCompletedRoute extends Route {
  @service homePage;
  @service organizationManager;
  @service router;

  beforeModel() {
    let { topupsStatus } = this.organizationManager.membership;
    if (topupsStatus !== 'completed') {
      return this.homePage.visitDefaultPage(this.organizationManager.organization.slug);
    }
  }
}
