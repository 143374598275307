export const editBudget = {
  steps: {
    'select-exercise': {
      componentName: 'budgets/edit-budget/select-exercise',
      nextStepId: ({ budget, exerciseId }) => {
        if (!exerciseId || budget.nextExercises.find(({ id }) => exerciseId === id)) {
          return 'time-frame';
        }
        return 'edit-exercise';
      },
    },
    'time-frame': {
      componentName: 'budgets/edit-budget/time-frame',
      nextStepId: 'edit-exercise',
    },
    'edit-exercise': {
      componentName: 'budgets/edit-budget/edit-exercise',
      nextStepId: 'success',
    },
    success: {
      componentName: 'budgets/edit-budget/success',
    },
  },
  options: {
    enablePersistence: true,
  },
};
