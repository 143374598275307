import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class InvitationsShowMemberInfoRoute extends Route {
  @service deviceManager;
  @service router;
  @service userManager;

  async beforeModel() {
    if (this.deviceManager.isMobile) {
      let { invite } = await this.modelFor('invitations.show');
      let { kycRequired } = invite;
      if (kycRequired) {
        return this.router.transitionTo('invitations.show.member.kyc.details');
      } else {
        return this.router.transitionTo('invitations.show.member.kyc.select');
      }
    }

    if (!this.userManager.currentUser) {
      return this.userManager.setup();
    }
  }
}
