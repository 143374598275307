import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class AgenciaTributariaCompletedRoute extends Route {
  @service segment;
  @service store;
  @service router;

  activate() {
    this.segment.track('agencia-tributaria_completed-section_opened');
  }

  queryParams = {
    page: { refreshModel: true },
    perPage: { refreshModel: true },
    sortBy: { refreshModel: true },
  };

  model(params) {
    let { page, perPage, sortBy } = params;

    let queryParams = { page, per_page: perPage, sort_by: sortBy };

    return this.store.query('nrc-payment', {
      status_group: 'completed',
      ...queryParams,
    });
  }

  @action
  error(error) {
    error.retryRouteName = this.routeName;
    return true;
  }
}
