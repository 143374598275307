export const cardSetPin = {
  steps: {
    'set-pin': {
      componentName: 'cards/set-pin',
      nextStepId: 'confirm-pin',
    },
    'confirm-pin': {
      componentName: 'cards/confirm-pin',
      nextStepId: ({ kycSubmitted }) => {
        if (!kycSubmitted) {
          return 'kyc-intro';
        }

        return 'success';
      },
    },
    'kyc-intro': {
      componentName: 'cards/kyc/set-pin-kyc-intro',
    },
    success: {
      componentName: 'cards/success-set-pin',
    },
  },
  options: {
    enablePersistence: false,
  },
};
