/* eslint-disable @qonto/no-import-roles-constants */
import { MEMBER_STATUS, ROLES } from 'qonto/constants/membership';

import TabRoute from '../tab/route';

export default class MemberListRoute extends TabRoute {
  rootRouteName = `members.active`;

  paginatedFilters = {
    status: [MEMBER_STATUS.ACTIVE, MEMBER_STATUS.INVITED],
    roles: [ROLES.OWNER, ROLES.MANAGER, ROLES.EMPLOYEE, ROLES.ADMIN],
  };
}
