import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

export default class CapitalReleaseContractController extends Controller {
  @service capitalIncreaseManager;
  @service toastFlashMessages;
  @service intl;

  @action
  handleSigned() {
    let { organization } = this.capitalIncreaseManager;
    organization.set('documentType', 'deposit_release');

    organization
      .handleContractSigned(this.documentId)
      .then(() => {
        this.set('showContract', false);
        this.replaceRoute('capital.finish');
      })
      .catch(error => {
        this.toastFlashMessages.toastError(error);
      });
  }

  @action
  handleDeclined() {
    Sentry.captureMessage('capital release: user declined contract');
  }

  @action
  handleSignError() {
    Sentry.captureMessage('capital release: contract signature failed');

    let message = this.intl.t('errors.internal_server_error');
    this.toastFlashMessages.toastError(message);
  }

  @action
  handleCanceled() {
    this.set('showContract', false);
    this.replaceRoute('capital.release');
  }
}
