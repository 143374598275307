export default {
  "page-header": "ZZm",
  "header-breadcrumb": "ZZp",
  "tabs-container": "ZZx",
  "bottom-border": "ZZw",
  "tabs-separator": "ZZN",
  "previous-request": "ZZv",
  "body-link": "ZZo",
  "border-top": "ZZn"
};
