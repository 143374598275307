export const cardRenewPhysical = {
  steps: {
    'verify-delivery-address': {
      componentName: 'cards/verify-delivery-address',
      nextStepId: ({
        hasSingleCardToRenew,
        isChangeAddress,
        isEligibleForUpsell,
        showCardCustomization,
      }) => {
        if (isChangeAddress) {
          return 'delivery-address';
        }
        if (isEligibleForUpsell) {
          return 'renewal-upsell';
        }
        if (hasSingleCardToRenew) {
          return showCardCustomization ? 'customization' : 'review';
        }
        return 'success';
      },
    },
    'delivery-address': {
      componentName: 'cards/delivery-address',
      nextStepId: ({ hasSingleCardToRenew, isEligibleForUpsell, showCardCustomization }) => {
        if (isEligibleForUpsell) {
          return 'renewal-upsell';
        }
        if (hasSingleCardToRenew) {
          return showCardCustomization ? 'customization' : 'review';
        }
        return 'success';
      },
    },
    'renewal-upsell': {
      componentName: 'cards/renewal-upsell',
      nextStepId: ({ showCardCustomization }) =>
        showCardCustomization ? 'customization' : 'review',
    },
    customization: {
      componentName: 'cards/customization-renew',
      nextStepId: 'review',
    },
    review: {
      componentName: 'cards/review-renewal',
      nextStepId: 'success',
    },
    success: {
      componentName: 'cards/success-renewal',
    },
  },
  options: {
    enablePersistence: false,
  },
};
