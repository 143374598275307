import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class InvoicingSettingsRoute extends Route {
  @service abilities;
  @service homePage;
  @service intl;
  @service menu;
  @service organizationManager;
  @service router;
  @service store;
  @service sentry;
  @service subscriptionManager;
  @service toastFlashMessages;

  queryParams = {
    //Used to scroll to a specific section of the form
    target: {
      refreshModel: false,
    },
  };

  activate() {
    this.menu.hide();
  }

  deactivate() {
    this.menu.show();
  }

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'invoices');

    let canNavigate = this.abilities.can('navigate receivable-invoice');

    if (!canNavigate) {
      return this.homePage.replaceWithDefaultPage();
    }
  }

  async model() {
    let { organization } = this.organizationManager;

    let settings;

    try {
      settings = await this.store.findRecord('receivable-invoices-settings', organization.id);
    } catch (error) {
      this.handleError(error);
      this._redirectOnError();
    }

    try {
      await organization.getAvatar();
    } catch (error) {
      this.handleError(error);
    }

    if (settings) {
      settings.contactEmail = settings.contactEmail || organization.membership?.email;
      organization.vatNumber = settings.vatNumber;
      organization.taxNumber = settings.taxNumber;
      organization.companyLeadership = settings.companyLeadership;
      organization.districtCourt = settings.districtCourt;
      organization.commercialRegisterNumber = settings.commercialRegisterNumber;
    }

    return {
      settings,
    };
  }

  handleError(error) {
    if (!error.isAdapterError) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  }

  _redirectOnError() {
    this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    return this.router.transitionTo('receivable-invoices');
  }
}
