import Controller from '@ember/controller';
import { service } from '@ember/service';

import { LottiePlayer } from '@repo/design-system-kit';

export default class ConfirmCompanyProfile extends Controller {
  lottiePlayer = LottiePlayer;

  @service deviceManager;
}
