export const pagopa = {
  steps: {
    'notice-info': {
      componentName: 'pagopa/notice-info',
      nextStepId: 'account',
    },
    account: {
      componentName: 'pagopa/account-selection',
      nextStepId: 'review',
    },
    review: {
      componentName: 'pagopa/review',
      nextStepId: 'result',
    },
    result: {
      componentName: 'pagopa/result',
    },
  },
  options: {
    enablePersistence: true,
  },
};
