import { action } from '@ember/object';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

import { ACCOUNT_TYPE, SAVINGS_DEFAULT_URL } from 'qonto/constants/bank-account';
import { FlowSetup } from 'qonto/routes/flows/setup/internals';

export class RemuneratedAccountCreationContext {
  account;
  origin;

  constructor(account, origin) {
    this.account = account;
    this.origin = origin;
  }
}

export default class RemuneratedAccountCreationFlowSetup extends FlowSetup {
  @service abilities;
  @service organizationManager;
  @service router;
  @service store;

  origin = this.router.currentRoute.queryParams.origin || SAVINGS_DEFAULT_URL;

  constructor() {
    super(...arguments);

    let bankAccount = this.store.createRecord('bank-account', {
      organization: this.organization,
      accountType: ACCOUNT_TYPE.REMUNERATED,
    });
    this.dataContext = new RemuneratedAccountCreationContext(bankAccount, this.origin);
  }

  get organization() {
    return this.organizationManager.organization;
  }

  beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'pricing');

    if (
      this.abilities.cannot('create remunerated-account') ||
      this.organization.isMaxRemuneratedAccountNumberReached
    ) {
      return this.router.replaceWith(this.origin);
    }
  }

  @action
  onAbort() {
    this.router.transitionTo(this.origin);
  }

  @action
  onComplete() {
    this.router.transitionTo(this.origin, {
      queryParams: {
        scroll: true,
      },
    });
  }
}
