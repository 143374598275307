import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

export default class F24V2ListErrorController extends Controller {
  @service router;

  @action
  retry() {
    this.router.replaceWith(this.model.retryRouteName);
  }
}
