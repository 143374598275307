import {
  PAY_LATER_SIGNATURE_STATUS,
  PAY_LATER_VALIDATION_ERROR_TYPES,
} from 'qonto/constants/financing';

export const payLaterTransfer = {
  steps: {
    'invoice-upload': {
      componentName: 'transfers/sepa/new/invoice-upload',
      nextStepId: 'invoice',
    },
    invoice: {
      componentName: 'transfers/sepa/pay-later/invoice',
      nextStepId: ({ validationError }) => {
        if (!validationError) return 'additional-settings';

        let nextStepId;

        switch (validationError.type) {
          case PAY_LATER_VALIDATION_ERROR_TYPES.INVALID_DATE:
            nextStepId = 'invalid-date';
            break;
          case PAY_LATER_VALIDATION_ERROR_TYPES.SELF_TRANSFER:
            nextStepId = 'self-transfer-error';
            break;
          case PAY_LATER_VALIDATION_ERROR_TYPES.MISSING_DETAILS:
            nextStepId = 'missing-details';
            break;
          case PAY_LATER_VALIDATION_ERROR_TYPES.ATTACHMENT_ID:
          default:
            nextStepId = 'invoice-expired';
            break;
        }

        return nextStepId;
      },
    },
    'additional-settings': {
      componentName: 'transfers/sepa/new/additional-settings',
      nextStepId: 'summary',
    },
    summary: {
      componentName: 'transfers/sepa/new/summary',
      nextStepId: ({ sepaTransfer, isPayLaterContractSigned }) => {
        if (sepaTransfer.isPayLater && !isPayLaterContractSigned) {
          return 'signature-warning';
        }
        return 'settlement';
      },
    },
    'signature-warning': {
      componentName: 'transfers/pay-later-application/signature-warning',
      nextStepId: 'signature',
    },
    signature: {
      componentName: 'financing/pay-later/application/signature',
      nextStepId: ({ signature }) =>
        signature?.status === PAY_LATER_SIGNATURE_STATUS.SUCCESS ? 'summary' : 'signature-failure',
    },
    'signature-failure': {
      componentName: 'transfers/pay-later-application/signature-failure',
    },
    settlement: {
      componentName: 'transfers/sepa/new/settlement',
    },
    'self-transfer-error': {
      componentName: 'transfers/sepa/pay-later/errors/self-transfer',
    },
    'invalid-date': {
      componentName: 'transfers/sepa/pay-later/errors/invalid-date',
    },
    'missing-details': {
      componentName: 'transfers/sepa/pay-later/errors/missing-details',
    },
    'invoice-expired': {
      componentName: 'transfers/sepa/pay-later/errors/invoice-expired',
    },
  },
  options: {
    enablePersistence: false,
  },
};
