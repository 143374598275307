export const f24ManualDeclaration = {
  steps: {
    'form-ordinario': {
      componentName: 'f24/manual-declaration/ordinario/form',
      nextStepId: 'schedule',
    },
    schedule: {
      componentName: 'f24/manual-declaration/schedule',
      nextStepId: 'set-name',
    },
    'set-name': {
      componentName: 'f24/manual-declaration/set-name',
      nextStepId: ({ f24Order, singleBankAccount }) =>
        singleBankAccount && f24Order.documentAmount <= singleBankAccount.authorizedBalance
          ? 'review'
          : 'select-account',
    },
    'select-account': {
      componentName: 'f24/manual-declaration/select-account',
      nextStepId: 'review',
    },
    review: {
      componentName: 'f24/manual-declaration/review',
      nextStepId: 'success',
    },
    success: {
      componentName: 'f24/manual-declaration/success',
    },
  },
  options: {
    enablePersistence: true,
  },
};
