import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { variation } from 'ember-launch-darkly';

import { Sidebar } from 'qonto/react/components/financing/pay-later/cockpit/sidebar';

export default class FinancingPayLaterCompletedController extends Controller {
  sidebar = Sidebar;

  @service router;

  queryParams = ['highlight'];

  @tracked highlight = null;
  @tracked selectedFinancing = null;

  @action
  closeSidebar() {
    this.highlight = null;
  }

  @action
  onRowClick(financing) {
    if (variation('feature--boolean-pay-later-early-repayment')) {
      this.highlight = financing.id;
      this.selectedFinancing = financing;
    }
  }

  @action
  onKeyPress(financing, event) {
    if (event.key === 'Enter') {
      this.onRowClick(financing);
    }
  }

  @action
  refreshRoute() {
    this.router.refresh('financing.pay-later.completed');
  }

  get financings() {
    return this.model.fetchFinancingsTask.lastSuccessful?.value ?? [];
  }

  get localState() {
    let state = {
      isEmpty: false,
      isError: false,
    };

    if (this.model.fetchFinancingsTask.isRunning) return state;

    state.isError = this.model.fetchFinancingsTask.last.isError;
    state.isEmpty = this.financings.length === 0;

    return state;
  }
}
