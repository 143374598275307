import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class ReceivableInvoicesExportRoute extends Route {
  @service abilities;
  @service homePage;

  setupController(controller, model, transition) {
    super.setupController(...arguments);

    if (['receivable-invoices.index', 'accounting-hub.details'].includes(transition.from?.name)) {
      controller.origin = transition.from;
    } else {
      controller.origin = {
        name: 'receivable-invoices.index',
        queryParams: {},
        paramNames: [],
      };
    }
  }
}
