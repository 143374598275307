import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

export default class SupplierInvoicesExportController extends Controller {
  @service router;

  @action
  handleClose() {
    let ids = this.origin.paramNames.map(param => this.origin.params[param]);
    this.router.transitionTo(this.origin.name, ...ids, { queryParams: this.origin.queryParams });
  }
}
