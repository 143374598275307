import Controller from '@ember/controller';
import { action } from '@ember/object';
import { later } from '@ember/runloop';
import { service } from '@ember/service';

import { isTesting, macroCondition } from '@embroider/macros';

export default class DirectDebitCollectionsProcessingPaymentLinkController extends Controller {
  @service router;

  @action
  onModalClose() {
    // wait for animation
    later(
      this,
      function () {
        this.transitionToRoute('direct-debit-collections.processing');
      },
      macroCondition(isTesting()) ? 0 : 250
    );
  }
}
