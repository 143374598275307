import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { isDevelopingApp, macroCondition } from '@embroider/macros';
import * as Sentry from '@sentry/ember';
import { variation } from 'ember-launch-darkly';
import window from 'ember-window-mock';

import ENV from 'qonto/config/environment';
import { clientHubV2Namespace, registerJsURL } from 'qonto/constants/hosts';
import { ErrorInfo } from 'qonto/utils/error-info';
import { markHandledBySentry, wasHandledBySentry } from 'qonto/utils/handled-by-sentry';
import { hiringMessage } from 'qonto/utils/hiring-message';
import isBrowserCompatible from 'qonto/utils/is-browser-compatible';

const mobileFriendlyPaths = [
  'oauth',
  'invitations',
  'confirm-company-profile',
  'desktop-only',
  'settings.connect-hub',
  'deeplinks',
  'gmi-setup',
];
const desktopOnlyPaths = [
  {
    name: 'deeplinks',
    validate: queryParams => queryParams.action === 'overview.upload_documents',
  },
  {
    name: 'deeplinks',
    validate: queryParams => queryParams.action === 'company_profile.show.desktop_only',
  },
  {
    name: 'deeplinks',
    validate: queryParams => queryParams.action === 'company_profile.upload_documents',
  },
];

export default class ApplicationRoute extends Route {
  @service bannerFlashMessages;
  @service intl;
  @service localeManager;
  @service router;
  @service sessionManager;
  @service deviceManager;
  @service cookiesConsentManager;
  @service metricsManager;
  @service sentry;
  @service launchdarkly;
  @service webviewManager;
  @service theme;
  @service store;

  constructor() {
    super(...arguments);
    if (macroCondition(isDevelopingApp())) {
      document.title = 'Qonto - dev';
    } else {
      // eslint-disable-next-line no-console
      console.log('app version:', ENV.qontoAppVersion);
      hiringMessage();
    }

    this.intl.setOnMissingTranslation(this.intl.onMissingTranslation);

    Sentry.getCurrentScope().setTag('CFT', 'no-cft');
  }

  queryParams = {
    utm_source: { refreshModel: false },
    utm_medium: { refreshModel: false },
    utm_campaign: { refreshModel: false },
    utm_term: { refreshModel: false },
    utm_content: { refreshModel: false },
  };

  async setupLocale() {
    try {
      await this.localeManager.setLocale();
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);

      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error, {
          captureContext: {
            extra: {
              description:
                'Failed to load locales, loaded fallback translations and redirected to error page.',
            },
          },
        });
      }

      markHandledBySentry(error);
      throw error;
    }
  }

  async beforeModel(transition) {
    setSentryTransaction(transition);
    let { isMobile } = this.deviceManager;

    this.router.on('routeWillChange', transition => setSentryTransaction(transition));
    this.router.on('routeDidChange', transition => {
      setSentryTransaction(transition);
      if (transition.from?.name !== transition.to?.name && !isMobile) {
        document.body.setAttribute('tabindex', '-1');
        document.body.focus();
        document.body.removeAttribute('tabindex');
      }
    });

    if (!this.cookiesConsentManager.shouldConsentBeCollected()) {
      this.metricsManager.activateMetricsAdapters();
    }

    let isDesktopOnlyPaths = desktopOnlyPaths.some(({ name, validate }) => {
      return transition.to?.name === name && validate(transition.to?.queryParams);
    });

    if (isMobile && isDesktopOnlyPaths) {
      return this.router.replaceWith('desktop-only');
    }

    let isMobileFriendly = mobileFriendlyPaths.some(path => transition.to?.name.startsWith(path));

    if (isMobile && !isMobileFriendly) {
      window.location.href = registerJsURL;
      // we need to return here to prevent the following code to execute and throw errors
      // since location.href doesn't complete immediately
      transition.abort();
      return;
    }

    try {
      await Promise.all([
        this.sessionManager.setup(),
        this.setupLocale(),
        this.launchdarkly.setup(),
        this.loadBrandAssetManifest(),
      ]);

      if (variation('feature--boolean-ar-import-clients-and-items')) {
        this.store.adapterFor('client-hub').namespace = clientHubV2Namespace;
      }
    } catch (error) {
      this.localeManager.setupFallbackTranslations();
      throw error;
    }
    // force maintenance mode, emergency only
    // this.replaceWith('maintenance')

    let localStorageAppearance = this.theme.localStorageAppearance;
    this.theme.setAppearance(localStorageAppearance);
  }

  afterModel() {
    // remove static <title> tag as it's set in templates/head.hbs
    document.head.querySelector('title')?.remove();
  }

  async loadBrandAssetManifest() {
    let darkAssets = (
      await import(
        /* webpackChunkName: "asset-manifest" */
        '/brand-asset-manifest.json'
      )
    ).default;
    this.theme.darkAssets = {
      inlined: new Set(darkAssets.inlined || []),
      other: new Set(darkAssets.other || []),
    };
  }

  @action
  error(err) {
    if (wasHandledBySentry(err)) {
      return true;
    }

    let errorInfo = ErrorInfo.for(err);
    if (errorInfo.shouldSendToSentry) {
      this.sentry.captureException(err, {
        captureContext: {
          message: 'User got redirected to error page',
        },
      });
    }

    return true;
  }

  @action
  didTransition() {
    let isBrowserSupported = isBrowserCompatible();
    let { isWebview } = this.webviewManager;
    let { isMobile } = this.deviceManager;

    if (!isBrowserSupported && !isWebview && !isMobile) {
      let options = {
        browser_warning_url: this.intl.t('topbar_msg.browser_warning_url'),
        htmlSafe: true,
      };
      let message = this.intl.t('topbar_msg.browser_warning', options);

      this.bannerFlashMessages.topBannerWarning(message);
    }
  }
}

function setSentryTransaction(transition) {
  let name = transition.to?.name;
  if (name) {
    Sentry.getCurrentScope().setTransactionName(name);
  }
}
