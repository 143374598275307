import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

export default class ProductsNewController extends Controller {
  @service router;
  @service toastFlashMessages;
  @service intl;
  @service segment;

  @action
  redirectOnSuccess() {
    this.segment.track('catalog_new-item_added', {
      source: 'catalog',
    });
    this.toastFlashMessages.toastSuccess(
      this.intl.t('products.creation-form.toasts.success.product-added')
    );
    this.router.transitionTo('products.index');
  }

  @action
  onConfirmedDuplicate() {
    this.segment.track('catalog_new-item_duplicate-added', {
      source: 'catalog',
    });
  }

  @action
  onDiscardedDuplicate() {
    this.segment.track('catalog_new-item_duplicate-discarded', {
      source: 'catalog',
    });
  }

  @action
  onClose() {
    this.router.transitionTo('products.index');
  }
}
