import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';

import { ignore404 } from 'qonto/utils/ignore-error';

const MAX_PER_PAGE = 100;
const FLASH_CARD_REQUEST = 'flash_card';
const VIRTUAL_CARD_REQUEST = 'virtual_card';
const TRANSFER_REQUEST = 'transfer';
const MULTI_TRANSFER_REQUEST = 'multi_transfer';
const EXPENSE_REPORT = 'expense_report';

export default class RequestsIndexRoute extends Route {
  @service abilities;
  @service router;
  @service store;

  async beforeModel(transition) {
    let {
      queryParams: { request_id, request_type },
    } = transition.to;

    if (
      request_id &&
      [FLASH_CARD_REQUEST, VIRTUAL_CARD_REQUEST, TRANSFER_REQUEST, EXPENSE_REPORT].includes(
        request_type
      )
    ) {
      await this.replaceWithApproverRouteHighlighted({ request_id, request_type });
    } else if (MULTI_TRANSFER_REQUEST === request_type) {
      let record = await this.fetchRequestTask.perform({ request_id, request_type });

      if (!record) {
        this.router.replaceWith('tasks.pending');
        return;
      }

      let canReviewRequests = this.abilities.can('review transfer request');

      let routeName;

      if (canReviewRequests) {
        routeName =
          record.status === 'pending'
            ? 'requests.pending.multi-transfer-detail'
            : 'requests.past.multi-transfer-detail';
      } else {
        routeName = 'requests.pending.requester-multi-transfer-detail';
      }

      this.router.replaceWith(routeName, request_id);
    } else {
      this.router.replaceWith('tasks.pending');
    }
  }

  async replaceWithApproverRouteHighlighted({ request_id, request_type }) {
    let record = await this.fetchRequestTask.perform({ request_id, request_type });
    if (!record) {
      this.router.replaceWith('tasks.pending');
    }
    if (record.status === 'pending') {
      this.router.replaceWith('tasks.pending', {
        queryParams: {
          highlight: record.id,
          per_page: MAX_PER_PAGE,
        },
      });
    } else {
      this.router.replaceWith('tasks.past', {
        queryParams: {
          highlight: record.id,
          per_page: MAX_PER_PAGE,
        },
      });
    }
  }

  async replaceWithRequesterRouteHighlighted({ request_id, request_type }) {
    let record = await this.fetchRequestTask.perform({ request_id, request_type });
    if (!record) {
      this.router.replaceWith('tasks.pending');
    }

    this.router.replaceWith('tasks.pending', {
      queryParams: {
        highlight: record.id,
        per_page: MAX_PER_PAGE,
      },
    });
  }

  fetchRequestTask = dropTask(async ({ request_id, request_type }) => {
    if (request_type === FLASH_CARD_REQUEST) {
      return await this.store.findRecord('request-flash-card', request_id).catch(ignore404);
    }

    if (request_type === VIRTUAL_CARD_REQUEST) {
      return await this.store.findRecord('request-virtual-card', request_id).catch(ignore404);
    }

    if (request_type === TRANSFER_REQUEST) {
      return await this.store.findRecord('request-transfer', request_id).catch(ignore404);
    }

    if (request_type === MULTI_TRANSFER_REQUEST) {
      return await this.store.findRecord('request-multi-transfer', request_id).catch(ignore404);
    }

    if (request_type === EXPENSE_REPORT) {
      return await this.store.findRecord('request-expense-report', request_id).catch(ignore404);
    }
  });
}
