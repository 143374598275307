import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

const SENTRY_IGNORE_HTTP_STATUSES = [404];

export default class EinvoicingConsentRoute extends Route {
  @service abilities;
  @service homePage;
  @service intl;
  @service menu;
  @service organizationManager;
  @service segment;
  @service sentry;
  @service store;
  @service toastFlashMessages;

  activate() {
    this.menu.hide();
  }

  deactivate() {
    this.menu.show();
  }

  beforeModel(transition) {
    Sentry.getCurrentScope().setTag('CFT', 'invoices');

    let canWrite = this.abilities.can('write einvoicingSetting');
    let isFrench = this.organizationManager.organization.legalCountry === 'FR';

    if (!canWrite || !isFrench || !variation('feature--boolean-einvoicing-q2q')) {
      return this.homePage.replaceWithDefaultPage();
    }

    let origin = transition?.to.queryParams.origin;
    if (origin) {
      this.segment.track('Einvoicing-consent_screen_displayed', {
        origin,
      });
    }
  }

  async model() {
    let einvoicingSettings = await this.fetchFrEinvoicingSettingsTask
      .perform()
      .catch(ignoreCancelation)
      .catch(this.handleError);

    if (!einvoicingSettings?.einvoicingOnboarded || einvoicingSettings?.einvoicingConsentGiven) {
      return this.homePage.replaceWithDefaultPage();
    }

    return { einvoicingSettings };
  }

  fetchFrEinvoicingSettingsTask = dropTask(async () => {
    try {
      let frEinvoicingSettings = await this.store.findRecord(
        'einvoicing-settings',
        this.organizationManager.organization.id
      );

      return frEinvoicingSettings;
    } catch (error) {
      this.handleError(error);
    }
  });

  @action
  handleError(error) {
    if (
      ErrorInfo.for(error).shouldSendToSentry &&
      !SENTRY_IGNORE_HTTP_STATUSES.includes(error.status)
    ) {
      this.sentry.captureException(error);
    }

    this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    this.homePage.replaceWithDefaultPage();
  }
}
