import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class KycIntroRoute extends Route {
  @service intl;
  @service homePage;
  @service segment;

  getRedirectRoute(transition) {
    return transition.to.queryParams.redirectRoute || this.homePage.defaultRouteName;
  }

  model(params, transition) {
    let redirectRoute = this.getRedirectRoute(transition);

    let model = {
      title: this.intl.t('kyc.intro.title.generic'),
      trackingEventOrigin: null,
      redirectRoute,
    };

    switch (redirectRoute) {
      case 'transactions':
        model.title = this.intl.t('kyc.intro.title.transactions');
        model.trackingEventOrigin = 'top_up_flow';
        break;
      case 'mandates':
        model.title = this.intl.t('kyc.intro.title.mandates');
        model.trackingEventOrigin = 'outgoing_direct_debit_flow';
        break;
      case 'receivable-invoices':
      case 'invoice-subscriptions':
        model.title = this.intl.t('kyc.intro.title.supplier-invoices');
        model.trackingEventOrigin = 'client_invoice_flow';
        break;
      case 'members.active':
        model.title = this.intl.t('kyc.intro.title.member-invite');
        model.trackingEventOrigin = 'invite_team_flow';
        break;
      case 'guests':
        model.title = this.intl.t('kyc.intro.title.accountant-invite');
        model.trackingEventOrigin = 'invite_accountant_flow';
        break;
      case 'accounts':
        model.title = this.intl.t('kyc.intro.title.iban-account');
        model.trackingEventOrigin = 'copy_iban_flow';
        break;
      case 'f24':
        model.title = this.intl.t('kyc.intro.title.payment');
        model.trackingEventOrigin = 'f24_flow';
        break;
      case 'pagopa':
        model.title = this.intl.t('kyc.intro.title.payment');
        model.trackingEventOrigin = 'pagopa_flow';
        break;
      case 'direct-debit-collections.processing':
      case 'direct-debit-collections.completed':
      case 'direct-debit-collections.requests.index':
      case 'direct-debit-collections.index':
        model.title = this.intl.t('kyc.intro.title.sdd-collection');
        model.trackingEventOrigin = 'incoming_direct_debit_flow';
        break;
    }

    this.segment.track('submit_kyc_information_viewed', {
      origin: model.trackingEventOrigin,
    });

    return model;
  }
}
