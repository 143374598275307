import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { INVITE_FIELDS } from 'qonto/components/invitations-new-user/info/desktop-form';

export default class InvitationsShowMemberAddressRoute extends Route {
  @service userManager;
  @service router;
  @service store;

  async redirect() {
    let { invite } = await this.modelFor('invitations.show');
    let { validations: inviteValidation } = await invite.validate({ on: INVITE_FIELDS });

    if (!inviteValidation.isValid) {
      this.router.replaceWith('invitations.show.member.info');
      return;
    }

    if (!this.userManager.currentUser) {
      return this.userManager.setup();
    }
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    let { invite, isMobile } = model;
    let user = this.userManager.currentUser;

    controller.setProperties({ invite, user, isMobile });

    if (!invite.address) {
      let address = this.store.createRecord('address');
      invite.address = address;
    }
  }
}
