import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class SelfBillingShowRoute extends Route {
  @service menu;
  @service abilities;
  @service router;
  @service store;
  @service modals;
  @service sentry;
  @service toastFlashMessages;
  @service intl;

  beforeModel() {
    if (this.abilities.cannot('read self-invoice')) {
      this.router.replaceWith('supplier-invoices.index');
    }
  }

  async model({ id }) {
    let selfInvoice;
    try {
      selfInvoice = await this.store.findRecord('self-invoice', id);
    } catch (error) {
      this.error(error);
      return;
    }

    this.modal = this.modals.open(
      'self-billing/show-invoice-modal',
      {
        isFullScreenModal: true,
        hasSidebar: true,
        selfInvoice,
      },
      {
        focusTrapOptions: {
          clickOutsideDeactivates: true,
          initialFocus: false,
        },
      }
    );
  }

  error(err) {
    let errorInfo = ErrorInfo.for(err);
    if (errorInfo.shouldSendToSentry) {
      this.sentry.captureException(err);
    }
    this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    this.router.replaceWith('supplier-invoices.index');
  }

  activate() {
    this.menu.hide();
  }

  deactivate() {
    this.menu.show();
    this.modal.close();
  }
}
