import Controller from '@ember/controller';
import { service } from '@ember/service';

import { variation } from 'ember-launch-darkly';

import { ORGA_STATUS } from 'qonto/constants/organization';

export default class SettingsPricePlanController extends Controller {
  @service abilities;
  @service featuresManager;
  @service router;
  @service intl;
  @service organizationManager;

  get tabs() {
    return [
      {
        itemId: 'details',
        title: this.intl.t('menu.settings.price-plan.tabs.details'),
        trackingEvent: 'price_plan_tab',
      },
      {
        itemId: 'invoices',
        title: this.intl.t('menu.settings.price-plan.tabs.invoices'),
        trackingEvent: 'invoices_tab',
      },
      ![ORGA_STATUS.DEACTIVATED, ORGA_STATUS.SUSPENDED].includes(
        this.organizationManager.organization.status
      )
        ? {
            itemId: 'discounts',
            title: this.intl.t('menu.settings.price-plan.tabs.discounts'),
            trackingEvent: 'offers_discounts_tab',
          }
        : null,
      variation('feature--boolean-charge-stored-card') &&
      this.abilities.can('manage organization') &&
      this.featuresManager.isEnabled('storedCards')
        ? {
            itemId: 'payment-methods',
            title: this.intl.t('menu.settings.price-plan.tabs.payment-methods'),
            trackingEvent: 'payment-methods_clicked',
          }
        : null,
    ].filter(Boolean);
  }
}
