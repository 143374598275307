/* eslint-disable  ember/no-controller-access-in-routes */
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { REQUEST_EVENTS } from 'qonto/constants/listeners';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class RequestsPendingIndexRoute extends Route {
  @service abilities;
  @service router;
  @service notifierManager;
  @service store;
  @service organizationManager;
  @service sentry;

  activate() {
    let controller = this.controllerFor(this.routeName);
    let isApprover = this.abilities.can('review transfer request');
    if (isApprover) {
      this.notifierManager.on(REQUEST_EVENTS.EMPLOYEE_CREATED, controller, 'reloadData');
      this.notifierManager.on(REQUEST_EVENTS.EMPLOYEE_CANCELED, controller, 'employeeCanceled');
    } else {
      this.notifierManager.on(REQUEST_EVENTS.ADMIN_APPROVED, controller, 'adminSettled');
      this.notifierManager.on(REQUEST_EVENTS.ADMIN_DECLINED, controller, 'adminSettled');
    }
  }

  queryParams = {
    highlight: {
      refreshModel: false,
    },
    page: { refreshModel: true },
    per_page: { refreshModel: true },
    sort_by: { refreshModel: true },
  };

  beforeModel() {
    if (this.abilities.cannot('read request')) {
      return this.router.replaceWith('requests.index');
    }
  }

  async model(params) {
    let controller = this.controllerFor(this.routeName);
    let isRequester =
      this.abilities.cannot('review transfer request') &&
      this.abilities.cannot('review card request');
    controller.isRequester = isRequester;

    let request = null;

    controller.fetchDataTask
      .perform(params)
      .then(async () => {
        if (params.highlight) {
          request = controller.requests.find(({ id }) => id === params.highlight);

          controller.toggleCanShowInsufficientWarning();
          if (request) {
            await request.belongsTo('approvalWorkflowState').reload();
            controller.setAccount(request, this.organizationManager.organization.mainAccount);
            if (this.abilities.can('create transfer') && request.requestType === 'transfer') {
              return controller.confirmRequestTask.perform(request);
            }
          }
        }
      })
      .then(() => {
        return controller.fetchAllocatedBudgetTask.perform(request);
      })
      .catch(error => {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });

    if (isRequester) {
      await controller.fetchRequestsAndHandleActivateLeversModalTask.perform();
    }
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.resetQueryParams();
    }
  }

  deactivate() {
    let controller = this.controllerFor(this.routeName);
    controller.destroyCurrentTransfer();

    this.notifierManager.off(REQUEST_EVENTS.EMPLOYEE_CREATED, controller, 'reloadData');
    this.notifierManager.off(REQUEST_EVENTS.EMPLOYEE_CANCELED, controller, 'employeeCanceled');
    this.notifierManager.off(REQUEST_EVENTS.ADMIN_DECLINED, controller, 'adminSettled');
    this.notifierManager.off(REQUEST_EVENTS.ADMIN_APPROVED, controller, 'adminSettled');
  }
}
