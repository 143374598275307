import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

import { DEPOSIT_CAPITAL_STEPPER_STEP } from 'qonto/constants/organization';

// step 0
// capital.index -> capital.by-laws -> capital.address -> capital.review
//
// step 1
// capital.deposit
//
// step 2
// capital.reviewed -> capital.reviewed.contract
//
// step 3
// capital.certificate -> capital.upload
//
// step 4
// capital.release -> capital.release.contract
//
// step 5
// capital.finish

export default class CapitalRoute extends Route {
  @service capitalIncreaseManager;
  @service router;

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'onboarding');
    if (!this.capitalIncreaseManager.organization.underRegistration) {
      this.router.transitionTo('organizations.show');
    }
  }

  setupController() {
    this.capitalIncreaseManager.setCurrentStep(DEPOSIT_CAPITAL_STEPPER_STEP.SHAREHOLDERS);
  }
}
