import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { Fourthline } from '@repo/kyc';

export default class KycStartController extends Controller {
  @service router;
  @service homePage;

  fourthline = Fourthline;

  queryParams = ['redirectRoute'];

  @action
  handleOnClose() {
    let redirectRouteOnClose = this.model.redirectRouteOnClose;
    if (redirectRouteOnClose.startsWith('http')) {
      this.router.transitionTo(this.homePage.defaultRouteName);
    } else {
      this.router.transitionTo(redirectRouteOnClose);
    }
  }
}
