import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { variation } from 'ember-launch-darkly';

import { sortByKey } from 'qonto/utils/sort-by-keys';

export default class SettingsPersonalNotificationsRoute extends Route {
  @service organizationManager;

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'account-management');
  }

  async model() {
    if (variation('feature--boolean-login-improvement')) {
      return this.organizationManager.organizations.slice().sort(sortByKey('name'));
    } else {
      let organizations = await this.organizationManager.loadOrganizationAndMemberships();

      let memberships = organizations
        .filter(({ accessDisabled }) => !accessDisabled)
        .sort(sortByKey('name'))
        .reduce((sum, { memberships }) => {
          sum.push(...memberships);
          return sum;
        }, []);

      // Even if having N + 1 queries is not a scalable solution, we consider it as "good enough" for now as
      // a user would rarely have more than 3 memberships.
      return Promise.all(
        memberships.map(async membership => {
          // Loading all membership's subscriptions
          // (not to be confused with organization-susbcription)
          // to populate organization sections, where each line represents a subscription configuration.
          await membership.loadSubscriptions();
          return membership;
        })
      );
    }
  }
}
