import { action } from '@ember/object';
import { setOwner } from '@ember/owner';

export class CardNewInviteePhysicalOneDataContext {}

export default class CardNewInviteePhysicalOneFlowSetup {
  dataContext = null;

  constructor(owner) {
    setOwner(this, owner);
    this.dataContext = new CardNewInviteePhysicalOneDataContext();
  }

  @action
  onComplete() {}

  @action
  onSuccessToast() {
    // DO NOTHING
    // (need to be defined for secondary flows)
  }
}
