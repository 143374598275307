export const requestExpenseReport = {
  steps: {
    receipt: {
      componentName: 'request-expense-report/receipt',
      nextStepId: 'details',
    },
    details: {
      componentName: 'request-expense-report/details',
      nextStepId: ({ shouldShowReviewAndPayStep }) =>
        shouldShowReviewAndPayStep ? 'review-and-pay' : 'success',
    },
    'review-and-pay': {
      componentName: 'request-expense-report/review-and-pay',
    },
    success: {
      componentName: 'request-expense-report/success',
    },
  },
  options: {
    enablePersistence: true,
  },
};
