import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

export default class CompanyProfileIndexController extends Controller {
  @service intl;
  @service modals;

  // initially we want to block navigation
  // with a custom modal to ensure that user
  // submitted all the changes
  shouldBlockNavigation = true;

  // this flag indicates that modal was already triggered
  // otherwise we could get multiple same modals
  // for example when clicking on "back" button
  modalIsInUse = false;

  @action guardFn({ transition }) {
    // the `router.refresh('protected')` call in the `company-profile.index`
    // controller is causing a transition, which we do *not* want to guard against.
    // every other transition should be warned about, but this one we explicitly
    // want to let through uninterrupted, because it is caused by the app code,
    // not the user.

    if (
      !this.model.organizationChangeRequest.differsFromOriginal &&
      !this.model.ownerChangeRequest.differsFromOriginal &&
      !this.model.representativeChangeRequests.some(cr => cr.differsFromOriginal)
    ) {
      return false;
    }

    if (!transition) {
      return true;
    }

    if (this.isTransitionToCompanyProfileRoute(transition)) {
      return false;
    }

    if (this.shouldBlockNavigation && !this.modalIsInUse) {
      this.showConfirmationModal(transition);
    }

    return this.shouldBlockNavigation;
  }

  showConfirmationModal(transition) {
    this.modalIsInUse = true;

    this.modals
      .open('settings/company-profile/modal', {
        title: this.intl.t('organizations.profile.company-profile.abort-modal-kyc-kyb.title'),
        description: this.intl.t(
          'organizations.profile.company-profile.abort-modal-kyc-kyb.subtitle'
        ),
        cancel: this.intl.t('organizations.profile.company-profile.abort-modal-kyc-kyb.cta-cancel'),
        confirm: this.intl.t('organizations.profile.company-profile.abort-modal-kyc-kyb.cta'),
      })
      .then(result => {
        if (result === 'confirm') {
          this.shouldBlockNavigation = false;

          transition.retry();
        }
        this.modalIsInUse = false;
      })
      .catch(() => {
        /* ignore errors */
      });
  }

  isTransitionToCompanyProfileRoute(transition) {
    return transition.targetName.includes('settings.company-profile');
  }

  get filteredRepresentativeChangeRequests() {
    return this.model.representativeChangeRequests.filter(
      ({ corporateOfficer, ubo }) => corporateOfficer || ubo
    );
  }

  get hasMissingInfo() {
    return (
      this.model.organizationChangeRequest.hasMissingInformation ||
      this.model.ownerChangeRequest.hasMissingInformation ||
      this.filteredRepresentativeChangeRequests.some(it => it.hasMissingInformation)
    );
  }

  get shouldShowNavigationGuard() {
    let { updateProcess } = this.model;

    if (!updateProcess.isEditable) {
      return false;
    }

    if (updateProcess.hasChanges || updateProcess.legallyRequired) {
      return true;
    }

    return false;
  }
}
