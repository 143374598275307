export const directDebitCollectionClient = {
  steps: {
    'add-client': {
      componentName: 'direct-debit-collections/client/add-client',
    },
    'edit-client': {
      componentName: 'direct-debit-collections/client/edit-client',
    },
  },
  options: {
    enablePersistence: false,
  },
};
