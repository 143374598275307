import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

class WizardContext {
  @tracked legalEntity;
  @tracked stakeholder;
  @tracked stakeholders;
  @tracked saveLegalEntityTask;
  @tracked resetStakeholder;
  @tracked wizardSuccess;
}

export default class CapitalLegalEntityNewController extends Controller {
  @service intl;
  @service modals;

  queryParams = ['step'];

  @tracked step = 'info';

  get steps() {
    return [
      {
        id: 'info',
        name: this.intl.t('on-boarding.capital-deposit.shareholders.add-legal-shareholder.step'),
        componentName: 'capital-deposit/legal-entity/infos',
      },
      {
        id: 'list',
        name: this.intl.t('on-boarding.capital-deposit.shareholders.add-legal-shareholder.step'),
        componentName: 'capital-deposit/legal-entity/stakeholders-list',
        getNextStep: context => {
          let { stakeholder } = context;
          return stakeholder?.isNew ? 'representative-infos' : 'success';
        },
      },
      {
        id: 'representative-infos',
        name: this.intl.t('on-boarding.capital-deposit.shareholders.add-legal-shareholder.step'),
        componentName: 'capital-deposit/legal-entity/representative-infos',
      },
      {
        id: 'success',
        name: this.intl.t('on-boarding.capital-deposit.success'),
        componentName: 'capital-deposit/wizard-success',
      },
    ];
  }

  get hideGoBack() {
    return this.step === 'success';
  }

  get wizardContext() {
    let wizardContext = new WizardContext();
    wizardContext.legalEntity = this.model.legalEntity;
    wizardContext.stakeholders = this.model.stakeholders;
    wizardContext.saveLegalEntityTask = this.saveLegalEntityTask;
    wizardContext.resetStakeholder = this.resetStakeholder;
    wizardContext.wizardSuccess = {
      title: this.intl.t(
        'on-boarding.capital-deposit.shareholders.add-physical-shareholder.success.title'
      ),
      text: this.intl.t(
        'on-boarding.capital-deposit.shareholders.add-physical-shareholder.success.subtitle'
      ),
    };

    return wizardContext;
  }

  saveLegalEntityTask = dropTask(async ({ legalEntity, stakeholder }) => {
    if (stakeholder.isNew) {
      await stakeholder.save();

      let { proofOfIdentity } = stakeholder;

      if (proofOfIdentity) {
        proofOfIdentity.subjectId = stakeholder.id;
        proofOfIdentity.subjectType = 'Membership';
        await proofOfIdentity.save();
      }
    }

    legalEntity.stakeholder = stakeholder;
    await legalEntity.save();
  });

  @action resetStakeholder(wizardContext) {
    wizardContext.stakeholder.deleteRecord();
  }

  onCloseTask = dropTask(async () => {
    await this.modals.open('popup/destructive', {
      title: this.intl.t('on-boarding.capital-deposit.shareholders.add-shareholder.warning.title'),
      description: this.intl.t(
        'on-boarding.capital-deposit.shareholders.add-shareholder.warning.description'
      ),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('btn.delete'),
      confirmTask: this.modalConfirmTask,
    });
  });

  modalConfirmTask = dropTask(async close => {
    this.wizardContext.legalEntity.deleteRecord();
    this.transitionToRoute('capital.shareholders.index');
    await close();
  });

  @action onWizardComplete() {
    this.transitionToRoute('capital.shareholders.index');
  }
}
