import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

export default class InsuranceHubRoute extends Route {
  @service abilities;
  @service homePage;

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'connect');

    if (this.abilities.cannot('navigate insurance')) {
      this.homePage.replaceWithDefaultPage();
    }
  }
}
