import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { receivableInvoicesURL } from 'qonto/constants/hosts';

export default class ReceivableInvoicesCreditNotesShareController extends Controller {
  @service router;
  @service intl;
  @service organizationManager;

  @tracked sendTo;
  @tracked sendToHasErrors;
  @tracked emailSubject;
  @tracked emailMessage;
  @tracked copyToSelf;
  @tracked contactEmail;

  get emailMessageLines() {
    return this.emailMessage
      ? this.emailMessage.replace('\r\n', '\n').replace('\r', '\n').split('\n')
      : [];
  }

  @action
  onClose() {
    this.router.transitionTo('receivable-invoices.index');
  }

  setDefaultParams() {
    let { membership } = this.organizationManager;

    this.contactEmail = this.settings?.contactEmail || membership.email;
    this.sendTo = this.invoice.customerSnapshot?.email || '';
    this.sendToHasErrors = false;
    this.emailSubject = this.intl.t(
      'receivable-invoices.share-email.field-subject.credit-note-placeholder',
      {
        language: this.emailPreviewLanguage,
        invoice_number: this.invoice.number,
      }
    );
    this.emailMessage = this.intl.t(
      'receivable-invoices.share-email.field-message.credit-note-placeholder',
      {
        language: this.emailPreviewLanguage,
        invoice_number: this.invoice.number,
      }
    );
    this.copyToSelf = true;
  }

  get shareableLink() {
    return `${receivableInvoicesURL}/credit-notes/${this.creditNote.id}`;
  }

  get organization() {
    return this.organizationManager.organization;
  }

  get logo() {
    return this.organization.get('isDefaultAvatar') !== null &&
      this.organization.get('isDefaultAvatar') === false
      ? this.organization.picture
      : null;
  }

  get invoice() {
    return this.model.fetchTask.lastSuccessful?.value.receivableInvoice;
  }

  get settings() {
    return this.model.fetchTask.lastSuccessful?.value.settings;
  }

  get creditNote() {
    return this.model.fetchTask.lastSuccessful?.value.creditNote;
  }

  get emailPreviewLanguage() {
    return this.creditNote.locale ?? this.organization.legalCountry.toLowerCase();
  }
}
