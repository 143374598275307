export default {
  "splash-landing": "qnB",
  "container": "qnE",
  "options": "qnd",
  "physical": "qnI",
  "card-label": "qnt",
  "physical-cta-container": "qnM",
  "physical-cta": "qnP",
  "coming-soon": "qnl"
};
