import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';

export default class FinancingPartnersRoute extends Route {
  @service abilities;
  @service segment;
  @service router;
  @service menu;
  @service userManager;

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'financing');
  }

  redirect() {
    if (this.abilities.cannot('access financing')) {
      this.router.replaceWith('organizations.show');
    } else {
      this.segment.track('financing_hub_displayed');
    }
  }

  activate() {
    let financingIntroItem = safeLocalStorage.getItem(
      `financing-intro-${this.userManager.currentUser.id}`
    );
    if (!financingIntroItem && this.menu.isVisible) {
      this.menu.hide();
    }
  }

  resetController(controller, isExiting, transition) {
    if (isExiting && transition.targetName !== 'error') {
      controller.skipIntro = false;
    }
  }

  deactivate() {
    if (!this.menu.isVisible) {
      this.menu.show();
    }
  }
}
