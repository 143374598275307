import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';

export default class BudgetsListController extends Controller {
  @service intl;
  @service segment;
  @service abilities;
  @service flowLinkManager;
  @service budgetManager;

  get budgetData() {
    return this.model.filter(({ budget }) => budget.archived !== true);
  }

  get emptyStateOptions() {
    let options = {
      lottieSrc: '/lotties/budgets/empty-state.json',
    };
    if (this.abilities.can('create budget')) {
      options.title = this.intl.t('team-budgets.budgets.list.empty-state.creation.title');
      options.subtitle = this.intl.t('team-budgets.budgets.list.empty-state.creation.subtitle');
      options.button = {
        label: this.intl.t('team-budgets.budgets.list.empty-state.creation.cta'),
        callback: this.onCreateBudget,
      };
    } else {
      options.title = this.intl.t('team-budgets.budgets.list.empty-state.manager-scope.title');
      options.subtitle = this.intl.t(
        'team-budgets.budgets.list.empty-state.manager-scope.subtitle'
      );
    }

    return options;
  }

  @action onCreateBudget(event) {
    if (typeof event === 'string') {
      this.segment.track(event);
    } else {
      this.segment.track('budget-creation_empty_state_clicked');
    }
    this.flowLinkManager.transitionTo({ name: 'create-budget' });
  }

  @action onUpdateBudget(budget, trackingEvent) {
    this.segment.track(trackingEvent);

    this.flowLinkManager.transitionTo({
      name: 'edit-budget',
      stepId: 'select-exercise',
      queryParams: { id: budget.id },
    });
  }

  onArchiveBudget = dropTask(async budget => {
    await this.budgetManager.archiveBudgetTask.perform({
      budget,
      trackingEvent: 'budget_archive_clicked',
    });
  });
}
