import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { LottiePlayer } from '@repo/design-system-kit';

export default class FeatureDiscoverySuccessController extends Controller {
  lottiePlayer = LottiePlayer;

  @service router;

  @action
  handleClose() {
    this.router.transitionTo('overview.index');
  }
}
