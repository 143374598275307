import { InvalidError } from '@ember-data/adapter/error';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { LottiePlayer } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class InvitationsShowMemberAddressController extends Controller {
  lottiePlayer = LottiePlayer;

  @service toastFlashMessages;
  @service intl;
  @service organizationManager;
  @service router;
  @service segment;
  @service sentry;

  isMobile; // This property is created in the route
  animationReady = false;

  handleAcceptInviteTask = dropTask(async invite => {
    try {
      await invite.accept();
      await this.organizationManager.setupTask.perform();
      this.segment.track('join_team_personal_address_filled', {
        role: invite.get('role'),
        invitation_id: invite.get('id'),
      });

      if (this.isMobile) {
        return this.router.transitionTo('invitations.show.member.success');
      }

      return this.router.transitionTo('organizations.show', invite.get('organization'));
    } catch (error) {
      let isInvitationAlreadyAccepted = error.errors?.some(
        err => err?.detail?.code === 'invalid_invite'
      );

      if (isInvitationAlreadyAccepted) {
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.invite_already_accepted'));
        return;
      }

      if (error instanceof InvalidError) {
        if (error.errors.some(e => e.detail?.source?.pointer === '/italian_fiscal_code')) {
          throw error;
        }

        this.toastFlashMessages.toastError(this.intl.t('validations.errors.invalid_format'));
        return;
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  @action
  handleGoBack() {
    this.router.transitionTo('invitations.show.member.kyc.details');
  }
}
