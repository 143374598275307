import ProductsNewRoute from 'qonto/routes/products/new/route';

export default class ProductsEditRoute extends ProductsNewRoute {
  async model({ id }) {
    let product = await this.store.findRecord('product', id);
    return {
      product,
    };
  }
}
