import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { restartableTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { NRC_LOGIN_PROMOTION_KEY } from 'qonto/constants/nrc-payments';
import { DEFAULT_SEARCH_INCLUDES, DEFAULT_SORT_BY } from 'qonto/constants/transactions';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class OverviewRoute extends Route {
  @service abilities;
  @service homePage;
  @service sentry;
  @service store;
  @service segment;
  @service organizationManager;
  @service periodicUpdate;
  @service externalBankRecommendations;

  queryParams = {
    bankAccounts: {
      refreshModel: true,
    },
  };

  beforeModel() {
    if (
      this.abilities.can('view nrc') &&
      variation('feature--boolean-nrc-payments-promo-signed-up-users')
    ) {
      //so we can show nrc-payments specific promotional content on the login screen
      safeLocalStorage.setItem(NRC_LOGIN_PROMOTION_KEY, 1);
    }

    //remove the NRC key from localStorage if the FF is turned off
    if (
      this.abilities.can('view nrc') &&
      !variation('feature--boolean-nrc-payments-promo-signed-up-users')
    ) {
      safeLocalStorage.removeItem(NRC_LOGIN_PROMOTION_KEY);
    }

    Sentry.getCurrentScope().setTag('CFT', 'cash-flow-management');
    if (this.abilities.cannot('view overview')) {
      return this.homePage.visitDefaultPage();
    }
  }

  async model({ bankAccounts }) {
    let onboardingState;

    this.fetchTransactionsTask.perform({ bankAccounts }).catch(error => {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    });

    let updateProcess = await this.periodicUpdate.fetchUpdateProcess();

    // Can be cleaned when removing AR promo popup
    if (this.abilities.can('read accountsReceivableOnboarding')) {
      let organizationId = this.organizationManager.organization.id;

      onboardingState = await this.store
        .findRecord('accounts-receivable-onboarding', organizationId)
        .catch(() => null);
    }

    let recommendationsBundle;
    if (this.abilities.can('view external-bank-recommendation')) {
      try {
        recommendationsBundle = await this.externalBankRecommendations.fetchRecommendationsBundle();
      } catch (error) {
        let errorInfo = ErrorInfo.for(error);
        if (errorInfo.shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      }
    }

    return {
      updateProcess,
      bankAccounts,
      recommendationsBundle,
      transactionsTask: this.fetchTransactionsTask,
      onboardingState,
    };
  }

  fetchTransactionsTask = restartableTask(async ({ bankAccounts } = {}) => {
    let [property, direction] = DEFAULT_SORT_BY.split(':');

    let bankAccountFilter;
    if (bankAccounts) {
      bankAccountFilter = { bank_account_ids: bankAccounts.split(',') };
    }

    let query = {
      includes: DEFAULT_SEARCH_INCLUDES,
      sort: { property, direction },
      pagination: { page: 1, per_page: 4 },
      organization_id: this.organizationManager.organization.id,
      ...bankAccountFilter,
    };

    let { transactions } = await this.store.adapterFor('transaction').search(query);

    return { transactions };
  });

  activate() {
    this.segment.track('overview_page_loaded');
  }
}
