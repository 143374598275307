export default {
  "dropdown-icon": "Zqn",
  "dropdown": "Zqi",
  "download-button": "ZqA",
  "connections-banner": "ZqY",
  "recommendation-cards": "Zqh",
  "wrapper-with-cards": "Zqf",
  "transactions-wrapper": "ZqK",
  "separator": "ZqG",
  "with-transaction": "Zqr",
  "annual-billing-banner": "Zqb",
  "mt-132": "Zqy",
  "transactions-sidebar": "ZqU",
  "empty-state-wrapper": "Zqj",
  "wrapper-with-modular": "ZqW",
  "modular-table-container": "Zqu"
};
