import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { BadgeStatus } from '@repo/design-system-kit';
import { dropTask } from 'ember-concurrency';

export default class ChecksMandateController extends Controller {
  badgeStatus = BadgeStatus;

  @service toastFlashMessages;
  @service intl;
  @service modals;
  @service organizationManager;
  @service router;
  @service store;

  @action
  suspendMandate() {
    this.modals.open('popup/destructive', {
      title: this.intl.t('checks.mandate.suspend.modal.title'),
      description: this.intl.t('checks.mandate.suspend.modal.description'),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('checks.mandate.suspend.modal.cta'),
      confirmTask: this.confirmSuspensionTask,
    });
  }

  confirmSuspensionTask = dropTask(async close => {
    try {
      let { organization } = this.organizationManager;

      await this.store.adapterFor('check').suspendMandate(organization.id);

      this.router.transitionTo('checks.index');
      this.toastFlashMessages.toastSuccess(this.intl.t('checks.mandate.suspend.success-toast'));
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('checks.mandate.suspend.error'));
    } finally {
      close();
    }
  });
}
