import Controller from '@ember/controller';
import { service } from '@ember/service';

export default class SettingsPersonalController extends Controller {
  @service router;
  @service intl;

  get tabs() {
    return [
      {
        itemId: 'profile',
        title: this.intl.t('menu.settings.personal-profile'),
        trackingEvent: 'personal_profile_tab',
      },
      {
        itemId: 'notifications',
        title: this.intl.t('menu.settings.notifications'),
        trackingEvent: 'notifications_tab',
      },
      {
        itemId: 'security',
        title: this.intl.t('menu.settings.security'),
        trackingEvent: 'security_tab',
      },
      {
        itemId: 'language',
        title: this.intl.t('menu.settings.language'),
        trackingEvent: 'language_tab',
      },
      {
        itemId: 'appearance',
        title: this.intl.t('menu.settings.appearance'),
        trackingEvent: 'appearance_tab',
      },
    ];
  }
}
