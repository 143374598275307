import { CARD_CREATION_AB_FLOW, ORIGIN } from 'qonto/constants/cards';

export const cardNewPhysical = {
  steps: {
    'choose-card': {
      componentName: 'cards/choose-card-physical',
      nextStepId: ({
        abTestingVariation,
        isUserCardHolder,
        hasMultipleActiveAccounts,
        shouldSkipHolderStep,
        showCardCustomization,
      }) => {
        if (showCardCustomization) {
          return 'customization';
        }
        if (hasMultipleActiveAccounts) {
          return 'bank-account';
        }

        if (
          shouldSkipHolderStep &&
          isUserCardHolder &&
          abTestingVariation === CARD_CREATION_AB_FLOW.B_SKIP_ALL
        ) {
          return 'delivery-address';
        }

        return shouldSkipHolderStep ? 'options' : 'holder';
      },
    },
    customization: {
      componentName: 'cards/customization-new',
      nextStepId: ({
        abTestingVariation,
        isUserCardHolder,
        hasMultipleActiveAccounts,
        shouldSkipHolderStep,
      }) => {
        if (hasMultipleActiveAccounts) {
          return 'bank-account';
        }

        if (
          shouldSkipHolderStep &&
          isUserCardHolder &&
          abTestingVariation === CARD_CREATION_AB_FLOW.B_SKIP_ALL
        ) {
          return 'delivery-address';
        }

        return shouldSkipHolderStep ? 'options' : 'holder';
      },
    },
    'bank-account': {
      componentName: 'cards/bank-account',
      nextStepId: ({ abTestingVariation, isUserCardHolder, shouldSkipHolderStep }) => {
        if (
          shouldSkipHolderStep &&
          isUserCardHolder &&
          abTestingVariation === CARD_CREATION_AB_FLOW.B_SKIP_ALL
        ) {
          return 'delivery-address';
        }

        return shouldSkipHolderStep ? 'options' : 'holder';
      },
    },
    holder: {
      componentName: 'cards/holder',
      nextStepId: ({ abTestingVariation, isUserCardHolder }) => {
        if (isUserCardHolder && abTestingVariation === CARD_CREATION_AB_FLOW.B_SKIP_ALL) {
          return 'delivery-address';
        }

        return 'options';
      },
    },
    options: {
      componentName: 'cards/options',
      nextStepId: ({ shouldShowRestrictions }) =>
        shouldShowRestrictions ? 'restrictions' : 'payment-limits',
    },
    restrictions: {
      componentName: 'cards/restrictions',
      nextStepId: 'payment-limits',
    },
    'payment-limits': {
      componentName: 'cards/payment-limits',
      nextStepId: ({ card }) => (card.atmOption ? 'withdrawal-limits' : 'delivery-address'),
    },
    'withdrawal-limits': {
      componentName: 'cards/withdrawal-limits',
      nextStepId: 'delivery-address',
    },
    'delivery-address': {
      componentName: 'cards/delivery-address',
      nextStepId: ({ isUserCardHolder }) => (isUserCardHolder ? 'set-pin' : 'review'),
    },
    'set-pin': {
      componentName: 'cards/set-pin',
      nextStepId: 'confirm-pin',
    },
    'confirm-pin': {
      componentName: 'cards/confirm-pin',
      nextStepId: 'review',
    },
    review: {
      componentName: 'cards/review',
      nextStepId: ({ isUserCardHolder, kycSubmitted, origin }) => {
        if (isUserCardHolder && !kycSubmitted) {
          return 'kyc-intro';
        }

        return origin === ORIGIN.MEMBER_INVITATION ? 'success-invitee' : 'success';
      },
    },
    'kyc-intro': {
      componentName: 'cards/physical-card-kyc-intro',
    },
    success: {
      componentName: 'cards/success-new-physical',
    },
    'success-invitee': {
      componentName: 'cards/success-invitee-physical-one',
    },
  },
  options: {
    enablePersistence: false,
  },
};
