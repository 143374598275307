export default {
  "container": "qrs",
  "content": "qrF",
  "logos": "qrg",
  "logo": "qrD",
  "animation": "qrV",
  "title": "qrL title-2 mb-32",
  "avatar": "qrT",
  "actions": "qrz mb-32",
  "footer": "qra body-2"
};
