export const approvalWorkflows = {
  steps: {
    builder: {
      componentName: 'approval-workflows/builder',
      nextStepId: 'success',
    },
    success: {
      componentName: 'approval-workflows/success',
    },
  },
  options: {
    enablePersistence: true,
  },
};
