export const paymentActivation = {
  steps: {
    context: {
      componentName: 'payment-activation/context',
      nextStepId: ({ topUpByCardEnabled }) => {
        return topUpByCardEnabled ? 'payment-method' : 'bank-details';
      },
    },
    'payment-method': {
      componentName: 'payment-activation/payment-method',
      nextStepId: ({ chosenPaymentStep }) => {
        return chosenPaymentStep === 'card-details' ? 'select-amount' : 'bank-details';
      },
    },
    'select-amount': {
      componentName: 'payment-activation/select-amount',
      nextStepId: 'card-details',
    },
    'card-details': {
      componentName: 'payment-activation/card-details',
      nextStepId: ({ nextTransitionId }) => {
        return nextTransitionId === 'success' ? 'success' : 'bank-details';
      },
    },
    'bank-details': {
      componentName: 'payment-activation/bank-details',
      nextStepId: 'success',
    },
    success: {
      componentName: 'payment-activation/success',
    },
  },
  options: {
    enablePersistence: true,
  },
};
