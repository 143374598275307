export default {
  "container": "ZSC",
  "security-method": "ZSk",
  "icon": "ZSJ",
  "body": "ZSs",
  "status": "ZSF body-2",
  "device": "ZSg",
  "device-icon": "ZSD",
  "subtitle": "ZSV body-2",
  "current": "ZSL"
};
