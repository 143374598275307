import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class ZendeskSsoRoute extends Route {
  @service userManager;

  async model(params, transition) {
    await this.userManager.setup();

    let queryParams = transition.to.queryParams;
    let returnTo = queryParams.return_to;

    return {
      jwt: this.userManager.currentUser.zendesk.sso_jwt,
      returnTo,
    };
  }
}
