import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class extends Route {
  @service segment;

  setupController(controller) {
    super.setupController(...arguments);
    controller.hasMissingInfoError = false;
  }

  async model() {
    let model = this.modelFor('settings.company-profile');
    let deactivationData = await model.organization.getDeactivation();

    return { ...model, deactivationData };
  }
}
