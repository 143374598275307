import { action } from '@ember/object';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

import { FlowSetup } from 'qonto/routes/flows/setup/internals';

export class CardNewAdvertisingDataContext {
  constructor({ card }) {
    this.card = card;
  }
}

export default class CardNewAdvertisingFlowSetup extends FlowSetup {
  @service abilities;
  @service flow;
  @service flowLinkManager;
  @service homePage;
  @service router;

  dataContext = null;

  constructor() {
    super(...arguments);

    let { dataContext: parentFlowContext } = this.flow;

    this.dataContext = new CardNewAdvertisingDataContext({
      card: parentFlowContext?.card,
    });
  }

  beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'cards');

    if (this.abilities.cannot('read card')) {
      return this.homePage.replaceWithDefaultPage();
    }

    if (
      this.abilities.cannot('access creation flow card') ||
      this.abilities.cannot('view advertising card')
    ) {
      return this.router.replaceWith('cards');
    }

    if (!this.dataContext.card) {
      return this.flowLinkManager.transitionTo({
        name: 'card-new-digital',
        stepId: 'choose-card',
      });
    }
  }

  @action
  onAbort() {
    this.router.transitionTo('cards.index');
  }

  @action
  onComplete() {
    // DO NOTHING
    // (need to be set if the page is reloaded)
  }

  @action
  onSuccessToast() {
    // DO NOTHING
    // (need to be defined for secondary flows)
  }
}
