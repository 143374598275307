import { action } from '@ember/object';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { dropTask } from 'ember-concurrency';

import { FlowSetup } from 'qonto/routes/flows/setup/internals';

class DirectDebitCollectionClientFlowDataContext {
  client = null;
}

export default class DirectDebitCollectionClientFlowSetup extends FlowSetup {
  @service modals;
  @service intl;
  @service router;

  dataContext = null;

  constructor() {
    super(...arguments);

    this.dataContext = new DirectDebitCollectionClientFlowDataContext();
  }

  beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'transfers');
  }

  @action
  onSuccessToast() {}

  onComplete() {}

  onAbortTask = dropTask(async () => {
    let result = await this.openAbortModalTask.perform();

    if (result === 'cancel') return false;

    this.router.transitionTo('direct-debit-collections.processing');

    return true;
  });

  openAbortModalTask = dropTask(async () => {
    return await this.modals.open('popup/confirmation', {
      title: this.intl.t('flow-in-flow.modal.abort.title'),
      description: this.intl.t('flow-in-flow.modal.abort.subtitle'),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('btn.leave'),
    });
  });
}
