export default {
  "container": "qYl",
  "page-wrapper": "qYX",
  "header": "qYC",
  "back-button-wrapper": "qYk",
  "header-inner": "qYJ",
  "header-main-wrapper": "qYs",
  "header-main": "qYF",
  "header-right": "qYg",
  "content-wrapper": "qYD",
  "content": "qYV",
  "content-body": "qYL",
  "sidebar": "qYT"
};
