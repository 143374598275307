import Route from '@ember/routing/route';

export default class CashFlowCategoriesTypeCategoriesRoute extends Route {
  beforeModel(transition) {
    let { type } = this.paramsFor('cash-flow-categories.type');
    let { category_id } = transition.to.parent.params;
    let isNewCategoryRoute = transition.to.name === 'cash-flow-categories.type.categories.new';
    let isNewSubcategoryRoute =
      transition.to.name === 'cash-flow-categories.type.categories.category.new';

    let shouldRedirect = !isNewCategoryRoute && !isNewSubcategoryRoute && !category_id;

    if (shouldRedirect) {
      this.transitionTo('cash-flow-categories.type', type);
    }
  }
}
