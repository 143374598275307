export default {
  "body": "qGN",
  "members": "qGv",
  "members-results": "qGo",
  "members-list": "qGn",
  "members-title": "qGi caption-bold",
  "member": "qGA",
  "empty-illustration": "qGY",
  "empty-title": "qGh title-3",
  "empty-description": "qGf body-2",
  "invitable-members": "qGK",
  "details": "qGG",
  "pagination-footer": "qGr"
};
