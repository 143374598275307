import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';

import { ACTIVITY_TAGS } from 'qonto/constants/categories';
import cloneProperties from 'qonto/utils/clone-properties';
import { getCurrentParisDateString } from 'qonto/utils/date';

export default class RequestsTransfersMultiController extends Controller {
  @service store;
  @service uploaderManager;
  @service organizationManager;
  @service networkManager;
  @service abilities;

  queryParams = ['origin'];

  get hasTransfer() {
    return this.model.transfers.length;
  }

  copyAttributes(rmt) {
    this.model.initiator = rmt.initiator;
    this.model.requestType = rmt.requestType;
    this.model.status = rmt.status;
    this.model.totalTransfersAmount = rmt.totalTransfersAmount;
    this.model.totalTransfersAmountCurrency = rmt.totalTransfersAmountCurrency;
    this.model.totalTransfersCount = rmt.totalTransfersCount;
  }

  uploadFileTask = dropTask(async () => {
    this.model.set('didValidate', false);
    this.model.activityTag = null;
    this.model.note = null;

    try {
      let response = await this.uploaderManager.uploadMultiTransferTask.perform(this.model.file);
      let payload = await response.json();

      payload.request_multi_transfer.id = crypto.randomUUID();

      for (let transfer of payload.request_multi_transfer.transfers) {
        transfer.id = crypto.randomUUID();
      }

      this.store.pushPayload({
        requestMultiTransfer: [payload.request_multi_transfer],
      });

      let rmt = this.store.peekRecord('request-multi-transfer', payload.request_multi_transfer.id);

      this.copyAttributes(rmt);

      let transfers = await Promise.all(
        rmt.transfers.map(async transfer => {
          let newTransfer = this.store.createRecord('request-multi-transfer-transfer');
          await cloneProperties(transfer, newTransfer);
          newTransfer.selected = true;

          if (this.abilities.can('assign category')) {
            newTransfer.activityTag = ACTIVITY_TAGS.OTHER_EXPENSE;
          }

          return newTransfer;
        })
      );

      this.model.transfers = transfers;
      this.transitionToRoute('requests.transfers.multi.review');
    } catch (error) {
      if (error.status === 422) {
        let payload = await error.json();
        this.model.errors.add('document', payload.errors[0].code);
      } else {
        this.networkManager.handleNetworkError(error);
      }
    }
  });

  @action handleDeleteFile() {
    this.set('model.file', null);
  }

  @action handleToggleCheck(transfer) {
    transfer.toggleProperty('selected');
  }

  @action handleActivityTagSelection(activityTagCode) {
    this.set('model.activityTag', activityTagCode);
    this.model.transfers.forEach(transfer => {
      transfer.activityTag = activityTagCode;
    });
  }

  @action handleToggleScheduled() {
    let { scheduled } = this.model;
    if (scheduled) {
      let today = getCurrentParisDateString();
      this.set('model.operationType', 'scheduled');
      this.handleScheduleSelection(today);
    } else {
      if (this.model.minDate > this.model.scheduledDate) {
        this.handleScheduleSelection(this.model.minDate);
      }
      this.model.operationType = 'scheduled';
    }

    this.set('model.scheduled', !scheduled);
  }

  @action handleScheduleSelection(date) {
    this.set('model.scheduledDate', date);
  }

  @action handleNotifyBeneficiary(transfer, isChecked) {
    if (!isChecked) {
      transfer.set('email', null);
    }

    transfer.toggleProperty('notifyByEmail');
  }
}
