import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { DEPOSIT_CAPITAL_STEPPER_STEP } from 'qonto/constants/organization';

export default class CapitalBylawRoute extends Route {
  @service capitalIncreaseManager;

  activate() {
    this.capitalIncreaseManager.setCurrentStep(DEPOSIT_CAPITAL_STEPPER_STEP.DOCUMENTS);
  }

  async model() {
    let { notary } = await this.capitalIncreaseManager.handleGetNotary();
    return notary;
  }
}
