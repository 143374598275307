import { action } from '@ember/object';
import { setOwner } from '@ember/owner';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

import { CARD_FLOWS_TRACKING_ORIGINS, CARD_LEVELS } from 'qonto/constants/cards';
import { ErrorInfo } from 'qonto/utils/error-info';

export class CardOnboardingDataContext {
  constructor({ card }) {
    this.card = card;
  }
}

export default class CardOnboardingFlowSetup {
  @service abilities;
  @service cardsManager;
  @service toastFlashMessages;
  @service flow;
  @service intl;
  @service organizationManager;
  @service router;
  @service segment;
  @service sentry;
  @service store;

  dataContext = null;

  constructor(owner) {
    setOwner(this, owner);

    let { mainAccount, memberships } = this.organization;
    let membership = memberships[0];

    let card = this.store.createRecord('card', {
      bankAccount: mainAccount,
      organization: this.organization,
      holder: membership,
    });

    card.addIdempotencyKey();

    this.dataContext = new CardOnboardingDataContext({
      card,
    });
  }

  get organization() {
    return this.organizationManager.organization;
  }

  async beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'cards');

    let { memberships } = this.organization;
    let membership = memberships[0];
    if (membership.get('onboardedCard') || !this.abilities.can('view card onboarding')) {
      return this.router.replaceWith('organizations.show', this.organization);
    }

    let isCardsMaxLimitsError = false;
    try {
      let cardsMaxLimits = await this.cardsManager.fetchCardsMaxLimitsTask
        .perform(this.dataContext.card.holder.get('id'))
        .catch(error => {
          isCardsMaxLimitsError = true;
          throw error;
        });
      let { payment_monthly_limit_maximum, atm_monthly_limit_maximum } =
        cardsMaxLimits[CARD_LEVELS.STANDARD];
      let { estimates, hasOneCardsLeft, isEstimateLoaded } =
        await this.cardsManager.fetchCardsCostsTask.perform(this.organization.id);

      this.dataContext.card.setProperties({
        atmMonthlyLimit: atm_monthly_limit_maximum,
        paymentMonthlyLimit: payment_monthly_limit_maximum,
      });
      this.dataContext.cardOptionsPrices = await this.cardsManager.fetchCardOptionsPricesTask
        .perform()
        .catch(() => []);
      this.dataContext.isOnboardingFlow = true;
      this.dataContext.isCardUnderCreation = true;
      this.dataContext.cardsLimits = cardsMaxLimits;
      this.dataContext.estimates = estimates;
      this.dataContext.hasOneCardsLeft = hasOneCardsLeft;
      this.dataContext.isEstimateLoaded = isEstimateLoaded;
      this.dataContext.mainAccount = this.organization.mainAccount;
      this.dataContext.showCardCustomization = false;
      this.dataContext.shouldAnimateCards = true;
      this.dataContext.flowTrackingOrigin = CARD_FLOWS_TRACKING_ORIGINS.CREATION_ONBOARDING;
      this.dataContext.shouldSubmitKyc = this.organizationManager.membership.shouldSubmitKyc;
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this._cleanupCard();

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      if (isCardsMaxLimitsError && this.organization.hasTopUpFeature) {
        return this.router.replaceWith('onboarding.topup.amount', this.organization);
      }
      this.organizationManager.flagMembershipOnboarded();
      return this.router.replaceWith('organizations.show', this.organization);
    }
  }

  @action
  onAbort() {
    this.segment.track('onboarding_cards_closed', {
      origin: 'onboarding_card_choice',
    });
    this.organizationManager.flagMembershipOnboarded();
    this.router.transitionTo('organizations.show', this.organizationManager.organization);
  }

  @action
  onComplete() {
    let { organization } = this.organizationManager;

    this.router.transitionTo('transactions.index', organization);
  }

  _cleanupCard() {
    let { card } = this.dataContext;
    if (card.isNew) {
      card.unloadRecord();
    }

    this.cardsManager.fetchCardsMaxLimitsTask.cancelAll();
  }
}
