export default {
  "container": "qyS",
  "form-container": "qyc",
  "title": "qyq",
  "title-border": "qyZ",
  "tabs-container": "qyR",
  "preview-container": "qyQ",
  "email-preview-container": "qye",
  "close-button": "qyB btn btn--icon-only btn--tertiary btn--large"
};
