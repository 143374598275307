import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { restartableTask } from 'ember-concurrency';

import { CARD_STATUSES_USABLE } from 'qonto/constants/cards';
import { cardListeners } from 'qonto/constants/listeners';
import { HIDDEN_CARD_NUMBERS } from 'qonto/routes/cards/index/controller';
import { isTransitioningFromCardsRoute } from 'qonto/utils/cards/cards-routes';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class CardsMyCardsRoute extends Route {
  @service abilities;
  @service cardsManager;
  @service errors;
  @service toastFlashMessages;
  @service notifierManager;
  @service organizationManager;
  @service sentry;
  @service store;

  templateName = 'cards/generic-tabs';

  queryParams = {
    bankAccounts: {
      refreshModel: true,
    },
    card_levels: {
      refreshModel: true,
    },
    highlight: {
      refreshModel: false,
    },
    page: {
      refreshModel: true,
    },
    per_page: {
      refreshModel: true,
    },
    sort_by: {
      refreshModel: true,
    },
    query: {
      refreshModel: true,
    },
    statuses: {
      refreshModel: true,
    },
  };

  currentUserPhysicalCards = null;
  lastLimits = null;

  async model(params, transition) {
    this.fetchDataTask
      .perform(params)
      .catch(ignoreCancelation)
      .catch(error => {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
        if (this.errors.shouldFlash(error)) {
          this.toastFlashMessages.toastError(this.errors.messageForStatus(error));
        }
      });

    if (isTransitioningFromCardsRoute(transition)) {
      this.currentUserPhysicalCards =
        this.cardsManager.fetchUserActivePhysicalCardsTask.lastSuccessful?.value;
      this.lastLimits = this.cardsManager.fetchCardsMaxLimitsTask.lastSuccessful?.value;
    } else {
      if (this.abilities.can('renew card')) {
        this.currentUserPhysicalCards = await this.cardsManager.fetchUserActivePhysicalCardsTask
          .perform()
          .catch(() => {});
      }

      if (this.abilities.can('upsell any card')) {
        this.lastLimits = this.cardsManager.fetchCardsMaxLimitsTask.lastSuccessful?.value;
        if (!this.lastLimits) {
          this.lastLimits = await this.cardsManager.fetchCardsMaxLimitsTask
            .perform()
            .catch(() => {});
        }
      }
    }

    let cardsModel = this.modelFor('cards');

    return {
      cardsLimits: this.lastLimits,
      currentUserPhysicalCards: this.currentUserPhysicalCards,
      fetchCardsTask: this.fetchDataTask,
      isEmptyGlobally: cardsModel.isEmptyGlobally,
    };
  }

  activate() {
    for (let eventName of cardListeners) {
      this.notifierManager.on(eventName, this, this.fetchData);
    }
  }

  deactivate() {
    for (let eventName of cardListeners) {
      this.notifierManager.off(eventName, this, this.fetchData);
    }
  }

  fetchData() {
    let params = this.paramsFor('cards.my-cards');
    this.fetchDataTask
      .perform(params, true)
      .catch(ignoreCancelation)
      .catch(error => {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });
  }

  fetchDataTask = restartableTask(async params => {
    return await this.cardsManager.fetchTabsData({
      params,
      cardStatuses: params.statuses.length ? params.statuses : CARD_STATUSES_USABLE,
      shouldIncludeHolder: true,
    });
  });

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.cardNumbers = HIDDEN_CARD_NUMBERS;
    }
  }
}
