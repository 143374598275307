import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

export default class BeneficiariesMultiReviewConfirmController extends Controller {
  @service router;

  @action
  handleCloseConfirm(closeModal) {
    if (closeModal !== 'submit') {
      return this.router.transitionTo('beneficiaries.multi.review');
    }

    if (closeModal === 'submit') {
      return this.router.transitionTo('transfers.landing');
    }
  }
}
