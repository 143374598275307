import MembershipDetailsRoute from '../../membership-details-route';

export default class MembersMemberRoute extends MembershipDetailsRoute {
  model({ membership_id }) {
    this.getMembershipTask
      .perform({ membership_id, suggested: true })
      .catch(() => this.router.replaceWith('members.to-invite'));

    return this.getMembershipTask;
  }
}
