import { service } from '@ember/service';

import TransferInternalNewRoute from 'qonto/routes/transfers/internal/new/route';
import { copyBeneficiaryIntoTransfer } from 'qonto/utils/transfers';

export default class TransfersInternalEditRoute extends TransferInternalNewRoute {
  templateName = 'transfers/internal/new';

  @service abilities;
  @service homePage;
  @service router;
  @service store;

  model(params) {
    return this.store.findRecord('transfer', params.transfer_id);
  }

  afterModel(transfer) {
    return transfer.get('beneficiary').then(beneficiary => {
      // we don't want to copy email from beneficiary
      // as it exists on its own on Transfer in backend
      copyBeneficiaryIntoTransfer(transfer, beneficiary);
    });
  }
}
