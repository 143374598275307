import Controller from '@ember/controller';
import { service } from '@ember/service';

import { LottiePlayer } from '@repo/design-system-kit';
import { reads } from 'macro-decorators';

import { PRODUCT_ROUTE } from 'qonto/constants/insurance-hub';

export default class InsuranceHubProductErrorController extends Controller {
  lottiePlayer = LottiePlayer;

  @service router;

  @reads('router.currentRoute.params.uid') uid;

  get productRoute() {
    return PRODUCT_ROUTE;
  }
}
