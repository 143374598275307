import Controller from '@ember/controller';
import { service } from '@ember/service';

import { equal, gt } from 'macro-decorators';

import {
  CONTRACT_STATUS,
  FAQ_TRACKING_ORIGIN,
  PAY_LATER_ELIGIBILITY_STATUSES,
} from 'qonto/constants/financing';

const ROUTES = {
  IN_PROGRESS: 'financing.pay-later.in-progress',
  COMPLETED: 'financing.pay-later.completed',
};
export default class FinancingPayLaterController extends Controller {
  @service abilities;
  @service router;

  queryParams = ['origin'];

  @equal('model.eligibility', PAY_LATER_ELIGIBILITY_STATUSES.ELIGIBLE) isEligible;
  @equal('model.eligibility', PAY_LATER_ELIGIBILITY_STATUSES.NON_ELIGIBLE) isNotEligible;
  @equal('model.eligibility', PAY_LATER_ELIGIBILITY_STATUSES.NO_MORE_ELIGIBLE) isNoMoreEligible;
  @equal('model.contractStatus', CONTRACT_STATUS.SIGNED) isContractSigned;
  @equal('model.contractStatus', CONTRACT_STATUS.ON_HOLD) isContractOnHold;
  @equal('model.contractStatus', CONTRACT_STATUS.NOT_SIGNED) isContractNotSigned;
  @gt('model.payLaterTransfersCount', 0) hasUsedPayLater;

  get isNotEligibleOrNoMoreEligible() {
    return this.isNotEligible || (this.isContractNotSigned && this.isNoMoreEligible);
  }

  get hasSignedContractOnce() {
    return this.isContractSigned || this.isContractOnHold;
  }

  get canFinanceTransfer() {
    return (
      this.abilities.can('create pay later transfer in financing') &&
      this.isContractSigned &&
      !this.isAvailableCreditAmountLessThanThreshold
    );
  }

  get canReactivate() {
    return this.isEligible && this.isContractOnHold;
  }

  get faqTrackingOrigin() {
    let { isContractOnHold, isNoMoreEligible } = this;

    let origin = FAQ_TRACKING_ORIGIN.ELIGIBLE;

    if (isContractOnHold) {
      origin = FAQ_TRACKING_ORIGIN.ON_HOLD;
    }

    if (isNoMoreEligible) {
      origin = FAQ_TRACKING_ORIGIN.NO_MORE_ELIGIBLE;
    }

    return origin;
  }

  get tabs() {
    return {
      inProgress: {
        route: ROUTES.IN_PROGRESS,
        active: this.router.currentRouteName === ROUTES.IN_PROGRESS,
      },
      completed: {
        route: ROUTES.COMPLETED,
        active: this.router.currentRouteName === ROUTES.COMPLETED,
      },
    };
  }

  get isAvailableCreditAmountLessThanThreshold() {
    let { availableCreditAmount, minTransferAmount } = this.model;

    return Number(availableCreditAmount?.value) < Number(minTransferAmount?.value);
  }
}
