/* eslint-disable  ember/no-controller-access-in-routes */
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class TasksPendingIndexRoute extends Route {
  @service notifierManager;
  @service sentry;
  @service abilities;
  @service organizationManager;
  @service requestsManager;

  queryParams = {
    highlight: {
      refreshModel: false,
    },
    page: { refreshModel: true },
    per_page: { refreshModel: true },
    sort_by: { refreshModel: true },
  };

  model(params) {
    let controller = this.controllerFor(this.routeName);

    let request = null;

    controller.fetchDataTask
      .perform(params)
      .then(async () => {
        if (params.highlight) {
          request = controller.requests.find(({ id }) => id === params.highlight);
          controller.toggleCanShowInsufficientWarning();
          // If the user tries to access a non-existing request, setting the account is unnecessary.
          if (request) {
            controller.setAccount(request, this.organizationManager.organization.mainAccount);
            await request.belongsTo('approvalWorkflowState').reload();
          }

          if (
            this.abilities.can('review transfer request') &&
            request &&
            request.requestType === 'transfer'
          ) {
            return controller.confirmRequestTask.perform(request);
          }
        }
      })
      .then(() => {
        return this.requestsManager.fetchAllocatedBudgetTask.perform(request);
      })
      .catch(error => {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });

    controller.notifierCounterManager.updateCounter();
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.resetQueryParams();
    }
  }

  deactivate() {
    let controller = this.controllerFor(this.routeName);
    controller.destroyCurrentTransfer();
  }
}
