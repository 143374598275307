import Controller from '@ember/controller';
import { action, get } from '@ember/object';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import { addDocumentFileToModel, removeDocumentFileFromModel } from 'qonto/utils/handle-documents';

export default class BylawsController extends Controller {
  @service capitalIncreaseManager;
  @service router;
  @service store;

  @reads('capitalIncreaseManager.organization') organization;

  queryParams = ['step'];
  step;

  @action
  handleAddDocument(model, docType, file) {
    addDocumentFileToModel(this.store, model, docType, file);
  }

  @action
  handleRemoveDocument(model, docType, file) {
    removeDocumentFileFromModel(this.store, model, docType, file);
  }

  @action
  handleBack() {
    this.router.transitionTo('capital.shareholders.index');
  }

  @action
  transitionToUpload() {
    this.router.transitionTo({ queryParams: { step: 'upload' } });
  }

  @action
  transitionToConfirm() {
    this.router.transitionTo({ queryParams: { step: 'confirm' } });
  }

  @action
  transitionAfterBylawsUpload() {
    if (this.organization.pendingReview) {
      return this.router.transitionTo('capital.review');
    }
    this.router.transitionTo('capital.choose-poa-type');
  }

  handleSaveDocuments = dropTask(async () => {
    let document = this.organization.documents.find(item => get(item, 'docType') === 'bylaw');
    document.subjectType = 'Organization';

    // calling `.save()` on an existing document causes a `PUT` request, but
    // that endpoint is not implemented on the backend. since we don't want to
    // mutate the existing record anyway, it is sufficient for us to only call
    // `.save()` if this is a new document that the backend does not know
    // about yet.
    if (document.isNew) {
      // TODO there is no error handling here (and there never was)
      let res = await document.save();
      await this.organization.reload();
      return res;
    }
  });
}
