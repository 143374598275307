export const check = {
  steps: {
    intro: {
      componentName: 'check/intro',
      nextStepId: ({ hasValidMandate }) => (hasValidMandate ? 'emitter' : 'mandate'),
    },
    mandate: {
      componentName: 'check/mandate',
      nextStepId: 'emitter',
    },
    emitter: {
      componentName: 'check/emitter',
      nextStepId: 'details',
    },
    details: {
      componentName: 'check/details',
      nextStepId: ({ hasMultipleActiveAccounts }) =>
        hasMultipleActiveAccounts ? 'account' : 'summary',
    },
    account: {
      componentName: 'check/account',
      nextStepId: 'summary',
    },
    summary: {
      componentName: 'check/summary',
      nextStepId: ({ isFNCIgenericError }) => (isFNCIgenericError ? 'error' : 'success'),
    },
    error: {
      componentName: 'check/error',
    },
    success: {
      componentName: 'check/success',
    },
  },
  options: {
    enablePersistence: true,
  },
};
