import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

export default class CapitalShareholdersChooseTypeController extends Controller {
  @service router;
  @service segment;

  @action
  handleClose() {
    this.router.transitionTo('capital.shareholders.index');
  }

  @action
  redirectToPhysicalShareholder() {
    this.router.transitionTo('capital.shareholders.owner.physical', { id: this.model.id });
    this.trackingEvent('physical');
  }

  @action
  redirectToLegalShareholder() {
    this.router.transitionTo('capital.shareholders.owner.legal', { id: this.model.id });
    this.trackingEvent('legal');
  }

  @action
  trackingEvent(type) {
    this.segment.track('kdeposit_shareholder_type_selected', { type });
  }
}
