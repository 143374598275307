export const accountingDiscovery = {
  steps: {
    landing: {
      componentName: 'accounting-discovery/landing',
      nextStepId: 'success',
    },
    success: {
      componentName: 'member/success/invite',
    },
  },
  options: {
    enablePersistence: true,
  },
};
