import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class InvitationsShowMemberKycRoute extends Route {
  @service deviceManager;
  @service router;

  beforeModel() {
    if (!this.deviceManager.isMobile) {
      return this.router.transitionTo('invitations.show.member.info');
    }
  }
}
