export const memberEditOtherPermissions = {
  steps: {
    'other-permissions': {
      componentName: 'member/other-permissions',
      nextStepId: ({ hasAllowedBankAccountsError }) => {
        if (hasAllowedBankAccountsError) {
          return 'edit-allowed-bank-accounts-error';
        }

        return 'edit-scope-success';
      },
    },
    'edit-scope-success': {
      componentName: 'member/success/edit-scope',
    },
    'edit-allowed-bank-accounts-error': {
      componentName: 'member/error/allowed-bank-accounts',
    },
  },
  options: {
    enablePersistence: true,
  },
};
