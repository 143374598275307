import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { restartableTask, timeout } from 'ember-concurrency';

import { DIRECT_DEBIT_COLLECTION_STATUS } from 'qonto/constants/direct-debit-collections';
import { DEBOUNCE_MS } from 'qonto/constants/timers';
import { filterParams } from 'qonto/utils/compute-query-params';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class DirectDebitCollectionsCompletedRoute extends Route {
  @service organizationManager;
  @service sentry;
  @service store;

  queryParams = {
    highlight: {
      refreshModel: false,
    },
    page: { refreshModel: true },
    perPage: { refreshModel: true },
    sortBy: { refreshModel: true },
    // need to follow the naming hardcoded inside <PageHeader::Common/>
    bankAccounts: { refreshModel: true },
    statusFilter: { refreshModel: true },
    search: { refreshModel: true },
  };

  model(params) {
    let { isActivatedNotEligible, collectionLimit, guardingDepositPercentage, hasGuarding } =
      this.modelFor('direct-debit-collections');

    this.fetchCollectionsTask
      .perform(params)
      .catch(ignoreCancelation)
      .catch(error => {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });

    return {
      fetchCollectionsTask: this.fetchCollectionsTask,
      isActivatedNotEligible,
      collectionLimit,
      guardingDepositPercentage,
      hasGuarding,
    };
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.resetQueryParams();
    }
  }

  fetchCollectionsTask = restartableTask(async (queryParams = {}) => {
    let { page, perPage, sortBy, bankAccounts, statusFilter, search } = filterParams(queryParams);

    if (search) {
      await timeout(DEBOUNCE_MS);
    }

    let allStatuses = [
      DIRECT_DEBIT_COLLECTION_STATUS.COMPLETED,
      DIRECT_DEBIT_COLLECTION_STATUS.CANCELED,
      DIRECT_DEBIT_COLLECTION_STATUS.DECLINED,
      DIRECT_DEBIT_COLLECTION_STATUS.REFUNDED,
      DIRECT_DEBIT_COLLECTION_STATUS.ON_HOLD,
    ];

    let filters = {
      bank_account_ids: [bankAccounts],
      status: [...(statusFilter ? [statusFilter] : allStatuses)],
    };

    filters.search = search;

    return await this.store.query('direct-debit-collection', {
      organization_id: this.organizationManager.organization.id,
      filters,
      page,
      per_page: perPage,
      sort_by: sortBy,
    });
  });
}
