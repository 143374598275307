import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class TopupFailRoute extends Route {
  @service segment;

  beforeModel() {
    this.segment.track('onboarding_top_up_3ds_fail');
  }
}
