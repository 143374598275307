import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';
import window from 'ember-window-mock';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class EInvoiceActivationController extends Controller {
  queryParams = ['origin'];

  @service toastFlashMessages;
  @service intl;
  @service router;
  @service sentry;
  @service organizationManager;

  confirmTask = dropTask(async () => {
    try {
      await this.model.save();

      await this.organizationManager.organization.reload();

      if (this.origin === 'supplier') {
        this.router.transitionTo('supplier-invoices.index');
      } else {
        this.router.transitionTo('receivable-invoices.new');
      }
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  });

  @action
  handleClose() {
    if (this.origin) {
      if (this.origin === 'supplier') {
        this.router.transitionTo('supplier-invoices.index');
      } else {
        this.router.transitionTo('receivable-invoices.index');
      }
    } else {
      window.history.back();
    }
  }
}
