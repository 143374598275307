export const cardResetPin = {
  steps: {
    'set-pin': {
      componentName: 'cards/set-pin',
      nextStepId: 'confirm-pin',
    },
    'confirm-pin': {
      componentName: 'cards/confirm-pin',
      nextStepId: ({ pinResetInProgress }) =>
        pinResetInProgress ? 'pin-change-in-progress' : 'success',
    },
    'pin-change-in-progress': {
      componentName: 'cards/pin-change-in-progress',
    },
    success: {
      componentName: 'cards/success-reset-pin',
    },
  },
  options: {
    enablePersistence: false,
  },
};
