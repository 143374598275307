import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';

export default class CardsSettingsNicknameController extends Controller {
  @service intl;
  @service toastFlashMessages;
  @service organizationManager;
  @service segment;
  @service router;

  updateNicknameTask = dropTask(async nickname => {
    let { card } = this.model;

    try {
      await card.updateNickname(nickname);
      this.segment.track('cards_side_panel_name_edit_modal_confirm', {
        card_type: card.cardLevel,
      });
      this.toastFlashMessages.toastInfo(this.intl.t('toasts.cards.nickname-updated'));
      this.goBack();
    } catch (error) {
      card.rollbackAttributes();
      throw error;
    }
  });

  @action goBack() {
    let { queryParams } = this.model;
    return this.router.transitionTo('cards.index', this.organizationManager.organization.slug, {
      queryParams,
    });
  }
}
