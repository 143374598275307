import Controller from '@ember/controller';
import { action } from '@ember/object';
import { getOwner } from '@ember/owner';
import { service } from '@ember/service';
import { htmlSafe } from '@ember/template';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

import colorSelector from 'qonto/utils/color-selector';
import { sortByKey } from 'qonto/utils/sort-by-keys';

export default class CapitalShareholdersIndexController extends Controller {
  @service capitalIncreaseManager;
  @service toastFlashMessages;
  @service intl;
  @service modals;
  @service router;
  @service segment;
  @service sentry;

  @tracked showOwnerContributionError = false;

  get stakeholderOwner() {
    return this.model.stakeholders.filter(stakeholder => stakeholder.isCurrentUser).shift();
  }

  get legalEntityOwner() {
    if (!this.isStakeholderOwnerContributionDeclared) {
      return this.model.legalEntities.find(legalEntity => legalEntity.stakeholder.isCurrentUser);
    }
  }

  get ownerAvatarStyle() {
    let color = colorSelector(this.stakeholderOwner?.email);
    return htmlSafe(`background-color: ${color};`);
  }

  get remainingStakeholders() {
    return this.model.stakeholders
      .filter(({ isCurrentUser, shareholder }) => !isCurrentUser && shareholder)
      .sort(sortByKey('createdAt'));
  }

  get legalEntities() {
    let legalEntityOwnerId = this.legalEntityOwner?.id;

    // exclude owner declared as a legal-entity from the
    // legal-entities list (to not display it twice)
    return this.model.legalEntities.filter(le => le.id !== legalEntityOwnerId);
  }

  get totalContribution() {
    let stakeholdersContribution = this.model.stakeholders.reduce(
      (acc, curr) => acc + curr.capitalAmount,
      0
    );
    let legalEntitiesContribution = this.model.legalEntities.reduce(
      (acc, curr) => acc + curr.capitalAmount,
      0
    );

    return stakeholdersContribution + legalEntitiesContribution;
  }

  get isStakeholderOwnerContributionDeclared() {
    return Boolean(this.stakeholderOwner?.capitalAmount);
  }

  get isLegalEntityOwnerContributionDeclared() {
    return Boolean(this.legalEntityOwner?.capitalAmount);
  }

  get isOwnerContributionDeclared() {
    return (
      this.isStakeholderOwnerContributionDeclared || this.isLegalEntityOwnerContributionDeclared
    );
  }

  deleteShareholderTask = dropTask(async shareholder => {
    await shareholder.destroyRecord();
  });

  deleteContributionTask = dropTask(async stakeholder => {
    stakeholder.capitalAmount = 0;
    stakeholder.shareholder = false;
    await stakeholder.save();
  });

  updateStakeholderToRepresentativeTask = dropTask(async stakeholder => {
    stakeholder.shareholder = false;
    stakeholder.capitalAmount = 0;
    await stakeholder.save();
  });

  confirmDeleteStakeholderTask = dropTask(async stakeholder => {
    let isAlsoRepresentative = this.legalEntities
      .filter(item => item.stakeholder === stakeholder)
      .shift();

    let result = await this.modals.open('popup/destructive', {
      title: this.intl.t(
        'on-boarding.capital-deposit.shareholders.delete-shareholder.warning.title'
      ),
      description: this.intl.t(
        'on-boarding.capital-deposit.shareholders.delete-shareholder.warning.description'
      ),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('btn.delete'),
    });

    if (result === 'confirm') {
      let successMessage = this.intl.t(
        'on-boarding.capital-deposit.shareholders.shareholder-delete.success-toast'
      );
      try {
        if (isAlsoRepresentative) {
          await this.updateStakeholderToRepresentativeTask.perform(stakeholder);
        } else {
          await this.deleteShareholderTask.perform(stakeholder);
        }
        this.toastFlashMessages.toastInfo(successMessage);
      } catch (error) {
        this.sentry.captureException(error);
        stakeholder.rollbackAttributes();
        let message = this.intl.t('toasts.errors.server_error');
        this.toastFlashMessages.toastError(message);
      }
    }
  });

  confirmDeleteOwnerTask = dropTask(async owner => {
    let result = await this.modals.open('popup/destructive', {
      title: this.intl.t('on-boarding.capital-deposit.shareholders.delete-owner.warning.title'),
      description: this.intl.t(
        'on-boarding.capital-deposit.shareholders.delete-owner.warning.description'
      ),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('btn.delete'),
    });

    if (result === 'confirm') {
      try {
        await this.deleteContributionTask.perform(owner);
        let message = this.intl.t(
          'on-boarding.capital-deposit.shareholders.owner-delete.success-toast'
        );
        this.toastFlashMessages.toastInfo(message);
      } catch (error) {
        this.sentry.captureException(error);
        owner.rollbackAttributes();
        let message = this.intl.t('toasts.errors.server_error');
        this.toastFlashMessages.toastError(message);
      }
    }
  });

  confirmDeleteLegalEntityTask = dropTask(async (legalEntity, isOwner) => {
    let result = await this.modals.open('popup/destructive', {
      title: isOwner
        ? this.intl.t('on-boarding.capital-deposit.shareholders.delete-owner.warning.title')
        : this.intl.t('on-boarding.capital-deposit.shareholders.delete-shareholder.warning.title'),
      description: isOwner
        ? this.intl.t('on-boarding.capital-deposit.shareholders.delete-owner.warning.description')
        : this.intl.t(
            'on-boarding.capital-deposit.shareholders.delete-shareholder.warning.description'
          ),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('btn.delete'),
    });

    if (result === 'confirm') {
      try {
        await this.deleteShareholderTask.perform(legalEntity);

        let message = this.intl.t(
          'on-boarding.capital-deposit.shareholders.shareholder-delete.success-toast'
        );
        this.toastFlashMessages.toastInfo(message);
      } catch (error) {
        this.sentry.captureException(error);
        legalEntity.rollbackAttributes();

        let message = this.intl.t('toasts.errors.server_error');
        this.toastFlashMessages.toastError(message);
      }

      await this.refreshModelTask.perform();
    }
  });

  refreshModelTask = dropTask(async () => {
    let route = getOwner(this).lookup('route:capital.shareholders');
    await route.refresh();
  });

  @action
  createStakeholder() {
    if (!this.isOwnerContributionDeclared) {
      this.showOwnerContributionError = true;
    } else {
      this.segment.track('kdeposit_shareholders_add');
      this.router.transitionTo('capital.shareholders.physical-or-legal');
    }
  }

  @action
  editStakeholder(stakeholder) {
    this.router.transitionTo('capital.shareholders.stakeholders.edit', stakeholder.id, {
      queryParams: { step: 'info' },
    });
  }

  @action
  editOwnerContribution() {
    this.router.transitionTo('capital.shareholders.owner.physical', this.stakeholderOwner.id);
  }

  @action
  redirectsToOwnerDeclaration(stakeholder) {
    this.router.transitionTo('capital.shareholders.owner.choose-type', stakeholder.id);
  }

  confirmDeclarationTask = dropTask(async () => {
    if (!this.isOwnerContributionDeclared) {
      this.showOwnerContributionError = true;
    } else {
      try {
        await this.capitalIncreaseManager.organization.updateDepositCapitalStatus();
        this.segment.track('kdeposit_shareholders_confirmed');

        this.router.transitionTo('capital.by-laws', {
          queryParams: { step: 'upload' },
        });
      } catch (error) {
        this.sentry.captureException(error);
        let message = this.intl.t('toasts.errors.server_error');
        this.toastFlashMessages.toastError(message);
      }
    }
  });

  @action
  resetErrors() {
    this.showOwnerContributionError = false;
  }

  @action
  handleBack() {
    this.router.transitionTo('capital.index');
  }
}
