import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class ReceivableInvoicesNewPaymentRoute extends Route {
  @service router;
  @service abilities;
  @service store;

  redirect() {
    if (this.abilities.cannot('write paymentLink')) {
      this.router.transitionTo('receivable-invoices.new.index');
    }
  }

  async model() {
    let parentModel = this.modelFor('receivable-invoices.new');
    let paymentMethods = await this.store.findAll('payment-link-method');
    return {
      ...parentModel,
      paymentMethods,
    };
  }
}
