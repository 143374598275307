import MembershipDetailsRoute from '../../membership-details-route';

export default class MembersMemberRoute extends MembershipDetailsRoute {
  model({ membership_id }) {
    this.getMembershipTask
      .perform({ membership_id })
      .catch(() => this.router.replaceWith('members.active'));

    return this.getMembershipTask;
  }
}
