import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { CATEGORIES } from 'qonto/constants/connect';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class ConnectHubFeaturedRoute extends Route {
  @service router;
  @service connectManager;

  async model(params) {
    let featuredIntegrations = await this.connectManager.getFeaturedIntegrations();
    let allCategories = await this.connectManager.getAllCategories();

    let category = allCategories.find(({ slug }) => slug === CATEGORIES.BANKS);

    if (params.searchQuery) {
      await this.connectManager.searchIntegrationsTask
        .perform(params.searchQuery)
        .catch(ignoreCancelation);
    }

    let highlightedBanks = {};

    highlightedBanks = await this.connectManager.highlightBankIntegrationsTask
      .perform({
        categoryId: category?.id,
      })
      .catch(ignoreCancelation);

    highlightedBanks = {
      ...highlightedBanks,
      results: highlightedBanks?.results.slice(0, 6) ?? [],
    };

    return {
      featuredIntegrations,
      highlightedBanks,
    };
  }

  resetController(controller, isExiting) {
    super.resetController(...arguments);

    if (isExiting) {
      controller.searchQuery = undefined;
      controller.stakeholderSlug = undefined;
    }
  }
}
