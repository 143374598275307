import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class AccountNewCurrentController extends Controller {
  queryParams = ['step'];

  @service intl;

  @tracked step = 'create';

  get steps() {
    return [
      {
        id: 'create',
        componentName: 'bank-accounts/create',
        name: this.intl.t('bank_accounts.create.wizard.account_name.step'),
      },
      {
        id: 'success',
        componentName: 'bank-accounts/success',
        name: this.intl.t('bank_accounts.create.wizard.account-success.step'),
      },
    ];
  }

  @action onWizardComplete() {
    this.transitionToRoute('accounts');
  }

  @action onWizardClose() {
    this.transitionToRoute('accounts');
  }
}
