import Controller from '@ember/controller';
import { action, get } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';
import { bool } from 'macro-decorators';

import { ErrorInfo } from 'qonto/utils/error-info';
import { isTaskCancelation } from 'qonto/utils/ignore-error';
import { copyBeneficiaryIntoTransfer } from 'qonto/utils/transfers';

const RESET_PAGINATION_COUNT = 1;

export default class RequestsTransferNewController extends Controller {
  @service intl;
  @service toastFlashMessages;
  @service modals;
  @service organizationManager;
  @service segment;
  @service store;
  @service router;
  @service sentry;
  @service featuresManager;

  @tracked beneficiarySearchQuery = null;

  confirmationModal;

  @bool('model.errors.iban') hasErrorIban;

  queryParams = ['origin', 'supplierInvoiceId'];

  searchBeneficiaryTask = dropTask(async () => {
    try {
      await this._searchBeneficiary();
    } catch (error) {
      if (!isTaskCancelation(error)) {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }

        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      }
    }
  });

  async _searchBeneficiary() {
    let { organization } = this.organizationManager;
    let query = this.beneficiarySearchQuery;
    let page = this.beneficiaryPage;

    let beneficiaries = await this.store.query('beneficiary', {
      filters: { hidden: false, fx: false, sct: true },
      sort_by: 'name:asc',
      organization_id: organization.get('id'),
      per_page: 20,
      query,
      page,
    });

    beneficiaries
      .filter(({ isIBANObfuscated }) => isIBANObfuscated === true)
      .forEach(beneficiary => {
        beneficiary.iban = beneficiary.iban.replace(/x/gi, '•');
      });

    this.set('beneficiaries', beneficiaries);
  }

  @action handleGoBack() {
    this._redirect('requests.landing');
  }

  @action handleSelectRequestBeneficiary(beneficiary) {
    let transfer = this.model;
    copyBeneficiaryIntoTransfer(transfer, beneficiary, {
      forceCopy: true,
      isRequest: true,
    });
    if (beneficiary.isIBANObfuscated) {
      this.model.beneficiary = beneficiary;
    }

    this.segment.track('single_transfer_request_rolodex_used');
  }

  @action handleClearBeneficiary() {
    this.model.setProperties({
      iban: '',
      name: '',
      activityTag: 'other_expense',
    });
    this.model.beneficiary = null;
  }

  handleCheckTransferTask = dropTask(async () => {
    if (!this.model.isIBANObfuscated) {
      this.model.beneficiary = null;
    }

    await this.model.check();

    let isApprovalWorkflowFeatureEnabled = this.featuresManager.isEnabled('approvalWorkflows');
    this.segment.track('request_confirmation_started', { request_type: 'regular_sepa' });

    this.confirmationModal = this.modals.open('request/confirm-dialog', {
      model: this.model,
      saveRequestTask: this.saveRequestTask,
      showDisclaimer: !isApprovalWorkflowFeatureEnabled,
    });
  });

  handleSearchBeneficiaryTask = dropTask(async searchQuery => {
    this.beneficiarySearchQuery = searchQuery;
    this.beneficiaryPage = RESET_PAGINATION_COUNT;

    await this.searchBeneficiaryTask.perform();
  });

  handleChangeBeneficiaryPageTask = dropTask(async page => {
    this.beneficiaryPage = page;

    await this.searchBeneficiaryTask.perform();
  });

  saveRequestTask = dropTask(async (request, closeModal) => {
    await request.save();

    let savedAttachements = request.attachments.filter(item => get(item, 'isNew'));
    savedAttachements.forEach(a => a.unloadRecord());

    let message = this.intl.t('toasts.request_created');
    this.toastFlashMessages.toastInfo(message);

    this.segment.track('request_created');
    this._redirect(this.origin === 'transfers' ? 'transfers.requests' : 'requests');
    closeModal();
  });

  _redirect(url) {
    if (this.supplierInvoiceId) {
      return this.router.transitionTo('supplier-invoices');
    }

    this.router.transitionTo(url);
  }
}
