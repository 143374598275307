import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class RequestsTransfersMultiNewRoute extends Route {
  @service organizationManager;
  @service segment;

  beforeModel(transition) {
    super.beforeModel(...arguments);

    this.segment.track('request_started', {
      request_type: 'multi_transfer',
      page_url: transition?.from?.name,
      team: this.organizationManager.membership.team.get('name'),
      origin: transition?.to.queryParams.origin,
    });
  }
}
