import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class ConnectHubApplicationsRoute extends Route {
  @service router;
  @service connectManager;

  async model(params) {
    let { searchQuery, stakeholderSlug, page } = params;

    let allStakeholders = await this.connectManager.getAllStakeholders();
    let stakeholder = allStakeholders.find(({ slug }) => slug === stakeholderSlug);

    let integrations = [];

    if (searchQuery) {
      integrations = await this.connectManager.searchIntegrationsTask
        .perform(params.searchQuery)
        .catch(ignoreCancelation);
    } else {
      integrations = await this.connectManager.getIntegrationsTask
        .perform({
          stakeholderId: stakeholder?.id,
          page,
          onlyMostPopularBanks: true,
        })
        .catch(ignoreCancelation);
    }

    return {
      integrations,
      allStakeholders,
    };
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    controller.setData(model);
  }

  resetController(controller, isExiting) {
    super.resetController(...arguments);

    if (isExiting) {
      controller.searchQuery = undefined;
      controller.stakeholderSlug = undefined;
      controller.page = 1;
    }
  }
}
