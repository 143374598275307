import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class TransactionsSuggestedAttachmentsRoute extends Route {
  @service router;

  beforeModel(transition) {
    super.beforeModel(...arguments);

    this.router.replaceWith('transactions', {
      queryParams: {
        highlight: transition.to.queryParams.transactionId,
        showSuggestedAttachments: true,
      },
    });
  }
}
