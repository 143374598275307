import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

export default class AppChecksRoute extends Route {
  @service abilities;
  @service homePage;
  @service router;

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'transfers');
    if (this.abilities.cannot('view check')) {
      this.homePage.replaceWithDefaultPage();
    }
  }
}
