import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class InsuranceHubCategoriesRoute extends Route {
  @service insuranceManager;
  @service router;

  controllerName = 'insurance-hub';

  async model(params) {
    let { category_slug: categorySlug } = params;

    return await this.insuranceManager.getInsuranceProducts(categorySlug);
  }
}
