import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

export default class TopupRoute extends Route {
  @service homePage;
  @service organizationManager;
  @service router;
  @service store;
  @service userManager;

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'onboarding');
  }

  async model(params) {
    let { organization_id } = params;
    let organization = await this.store.findRecord('organization', organization_id);
    await this.organizationManager.setCurrentOrganizationAndMembership(organization);

    await this.userManager.findOrganizationMembership(organization);
    await this.organizationManager.updateBankAccounts();
    await this.organizationManager.loadOrganizationAndMemberships();

    return {};
  }

  redirect() {
    let { hasTopUpFeature, slug, kybPending } = this.organizationManager.organization;
    let { topupsStatus, shouldSubmitKyc } = this.organizationManager.membership;

    if (shouldSubmitKyc && kybPending) {
      return this.router.replaceWith('kyc.intro', slug, {
        queryParams: {
          redirectRoute: 'transactions',
        },
      });
    }

    if (!hasTopUpFeature || topupsStatus === 'forbidden' || topupsStatus === null) {
      return this.homePage.visitDefaultPage(slug);
    }
  }
}
