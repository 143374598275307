import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class F24V2ListUpcomingRoute extends Route {
  @service store;

  queryParams = {
    page: { refreshModel: true },
    perPage: { refreshModel: true },
    sortBy: { refreshModel: true },
  };

  model(params) {
    let { page, perPage, sortBy } = params;

    let queryParams = { page, per_page: perPage, sort_by: sortBy };

    return this.store.query('f24-order', {
      status_group: 'upcoming',
      ...queryParams,
    });
  }

  @action
  error(error) {
    error.retryRouteName = this.routeName;
    return true;
  }
}
