import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

export default class FeatureDiscoveryInterestController extends Controller {
  @service router;
  @service segment;

  @action
  handleClick() {
    this.segment.track('cash-flow_forecast_join-beta', {
      price: this.model.optionKey,
    });
    this.router.transitionTo('feature-discovery.success', {
      queryParams: { flow: this.model.flow },
    });
  }

  @action
  handleClose() {
    this.router.transitionTo('overview.index');
  }
}
