export default {
  "page-container": "qKz",
  "form": "qKa",
  "close-button": "qKH btn btn--icon-only btn--tertiary btn--large",
  "preview": "qKO",
  "preview-padding": "qKm",
  "title": "qKp",
  "header": "qKx",
  "without-tabs": "qKw",
  "form-section": "qKN",
  "form-footer": "qKv",
  "disclaimer-text": "qKo"
};
