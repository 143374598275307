import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { restartableTask } from 'ember-concurrency';

import { REWARDS_STATUS } from 'qonto/constants/referral';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class SettingsReferralsIndexController extends Controller {
  @service('sentry') sentry;
  @service organizationManager;
  @service store;
  @service abilities;
  @service segment;

  queryParams = ['status', 'page', 'reward_status'];

  @tracked page = 1;
  @tracked status = 'rewarded';
  @tracked rewardStatus = REWARDS_STATUS.EARNED;
  @tracked hasRewardsError = false;

  @tracked referrals = [];
  @tracked meta = {};

  get isTieredReferral() {
    return this.abilities.can('tiered referral referral');
  }

  @action
  setPage(page) {
    this.page = page;
    this.fetchData();
  }

  @action
  setStatus(status) {
    if (this.status !== status) {
      this.status = status;
      this.page = 1;
    }
    this.fetchData();
  }

  @action
  setRewardStatus(rewardStatus) {
    if (this.rewardStatus !== rewardStatus) {
      this.rewardStatus = rewardStatus;
      this.page = 1;
    }
    this.segment.track('referral_status_tab_clicked', {
      status: this.rewardStatus,
    });
    this.fetchData();
  }

  fetchDataTask = restartableTask(async params => {
    let organization_id = this.organizationManager.organization.id;
    let queryParams = { organization_id, ...params };
    let referrals = await this.store.query('referral', queryParams);

    this.referrals = referrals;
    this.meta = referrals.meta;
  });

  @action
  fetchData() {
    if (this.isTieredReferral) {
      this.model.fetchFilteredTieredReferralsTask
        .perform({
          page: this.page,
          rewardStatus: this.rewardStatus,
        })
        .catch(ignoreCancelation)
        .catch(error => {
          this.hasRewardsError = true;
          if (ErrorInfo.for(error).shouldSendToSentry) {
            this.sentry.captureException(error);
          }
        });
    } else {
      this.fetchDataTask
        .perform({
          page: this.page,
          status: this.status,
        })
        .catch(ignoreCancelation);
    }
  }
}
