export default {
  "main-container": "qnV",
  "main": "qnL",
  "main-wrapper": "qnT",
  "close-button": "qnz",
  "active-block": "qna",
  "title": "qnH",
  "list-options": "qnO",
  "card": "qnm",
  "card-container": "qnp",
  "card-image": "qnx",
  "card-tiles": "qnw"
};
