export const cardReorder = {
  steps: {
    review: {
      componentName: 'cards/review-reorder',
      nextStepId: 'success',
    },
    success: {
      componentName: 'cards/success-reorder',
    },
  },
  options: {
    enablePersistence: false,
  },
};
