export default {
  "details": "qKj",
  "header": "qKW",
  "avatar": "qKu mr-16",
  "header-beneficiary": "qGS",
  "header-beneficiary-activity": "qGc",
  "header-beneficiary-details": "qGq",
  "header-beneficiary-details-recurring": "qGZ",
  "infos": "qGR",
  "infos-title": "qGQ",
  "infos-empty": "qGe",
  "infos-list": "qGB",
  "infos-list-item": "qGE",
  "infos-list-item-left": "qGd",
  "infos-list-item-right": "qGI",
  "infos-list-item-attachment": "qGt",
  "infos-transactions": "qGM",
  "actions": "qGP"
};
