export default {
  "wrapper": "qug",
  "integrations": "quD",
  "description": "quV body-2",
  "api": "quL flex flex-column",
  "api-container": "quT flex large",
  "api-input": "quz large",
  "api-btn": "qua",
  "key-input": "quH"
};
