import Route from '@ember/routing/route';
import { service } from '@ember/service';

import window from 'ember-window-mock';

import { companyCreationJsURL, registerJsURL, registerPartnersJsURL } from 'qonto/constants/hosts';

export default class ProtectedIndexRoute extends Route {
  @service toastFlashMessages;
  @service intl;
  @service router;
  @service sentry;
  @service sessionManager;
  @service abilities;
  @service organizationManager;

  async afterModel() {
    await this.organizationManager.loadRegisteringOrganizations();
  }

  redirect() {
    let organization = this.organizationManager.getDefaultOrganization();

    if (this.abilities.can('access accounting-hub')) {
      this.router.transitionTo('accounting-hub');
    } else if (organization && !organization.get('accessDisabled')) {
      this.router.replaceWith('organizations.show', organization);
    } else if (this.organizationManager.partnerOrganizations?.length) {
      window.location.replace(registerPartnersJsURL);
    } else if (this.organizationManager.companyCreationOrganizations?.length) {
      window.location.replace(companyCreationJsURL);
    } else if (organization?.get('accessDisabled')) {
      let message = this.intl.t('sign_in.access_disabled');
      this.toastFlashMessages.toastError(message, 'status_invalid');

      return this.sessionManager.invalidate();
    } else {
      // We still have users who have been created without organization so we want to redirect them to the register
      window.location.replace(registerJsURL);
    }
  }
}
