import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { CONTRACT_STATUS } from 'qonto/constants/financing';

export default class FinancingPayLaterIndexRoute extends Route {
  @service router;
  @service financing;

  async beforeModel() {
    let response = await this.financing.getLastPayLaterEligibility();

    if (
      response.contractStatus === CONTRACT_STATUS.SIGNED ||
      response.contractStatus === CONTRACT_STATUS.ON_HOLD
    ) {
      this.router.replaceWith('financing.pay-later.in-progress');
    }
  }
}
