import Route from '@ember/routing/route';

import * as Sentry from '@sentry/ember';

export default class SettingsPersonalLanguageRoute extends Route {
  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'account-management');
  }

  resetController({ user }) {
    if (user.hasDirtyAttributes) {
      user.rollbackAttributes();
    }
  }
}
