import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class OverviewIndexRoute extends Route {
  @service productDiscovery;
  @service router;
  @service periodicUpdate;

  beforeModel() {
    if (this.productDiscovery.isProductDiscoverySystemFeatureEnabled) {
      this.router.transitionTo('overview.new');
    }
  }

  model() {
    let { bankAccounts, transactionsTask, updateProcess } = this.modelFor('overview');
    return {
      updateProcess,
      bankAccounts,
      transactionsTask,
    };
  }

  afterModel({ updateProcess }) {
    this.periodicUpdate.setupPeriodicPopup({ updateProcess });
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.set('showPeriodicUpdateBanner', true);
    }
  }
}
