import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { restartableTask, timeout } from 'ember-concurrency';

import { DEBOUNCE_MS } from 'qonto/constants/timers';
import { isTransitioningFromCardsRoute } from 'qonto/utils/cards/cards-routes';
import { filterParams } from 'qonto/utils/compute-query-params';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class CardsRequestsRoute extends Route {
  @service abilities;
  @service cardsManager;
  @service store;
  @service organizationManager;
  @service sentry;

  queryParams = {
    highlight: { refreshModel: false },
    page: { refreshModel: true },
    per_page: { refreshModel: true },
    sort_by: { refreshModel: true },
  };

  get isApprover() {
    return this.abilities.can('review card request');
  }

  get isRequester() {
    return this.abilities.cannot('review card request');
  }

  async model(params, transition) {
    this.fetchRequestsTask
      .perform(params, false)
      .catch(ignoreCancelation)
      .catch(error => {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });

    if (isTransitioningFromCardsRoute(transition)) {
      this.currentUserPhysicalCards =
        this.cardsManager.fetchUserActivePhysicalCardsTask.lastSuccessful?.value;
      this.lastLimits = this.cardsManager.fetchCardsMaxLimitsTask.lastSuccessful?.value;
    } else {
      if (this.abilities.can('renew card')) {
        this.currentUserPhysicalCards = await this.cardsManager.fetchUserActivePhysicalCardsTask
          .perform()
          .catch(() => {});
      }

      if (this.abilities.can('upsell any card')) {
        this.lastLimits = this.cardsManager.fetchCardsMaxLimitsTask.lastSuccessful?.value;
        if (!this.lastLimits) {
          this.lastLimits = await this.cardsManager.fetchCardsMaxLimitsTask
            .perform()
            .catch(() => {});
        }
      }
    }

    let cardsModel = this.modelFor('cards');

    return {
      currentUserPhysicalCards: this.currentUserPhysicalCards,
      fetchRequestsTask: this.fetchRequestsTask,
      isCardsEmptyGlobally: cardsModel.isCardsEmptyGlobally,
      isApprover: this.isApprover,
      isRequester: this.isRequester,
      isEmptyGlobally: cardsModel.isEmptyGlobally,
    };
  }

  fetchRequestsTask = restartableTask(async (params = {}) => {
    await timeout(DEBOUNCE_MS);

    let { organization } = this.organizationManager;
    let { page, per_page, sort_by } = filterParams(params);

    return await this.store.query('request', {
      ...(this.isApprover && { status: ['pending'] }),
      includes: ['memberships'],
      request_type: ['flash_card', 'virtual_card'],
      organization_id: organization.id,
      page,
      per_page,
      sort_by,
    });
  });
}
