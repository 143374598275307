export const payLaterEarlyRepayment = {
  steps: {
    'option-selection': {
      componentName: 'financing/pay-later/early-repayment/option-selection',
      nextStepId: 'overview',
    },
    overview: {
      componentName: 'financing/pay-later/early-repayment/overview',
      nextStepId: 'settlement',
    },
    settlement: {
      componentName: 'financing/pay-later/early-repayment/settlement',
    },
  },
  options: {
    enablePersistence: false,
  },
};
