import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { EmptyStatesDivider } from '@repo/domain-kit/pricing';
import { dropTask } from 'ember-concurrency';

import { getEmptyStateConfig } from 'qonto/constants/empty-states/products';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class ProductsIndexController extends Controller {
  emptyStatesDivider = EmptyStatesDivider;

  @service intl;
  @service router;
  @service segment;
  @service emptyStates;
  @service modals;
  @service networkManager;
  @service sentry;
  @service toastFlashMessages;
  @service productsCatalog;
  @service flowLinkManager;

  queryParams = ['page', 'perPage', 'sortBy', 'query'];

  @tracked page = 1;
  @tracked perPage = 25;
  @tracked sortBy = 'title:asc,unit_price.value:asc';
  @tracked query = '';

  get currentParams() {
    return {
      page: this.page,
      perPage: this.perPage,
      sortBy: this.sortBy,
      query: this.query,
    };
  }

  get products() {
    return this.model.productsTask.lastSuccessful?.value.products || [];
  }

  get selectedItemsCount() {
    return this.products.filter(product => product.isSelected).length;
  }

  get areAllSelected() {
    return this.selectedItemsCount === this.products.length && this.products.length;
  }

  get areSomeSelected() {
    return this.selectedItemsCount > 0;
  }

  get hasNoResult() {
    return (
      this.query && !this.products.length && !this.isError && !this.model.productsTask.isRunning
    );
  }

  get emptyStateOptions() {
    let options = this.emptyStates.getEmptyStateOptions({
      isOrgEligibleForFeature: true,
      isEmptyGlobally: true,
      isEmptyLocally: true,
      hasActiveFilterOrSearch: false,
      config: getEmptyStateConfig(this.intl, {
        ctaCallback: this.emptyStateAddCTA,
        secondaryCtaCallback: this.emptyStateSecondaryCTA,
      }),
    });
    return options;
  }

  get displayEmptyState() {
    return (
      !this.query && !this.model.productsTask.isRunning && !this.products.length && !this.isError
    );
  }

  get isError() {
    return this.model.productsTask.last.isError;
  }

  @action emptyStateAddCTA() {
    this.segment.track('catalog_add-new_clicked', { source: 'empty-state' });
    this.router.transitionTo('products.new');
  }

  @action emptyStateSecondaryCTA() {
    this.segment.track('items-import_started', { source: 'empty-state' });

    this.flowLinkManager.transitionTo({
      name: 'invoice-products-import',
      stepId: 'upload',
    });
  }

  @action changePage(page) {
    this.page = page;
  }

  @action changePerPage(perPage) {
    this.perPage = perPage;
  }

  @action handleSortBy(sortDefinition) {
    if (['title', 'title:asc'].includes(sortDefinition)) {
      this.sortBy = `${sortDefinition},unit_price.value:asc`;
    } else {
      this.sortBy = sortDefinition;
    }
  }

  onSearch = query => {
    this.query = query;
  };

  @action onEditProduct(item) {
    this.router.transitionTo('products.edit', item.id);
  }

  @action onDeleteProduct(product) {
    this.segment.track('catalog_item_delete', { type: 'single_action' });
    this.modals.open('popup/destructive', {
      title: this.intl.t('products.list.delete-modal.title', { count: 1 }),
      description: this.intl.t('products.list.delete-modal.description'),
      cancel: this.intl.t('products.list.delete-modal.cta.cancel'),
      confirm: this.intl.t('products.list.delete-modal.cta.delete', { count: 1 }),
      confirmTask: this.deleteProductTask,
      product,
    });
  }

  @action onRowClick(item) {
    this.router.transitionTo('products.edit', item.id);
  }

  @action onSelectProduct(item) {
    item.isSelected = !item.isSelected;
  }

  @action toggleSelectAll() {
    let areAllSelected = this.areAllSelected;
    this.products.forEach(item => {
      item.isSelected = !areAllSelected;
    });
  }

  @action onBulkDelete() {
    this.segment.track('catalog_item_delete', { type: 'bulk_action' });
    this.modals.open('popup/destructive', {
      title: this.intl.t('products.list.delete-modal.title', { count: this.selectedItemsCount }),
      description:
        this.selectedItemsCount > 1
          ? this.intl.t('products.list.delete-modal.description-plural')
          : this.intl.t('products.list.delete-modal.description'),
      cancel: this.intl.t('products.list.delete-modal.cta.cancel'),
      confirm: this.intl.t('products.list.delete-modal.cta.delete', {
        count: this.selectedItemsCount,
      }),
      confirmTask: this.deleteProductsTask,
    });
  }

  @action
  onError(error) {
    this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
    if (ErrorInfo.for(error).shouldSendToSentry) {
      this.sentry.captureException(error);
    }
  }

  deleteProductTask = dropTask(async (close, { product }) => {
    try {
      await product.destroyRecord();
      this.toastFlashMessages.toastSuccess(
        this.intl.t('products.list.toasts.success.product-deleted', { count: 1 })
      );
    } catch {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
    } finally {
      close();
    }
  });

  deleteProductsTask = dropTask(async close => {
    let productIds = this.products.filter(item => item.isSelected).map(item => item.id);
    try {
      await this.productsCatalog.bulkDeleteProductsTask.perform(productIds, this.onDeleteSuccess);
      this.toastFlashMessages.toastSuccess(
        this.intl.t('products.list.toasts.success.product-deleted', { count: productIds.length })
      );
    } catch (error) {
      this.onError(error);
    }

    close();

    await this.model.productsTask
      .perform(this.currentParams)
      .catch(ignoreCancelation)
      .catch(this.onError);
  });
}
