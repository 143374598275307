export default {
  "supplier-invoices-page-header": "ZcK",
  "filters-container": "ZcG",
  "search-bar": "Zcr",
  "filters": "Zcb",
  "bulk-actions": "Zcy",
  "item-count": "ZcU",
  "higher-index": "Zcj",
  "title-wrapper": "ZcW",
  "title": "Zcu",
  "subtitle": "ZqS",
  "table-container": "Zqc",
  "file-dropzone": "Zqq",
  "dropzone-visible": "ZqZ",
  "header-actions": "ZqR",
  "header-wrapper": "ZqQ",
  "disclaimer-wrapper": "Zqe"
};
