import { action } from '@ember/object';
import { setOwner } from '@ember/owner';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { sortByKey } from 'qonto/utils/sort-by-keys';

export default class WizardContext {
  constructor(owner) {
    setOwner(this, owner);
  }

  @service store;
  @service organizationManager;
  @service intl;

  information = null;

  @tracked procedureId = null;
  @tracked amount = null;
  @tracked interestEarned = null;
  @tracked fundOriginOther = null;
  @tracked sourceOfFundsOption = null;
  @tracked durationOption = null;

  @tracked allowFATCA = false;
  @tracked allowBannedCountries = false;
  @tracked allowProSecrecy = false;
  @tracked hasAgreedTermsConditions = false;
  @tracked memberships = [];

  @tracked selectedCountryOptions = {};
  @tracked tins = {};
  @tracked displayTinErrors = false;
  @tracked displayCountryErrors = {};

  get maturity() {
    return this.durationOption?.code;
  }

  get sourceOfFunds() {
    return this.sourceOfFundsOption?.code;
  }

  async loadMemberships() {
    let { membershipIdsFiscalResidencesToFill: ids } = this.information;
    if (ids) {
      this.memberships = this.store.peekAll('membership').filter(({ id }) => ids.includes(id));
      if (this.memberships.length !== ids.length) {
        this.memberships = await this.store.query('membership', {
          filters: { ids },
          organization_id: this.organizationManager.organization.id,
        });
      }

      this.memberships = [...this.memberships].sort(sortByKey('fullName'));
    } else {
      this.memberships = [];
    }
  }

  async createProcedure() {
    let params = this.createProcedureParams;
    if (!this.isEqual(params, this.lastCreateProcedureParams)) {
      this.invalidateTermsConditions();
      let { procedureId, expectedGain } = await this.store
        .adapterFor('savings-account')
        .createProcedure(params);
      this.procedureId = procedureId;
      this.interestEarned = expectedGain;
      this.lastCreateProcedureParams = params;
    }
  }

  invalidateTermsConditions() {
    this.hasAgreedTermsConditions = false;
  }

  get createProcedureParams() {
    return {
      amount: this.amount,
      fundOrigin: this.sourceOfFunds,
      fundOriginOther: this.fundOriginOther,
      maturity: this.maturity,
    };
  }

  isEqual(a, b) {
    return JSON.stringify(a) === JSON.stringify(b);
  }

  updateAmount(value) {
    this.amount = value;
  }

  updateSourceOfFundsOption(value) {
    this.sourceOfFundsOption = value;
  }

  updateFundOriginOther(value) {
    this.fundOriginOther = value;
  }

  updateDurationOption(value) {
    this.durationOption = value;
  }

  toggleAllowFATCA() {
    this.allowFATCA = !this.allowFATCA;
  }

  toggleAllowBannedCountries() {
    this.allowBannedCountries = !this.allowBannedCountries;
  }

  toggleAllowProSecrecy() {
    this.allowProSecrecy = !this.allowProSecrecy;
  }

  toggleHasAgreedTermsConditions() {
    this.hasAgreedTermsConditions = !this.hasAgreedTermsConditions;
  }

  get membershipFiscalResidences() {
    return Object.entries(this.selectedCountryOptions).reduce(
      (acc, [membership_id, countryOptions]) => {
        countryOptions.forEach(({ key }) => {
          acc.push({
            membership_id,
            country: key,
            tin_code: this.tins[membership_id]?.[key] || '',
          });
        });
        return acc;
      },
      []
    );
  }

  displayUboErrors() {
    this.shouldDisplayCountryErrorsForAllMemberships();
    this.displayTinErrors = true;
  }

  shouldDisplayCountryErrorsFor(membershipId) {
    this.displayCountryErrors = { ...this.displayCountryErrors, [membershipId]: true };
  }

  shouldDisplayCountryErrorsForAllMemberships() {
    this.displayCountryErrors = this.memberships.reduce((acc, { id }) => {
      acc[id] = true;
      return acc;
    }, {});
  }

  get countryErrorsToDisplay() {
    return Object.entries(this.displayCountryErrors).reduce(
      (acc, [membershipId, shouldDisplay]) => {
        if (shouldDisplay) {
          acc[membershipId] = this.countryErrors[membershipId];
        }
        return acc;
      },
      {}
    );
  }

  get countryErrors() {
    return this.memberships.reduce((acc, { id }) => {
      if (!this.selectedCountryOptions[id]?.length) {
        acc[id] = this.intl.t('accounts.savings.creation.fatca-crs.error.select-country');
      } else if (this.selectedCountryOptions[id]?.find(({ key }) => key === 'US')) {
        acc[id] = this.intl.t('accounts.savings.creation.fatca-crs.error.ubo-usa');
      }
      return acc;
    }, {});
  }

  get tinErrorsToDisplay() {
    if (this.displayTinErrors) {
      return this.tinErrors;
    }
  }

  get tinErrors() {
    return Object.entries(this.selectedCountryOptions).reduce(
      (acc, [membershipId, countryOptions]) => {
        countryOptions.forEach(({ key }) => {
          if (key !== 'FR' && !this.tins[membershipId]?.[key]) {
            acc[membershipId] = {
              ...acc[membershipId],
              [key]: this.intl.t('accounts.savings.creation.fatca-crs.error.tin-code'),
            };
          }
        });
        return acc;
      },
      {}
    );
  }

  @action
  updateSelectedCountryOptions(membershipId, options) {
    this.shouldDisplayCountryErrorsFor(membershipId);
    this.displayTinErrors = false;
    this.selectedCountryOptions = {
      ...this.selectedCountryOptions,
      [membershipId]: options,
    };

    // remove tin if country deselected
    if (this.tins[membershipId]) {
      this.tins = {
        ...this.tins,
        [membershipId]: options.reduce((acc, { key }) => {
          let tin = this.tins[membershipId][key];
          if (tin) acc[key] = tin;
          return acc;
        }, {}),
      };
    }
  }

  @action
  updateTin(membershipId, countryCode, tin) {
    this.tins = {
      ...this.tins,
      [membershipId]: {
        ...this.tins[membershipId],
        [countryCode]: tin,
      },
    };
  }
}
