export const editSepaTransfer = {
  steps: {
    details: {
      componentName: 'transfers/sepa/new/details',
      nextStepId: 'additional-settings',
    },
    'additional-settings': {
      componentName: 'transfers/sepa/new/additional-settings',
      nextStepId: 'summary',
    },
    summary: {
      componentName: 'transfers/sepa/new/summary',
      nextStepId: 'settlement',
    },
    settlement: {
      componentName: 'transfers/sepa/new/settlement',
    },
  },
  options: {
    enablePersistence: false,
  },
};
