import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class ConnectionsIndexRoute extends Route {
  @service abilities;
  @service router;

  beforeModel() {
    if (this.abilities.can('view oauth')) {
      this.router.transitionTo('settings.connections.apps');
    } else if (this.abilities.can('view connections external-account')) {
      this.router.transitionTo('settings.connections.banks');
    } else {
      this.router.transitionTo('application');
    }
  }
}
