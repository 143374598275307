/* eslint-disable @qonto/no-import-roles-constants */
import Controller from '@ember/controller';
import { service } from '@ember/service';

import { ROLES } from 'qonto/constants/membership';

export default class RequestsUpgradePlanController extends Controller {
  @service intl;
  @service organizationManager;

  get upgradePlanOptions() {
    let { role } = this.organizationManager.membership;
    let isOwnerOrAdminOrManager = [ROLES.ADMIN, ROLES.OWNER, ROLES.MANAGER].includes(role);

    if (isOwnerOrAdminOrManager) {
      return {
        title: this.intl.t('upsell.empty-state.requests.title'),
        description: this.intl.t('upsell.empty-state.requests.subtitle'),
        imageType: 'requestsOwnerAdmin',
      };
    }

    if (role === ROLES.EMPLOYEE) {
      return {
        title: this.intl.t('upsell.empty-state.requests.employee.no-cta.title'),
        description: this.intl.t('upsell.empty-state.requests.employee.no-cta.subtitle'),
        imageType: 'requestsEmployee',
      };
    }

    return {
      title: this.intl.t('upsell.empty-state.requests.accountant.no-cta.title'),
      description: this.intl.t('upsell.empty-state.requests.accountant.no-cta.subtitle'),
      imageType: 'requestsAccountant',
    };
  }
}
