import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

export default class ConnectHubRoute extends Route {
  @service abilities;
  @service organizationManager;
  @service router;
  @service deviceManager;
  @service webviewManager;
  @service zendeskWidget;

  // For deeplinks
  queryParams = {
    documentId: {
      refreshModel: true,
    },
  };

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'connect');

    let isMobileorWebview = this.deviceManager.isMobile || this.webviewManager.isWebview;

    if (isMobileorWebview) this.zendeskWidget.unmount();
  }

  redirect() {
    let organization = this.organizationManager.getDefaultOrganization();

    if (this.abilities.cannot('view connect')) {
      this.router.replaceWith('overview', organization);
    }
  }
}
