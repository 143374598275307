import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';

import { FlowSetup } from 'qonto/routes/flows/setup/internals';

export default class NrcPaymentFlowSetup extends FlowSetup {
  @service store;
  @service router;
  @service modals;
  @service intl;

  onAbortTask = dropTask(async (_, { id: stepId }) => {
    if (stepId === 'modelo-select') {
      this.router.transitionTo('agencia-tributaria.list.processing');
      return true;
    }

    let modalResult = await this.modals.open('popup/confirmation', {
      title: this.intl.t('aeat.pay.form-details.popups.exit.title'),
      description: this.intl.t('aeat.pay.form-details.popups.exit.subtitle'),
      cancel: this.intl.t('btn.cancel'),
      confirm: this.intl.t('aeat.pay.form-details.popups.exit.btn.leave'),
    });

    if (modalResult === 'confirm') {
      this.router.transitionTo('agencia-tributaria.list.processing');
      return true;
    }

    return false;
  });

  onComplete() {
    this.router.transitionTo('agencia-tributaria.list.processing');
  }
}
