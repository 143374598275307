import { action } from '@ember/object';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

import { CHECKOUT_REDIRECTION } from 'qonto/constants/payment-activation';
import { FlowSetup } from 'qonto/routes/flows/setup/internals';
import { ErrorInfo } from 'qonto/utils/error-info';

class PaymentActivationFlowDataContext {
  // eslint-disable-next-line no-useless-constructor
  constructor() {}
}

export default class PaymentActivationFlowSetup extends FlowSetup {
  @service abilities;
  @service homePage;
  @service intl;
  @service router;
  @service sentry;
  @service store;
  @service subscriptionManager;
  @service toastFlashMessages;

  sortedPriceScale = ['150.00', '300.00', '600.00', '1500.00', '3600.00'];

  constructor() {
    super(...arguments);

    this.dataContext = new PaymentActivationFlowDataContext();

    this.dataContext.recommendedPrice = this.getRecommendedPrice();
    this.dataContext.sortedPriceScale = this.sortedPriceScale;
  }

  async beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'pricing');

    // After a redirection from checkout.com we will return back to either success step or card-details step
    // This will trigger `beforeFlow()` again, which we don't want to happen
    let stepId = this.router.currentRoute?.params?.step_id;
    if (
      stepId === CHECKOUT_REDIRECTION.SUCCESS_STEP_ID ||
      stepId === CHECKOUT_REDIRECTION.FAILURE_STEP_ID
    )
      return;

    if (this.abilities.can('access in payment-activation')) {
      let organizationAdapter = this.store.adapterFor('organization');
      let canView;

      try {
        let response = await organizationAdapter.getPaymentActivationEligibility();
        canView = response.paymentActivationTopupEnabled;
        this.dataContext.topUpByCardEnabled =
          this.abilities.can('top up by card in payment-activation') &&
          response.paymentActivationTopupByCardEnabled;
      } catch (error) {
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      }

      if (canView) {
        return;
      }
    }
    this.homePage.visitDefaultPage();
  }

  getRecommendedPrice() {
    let { value: cost } = this.subscriptionManager.planPriceWithVAT;

    let price = this.sortedPriceScale.find(price => parseFloat(cost) < parseFloat(price));
    return price || this.sortedPriceScale.at(-1);
  }

  @action
  onComplete() {
    this.homePage.visitDefaultPage();
  }

  @action
  onAbort() {
    this.homePage.visitDefaultPage();
  }
}
