import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class AccountsNewIndexRoute extends Route {
  @service abilities;
  @service flowLinkManager;
  @service menu;
  @service router;

  savings = false;
  origin = 'accounts';

  activate() {
    this.menu.hide();
  }

  deactivate() {
    this.menu.show();
  }

  beforeModel(transition) {
    this.origin = transition.from?.queryParams?.origin || transition.from?.parent?.name;
    this.savings = this.origin?.includes('savings');

    if (
      this.abilities.cannot('import external-account') &&
      this.abilities.cannot('create savings-account') &&
      this.abilities.cannot('create remunerated-account') &&
      !this.savings
    ) {
      this.router.replaceWith('accounts.new.current');
    } else {
      if (this.savings) {
        if (
          this.abilities.cannot('create savings-account') &&
          this.abilities.can('create remunerated-account')
        ) {
          this.flowLinkManager.transitionTo({
            name: 'remunerated-account-creation',
            stepId: 'create',
            queryParams: {
              origin: this.origin,
            },
          });
        }
      }
    }
  }

  model() {
    return this.modelFor('accounts.new');
  }

  afterModel({ savingsInformation }) {
    if (
      this.abilities.cannot('import external-account') &&
      this.abilities.cannot('create remunerated-account') &&
      !savingsInformation?.canManage
    ) {
      this.router.replaceWith('accounts.new.current');
    }

    if (this.savings && savingsInformation.canCreate) {
      if (
        this.abilities.can('create savings-account') &&
        this.abilities.cannot('create remunerated-account')
      ) {
        this.router.transitionTo('accounts.new.savings', {
          queryParams: { origin: this.origin },
        });
      }
    }
  }
}
