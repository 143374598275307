import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { NUM_OF_NAMES_TO_DISPLAY } from 'qonto/constants/budget';
import loadAllPaginated from 'qonto/routes/budgets/list/load-all-paginated';

export default class BudgetListRoute extends Route {
  @service organizationManager;
  @service abilities;
  @service store;
  @service router;

  beforeModel() {
    if (this.abilities.cannot('use budget')) {
      this.router.replaceWith('budgets.upgrade-plan');
    }
  }

  async model() {
    let budgets = await this._loadBudgets();

    let managers = await this._loadSupervisors(budgets);
    return budgets.map(budget => {
      return {
        budget,
        managedBy: this._supervisedBy(budget, managers),
      };
    });
  }

  _supervisedBy(budget, loadedManagers) {
    let managers = loadedManagers.filter(membership =>
      // we don't want to use a budget.supervisors relationship as
      // we don't want to load all supervisors for all budgets
      budget.supervisorIds.includes(membership.id)
    );

    managers.meta = { total_count: budget.supervisorIds.length };

    return managers;
  }

  _loadSupervisors(budgets) {
    let managerIdsToLoad = budgets.reduce((acc, budget) => {
      let ids = budget.supervisorIds.slice(0, NUM_OF_NAMES_TO_DISPLAY);
      return acc.concat(ids);
    }, []);
    return this._loadMemberships(managerIdsToLoad);
  }

  _loadMemberships(ids) {
    let { organization } = this.organizationManager;

    if (ids.length) {
      return this.store.query('membership', {
        organization_id: organization.id,
        filters: { ids },
        per_page: ids.length,
      });
    }
    return [];
  }

  _loadBudgets() {
    return loadAllPaginated(page =>
      this.store.query('budget', {
        filters: { archived: false, restricted: false },
        includes: ['active_period'],
        page,
      })
    );
  }
}
