import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class TransactionsV2IndexRoute extends Route {
  @service router;

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.currentModal?.close();
      controller.currentModal = null;
    }
  }
}
