import { NotFoundError } from '@ember-data/adapter/error';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { dropTask, restartableTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import CURRENCIES from 'qonto/constants/currencies';
import {
  IRPF_ES_DEFAULT_RATE,
  SENTRY_IGNORE_HTTP_STATUSES,
} from 'qonto/constants/receivable-invoice';
import BaseReceivableInvoicesRoute from 'qonto/routes/receivable-invoices/base/route';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class ReceivableInvoicesEditRoute extends BaseReceivableInvoicesRoute {
  @service toastFlashMessages;
  @service intl;
  @service menu;
  @service sentry;
  @service router;

  activate() {
    this.menu.hide();
  }

  async beforeModel() {
    let canReadInvoice = this.abilities.can('read receivableInvoice');
    let canUpdateDraft = this.abilities.can('update receivableInvoice');
    let { organization } = this.organizationManager;

    if (!(canUpdateDraft && canReadInvoice)) {
      this._redirectOnError();
    }

    // IT organizations need to have filled the e-invoice-activation step before creating or editing invoices
    if (organization.legalCountry === 'IT') {
      let eInvoiceActivation = await this.store.findRecord('e-invoice-activation', organization.id);

      if (!eInvoiceActivation.eInvoicingActivated) {
        this._redirectOnError();
      }
    }
  }

  async model({ id }, transition) {
    try {
      let { organization } = this.organizationManager;

      let settings;

      try {
        settings = await this.store.findRecord('receivable-invoices-settings', organization.id);
      } catch (error) {
        if (error instanceof NotFoundError) {
          return;
        } else if (!(error instanceof NotFoundError)) {
          throw error;
        }
      }

      if (variation('feature--boolean-client-hub')) {
        await this.fetchClientsTask.perform().catch(ignoreCancelation);
      }
      let editedDraft = await this.store.findRecord('receivable-invoice', id);

      if (!settings.contactEmail) {
        settings.contactEmail = this.organizationManager.membership.email;
      }
      editedDraft.contactEmail = settings.contactEmail;

      await this.fetchOrganizationAvatarTask.perform(organization);
      await this.store.query('customer', {
        filter: { organization_id: organization.id },
      });

      editedDraft.welfareFund =
        editedDraft.welfareFund ?? this.store.createRecord('receivable-invoice/welfare-fund');
      editedDraft.payment =
        editedDraft.payment ?? this.store.createRecord('receivable-invoice/payment');

      editedDraft.organization.vatNumber = settings.vatNumber;

      if (organization.legalCountry === 'DE') {
        editedDraft.header = settings.invoiceHeader;
        editedDraft.footer = settings.invoiceFooter;
        editedDraft.organization.taxNumber = settings.taxNumber;
        editedDraft.organization.companyLeadership = settings.companyLeadership;
        editedDraft.organization.districtCourt = settings.districtCourt;
        editedDraft.organization.commercialRegisterNumber = settings.commercialRegisterNumber;
      }

      let { lastNumber, lastInvoice } = await this._getLastInvoiceDetails();
      editedDraft.lastNumber = lastNumber;

      await editedDraft.customer.catch(error => {
        let client = this.store.peekRecord('client-hub', editedDraft.belongsTo('customer').id());
        if (variation('feature--boolean-client-hub') && client) {
          editedDraft.customer = client;
        } else {
          editedDraft.customer = null;
        }

        this.handleError(error);
      });

      editedDraft.currency =
        editedDraft?.customer.get('currency') || editedDraft.currency || CURRENCIES.default;

      let customerBillingCountry = editedDraft?.customer?.get('billingAddress.countryCode');

      if (this.isSpanishFreelancer && customerBillingCountry === 'ES') {
        editedDraft.withholdingTax = this.store.createRecord('receivable-invoice/withholding-tax', {
          type: '',
          rate: editedDraft.withholdingTax?.rate || IRPF_ES_DEFAULT_RATE,
          reason: '',
        });
      } else if (organization.legalCountry === 'IT') {
        editedDraft.withholdingTax =
          editedDraft.withholdingTax ??
          this.store.createRecord('receivable-invoice/withholding-tax');
      }

      editedDraft.number =
        settings?.numberingMode === 'automatic'
          ? settings.invoiceNextNumberFormatted || settings.nextInvoiceNumber
          : editedDraft.number;

      let canCreateFrEinvoice = await this.fetchFrEinvoicingSettingsTask
        .perform()
        .catch(ignoreCancelation)
        .catch(this.handleError);

      let isFirstFrenchEinvoice = false;
      if (
        organization.legalCountry === 'FR' &&
        canCreateFrEinvoice &&
        variation('feature--boolean-einvoicing-q2q')
      ) {
        await this.fetchFirstEInvoice();

        let allEinvoices = this.fetchFirstEInvoiceTask?.lastSuccessful?.value || false;

        isFirstFrenchEinvoice = allEinvoices && allEinvoices.length === 0;
      }

      editedDraft.isEinvoice =
        (canCreateFrEinvoice && editedDraft?.customer.get('einvoicing')) || false;

      let isFirstInvoice = Boolean(!lastInvoice);

      let invoice = {};

      // when coming from the settings modal, there might be already some changed applied to the opened edited draft
      // instead of initializing fetching the edited one, we will peek in the store the the one with the latest changes
      if (transition?.from?.name === 'invoicing-settings' && this.peekRecordedInvoices.length > 0) {
        // only one newly created invoice can be expected inside the array
        invoice = this.peekRecordedInvoices[0];

        // the email needs to be the latest one
        invoice.contactEmail = settings.contactEmail;
      } else {
        invoice = editedDraft;
      }

      return {
        invoice,
        settings,
        isFirstInvoice,
        canCreateFrEinvoice,
        isFirstFrenchEinvoice,
      };
    } catch (error) {
      this.handleError(error);
      this._redirectOnError();
    }
  }

  afterModel(model) {
    if (model.invoice.status !== 'draft') {
      this._redirectOnError();
    }
  }

  get peekRecordedInvoices() {
    // peek in the store the already created but not saved invoice without an id
    return this.store
      .peekAll('receivable-invoice')
      .filter(invoice => invoice.isNew && invoice.id === null);
  }

  @action
  willTransition(transition) {
    if (
      !transition.targetName.includes('receivable-invoices.edit') &&
      !transition.targetName.includes('invoicing-settings')
    ) {
      let { invoice } = this.context;
      invoice.rollbackAttributes();
    }
  }

  fetchOrganizationAvatarTask = dropTask(async organization => {
    try {
      await organization.getAvatar();
    } catch (error) {
      this.handleError(error);
    }
  });

  fetchClientsTask = restartableTask(async () => {
    let clientsParams = {
      page: 1,
      per_page: 500,
      sort_by: 'name:asc',
    };
    let clients = await this.store.query('client-hub', clientsParams);
    let total = clients.meta.total_count;

    while (total > clients.length) {
      clientsParams.page++;
      clients = clients.concat(await this.store.query('client-hub', clientsParams));
    }
  });

  handleError(error) {
    if (!error.isAdapterError) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry && !SENTRY_IGNORE_HTTP_STATUSES.includes(error.status)) {
        this.sentry.captureException(error);
      }
    }
  }

  deactivate() {
    this.menu.show();
  }

  async _getLastInvoiceDetails() {
    let termsAndConditions,
      lastNumber = '',
      lastInvoice;
    try {
      lastInvoice = await this.store.modelFor('receivable-invoice').last(this.store);
      termsAndConditions = lastInvoice.termsAndConditions;
      lastNumber = lastInvoice.number;
    } catch (error) {
      // we expect 404 if no last invoice
      if (ErrorInfo.for(error).shouldSendToSentry && error.status !== 404) {
        this.sentry.captureException(error);
      }
    }
    return { lastNumber, termsAndConditions, lastInvoice };
  }

  _redirectOnError() {
    this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    return this.router.transitionTo('receivable-invoices.index');
  }
}
