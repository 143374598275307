import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { restartableTask } from 'ember-concurrency';

import { filterParams } from 'qonto/utils/compute-query-params';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class TasksPendingMultiDirectDebitCollectionRoute extends Route {
  @service store;
  @service sentry;

  queryParams = {
    page: { refreshModel: true },
    perPage: { refreshModel: true },
  };

  async model(params) {
    this.fetchRequestsTask
      .perform(params)
      .catch(ignoreCancelation)
      .catch(error => {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });

    let multiRequest = await this.store.findRecord(
      'request-multi-direct-debit-collection',
      params.multi_request_id
    );

    return {
      multiRequest,
      fetchRequestsTask: this.fetchRequestsTask,
    };
  }

  fetchRequestsTask = restartableTask(async params => {
    let { multi_request_id, page, perPage } = filterParams(params);

    return await this.store.query('request-direct-debit-collection', {
      multi_request_id,
      page,
      per_page: perPage,
    });
  });
}
