import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { task } from 'ember-concurrency';

import { apiBaseURL, bankAccountNamespace } from 'qonto/constants/hosts';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class AccountsRoute extends Route {
  @service abilities;
  @service store;
  @service sentry;
  @service router;
  @service featuresManager;
  @service networkManager;
  @service subscriptionManager;
  @service externalBankRecommendations;

  beforeModel(transition) {
    let { showStatement, bank_account_slug: bankAccountSlug } = transition.to.queryParams;

    if (showStatement) {
      this.router.replaceWith('accounts.statements');
    } else if (bankAccountSlug) {
      this.router.replaceWith('accounts.details', bankAccountSlug);
    }
  }

  async model() {
    let accountTypes = ['current', 'remunerated', 'wealth', 'other'];
    let accountsPromises = accountTypes.map(type => this.fetchAccountByType([type]));
    accountsPromises.push(this.fetchBalances());

    let [currentAccounts, remuneratedAccounts, wealthAccounts, otherAccounts, balances] =
      await Promise.all(accountsPromises);

    let remuneratedAccountsTask;
    if (this.shouldFetchRemunerationDetails) {
      await this.fetchRemuneratedAccountsTask.perform(this.organization.id).catch(error => {
        let errorInfo = ErrorInfo.for(error);
        if (errorInfo.shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });
      remuneratedAccountsTask = this.fetchRemuneratedAccountsTask;
    }

    let recommendationsBundle;
    if (this.abilities.can('view external-bank-recommendation')) {
      try {
        recommendationsBundle = await this.externalBankRecommendations.fetchRecommendationsBundle();
      } catch (error) {
        let errorInfo = ErrorInfo.for(error);
        if (errorInfo.shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      }
    }

    return {
      organization: this.organization,
      currentPricePlanCode: this.subscriptionManager.currentPricePlan?.code,
      currentAccounts,
      remuneratedAccounts,
      wealthAccounts,
      otherAccounts,
      balances,
      remuneratedAccountsTask,
      recommendationsBundle,
    };
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.showCloseAccountModalTask?.cancelAll();
      controller.showRenameAccountModalTask?.cancelAll();
    }
  }

  get organization() {
    return this.modelFor('organizations');
  }

  get shouldFetchRemunerationDetails() {
    return (
      this.abilities.can('read remunerated-account') &&
      this.organization.bankAccounts.some(({ isRemunerated }) => isRemunerated)
    );
  }

  fetchRemuneratedAccountsTask = task(async organizationId => {
    let remuneratedAccounts = await this.store.query('remunerated-account', {
      organization_id: organizationId,
    });
    return { remuneratedAccounts };
  });

  async fetchAccountByType(account_types) {
    let queryOptions = {
      organization_id: this.organization.id,
      per_page: 500,
      account_types,
    };
    let result = await this.store.query('bank-account', queryOptions);
    return [...result];
  }

  async fetchBalances() {
    let balances = {};

    let result = await this.networkManager.request(
      `${apiBaseURL}/${bankAccountNamespace}/bank_accounts/balances`,
      {
        method: 'POST',
        headers: { 'X-Qonto-Organization-ID': this.organization.id },
      }
    );

    result.balances.forEach(balance => {
      let {
        label,
        data: {
          amount: { value },
        },
      } = balance;
      balances[label] = value;
    });

    return balances;
  }
}
