import Controller from '@ember/controller';
import { service } from '@ember/service';

import { Toggle } from '@repo/design-system-kit';
import { task } from 'ember-concurrency';

export default class ReceiptsReminderController extends Controller {
  toggle = Toggle;

  @service toastFlashMessages;
  @service segment;
  @service intl;

  toggleReminderTask = task(async () => {
    try {
      let reminder = !this.model.reminder;
      this.model.reminder = reminder;

      await this.model.save();

      let successMessage;
      if (reminder) {
        this.segment.track('weekly_reminders_setting_on');
        successMessage = this.intl.t(
          'settings.receipt-reminders.weekly-reminder.toast.activated-success'
        );
      } else {
        this.segment.track('weekly_reminders_setting_off');
        successMessage = this.intl.t(
          'settings.receipt-reminders.weekly-reminder.toast.deactivated-success'
        );
      }

      this.toastFlashMessages.toastSuccess(successMessage);
    } catch (error) {
      this.model.reminder = !this.model.reminder;
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));

      if (!error.isAdapterError) {
        throw error;
      }
    }
  });

  toggleSummaryTask = task(async () => {
    try {
      let summary = !this.model.summary;
      this.model.summary = summary;

      await this.model.save();

      let successMessage;
      if (summary) {
        this.segment.track('weekly_summary_setting_on');
        successMessage = this.intl.t(
          'settings.receipt-reminders.weekly-summary.toast.activated-success'
        );
      } else {
        this.segment.track('weekly_summary_setting_off');
        successMessage = this.intl.t(
          'settings.receipt-reminders.weekly-summary.toast.deactivated-success'
        );
      }

      this.toastFlashMessages.toastSuccess(successMessage);
    } catch (error) {
      this.model.summary = !this.model.summary;
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));

      if (!error.isAdapterError) {
        throw error;
      }
    }
  });
}
