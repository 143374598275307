/* eslint-disable @qonto/no-import-roles-constants, @qonto/no-redirect-in-unrelated-hooks */
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { restartableTask } from 'ember-concurrency';

import { ROLES } from 'qonto/constants/membership';

export default class MembersMemberRoute extends Route {
  @service router;
  @service abilities;
  @service store;
  @service segment;
  @service organizationManager;

  model({ membership_id }) {
    this.getMembershipTask
      .perform({ membership_id })
      .catch(() => this.router.replaceWith('members.active'));

    return this.getMembershipTask;
  }

  getMembershipTask = restartableTask(async ({ membership_id }) => {
    let membership = await this.store.findRecord('membership', membership_id);
    let currentUserId = this.organizationManager.membership.id;
    let isManager = this.organizationManager.membership.role === ROLES.MANAGER;
    let managerCondition = isManager && currentUserId === membership_id;

    let adminCondition = this.abilities.can('view transfer-limit');

    let membershipPromises = [];
    if (!membership.hasFetchedSpendLimits && (managerCondition || adminCondition)) {
      membershipPromises.push(membership.getSpendLimits());
    }

    if (this.abilities.can('read role') && membership.role === ROLES.MANAGER) {
      if (!membership.customPermissions) {
        membershipPromises.push(membership.getRole());
      }
    } else {
      membership.customPermissions = null;
    }

    await Promise.all(membershipPromises);

    this.segment.track('user-access_details_displayed');
    this.handleSearchInputFocus();
    return membership;
  });

  handleSearchInputFocus() {
    let membersModel = this.modelFor('members');

    if (membersModel) {
      let { inputId } = membersModel;
      let queryString = `#${inputId} input`;
      document.querySelector(queryString)?.focus();
    }
  }
}
