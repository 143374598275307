import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';

import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { LOCAL_STORAGE_ORGANIZATION_KEY } from 'qonto/constants/accounting-hub';
import { SUBSCRIPTION_STATUS } from 'qonto/constants/subscriptions';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';
export default class AccountingHubDetailsRoute extends Route {
  @service store;
  @service router;
  @service connectManager;
  @service abilities;
  @service userManager;
  @service subscriptionManager;
  @service organizationManager;
  @service refiner;
  @service sentry;
  @service supplierInvoicesInsights;

  model({ organization_slug }) {
    let membership;
    let organizationToDisplay;

    if (variation('feature--boolean-login-improvement')) {
      organizationToDisplay = this.organizationManager.organizations.find(
        ({ slug }) => slug === organization_slug
      );

      membership = this.organizationManager.membership;
    } else {
      organizationToDisplay = this.modelFor('accounting-hub')?.organizationsToRoleMap.find(
        ({ organization }) => organization.slug === organization_slug
      )?.organization;

      membership = organizationToDisplay.memberships.find(
        ({ user }) => this.userManager.currentUser.id === user.id
      );
    }

    if (!organizationToDisplay) {
      return null;
    }

    this.fetchOrganizationAccountingDetailsTask
      .perform(organizationToDisplay)
      .catch(ignoreCancelation)
      .catch(error => {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });

    return {
      membership,
      organization: organizationToDisplay,
      fetchOrganizationAccountingDetailsTask: this.fetchOrganizationAccountingDetailsTask,
    };
  }

  afterModel(model) {
    if (!model) {
      this.router.replaceWith('/404');
    }
  }

  setupTask = dropTask(async organization => {
    await Promise.resolve();
    await this.organizationManager.setCurrentOrganizationAndMembership(organization);
    await this.subscriptionManager.refresh(organization);
    await this.refiner.loadTask.perform();
  });

  fetchOrganizationAccountingDetailsTask = dropTask(
    waitFor(async organization => {
      await this.setupTask.perform(organization);

      if (this.abilities.cannot('access organization in accounting-hub')) {
        safeLocalStorage.removeItem(LOCAL_STORAGE_ORGANIZATION_KEY);
        return { organization };
      }

      safeLocalStorage.setItem(LOCAL_STORAGE_ORGANIZATION_KEY, organization.slug);

      let bankAccounts = await organization.loadBankAccounts();

      let searchPresets = await this.store.query('search-preset', {
        organization_id: organization.id,
      });

      let receivableInvoicesStats;
      if (this.subscriptionManager.currentSubscription?.status === SUBSCRIPTION_STATUS.ACTIVE) {
        await this.supplierInvoicesInsights.fetchInsights();
        receivableInvoicesStats = await this.store
          .modelFor('receivable-invoice')
          .getStats(this.store);
      }

      let integration;
      let solutionInstance;
      if (this.abilities.can('access integrations in accounting-hub')) {
        integration = await this.connectManager.getIntegration('jedeclare');

        if (integration) {
          solutionInstance = await this.store.query('solution-instance', {
            organization_id: organization.id,
            solution_id: integration.data.tray_solution_id,
          });
        }
      }

      let statements = await this.store.query('statement', {
        organization_id: organization.id,
        per_page: bankAccounts.length,
        filters: { latest: true },
      });

      return {
        statements,
        searchPresets,
        integration,
        solutionInstance: solutionInstance?.[0],
        receivableInvoicesStats,
        supplierInvoicesInsights: this.supplierInvoicesInsights.currentInsights,
      };
    })
  );
}
