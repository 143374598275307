import { action } from '@ember/object';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import { ELIGIBILITY_STATUS } from 'qonto/constants/international-out/eligibility';
import {
  DEFAULT_SOURCE_CURRENCY_CODE,
  DEFAULT_TARGET_CURRENCY_CODE,
} from 'qonto/constants/international-out/quote';
import {
  DEFAULT_MAXIMUM_APPLICABLE_AMOUNT,
  ERROR_CODE,
} from 'qonto/constants/international-out/voucher';
import { FlowSetup } from 'qonto/routes/flows/setup/internals';
import transformKeys from 'qonto/utils/transform-keys';

class InternationalOutFlowDataContext {
  transfer = null;
  quote = null;
  fees = null;
  beneficiary = null;
  targetAccount = null;
  bankAccount = null;
  transferDetails = null;
  attachments = [];
  additionalRequirements = null;
  settings = null;

  constructor({ bankAccount, settings }) {
    this.bankAccount = bankAccount;
    this.settings = settings;
  }
}

export default class InternationalOutFlowSetup extends FlowSetup {
  @service abilities;
  @service homePage;
  @service internationalOutManager;
  @service intl;
  @service modals;
  @service organizationManager;
  @service router;
  @service toastFlashMessages;

  constructor() {
    super(...arguments);
    let { defaultAccount, transferSettings } = this.organizationManager.organization;
    let { origin, targetCurrency } = this.router.currentRoute.queryParams;
    this.dataContext = new InternationalOutFlowDataContext({
      bankAccount: defaultAccount,
      settings: {
        attachment: {
          maximumAmountWithout:
            (transformKeys(transferSettings)?.maxAmountWithoutAttachmentCents ?? 0) / 100,
        },
        preferred: {
          sourceCurrency: DEFAULT_SOURCE_CURRENCY_CODE,
          targetCurrency: targetCurrency ?? DEFAULT_TARGET_CURRENCY_CODE,
        },
        ...(origin && { origin }),
      },
    });
    this.internationalOutManager.addIdempotencyKey();
  }

  async beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'transfers');

    let canAccess =
      this.abilities.can('create transfer') && (await this.internationalOutManager.isEligible());

    if (!canAccess) {
      this.homePage.replaceWithDefaultPage();
    }

    let isVoucherCandidate =
      variation('feature--boolean-international-out-promotional-campaign') &&
      Boolean(this.router.currentRoute.queryParams.voucher);

    if (isVoucherCandidate) {
      let voucher = {
        enabled: false,
      };

      try {
        let { status, maxAmount } =
          await this.internationalOutManager.getPromotionalEligibilityStatus();

        let maximumAmount = maxAmount ? Number(maxAmount.value) : DEFAULT_MAXIMUM_APPLICABLE_AMOUNT;
        let isVoucherEnabled = status === ELIGIBILITY_STATUS.ELIGIBLE && maximumAmount > 0;

        voucher = {
          enabled: isVoucherEnabled,
          ...(isVoucherEnabled && {
            rules: { maximumAmount },
          }),
        };

        if (isVoucherEnabled) {
          this.toastFlashMessages.toastSuccess(
            this.intl.t('international-out.promotions.voucher.applied')
          );
        }
      } catch ({ errors }) {
        this.toastFlashMessages.toastError(
          errors?.at(0).code === ERROR_CODE.DUPLICATE_VOUCHER
            ? this.intl.t('international-out.promotions.voucher.errors.already-used')
            : this.intl.t('international-out.promotions.voucher.errors.generic')
        );
      } finally {
        Object.assign(this.dataContext, {
          settings: {
            ...this.dataContext.settings,
            voucher,
          },
        });
      }
    }
  }

  @action
  onComplete() {
    this.router.transitionTo('transfers.landing');
  }

  onAbortTask = dropTask(async (_, { id: currentStepId }) => {
    if (currentStepId !== 'quote') {
      let result = await this.openAbortModalTask.perform();

      if (result !== 'confirm') {
        return false;
      }
    }

    this.router.transitionTo('transfers.landing');

    return true;
  });

  openAbortModalTask = dropTask(async () => {
    return await this.modals.open('popup/destructive', {
      title: this.intl.t('transfers.exit-flow.title'),
      description: this.intl.t('transfers.exit-flow.subtitle'),
      cancel: this.intl.t('transfers.exit-flow.cancel'),
      confirm: this.intl.t('transfers.exit-flow.confirm'),
    });
  });
}
