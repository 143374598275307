import Controller from '@ember/controller';
import { service } from '@ember/service';

export default class GuestsLoadingController extends Controller {
  @service organizationManager;

  get teams() {
    return this.organizationManager.organization.teams;
  }
}
