import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { reads } from 'macro-decorators';

export default class RequestsPendingRequesterMultiTransferDetailController extends Controller {
  @service organizationManager;
  @service router;
  @service intl;

  @reads('organizationManager.membership') membership;

  @tracked highlight = '';

  get isEmpty() {
    return !this.get('model.transfers.length');
  }

  get localState() {
    return {
      empty: this.isEmpty,
    };
  }

  get emptyStateOptions() {
    let lottieSrc = '/lotties/empty-state/no-request-pending.json';
    let { organization } = this.membership;

    if (this.isEmpty) {
      return {
        lottieSrc,
        title: this.intl.t('empty-states.requests.pending.reporting.title'),
        subtitle: this.intl.t('empty-states.requests.pending.reporting.subtitle'),
        button: {
          label: this.intl.t('empty-states.requests.pending.reporting.button'),
          callback: () => {
            this.router.transitionTo('requests.landing', organization.get('slug'));
          },
        },
      };
    }
  }

  get shouldShowValidation() {
    return this.model.request.status === 'pending' && !this.isEmpty;
  }

  @action handleCancel() {
    return this.transitionToRoute('requests.pending');
  }

  @action
  updateHighlightedItem(itemId) {
    this.highlight = itemId;
  }
}
