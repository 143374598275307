import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class ProtectedRoute extends Route {
  @service refreshSessionManager;
  @service sessionManager;

  beforeModel(transition) {
    this.sessionManager.requireAuthentication(transition, 'signin');
  }

  async afterModel() {
    await this.sessionManager.setupData();
  }

  activate() {
    this.refreshSessionManager.startActivityTracking();
  }

  deactivate() {
    this.refreshSessionManager.cancelActivityTracking();
  }
}
