import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import emailMessageInLines from 'qonto/utils/email-message-in-lines';

export default class QuotesShareController extends Controller {
  @service router;
  @service intl;
  @service organizationManager;

  @tracked sendTo;
  @tracked sendToHasErrors;
  @tracked emailSubject;
  @tracked emailMessage;
  @tracked copyToSelf;
  @tracked contactEmail;

  @action
  onClose() {
    this.router.transitionTo('quotes.index');
  }

  get emailMessageLines() {
    return emailMessageInLines(this.emailMessage);
  }

  get pdfUrl() {
    return this.model.quote.pdfUrl;
  }

  setEmailSubjectAndMessage() {
    this.emailSubject = this.model.emailTemplate?.subject;
    this.emailMessage = this.model.emailTemplate?.body;
  }

  get isDefaultEmailTemplate() {
    return !this.model.emailTemplate?.updated_at;
  }

  setDefaultParams() {
    this.contactEmail =
      this.model.settings?.contactEmail || this.organizationManager.membership.email;

    this.sendTo = this.model.quote.customerSnapshot?.email || '';

    this.sendToHasErrors = false;

    this.copyToSelf = true;

    this.setEmailSubjectAndMessage();
  }

  get logo() {
    return this.organizationManager.organization.get('isDefaultAvatar') === false
      ? this.organizationManager.organization.picture
      : null;
  }

  get showUpdateEmailTrigger() {
    return (
      this.model.emailTemplate?.subject !== this.emailSubject ||
      this.model.emailTemplate?.body !== this.emailMessage
    );
  }

  get emailPreviewLanguage() {
    return this.model.quote.locale ?? this.model.quote.get('customer.locale');
  }
}
