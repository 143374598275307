import Controller, { inject as controller } from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { task } from 'ember-concurrency';

export default class extends Controller {
  @service capitalIncreaseManager;
  @service toastFlashMessages;
  @service intl;
  @service router;

  @controller('capital.reviewed') capitalReviewedController;

  handleSignedTask = task(async () => {
    try {
      let organization = this.capitalIncreaseManager.organization;
      organization.set('documentType', 'deposit_request');

      await organization.handleContractSigned(this.documentId);

      this.set('showContract', false);
      this.capitalReviewedController.set('isDepositRequestSigned', true);
      this.capitalIncreaseManager.set('isDepositRequestSigned', true);

      if (organization.isFastTrackDepositCapitalFlowType) {
        this.router.transitionTo('capital.status');
      } else {
        this.replaceRoute('capital.reviewed');
      }
    } catch (error) {
      this.toastFlashMessages.toastError(error);
    }
  });

  @action handleDeclined() {
    Sentry.captureMessage('capital reviewed: user declined contract');
  }

  @action handleSignError() {
    Sentry.captureMessage('capital reviewed: contract signature failed');

    let message = this.intl.t('errors.internal_server_error');
    this.toastFlashMessages.toastError(message);
  }

  @action handleCanceled() {
    this.set('showContract', false);
    this.transitionToRoute('capital.reviewed');
  }
}
