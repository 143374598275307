import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { restartableTask } from 'ember-concurrency';

export default class AccountStatementsRoute extends Route {
  @service abilities;
  @service errors;
  @service toastFlashMessages;
  @service organizationManager;
  @service store;

  queryParams = {
    bankAccounts: {
      refreshModel: true,
    },
    page: {
      defaultValue: 1,
      refreshModel: true,
    },
    per_page: {
      defaultValue: 25,
      refreshModel: true,
    },
  };

  // route
  fetchStatementsTask = restartableTask(async params => {
    let { organization } = this.organizationManager;
    let { bankAccounts, per_page, page } = params;
    let bankAccountFilter = { bank_account_ids: bankAccounts ? bankAccounts.split(',') : [] };

    return await this.store.query('statement', {
      organization_id: organization.id,
      filters: bankAccountFilter,
      per_page,
      page,
    });
  });

  async performFetchStatementsTask(params) {
    try {
      await this.fetchStatementsTask.perform(params);
    } catch (error) {
      this.errors.handleError(error);
    }
  }

  beforeModel() {
    if (this.abilities.cannot('access bank-account')) {
      this.replaceWith('transactions');
    }
  }

  model(params) {
    this.performFetchStatementsTask(params);

    return {
      statementsTask: this.fetchStatementsTask,
    };
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.bankAccounts = '';
      controller.page = 1;
      controller.per_page = 25;
    }
  }
}
