import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

import { THEME } from 'qonto/constants/appearance-setting';
import { OAUTH_SOURCE } from 'qonto/constants/oauth';

export default class OauthRoute extends Route {
  @service refreshSessionManager;
  @service sessionManager;
  @service theme;

  beforeModel(transition) {
    Sentry.getCurrentScope().setTag('CFT', 'connect');

    let source = transition.to.queryParams?.source;

    if (source === OAUTH_SOURCE.REGATE) {
      this.theme.setAppearance(THEME.LIGHT);
    }
  }

  activate() {
    this.refreshSessionManager.startActivityTracking();
  }

  deactivate() {
    this.refreshSessionManager.cancelActivityTracking();
  }
}
