export const cardNewInviteePhysicalOne = {
  steps: {
    'bank-account': {
      componentName: 'cards/bank-account',
      nextStepId: 'payment-limits',
    },
    'payment-limits': {
      componentName: 'cards/payment-limits',
      nextStepId: 'delivery-address',
    },
    'delivery-address': {
      componentName: 'cards/delivery-address',
      nextStepId: 'review',
    },
    review: {
      componentName: 'cards/review',
      nextStepId: ({ card, hasMultipleActiveAccounts }) => {
        if (card.isUnsaved) {
          return hasMultipleActiveAccounts ? 'edit-bank-account' : 'edit-options';
        }
        return 'success';
      },
    },
    'edit-bank-account': {
      componentName: 'cards/bank-account',
      nextStepId: 'edit-options',
    },
    'edit-options': {
      componentName: 'cards/options',
      nextStepId: ({ shouldShowRestrictions }) =>
        shouldShowRestrictions ? 'edit-restrictions' : 'edit-payment-limits',
    },
    'edit-restrictions': {
      componentName: 'cards/restrictions',
      nextStepId: 'edit-payment-limits',
    },
    'edit-payment-limits': {
      componentName: 'cards/payment-limits',
      nextStepId: ({ card }) => (card.atmOption ? 'edit-withdrawal-limits' : 'review'),
    },
    'edit-withdrawal-limits': {
      componentName: 'cards/withdrawal-limits',
      nextStepId: 'review',
    },
    success: {
      componentName: 'member/success/invite',
    },
  },
  options: {
    enablePersistence: false,
  },
};
