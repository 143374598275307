import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { SORT_ORDER } from 'qonto/constants/sort';
import { sortByKey } from 'qonto/utils/sort-by-keys';

export default class CapitalLegalEntityNewRoute extends Route {
  @service capitalIncreaseManager;
  @service menu;
  @service store;

  queryParams = ['step'];

  activate() {
    this.capitalIncreaseManager.hideCapitalStepper();
    if (this.menu.isVisible) {
      this.menu.hide();
    }
  }

  async model() {
    let stakeholders = await this.store.query('stakeholder', {
      organization_id: this.capitalIncreaseManager.organization.id,
    });
    let legalEntity = this.store.createRecord('legal-entity', {
      organization: this.capitalIncreaseManager.organization,
    });

    stakeholders = [...stakeholders].sort(sortByKey('isCurrentUser', SORT_ORDER.DESC));

    return {
      legalEntity,
      stakeholders,
    };
  }

  deactivate() {
    this.capitalIncreaseManager.showCapitalStepper();
    this.menu.show();
  }
}
