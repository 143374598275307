import ReimbursementsPendingRoute from 'qonto/routes/reimbursements/pending/route';

export default class ReimbursementsToPayRoute extends ReimbursementsPendingRoute {
  controllerName = 'reimbursements.pending';

  setupController(controller, model) {
    controller.set('tab', 'to-pay');
    controller.set('approvalWorkflowLastStep', true);
    super.setupController(controller, model);
  }
}
