import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

export default class InvoicingSettingsController extends Controller {
  @service router;

  @action handleClose() {
    let { queryParams } = this.router.currentRoute || {};
    let origin = queryParams?.origin || 'receivable-invoices.index';
    if (queryParams?.params) {
      this.router.transitionTo(origin, queryParams?.params);
    } else {
      this.router.transitionTo(origin);
    }
  }
}
