/* eslint-disable @qonto/no-import-roles-constants */
import { INVITATION_TYPES, ROLES } from 'qonto/constants/membership';

export const memberInvite = {
  steps: {
    'fm-discovery': {
      componentName: 'member/fm-discovery',
      nextStepId: 'role',
    },
    'plan-upgrade': {
      componentName: 'member/plan-upgrade',
      nextStepId: ({ isManager, isGbRPartner, invitationType }) =>
        isManager || isGbRPartner || invitationType === INVITATION_TYPES.GUEST
          ? 'personal-info'
          : 'role',
    },
    role: {
      componentName: 'member/role-selection',
      nextStepId: ({ showComparison }) => (showComparison ? 'compare' : 'personal-info'),
    },
    'personal-info': {
      componentName: 'member/personal-information-form',
      nextStepId: ({ member }) =>
        member.role === ROLES.MANAGER || member.role === ROLES.ADVANCED_MANAGER
          ? 'scope'
          : 'review',
    },
    compare: {
      componentName: 'member/compare-roles',
      nextStepId: 'personal-info',
    },
    scope: {
      componentName: 'member/scope-selection',
      nextStepId: ({ member }) => {
        let { scope } = member.customPermissions;
        return scope === 'team' ? 'account-management-team' : 'account-management-organization';
      },
    },
    'account-management-team': {
      componentName: 'member/account-management/team-scope',
      nextStepId: 'expense-permissions',
    },
    'account-management-organization': {
      componentName: 'member/account-management/organization-scope',
      nextStepId: 'expense-permissions',
    },
    'expense-permissions': {
      componentName: 'member/expense-permissions',
      nextStepId: 'other-permissions',
    },
    'other-permissions': {
      componentName: 'member/other-permissions',
      nextStepId: 'review',
    },
    review: {
      componentName: 'member/review',
      nextStepId: ({ shouldDisplayCardCreationSuccess }) =>
        shouldDisplayCardCreationSuccess ? 'success-card' : 'success',
    },
    success: {
      componentName: 'member/success/invite',
    },
    'success-card': {
      componentName: 'member/success/invite-card',
    },
  },
  options: {
    enablePersistence: true,
  },
};
