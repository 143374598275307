import { action } from '@ember/object';
import { setOwner } from '@ember/owner';

export class CardNewInviteeVirtualDataContext {}

export default class CardNewInviteeVirtualFlowSetup {
  dataContext = null;

  constructor(owner) {
    setOwner(this, owner);
    this.dataContext = new CardNewInviteeVirtualDataContext();
  }

  @action
  onComplete() {}

  @action
  onSuccessToast() {
    // DO NOTHING
    // (need to be defined for secondary flows)
  }
}
