import { action } from '@ember/object';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

import { FlowSetup } from 'qonto/routes/flows/setup/internals';

export class CreateTeamFlowDataContext {
  team = null;

  constructor(team) {
    this.team = team;
  }
}

export default class CreateTeamFlowSetup extends FlowSetup {
  @service abilities;
  @service router;
  @service store;
  @service organizationManager;
  @service toastFlashMessages;
  @service intl;

  constructor() {
    super(...arguments);

    let team = this.store.createRecord('team', {
      organization: this.organizationManager.organization,
    });

    this.dataContext = new CreateTeamFlowDataContext(team);
  }

  beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'spend-management');
    if (this.abilities.cannot('create team') || this.abilities.cannot('create more team')) {
      return this.router.replaceWith('teams');
    }
  }

  @action
  onAbort() {
    this.router.transitionTo('teams');
  }

  @action
  onComplete() {
    this.router.transitionTo('teams');
  }

  @action
  onSuccessToast() {
    this.toastFlashMessages.toastSuccess(this.intl.t('toasts.create-team-success'));
  }
}
