import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class FinancingOfferRoute extends Route {
  @service router;

  beforeModel(transition) {
    let { uid } = transition.to.params;

    if (uid) {
      // The financing.offer route has moved to financing.partners.offer
      // Redirect to the new route
      this.router.replaceWith('financing.partners.offer', uid);
    }
  }
}
