export const invoice = {
  steps: {
    'einvoice-activation': {
      componentName: 'invoice-onboarding/einvoice-activation',
    },
  },
  options: {
    enablePersistence: false,
  },
};
