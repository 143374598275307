import Route from '@ember/routing/route';

import * as Sentry from '@sentry/ember';

export default class BeneficiariesRoute extends Route {
  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'transfers');
    // for now, we only support beneficiaries upload
    // but we could add beneficiaries.edit / beneficiaries.new routes
    this.transitionTo('beneficiaries.multi');
  }
}
