import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class DirectDebitCollectionsProcessingPaymentLinkRoute extends Route {
  @service intl;
  @service router;
  @service sentry;
  @service store;
  @service toastFlashMessages;

  async model({ id }) {
    let subscription;

    try {
      subscription = await this.store.findRecord('direct-debit-subscription', id);
    } catch (error) {
      this.handleError(error);
      return;
    }

    return {
      subscription,
    };
  }

  handleError(error) {
    if (ErrorInfo.for(error).shouldSendToSentry) {
      this.sentry.captureException(error);
    }

    this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    this.router.replaceWith('direct-debit-collections.processing');
  }
}
