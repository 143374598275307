export const invoicesProductsImport = {
  steps: {
    upload: {
      componentName: 'invoices/products-import/upload',
      nextStepId: 'mapping',
    },
    mapping: {
      componentName: 'invoices/products-import/mapping',
      nextStepId: 'success',
    },
    success: {
      componentName: 'invoices/products-import/success',
    },
  },
  options: {
    enablePersistence: false,
  },
};
