import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class InvitationsShowMemberCredentialsRoute extends Route {
  @service sessionManager;
  @service userManager;
  @service router;
  @service segment;
  @service store;

  setupController(controller, model) {
    super.setupController(...arguments);

    let currentUser;
    if (this.sessionManager.isAuthenticated) {
      currentUser = this.userManager.currentUser;
    } else {
      currentUser = this.store.createRecord('user');
      this.set('userManager.currentUser', currentUser);
    }

    let { invite, user, isMobile } = this._setupModels(model, currentUser);

    this.segment.track('join_team_set_phone_and_password', {
      role: invite.role,
      invitation_id: invite.id,
    });

    controller.setProperties({ user, invite, isMobile });
  }

  _setupModels({ invite, isMobile }, user) {
    let attrsToBind = ['email', 'firstName', 'lastName', 'birthdate'];

    attrsToBind.forEach(attr => user.set(attr, invite.get(attr)));

    let address = this.store.createRecord('address');
    invite.set('address', address);

    return { invite, user, isMobile };
  }
}
