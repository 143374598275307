/* eslint-disable @qonto/no-redirect-in-unrelated-hooks */
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { TrackedArray } from 'tracked-built-ins';

export default class CompanyEditRoute extends Route {
  @service menu;
  @service router;

  activate() {
    if (this.menu.isVisible) {
      this.menu.hide();
    }
  }

  deactivate() {
    if (!this.menu.isVisible) {
      this.menu.show();
    }
  }

  async model() {
    let { organizationChangeRequest, updateProcess } = this.modelFor('settings.company-profile');
    if (!updateProcess.isEditable) {
      return this.router.replaceWith('settings.company-profile.index');
    }

    let organization = await updateProcess.get('organization');

    let updateProcessKbis = await updateProcess.kycKybUpdateKbisFile;

    await organization.getAvatar();

    let kbis = new TrackedArray();
    if (updateProcessKbis) {
      kbis.push(updateProcessKbis);
    }

    return { organizationChangeRequest, organization, updateProcess, kbis };
  }
}
