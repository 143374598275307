import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { restartableTask } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class ClientsIndexRoute extends Route {
  @service store;
  @service sentry;
  @service intl;
  @service toastFlashMessages;
  @service router;

  queryParams = {
    page: { refreshModel: true },
    perPage: { refreshModel: true },
    sortBy: { refreshModel: true },
    highlight: { refreshModel: false },
    hasReminders: { refreshModel: false },
    hasNoReminders: { refreshModel: false },
  };

  model(params) {
    this.fetchClientsTask
      .perform(params)
      .catch(ignoreCancelation)
      .catch(error => this._handleError(error));
    this.fetchStatsTask.perform().catch(ignoreCancelation);

    return {
      clientsTask: this.fetchClientsTask,
      statsTask: this.fetchStatsTask,
    };
  }

  fetchClientsTask = restartableTask(async params => {
    let clients = [];
    let { page, perPage, sortBy, hasReminders, hasNoReminders } = params;

    clients = await this.store.query('client-hub', {
      page,
      per_page: perPage,
      sort_by: sortBy,
    });

    if (hasReminders || hasNoReminders) {
      let client = clients.find(({ id }) => [hasReminders, hasNoReminders].includes(id));
      if (client) {
        client.hasReminders = hasReminders ? true : false;
      }
    }

    return {
      clients,
      meta: {
        total_count: clients?.meta?.total_count ?? 0,
        total_pages: clients?.meta?.total_pages ?? 0,
        per_page: perPage,
        current_page: page,
      },
    };
  });

  fetchStatsTask = restartableTask(async () => {
    let invoiceStats = await this.store.modelFor('receivable-invoice').getStats(this.store);
    let quoteStats = await this.store.modelFor('quote').getStats(this.store);

    return { invoiceStats, quoteStats };
  });

  setupController(controller) {
    super.setupController(...arguments);
    controller.hasReminders = null;
    controller.hasNoReminders = null;
    controller._highlightedRemindersClientId = null;
  }

  resetController(controller) {
    controller.page = 1;
    controller.perPage = 25;
    controller.sortBy = 'name:asc';
  }

  _handleError(exception) {
    if (ErrorInfo.for(exception).shouldSendToSentry) {
      this.sentry.captureException(exception);
    }
    this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
  }
}
