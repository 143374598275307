import Controller from '@ember/controller';
import { service } from '@ember/service';

export default class AgenciaTributariaListController extends Controller {
  @service router;

  get isProcessingActive() {
    return this.router.currentRouteName === 'agencia-tributaria.list.processing';
  }

  get isCompletedActive() {
    return this.router.currentRouteName === 'agencia-tributaria.list.completed';
  }
}
