import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { restartableTask, timeout } from 'ember-concurrency';

import { REQUEST_EVENTS } from 'qonto/constants/listeners';
import { DEBOUNCE_MS } from 'qonto/constants/timers';
import { filterParams } from 'qonto/utils/compute-query-params';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

const DEFAULT_SORT_BY = 'processed_at:desc';

export default class RequestsPastIndexRoute extends Route {
  @service abilities;
  @service router;
  @service notifierManager;
  @service organizationManager;
  @service store;

  get isApprover() {
    return (
      this.abilities.can('review transfer request') || this.abilities.can('review card request')
    );
  }

  activate() {
    if (this.isApprover) {
      this.notifierManager.on(REQUEST_EVENTS.EMPLOYEE_CANCELED, this, 'reloadData');
    } else {
      this.notifierManager.on(REQUEST_EVENTS.ADMIN_APPROVED, this, 'reloadData');
      this.notifierManager.on(REQUEST_EVENTS.ADMIN_DECLINED, this, 'reloadData');
    }
  }

  beforeModel() {
    if (this.abilities.cannot('read request')) {
      return this.router.replaceWith('requests.upgrade-plan');
    }

    if (!this.isApprover) {
      return this.router.replaceWith('requests.index');
    }

    if (this.isApprover && this.abilities.can('navigate tasks')) {
      return this.router.replaceWith('tasks.past');
    }
  }

  queryParams = {
    highlight: {
      refreshModel: false,
    },
    page: { refreshModel: true },
    per_page: { refreshModel: true },
    sort_by: { refreshModel: true },
  };

  model(params) {
    this.fetchDataTask.perform(params, false).catch(ignoreCancelation);
    return {
      fetchRequestsTask: this.fetchDataTask,
    };
  }

  reloadData() {
    this.fetchDataTask.perform({}).catch(ignoreCancelation);
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.highlight = '';
      controller.page = 1;
      controller.per_page = 25;
      controller.sort_by = DEFAULT_SORT_BY;
    }
  }

  deactivate() {
    this.notifierManager.off(REQUEST_EVENTS.EMPLOYEE_CANCELED, this, 'reloadData');
    this.notifierManager.off(REQUEST_EVENTS.ADMIN_DECLINED, this, 'reloadData');
    this.notifierManager.off(REQUEST_EVENTS.ADMIN_APPROVED, this, 'reloadData');
  }

  fetchDataTask = restartableTask(async params => {
    await timeout(DEBOUNCE_MS);

    let { organization, membership } = this.organizationManager;
    let { page, per_page, sort_by } = filterParams(params);

    if (this.abilities.can('review transfer request') && membership.manager) {
      await membership.getSpendLimits();
    }

    return await this.store.query('request', {
      status: ['declined', 'canceled', 'approved'],
      includes: ['memberships'],
      organization_id: organization.id,
      page,
      per_page,
      sort_by,
    });
  });
}
