import Controller from '@ember/controller';
import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { equal, reads } from 'macro-decorators';

export default class OverviewIndexController extends Controller {
  @service abilities;
  @service organizationManager;
  @service notifierCounterManager;
  @service featuresManager;
  @service router;
  @service periodicUpdate;

  @reads('organizationManager.organization.hasExternalAccounts') hasExternalAccounts;

  @tracked hasCardsToDisplay = null;
  @tracked showPeriodicUpdateBanner = true;

  get selectedAccount() {
    return (
      this.organizationManager.organization.bankAccounts.find(
        ({ id }) => id === this.model.bankAccounts
      ) || this.organizationManager.currentAccount
    );
  }

  @equal('selectedAccount.status', 'closed') selectedAccountIsClosed;

  get canDisplayRecommendationCards() {
    if (this.model.transactionsTask.isRunning || this.model.transactionsTask.last?.isError) {
      return false;
    }

    // this.notifierCounterManager.counter is used inside GetStartedActions::RecommendationCards for internal conditions
    // without this check here there are issue with displaying banner
    if (
      this.selectedAccountIsClosed ||
      Boolean(this.model.bankAccounts) ||
      !this.notifierCounterManager.counter
    ) {
      return false;
    }

    if (this.abilities.cannot('view get started actions onboarding')) {
      return false;
    }

    return true;
  }

  get canDisplayPeriodicUpdateBanner() {
    return this.periodicUpdate.shouldDisplayPeriodicUpdateAction({
      updateProcess: this.model.updateProcess,
    });
  }

  get shouldDisplaySubmitKycUpsizeBanner() {
    return (
      this.organizationManager.membership.shouldSubmitKyc &&
      this.organizationManager.organization.kybPending
    );
  }

  @action
  onCardsDisplay(value) {
    next(this, () => {
      this.hasCardsToDisplay = value;
    });
  }

  @action
  clickOnPeriodicUpdateBanner() {
    this.router.transitionTo('settings.company-profile');
  }

  @action
  closePeriodicUpdateBanner() {
    this.showPeriodicUpdateBanner = false;
  }
}
