export default {
  "header": "qjf",
  "content": "qjK",
  "read-only": "qjG",
  "document-collection-banner": "qjr",
  "submit-banner": "qjb",
  "review-banner": "qjy",
  "disclaimer": "qjU",
  "title": "qjj",
  "card-container": "qjW",
  "representatives-section": "qju",
  "disabled-href": "qWS"
};
