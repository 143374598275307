import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

export default class InvitationsShowMemberKycSelectController extends Controller {
  @service router;

  @action
  goToUploadStep(documentType) {
    this.router.transitionTo('invitations.show.member.kyc.upload', {
      queryParams: { documentType },
    });
  }
}
