import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { ErrorInfo } from 'qonto/utils/error-info';

const SENTRY_IGNORE_HTTP_STATUSES = [404];

export default class ClientsClientRoute extends Route {
  @service router;
  @service store;
  @service sentry;
  @service toastFlashMessages;
  @service intl;

  rootRouteName = `clients.client`;

  async model({ id }) {
    let client;

    try {
      client = await this.store.findRecord('client-hub', id);
    } catch (error) {
      this.handleError(error);
    }

    return {
      client,
    };
  }

  redirect(model, transition) {
    if (transition.to.name === this.rootRouteName + '.index') {
      this.router.transitionTo(`${this.rootRouteName}.edit`);
    }
  }

  handleError(error) {
    if (
      ErrorInfo.for(error).shouldSendToSentry &&
      !SENTRY_IGNORE_HTTP_STATUSES.includes(error.status)
    ) {
      this.sentry.captureException(error);
    }

    this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));
    this.router.transitionTo('clients');
  }
}
