import { action } from '@ember/object';

import ProductsNewController from 'qonto/routes/products/new/controller';

export default class ProductsEditController extends ProductsNewController {
  @action
  redirectOnSuccess() {
    this.segment.track('catalog_item_edited');
    this.toastFlashMessages.toastSuccess(
      this.intl.t('products.creation-form.toasts.success.product-updated')
    );
    this.router.transitionTo('products.index');
  }

  @action
  onConfirmedDuplicate() {
    this.segment.track('catalog_edit-item_duplicate-added', {
      source: 'catalog',
    });
  }

  @action
  onDiscardedDuplicate() {
    this.segment.track('catalog_edit-item_duplicate-discarded', {
      source: 'catalog',
    });
  }
}
