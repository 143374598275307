import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class TransfersMultiRoute extends Route {
  @service abilities;
  @service organizationManager;
  @service homePage;
  @service router;
  @service store;

  beforeModel(transition) {
    let canReadTransfers = this.abilities.can('read transfer');

    if (!canReadTransfers) {
      let queryParams = transition.to.queryParams;
      delete queryParams.query;
      this.homePage.visitDefaultPage({ queryParams });
    }
  }

  model() {
    let file = null;
    let { organization } = this.organizationManager;
    let { mainAccount } = organization;

    let multiTransfer = this.store.createRecord('multi-transfer', {
      bankAccount: mainAccount,
      file,
      organization,
    });
    multiTransfer.addIdempotencyKey();
    return multiTransfer;
  }

  afterModel() {
    this.router.transitionTo('transfers.multi.new');
  }

  resetController(controller, isExiting) {
    if (isExiting) controller.set('extension', null);
  }
}
