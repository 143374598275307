import { action } from '@ember/object';
import { service } from '@ember/service';

import { reads } from 'macro-decorators';

import {
  getTrackingNameAndProperties,
  LAYOUT,
  TRACKING_ORIGINS,
  TYPES,
} from 'qonto/constants/empty-states/system';

import { getEmptyStateConfig } from '../../../constants/empty-states/transfers';
import BaseTransfersController from '../base-controller';

export default class PastTransfersController extends BaseTransfersController {
  @service abilities;
  @service emptyStates;
  @service intl;
  @service notifierCounterManager;
  @service organizationManager;
  @service subscriptionManager;
  @service router;
  @service zendeskLocalization;

  @reads('organizationManager.organization.hasExternalAccounts') hasExternalAccounts;

  get shouldShowNotification() {
    return this.notifierCounterManager.counter?.transferRequests > 0;
  }

  get pendingAriaLabel() {
    return this.shouldShowNotification
      ? this.intl.t('transfers.tabs.tasks.pending.aria-label')
      : this.intl.t('transfers.tabs.tasks.no-pending.aria-label');
  }

  get selectedBankAccount() {
    let { accounts } = this.organizationManager;
    return (
      accounts.find(({ id }) => id === this.bankAccounts) || this.organizationManager.currentAccount
    );
  }

  get isEmptyGlobally() {
    let { transferRequestsCompleted = 0, transferRequests = 0 } =
      this.notifierCounterManager.counter || {};
    return (
      transferRequestsCompleted + transferRequests === 0 &&
      this.model.fetchTotalCountTask.last?.value?.meta?.total_count === 0
    );
  }

  get isEmptyLocally() {
    return this.model.fetchTransfersTask.last?.value?.meta?.total_count === 0;
  }

  get isInformEmptyState() {
    return this.emptyStateRevampOptions?.layout === LAYOUT.INFORM;
  }

  get emptyStateRevampOptions() {
    if (
      this.model.fetchTransfersTask.isRunning ||
      this.model.fetchTransfersTask.last?.isError ||
      this.model.fetchTotalCountTask.isRunning ||
      this.model.fetchTotalCountTask.last?.isError
    ) {
      return;
    }

    let isApprover = this.abilities.can('create transfer');
    return this.emptyStates.getEmptyStateOptions({
      isOrgEligibleForFeature: true,
      isEmptyGlobally: this.isEmptyGlobally,
      isEmptyLocally: this.isEmptyLocally,
      hasActiveFilterOrSearch: false,
      config: getEmptyStateConfig(this.intl, {
        thirdPartyFaqUrl: this.zendeskLocalization.getLocalizedArticle(4359616),
      }),
      customInputs: {
        tab: 'past',
        isSubAccountIsClosed: Boolean(this.selectedBankAccount?.isClosed),
      },
      abilities: {
        canCreateExternalTransfers: isApprover,
        canRequestTransfers: this.abilities.can('create transfer request'),
        canReadBankAccounts: this.abilities.can('read bank-account'),
        hasMultiAccounts: this.subscriptionManager.features.multiAccounts,
      },
    });
  }

  @action
  handleTracking(origin) {
    if (this.emptyStateRevampOptions) {
      this.emptyStates.trackCta(this.emptyStateRevampOptions, origin);
    } else {
      let trackingData = getTrackingNameAndProperties({
        type: TYPES.ACTIVATE,
        name: 'transfers',
      })({
        isClickEvent: true,
        isEmptyState: false,
        origin: TRACKING_ORIGINS.HEADER,
      });
      if (trackingData?.name && trackingData.properties) {
        this.segment.track(trackingData.name, trackingData.properties);
      }
    }
  }

  @action
  handleHeaderTracking(origin) {
    this.handleTracking(origin);
  }

  get isEmptyStatePreviewLayout() {
    return this.emptyStateRevampOptions?.layout === LAYOUT.DISCOVER_PREVIEW;
  }
}
