import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class CardsConciergeAndLoungeRoute extends Route {
  @service router;
  @service abilities;

  beforeModel() {
    if (this.abilities.cannot('access concierge-and-lounge')) {
      this.router.replaceWith('cards.index');
    }
  }
}
