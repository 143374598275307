import { action } from '@ember/object';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { dropTask } from 'ember-concurrency';

import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { FlowSetup } from 'qonto/routes/flows/setup/internals';

export class CreateBudgetFlowDataContext {
  team;
  skipInviteManagerStep;
  supervisors = [];
}

export default class CreateBudgetFlowSetup extends FlowSetup {
  @service router;
  @service segment;
  @service abilities;
  @service modals;
  @service intl;
  @service userManager;
  @service subscriptionManager;
  @service store;

  constructor() {
    super(...arguments);

    this.dataContext = new CreateBudgetFlowDataContext();

    this.dataContext.userLimitReached = this.subscriptionManager.hasReachedUserLimit;
    this.dataContext.skipInviteManagerStep =
      this.subscriptionManager.hasReachedUserLimit || this.abilities.cannot('create invites');
  }

  get initialStepId() {
    if (this.hasUserCreatedBudget) {
      return 'team-supervisors-selection';
    }
    return 'intro';
  }

  markUserCreatedBudget() {
    safeLocalStorage.setItem(this.hasUserCreatedBudgetKey, true);
  }

  get hasUserCreatedBudget() {
    return Boolean(safeLocalStorage.getItem(this.hasUserCreatedBudgetKey));
  }

  get hasUserCreatedBudgetKey() {
    return `team-budgets-has-user-created-budget-${this.userManager.currentUser.id}`;
  }

  beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'cards');

    if (this.abilities.cannot('create budget')) {
      return this._goBackToMainPage();
    }
  }

  @action
  onComplete({ budget_id }) {
    this.markUserCreatedBudget();
    this.router.transitionTo('budgets.show', budget_id);
  }

  onAbortTask = dropTask(async (_, { id: stepId }) => {
    if (stepId === 'intro') {
      return this._goBackToMainPage();
    }
    if (stepId === 'team-selection' || stepId === 'team-supervisors-selection') {
      this.segment.track('budget-creation_create-team-cancel_clicked');
      return this._goBackToMainPage();
    }
    let result = await this.modals.open('popup/destructive', {
      title: this.intl.t('team-budgets.creation.close-modal.title'),
      description: this.intl.t('team-budgets.creation.close-modal.subtitle'),
      cancel: this.intl.t('btn.back'),
      confirm: this.intl.t('team-budgets.creation.close-modal.button.cancel'),
    });

    if (result === 'confirm') {
      this.segment.track('budget-creation_cancel-confirmation_clicked');
      return this._goBackToMainPage();
    }

    this.segment.track('budget-creation_cancel-back_clicked');
    return false;
  });

  _goBackToMainPage() {
    return this.router.replaceWith('budgets.list');
  }
}
