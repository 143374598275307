import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

export default class TopupCompletedController extends Controller {
  @service homePage;
  @service organizationManager;

  @action
  handleClose() {
    this.homePage.visitDefaultPage(this.organizationManager.organization.slug);
  }
}
