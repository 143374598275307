import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class OrganizationsLandingRoute extends Route {
  @service homePage;

  redirect() {
    this.homePage.replaceWithDefaultPage();
  }
}
