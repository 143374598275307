import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';

import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class InvoiceSubscriptionSddSetupRoute extends Route {
  @service menu;
  @service store;
  @service sentry;
  @service intl;
  @service toastFlashMessages;

  model({ id }) {
    let invoiceSubscription = this.peekOrFetchInvoiceSubscriptionTask
      .perform(id)
      .catch(ignoreCancelation);

    return invoiceSubscription;
  }

  peekOrFetchInvoiceSubscriptionTask = dropTask(async id => {
    let tryInvoiceSubscription = this.store.peekRecord('invoice-subscription', id);

    if (tryInvoiceSubscription) {
      return tryInvoiceSubscription;
    }

    try {
      return await this.store.findRecord('invoice-subscription', id);
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
    }
  });
}
