import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';
import window from 'ember-window-mock';
import { reads } from 'macro-decorators';

import { ErrorInfo } from 'qonto/utils/error-info';
import { isNoBackRoute } from 'qonto/utils/route/is-no-back-route';

export default class FlexKybController extends Controller {
  @service abilities;
  @service modals;
  @service router;
  @service intl;
  @service sentry;
  @service toastFlashMessages;
  @service organizationManager;

  @reads('organizationManager.organization') organization;

  formHasChanged = false;

  @action
  handleFormChange({ legalNumber, files }) {
    this.formHasChanged =
      (legalNumber && legalNumber !== this.organization.legalNumber) || files.length > 0;
  }

  handleCloseTask = dropTask(async () => {
    let result;

    if (this.formHasChanged) {
      result = await this.modals.open('popup/destructive', {
        title: this.intl.t('flex-kyb.abort-modal.title'),
        description: this.intl.t('flex-kyb.abort-modal.subtitle'),
        cancel: this.intl.t('btn.cancel'),
        confirm: this.intl.t('flex-kyb.abort-modal.confirm-cta'),
      });
    } else {
      result = 'confirm';
    }

    if (result === 'confirm') {
      this.handleCloseRedirections();
    }
  });

  @action
  handleCloseRedirections() {
    if (isNoBackRoute(this.model.fromRouteName)) {
      if (this.abilities.can('navigate overview')) {
        this.router.transitionTo('overview.index');
      } else {
        this.router.transitionTo('transactions.index');
      }
    } else {
      window.history.back();
    }
  }

  handleFormSubmitTask = dropTask(async ({ legalNumber, fileId }) => {
    try {
      await this.organization.createFlexKybSubmission({
        legalNumber,
        kbisFileId: fileId,
      });

      await this.organization.loadFlexKyb();

      this.toastFlashMessages.toastSuccess(
        this.intl.t('flex-kyb.de-freelancers-form.success-toast')
      );

      this.handleCloseRedirections();
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
      this.toastFlashMessages.toastError(
        this.intl.t('flex-kyb.de-freelancers-form.generic-error-toast')
      );
    }
  });
}
