import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { restartableTask, task, timeout } from 'ember-concurrency';

import { TRANSFER_EVENTS } from 'qonto/constants/listeners';
import { DEBOUNCE_MS } from 'qonto/constants/timers';
import { COMPLETED_STATUS } from 'qonto/constants/transfers';
import { filterParams } from 'qonto/utils/compute-query-params';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class pastTransfersRoute extends Route {
  @service abilities;
  @service homePage;
  @service notifierManager;
  @service organizationManager;
  @service router;
  @service sentry;
  @service store;

  queryParams = {
    highlight: { refreshModel: false },
    complete: { refreshModel: false },
    page: { refreshModel: true },
    per_page: { refreshModel: true },
    sort_by: { refreshModel: true },
    bankAccounts: { refreshModel: true },
  };

  statusFilters = COMPLETED_STATUS;

  beforeModel(transition) {
    let canReadTransfers = this.abilities.can('read transfer');

    if (!canReadTransfers) {
      let queryParams = transition.to.queryParams;
      delete queryParams.query;
      this.homePage.visitDefaultPage({ queryParams });
    }
  }

  model(params) {
    this.fetchDataTask.perform(params).catch(error => {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    });

    this.fetchTotalCountTask.perform().catch(error => {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    });

    return {
      fetchTransfersTask: this.fetchDataTask,
      fetchTotalCountTask: this.fetchTotalCountTask,
    };
  }

  fetchTotalCountTask = restartableTask(async () => {
    return await this.fetchTask.perform({ per_page: 1, status: null, bankAccounts: null });
  });

  fetchDataTask = restartableTask(async params => {
    await timeout(DEBOUNCE_MS);
    return await this.fetchTask.perform(params);
  });

  fetchTask = task(
    async ({
      highlight,
      complete,
      page,
      per_page,
      sort_by,
      bankAccounts,
      status = this.statusFilters,
    }) => {
      let params = {
        highlight,
        complete,
        page,
        per_page,
        sort_by,
        filters: { status },
      };

      if (bankAccounts) {
        params.filters.bank_account_ids = bankAccounts.split(',');
      }

      let nonNullParams = filterParams(params);

      return await this.fetchTransfer(nonNullParams);
    }
  );

  fetchTransfer({ page, per_page, sort_by, filters }) {
    let organizationId = this.organizationManager.organization.id;

    let includes = ['beneficiaries', 'memberships'];

    let requiredParams = { filters, includes, organization_id: organizationId };
    let optionalParams = { page, per_page, sort_by };
    let mergedParams = { ...requiredParams, ...optionalParams };
    return this.store.query('transfer', mergedParams);
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      this.fetchDataTask.cancelAll({ resetState: true });
      this.fetchTotalCountTask.cancelAll({ resetState: true });
      controller.setProperties({
        page: 1,
        highlight: null,
        complete: false,
        per_page: 25,
        sort_by: null,
        bankAccounts: '',
        sidebarOpened: false,
      });
    }
  }

  refreshTransfers() {
    this.refresh();
  }

  activate() {
    super.activate(...arguments);

    Object.values(TRANSFER_EVENTS).forEach(eventName => {
      this.notifierManager.on(eventName, this, 'refreshTransfers');
    });
  }

  deactivate() {
    super.deactivate(...arguments);

    Object.values(TRANSFER_EVENTS).forEach(eventName => {
      this.notifierManager.off(eventName, this, 'refreshTransfers');
    });
  }
}
