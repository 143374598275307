import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

const ALLOWED_STATUSES = ['waiting', 'rejected'];

export default class FlexKybRoute extends Route {
  @service router;
  @service menu;
  @service organizationManager;

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'onboarding');
    let { flexKyb, isDeFreelancer } = this.organizationManager.organization;

    if (!isDeFreelancer || !ALLOWED_STATUSES.includes(flexKyb?.status)) {
      this.router.replaceWith('organizations.show');
    }
  }

  model(params, transition) {
    return {
      fromRouteName: transition.from?.name,
    };
  }

  activate() {
    this.menu.hide();
  }

  deactivate() {
    this.menu.show();
  }
}
