import Controller from '@ember/controller';
import { service } from '@ember/service';

import { DEPOSIT_CAPITAL_STEPPER_STEP } from 'qonto/constants/organization';

export default class CapitalController extends Controller {
  @service capitalIncreaseManager;
  @service featuresManager;

  get currentStep() {
    let { currentStep } = this.capitalIncreaseManager;
    let qontoNotarySkipStep = DEPOSIT_CAPITAL_STEPPER_STEP.CAPITAL_DEPOSIT;

    if (this.isFastTrackDepositCapitalFlowType) {
      currentStep -= 1;
      qontoNotarySkipStep -= 1;
    }

    if (this.isQontoNotaryEnabled && currentStep > qontoNotarySkipStep) {
      currentStep -= 1;
    }

    return currentStep;
  }

  get isFastTrackDepositCapitalFlowType() {
    return this.capitalIncreaseManager.organization.isFastTrackDepositCapitalFlowType;
  }

  get isQontoNotaryEnabled() {
    return this.featuresManager.isEnabled('qontoNotary');
  }
}
