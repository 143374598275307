/* eslint-disable @qonto/no-import-roles-constants */
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';

import { ROLES } from 'qonto/constants/membership';
import { ErrorInfo } from 'qonto/utils/error-info';

export default class InvitationsShowIndexRoute extends Route {
  @service intl;
  @service router;
  @service sessionManager;
  @service userManager;
  @service sensitiveActions;
  @service organizationManager;
  @service store;
  @service sentry;
  @service toastFlashMessages;

  async redirect() {
    let { invite } = await this.modelFor('invitations.show');
    let loginToken = invite.loginToken;

    if (this.sessionManager.isAuthenticated) {
      await this.userManager.setup();
    } else {
      await this.authenticateTask.perform(loginToken, invite);
    }

    if (
      this.sessionManager.isAuthenticated &&
      this.userManager.currentUser.email !== invite.email
    ) {
      this.set('userManager.redirectUponLogout', `invitations/${invite.id}`);
      this.sessionManager.invalidate();
    }

    if (
      this.sessionManager.isAuthenticated &&
      this.userManager.currentUser.email === invite.email
    ) {
      await this.redirectAuthenticatedUserTask.perform(invite);
    }
  }

  startSessionTask = dropTask(async loginToken => {
    await this.userManager.setup({ loginToken });
  });

  authenticateTask = dropTask(async (loginToken, invite) => {
    try {
      await this.sensitiveActions.runTask
        .linked()
        .perform(this.startSessionTask, loginToken, invite);
    } catch (error) {
      if (error.status === 404) {
        this.redirectUnauthenticatedUser(invite);
      }
    }
    if (this.sessionManager.isAuthenticated) {
      await this.redirectAuthenticatedUserTask.perform(invite);
    }
  });

  redirectUnauthenticatedUser(invite) {
    if (invite.role === ROLES.REPORTING) {
      return this.router.replaceWith('invitations.show.accountant');
    } else {
      return this.router.replaceWith('invitations.show.credentials');
    }
  }

  redirectAuthenticatedUserTask = dropTask(async invite => {
    if (invite.role === ROLES.REPORTING) {
      return await this.acceptInviteTask.perform(invite);
    } // We can have an edgy case where user starts a register without filling phoneNumber which is required
    else if (!this.userManager.currentUser.phoneNumber) {
      return this.router.replaceWith('invitations.show.credentials');
    }

    if (invite.role === ROLES.EMPLOYEE) {
      return this.router.replaceWith('invitations.show.member.address');
    }

    return this.router.replaceWith('invitations.show.member.info');
  });

  acceptInviteTask = dropTask(async invite => {
    let address = this.store.createRecord('address');
    invite.set('address', address);

    try {
      await invite.accept();
      await this.organizationManager.setupTask.perform();

      this.router.replaceWith('organizations.show', invite.organization);
    } catch (error) {
      let isInvitationAlreadyAccepted = error.errors?.some(
        err => err?.detail?.code === 'invalid_invite'
      );

      if (isInvitationAlreadyAccepted) {
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.invite_already_accepted'));
        return;
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });
}
