import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class ReceivableInvoicesExportIndexRoute extends Route {
  @service segment;
  @service menu;

  beforeModel(transition) {
    let origin;
    switch (transition.from?.name) {
      case 'receivable-invoices.index':
        origin = 'client_invoice_page';
        break;
      case 'accounting-hub.details':
        origin = 'accounting_hub';
        break;
    }
    this.segment.track('client-invoices_export_modal_displayed', { origin });

    if (transition.from?.name === 'accounting-hub.details' && this.menu.isVisible) {
      this.menu.hide();
    }
  }

  setupController(controller) {
    super.setupController(controller);

    controller.selectedPeriod = controller.periodOptions[0];
    controller.format = controller.formatOptions[0].value;
    controller.separateFolders = false;
  }
}
