import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { ORGA_STATUS } from 'qonto/constants/organization';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class SettingsPricePlanPromoCodesRoute extends Route {
  @service sentry;
  @service toastFlashMessages;
  @service organizationManager;
  @service intl;

  beforeModel() {
    if (
      [ORGA_STATUS.DEACTIVATED, ORGA_STATUS.SUSPENDED].includes(
        this.organizationManager.organization.status
      )
    ) {
      this.router.replaceWith('settings.price-plan');
    }
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.fetchDiscountsTask.cancelAll();
      controller.discounts = [];
    }
  }

  setupController(controller) {
    controller.fetchDiscountsTask
      .perform()
      .catch(ignoreCancelation)
      .catch(error => {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }

        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      });
  }
}
