import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class SecurityRoute extends Route {
  @service router;

  beforeModel() {
    this.router.replaceWith('settings.personal.security');
  }
}
