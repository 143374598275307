import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

export default class IntegrationsRoute extends Route {
  @service organizationManager;
  @service abilities;
  @service router;
  @service store;

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'connect');
    if (this.abilities.cannot('view integration')) {
      this.router.replaceWith('settings');
    }
  }

  model() {
    let organizationId = this.organizationManager.organization.id;
    return this.store.queryRecord('integration', {
      organization_id: organizationId,
    });
  }
}
