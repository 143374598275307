import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';
import window from 'ember-window-mock';

import { ErrorInfo } from 'qonto/utils/error-info';
import { isNoBackRoute } from 'qonto/utils/route/is-no-back-route';

export default class UploadPowerOfAttorneyController extends Controller {
  @service sentry;
  @service store;
  @service intl;
  @service router;
  @service organizationManager;
  @service modals;
  @service toastFlashMessages;
  @service segment;

  get membership() {
    return this.organizationManager.membership;
  }

  formHasChanged = false;

  @action
  handleFormChange({ files }) {
    this.formHasChanged = files.length > 0;
  }

  handleCloseTask = dropTask(async () => {
    let result;

    if (this.formHasChanged) {
      result = await this.modals.open('popup/destructive', {
        title: this.intl.t('on-behalf.power-of-attorney-form.destructive-popup.title'),
        description: this.intl.t('on-behalf.power-of-attorney-form.destructive-popup.subtitle'),
        cancel: this.intl.t('on-behalf.power-of-attorney-form.destructive-popup.cancel-cta'),
        confirm: this.intl.t('on-behalf.power-of-attorney-form.destructive-popup.leave-cta'),
      });
    } else {
      result = 'confirm';
    }

    if (result === 'confirm') {
      this.formHasChanged = false;
      this.handleCloseRedirections();
    }
  });

  @action
  handleCloseRedirections() {
    if (isNoBackRoute(this.model.fromRouteName)) {
      this.router.transitionTo('transactions.index');
    } else {
      window.history.back();
    }
  }

  handleFormSubmitTask = dropTask(async ({ files }) => {
    try {
      let powerOfAttorney = this.store.createRecord('document', {
        docType: 'power',
        subjectType: 'Membership',
        subjectId: this.membership.id,
        files,
      });

      await powerOfAttorney.save(); // Endpoint: /v1/documents

      this.toastFlashMessages.toastSuccess(
        this.intl.t('on-behalf.power-of-attorney-form.success-toast')
      );

      this.segment.track('power_uploaded', {
        page: this.model.fromRouteName,
      });

      this.handleCloseRedirections();
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(
        this.intl.t('on-behalf.power-of-attorney-form.errors.generic')
      );
    }
  });
}
