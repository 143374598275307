import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { ErrorInfo } from 'qonto/utils/error-info';

export default class CardsIndexRoute extends Route {
  @service intl;
  @service toastFlashMessages;
  @service organizationManager;
  @service sentry;
  @service store;
  @service router;

  queryParams = {
    card_levels: {
      refreshModel: true,
    },
    holder_id: {
      refreshModel: true,
    },
    statuses: {
      refreshModel: true,
    },
    team_ids: {
      refreshModel: true,
    },
    highlight: {
      refreshModel: false,
    },
    page: {
      refreshModel: true,
    },
    per_page: {
      refreshModel: true,
    },
    sort_by: {
      refreshModel: true,
    },
    query: {
      refreshModel: true,
    },
    bankAccounts: {
      refreshModel: true,
    },
    team_id: {
      refreshModel: true,
    },
  };

  async beforeModel(transition) {
    if (transition.to.queryParams.highlight) {
      try {
        let card = await this.store.findRecord('card', transition.to.queryParams.highlight);
        let holder = await card.holder;
        if (card.ghost) {
          return this.router.replaceWith('cards.archived');
        } else if (holder.id !== this.organizationManager.membership.id) {
          return this.router.replaceWith('cards.team');
        }
      } catch (error) {
        this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
        // 404 error received if the card is not found, this is an expected case today
        if (!(error.status === 404) && ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      }
    }
    return this.router.replaceWith('cards.my-cards');
  }
}
