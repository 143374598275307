import { variation } from 'ember-launch-darkly';

export const subscriptionClose = {
  steps: {
    introduction: {
      componentName: 'subscription/close/introduction',
      nextStepId: ({ isEligibleForVoucher }) => {
        let isVoucherFeatureEnabled = variation('feature--improve-retention-vouchers-ab-test');
        return isVoucherFeatureEnabled && isEligibleForVoucher ? 'voucher' : 'reason-category';
      },
    },
    voucher: {
      componentName: 'subscription/close/voucher',
      nextStepId: ({ continueClosure }) => {
        let isVoucherFeatureEnabled = 'feature--improve-retention-vouchers-ab-test';
        if (isVoucherFeatureEnabled && !continueClosure) {
          return 'success-retention';
        }
        return 'reason-category';
      },
    },
    'reason-category': {
      componentName: 'subscription/close/reason-category',
      nextStepId: ({ closingReasonCategory }) => {
        if (closingReasonCategory?.key === 'other') {
          return 'reason-details';
        } else if (closingReasonCategory?.items?.length) {
          return 'reason';
        }
        return 'disclaimer';
      },
    },
    'reason-details': {
      componentName: 'subscription/close/reason-details',
      nextStepId: 'disclaimer',
    },
    reason: {
      componentName: 'subscription/close/reason',
      nextStepId: ({ closingReasonCategory, hearFromYou }) => {
        switch (variation('feature--improve-retention-slice-3')) {
          case 'screen':
          case 'banner-screen':
            if (closingReasonCategory.key === 'service_quality' && hearFromYou) {
              return 'hear-from-you';
            }
            break;
        }
        return 'disclaimer';
      },
    },
    disclaimer: {
      componentName: 'subscription/close/disclaimer',
      nextStepId: ({ hearFromYou }) => (hearFromYou ? 'hear-from-you' : 'success'),
    },
    confirm: {
      componentName: 'subscription/close/confirm',
      nextStepId: 'success',
    },
    success: {
      componentName: 'subscription/close/success',
    },
    'success-retention': {
      componentName: 'subscription/close/success-retention',
    },
    'hear-from-you': {
      componentName: 'subscription/hear-from-you/hear-from-you',
      nextStepId: ({ bookCall }) => {
        if (bookCall) {
          return 'success-hear-from-you';
        } else {
          return 'disclaimer';
        }
      },
    },
    'success-hear-from-you': {
      componentName: 'subscription/hear-from-you/success',
    },
  },
  options: {
    enablePersistence: false,
  },
};
