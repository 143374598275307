export const cardNewInviteeVirtual = {
  steps: {
    'bank-account': {
      componentName: 'cards/bank-account',
      nextStepId: 'payment-limits',
    },
    'payment-limits': {
      componentName: 'cards/payment-limits',
      nextStepId: 'review',
    },
    review: {
      componentName: 'cards/review',
      nextStepId: ({ card, hasMultipleActiveAccounts, shouldShowRestrictions }) => {
        if (card.isUnsaved) {
          if (hasMultipleActiveAccounts) {
            return 'edit-bank-account';
          } else {
            return shouldShowRestrictions ? 'edit-restrictions' : 'edit-payment-limits';
          }
        }
        return 'success';
      },
    },
    'edit-bank-account': {
      componentName: 'cards/bank-account',
      nextStepId: ({ shouldShowRestrictions }) =>
        shouldShowRestrictions ? 'edit-restrictions' : 'edit-payment-limits',
    },
    'edit-restrictions': {
      componentName: 'cards/restrictions',
      nextStepId: 'edit-payment-limits',
    },
    'edit-payment-limits': {
      componentName: 'cards/payment-limits',
      nextStepId: 'review',
    },
    success: {
      componentName: 'member/success/invite',
    },
  },
  options: {
    enablePersistence: false,
  },
};
