import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { variation } from 'ember-launch-darkly';

import { FlowSetup } from 'qonto/routes/flows/setup/internals';
import { ErrorInfo } from 'qonto/utils/error-info';

class PayLaterEarlyRepaymentFlow {
  currentRepaymentOption = null;
  repaymentOptions = null;
}

export default class PayLaterEarlyRepaymentFlowSetup extends FlowSetup {
  @service homePage;
  @service abilities;
  @service router;
  @service toastFlashMessages;
  @service financing;
  @service sentry;
  @service intl;

  constructor() {
    super(...arguments);

    this.dataContext = new PayLaterEarlyRepaymentFlow();
  }

  async beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'financing');

    let { financingId } = this.router.currentRoute.queryParams;

    if (
      !variation('feature--boolean-pay-later-early-repayment') ||
      this.abilities.cannot('early repay in financing') ||
      !financingId
    ) {
      return this.homePage.replaceWithDefaultPage();
    }

    try {
      let { repaymentOptions } = await this.financing.fetchEarlyRepaymentOptions(financingId);
      this.dataContext.repaymentOptions = repaymentOptions;
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }

      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.server_error'));
      this.router.replaceWith('financing.pay-later');
    }
  }

  onComplete() {
    // TODO: Redirections
  }

  onAbort() {
    // TODO: Redirections
  }
}
