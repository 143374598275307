import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { LottiePlayer } from '@repo/design-system-kit';
import window from 'ember-window-mock';

export default class LogoutController extends Controller {
  lottiePlayer = LottiePlayer;

  @service sentry;
  animationReady = false;

  @action
  handleSigninRedirect() {
    // let's refresh the app
    window.location.replace('signin');
  }

  @action
  handleError(error) {
    if (Boolean(error.status) && error.status >= 400 && error.status < 500) {
      this.sentry.captureException(error);
    }
  }
}
