export const connectApplicationsActivation = {
  steps: {
    'choose-alert': {
      componentName: 'connect/applications/activation/choose-alert',
      nextStepId: ({ hubNotificationRule }) => {
        if (hubNotificationRule?.trigger === 'transaction') {
          return 'transaction-amount';
        } else {
          return 'balance-amount';
        }
      },
    },
    'transaction-amount': {
      componentName: 'connect/applications/activation/transaction-amount',
      nextStepId: 'success',
    },
    'balance-amount': {
      componentName: 'connect/applications/activation/balance-amount',
      nextStepId: 'success',
    },
    success: {
      componentName: 'connect/applications/activation/success',
    },
  },
  options: {
    enablePersistence: false,
  },
};
