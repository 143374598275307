export default {
  "container": "ZSr",
  "header-cell": "ZSb caption-bold",
  "col-8": "ZSy",
  "table": "ZSU",
  "cell": "ZSj body-2",
  "cell-content": "ZSW",
  "row": "ZSu",
  "empty": "ZcS",
  "quick-actions": "Zcc",
  "card-infos": "Zcq",
  "card-digits": "ZcZ",
  "empty-state": "ZcR"
};
