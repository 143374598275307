import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class CashFlowCategoriesRoute extends Route {
  @service abilities;
  @service homePage;

  beforeModel() {
    if (this.abilities.cannot('navigate category')) {
      return this.homePage.visitDefaultPage();
    }
  }
}
