export default {
  "container": "qyi",
  "form-container": "qyA",
  "preview-container": "qyY",
  "pdf-preview-container": "qyh",
  "content": "qyf",
  "title": "qyK",
  "tabs-container": "qyG",
  "close-button": "qyr btn btn--icon-only btn--tertiary btn--large",
  "pdf-preview": "qyb",
  "header": "qyy",
  "without-tabs": "qyU"
};
