import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { DEFAULT_VAT_RATE_PER_COUNTRY } from 'qonto/constants/receivable-invoice';

export default class ProductsNewRoute extends Route {
  @service homePage;
  @service abilities;
  @service store;
  @service organizationManager;

  beforeModel() {
    if (this.abilities.cannot('write products')) {
      this.homePage.visitDefaultPage();
    }
  }

  model() {
    return {
      product: this.store.createRecord('product', {
        vatRate: DEFAULT_VAT_RATE_PER_COUNTRY[this.organizationManager.organization?.legalCountry],
      }),
    };
  }

  resetController({ model }, isExiting) {
    if (isExiting && model.product.hasDirtyAttributes) {
      model.product.rollbackAttributes();
    }
  }
}
