import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { dropTask } from 'ember-concurrency';

import { FlowSetup } from 'qonto/routes/flows/setup/internals';

class SddActivationFlowDataContext {
  activation = null;
  origin = null;

  constructor(activation, origin) {
    this.activation = activation;
    this.origin = origin;
  }
}

export default class SddActivationFlowSetup extends FlowSetup {
  @service abilities;
  @service homePage;
  @service intl;
  @service modals;
  @service router;
  @service store;
  @service flow;

  beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'local-payments');

    if (
      this.abilities.cannot('read directDebitCollection') ||
      this.abilities.cannot('activate directDebitCollection')
    ) {
      return this.homePage.replaceWithDefaultPage();
    }

    let activations = this.store.peekAll('direct-debit-collection-activation');

    // there can be only one activation object at a time
    let activation = activations[0];
    if (!activation) {
      return this.router.transitionTo('direct-debit-collections');
    }

    let origin = this.router.currentRoute.queryParams?.origin;

    this.dataContext = new SddActivationFlowDataContext(activation, origin);
  }

  onComplete() {
    this.router.transitionTo('direct-debit-collections');
  }

  onAbortTask = dropTask(async (_, { id: stepId }) => {
    if (stepId === 'intro') {
      this.#goBackToPreviousPage();
      return;
    }

    let result = await this.openAbortModalTask.perform();
    let shouldContinueAbort = result === 'confirm';
    if (shouldContinueAbort) {
      this.#goBackToPreviousPage();
      return;
    }
    return shouldContinueAbort;
  });

  openAbortModalTask = dropTask(async () => {
    return await this.modals.open('popup/confirmation', {
      title: this.intl.t('sdd-collections.cancel-activation-flow.title'),
      description: this.intl.t('sdd-collections.cancel-activation-flow.subtitle'),
      cancel: this.intl.t('btn.back'),
      confirm: this.intl.t('btn.leave'),
    });
  });

  #goBackToPreviousPage() {
    if (this.flow.refererPage && !this.flow.refererPage.includes('_loading')) {
      return this.router.replaceWith(this.flow.refererPage);
    }

    return this.router.replaceWith('direct-debit-collections');
  }
}
