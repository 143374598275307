import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class SettingsPricePlanIndexRoute extends Route {
  @service router;

  beforeModel() {
    this.router.replaceWith('settings.price-plan.details');
  }
}
