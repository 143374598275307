/* eslint-disable @qonto/no-import-roles-constants */
import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { isPresent } from '@ember/utils';

import { restartableTask } from 'ember-concurrency';

import { MEMBER_STATUS, ROLES } from 'qonto/constants/membership';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class MemberListRoute extends Route {
  @service abilities;
  @service cardsManager;
  @service organizationManager;
  @service router;
  @service store;
  @service subscriptionManager;

  queryParams = {
    page: {
      defaultValue: 1,
      refreshModel: true,
    },
  };
  rootRouteName = `members.list`;

  async model({ page, perPage }) {
    let { teamId } = this.paramsFor('members');

    await this.invitableMembersResponseTask.perform({ teamId }).catch(ignoreCancelation);
    await this.paginatedMembersResponseTask
      .perform({ page, perPage, teamId })
      .catch(ignoreCancelation);

    return {
      invitableMembersTask: this.invitableMembersResponseTask,
      paginatedMembersTask: this.paginatedMembersResponseTask,
    };
  }

  paginatedMembersResponseTask = restartableTask(async ({ page, perPage, teamId }) => {
    let { query } = this.paramsFor('members');

    let paginatedFilters = {
      status: [MEMBER_STATUS.ACTIVE, MEMBER_STATUS.REVOKED, MEMBER_STATUS.INVITED],
      roles: [ROLES.OWNER, ROLES.MANAGER, ROLES.EMPLOYEE, ROLES.ADMIN],
    };

    if (isPresent(teamId)) {
      paginatedFilters.team_ids = [teamId];
    }

    let paginatedMembersResponse = await this.store.query('membership', {
      organization_id: this.organizationManager.organization.id,
      per_page: perPage,
      page: page ?? 1,
      filters: paginatedFilters,
      query,
    });

    this.handleSearchInputFocus();
    return paginatedMembersResponse;
  });

  invitableMembersResponseTask = restartableTask(async ({ teamId }) => {
    let invitableFilters = {
      status: [MEMBER_STATUS.INVITABLE],
    };

    if (isPresent(teamId)) {
      invitableFilters.team_ids = [teamId];
    }

    let invitableMembersResponse = await this.store.query('membership', {
      organization_id: this.organizationManager.organization.id,
      filters: invitableFilters,
    });

    return invitableMembersResponse;
  });

  setupController(controller, model) {
    super.setupController(controller, model);

    let { query } = this.paramsFor('members');

    controller.setProperties({
      query,
    });

    this.cardsManager.fetchCounters(true);
  }

  redirect(model, transition) {
    let { query } = this.paramsFor('members');
    let memberRouteName = `${this.rootRouteName}.member`;

    let firstActive = model.paginatedMembersTask.last.value.find(
      ({ invitable, revoked }) => !invitable && !revoked
    );

    if (firstActive) {
      if (transition.to.name === this.rootRouteName + '.index') {
        this.transitionTo(
          memberRouteName,
          this.organizationManager.organization.slug,
          firstActive.id
        );
      }
      if (query && transition.to?.params?.membership_id !== firstActive.id) {
        this.transitionTo(
          memberRouteName,
          this.organizationManager.organization.slug,
          firstActive.id
        );
      }
    }
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.setProperties({ page: 1, query: null, teamId: null });
    }
  }

  handleSearchInputFocus() {
    let membersModel = this.modelFor('members');

    if (membersModel) {
      let { inputId } = membersModel;
      let queryString = `#${inputId} input`;
      document.querySelector(queryString)?.focus();
    }
  }
}
