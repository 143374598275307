import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import {
  FINANCING_TRANSFER_STATUS,
  IN_PROGRESS_FINANCING_STATUSES,
} from 'qonto/constants/financing';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class FinancingPayLaterInProgressRoute extends Route {
  @service financing;
  @service sentry;
  @service organizationManager;
  @service store;

  model() {
    this.fetchFinancingsTask
      .perform()
      .catch(ignoreCancelation)
      .catch(error => {
        if (ErrorInfo.for(error).shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      });

    return {
      fetchFinancingsTask: this.fetchFinancingsTask,
    };
  }

  fetchFinancingsTask = dropTask(async () => {
    let statuses = [...IN_PROGRESS_FINANCING_STATUSES];
    if (variation('feature--boolean-pay-later-early-repayment')) {
      statuses.push(FINANCING_TRANSFER_STATUS.PROCESSING_REPAYMENT);
    }
    return await this.financing.fetchFinancings(statuses);
  });
}
