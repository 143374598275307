import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class FeatureDiscoverySuccessRoute extends Route {
  @service menu;

  queryParams = {
    flow: {
      refreshModel: false,
    },
  };

  model(params) {
    let flow = params.flow;
    return { flow };
  }

  activate() {
    if (this.menu.isVisible) {
      this.menu.hide();
    }
  }

  deactivate() {
    this.menu.show();
  }
}
