export const directDebitSubscription = {
  steps: {
    'type-selection': {
      componentName: 'direct-debit-collections/subscription/type-selection',
      nextStepId: 'clients',
    },
    clients: {
      componentName: 'direct-debit-collections/subscription/clients',
      nextStepId: 'details',
    },
    details: {
      componentName: 'direct-debit-collections/subscription/details',
      nextStepId: 'additional-settings',
    },
    'additional-settings': {
      componentName: 'direct-debit-collections/subscription/additional-settings',
      nextStepId: 'summary',
    },
    summary: {
      componentName: 'direct-debit-collections/subscription/summary',
      nextStepId: 'payment-link',
    },
  },
  options: {
    enablePersistence: false,
  },
};
