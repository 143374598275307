import { action } from '@ember/object';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';
import { dropTask } from 'ember-concurrency';
import { TrackedArray } from 'tracked-built-ins';

import { FlowSetup } from 'qonto/routes/flows/setup/internals';

class ManagementKycDataContext {
  documentType = null;
  documents = new TrackedArray();
}

export default class ManagementKycFlowSetup extends FlowSetup {
  @service router;
  @service flow;
  @service organizationManager;
  @service toastFlashMessages;
  @service intl;
  @service segment;
  @service modals;
  @service homePage;

  constructor() {
    super(...arguments);

    this.dataContext = new ManagementKycDataContext();
  }

  beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'spend-management');
    if (this.organizationManager.membership.kycSubmitted) {
      this.toastFlashMessages.toastError(this.intl.t('kyc.management-kyc.toast-already-submitted'));
      return this.homePage.visitDefaultPage();
    }
  }

  transitionToRefererOrDefaultPage() {
    if (this.flow.refererPage && this.flow.refererPage !== '/') {
      this.router.transitionTo(this.flow.refererPage);
      return;
    }

    this.homePage.visitDefaultPage();
  }

  @action
  onComplete() {
    this.transitionToRefererOrDefaultPage();
  }

  onAbortTask = dropTask(async (_, { id: stepId }) => {
    if (stepId === 'document-selection') {
      this.transitionToRefererOrDefaultPage();
      return true;
    }

    let modalResult = await this.modals.open('popup/destructive', {
      title: this.intl.t('flow-in-flow.modal.abort.title'),
      description: this.intl.t('flow-in-flow.modal.abort.subtitle'),
      cancel: this.intl.t('btn.back'),
      confirm: this.intl.t('btn.leave'),
    });

    if (modalResult === 'confirm') {
      this.transitionToRefererOrDefaultPage();
      return true;
    }

    return false;
  });

  @action
  onSuccessToast() {
    // Nothing to do here
  }
}
