import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';

class WizardContext {
  saveStakeholderTask = dropTask(async stakeholder => {
    stakeholder.shareholder = true;
    await stakeholder.save();
  });
}

export default class ShareholdersEditController extends Controller {
  @service intl;
  @service modals;

  queryParams = ['step'];

  @tracked step = 'contribution';

  get steps() {
    return [
      {
        id: 'contribution',
        name: this.intl.t('on-boarding.capital-deposit.shareholders.owner-contribution.step'),
        componentName: 'capital-deposit/stakeholder/contribution',
      },
      {
        id: 'success',
        name: this.intl.t('on-boarding.capital-deposit.success'),
        componentName: 'capital-deposit/wizard-success',
      },
    ];
  }

  get wizardContext() {
    let context = new WizardContext();

    context.stakeholder = this.model;
    context.wizardSuccess = {
      title: this.intl.t(
        'on-boarding.capital-deposit.shareholders.owner-contribution.success.title'
      ),
      text: this.intl.t(
        'on-boarding.capital-deposit.shareholders.owner-contribution.success.subtitle'
      ),
    };

    return context;
  }

  get hideGoBack() {
    return this.step === 'success';
  }

  @action onGoBack() {
    if (this.wizardContext.stakeholder.hasDirtyAttributes) {
      this.rollbackStakeholderOwnerAttributes();
    }
  }

  onCloseTask = dropTask(async () => {
    if (this.wizardContext.stakeholder.hasDirtyAttributes) {
      await this.modals.open('popup/destructive', {
        title: this.intl.t(
          'on-boarding.capital-deposit.shareholders.add-shareholder.warning.title'
        ),
        description: this.intl.t(
          'on-boarding.capital-deposit.shareholders.add-shareholder.warning.description'
        ),
        cancel: this.intl.t('btn.cancel'),
        confirm: this.intl.t('btn.delete'),
        confirmTask: this.modalConfirmTask,
      });
    } else {
      this.rollbackStakeholderOwnerAttributes();
      await this.transitionToRoute('capital.shareholders.index');
    }
  });

  modalConfirmTask = dropTask(async close => {
    this.rollbackStakeholderOwnerAttributes();
    this.transitionToRoute('capital.shareholders.index');
    await close();
  });

  rollbackStakeholderOwnerAttributes() {
    this.wizardContext.stakeholder.rollbackAttributes();
  }

  @action onWizardComplete() {
    this.transitionToRoute('capital.shareholders.index');
  }
}
