import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

export default class HistoricalDataRoute extends Route {
  @service organizationManager;
  @service toastFlashMessages;
  @service intl;
  @service abilities;
  @service sentry;

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'ledger');
    // Redirects employees and organizations without historical-data
    if (this.abilities.cannot('view post-migration')) {
      return this.replaceWith('settings');
    }
  }

  handleError(error_) {
    let error = error_.errors?.[0];

    // The organization doesn't have historical-data or required permissions
    if (error?.detail === 4020 || error?.status === '403') {
      return this.transitionTo('settings');
    }
    this.sentry.captureException(error);
    let toastErrorMessage = this.intl.t('errors.internal_server_error');
    this.toastFlashMessages.toastError(toastErrorMessage);
  }

  async model() {
    let { organization } = this.organizationManager;

    let statements;
    let invoices;

    // Fetch statements
    try {
      statements = await organization.getOldStatements({ per_page: 60 });
    } catch (error) {
      let promise = this.handleError(error);
      if (promise) {
        return promise;
      }
    }

    // Fetch invoices
    try {
      invoices = await organization.getOldInvoicesTask.perform();
    } catch (error) {
      let promise = this.handleError(error);
      if (promise) {
        return promise;
      }
    }

    return {
      statements,
      invoices,
    };
  }
}
