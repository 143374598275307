import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { variation } from 'ember-launch-darkly';

import {
  DEFAULT_ELIGIBILITY,
  DEFAULT_PROMOTIONAL_ELIGIBILITY,
} from 'qonto/constants/international-out/eligibility';

export default class TransferLandingRoute extends Route {
  @service abilities;
  @service errors;
  @service financing;
  @service homePage;
  @service internationalOutManager;
  @service organizationManager;
  @service router;
  @service toastFlashMessages;

  beforeModel(transition) {
    let canReadTransfers = this.abilities.can('read transfer');

    if (!canReadTransfers) {
      let queryParams = transition.to.queryParams;
      delete queryParams.query;
      this.homePage.visitDefaultPage({ queryParams });
    }
  }

  async model() {
    return {
      internationalOut: await this.checkInternationalOutEligibility(),
      payLater: await this.checkPayLaterEligibility(),
      ...(variation('feature--boolean-international-out-promotional-campaign-slice-2') && {
        internationalOutPromotion: await this.checkInternationalOutPromotionalEligibility(),
      }),
    };
  }

  async checkInternationalOutEligibility() {
    try {
      return await this.internationalOutManager.getEligibilityStatus();
    } catch {
      return DEFAULT_ELIGIBILITY;
    }
  }

  async checkInternationalOutPromotionalEligibility() {
    try {
      return await this.internationalOutManager.getPromotionalEligibilityStatus();
    } catch {
      return DEFAULT_PROMOTIONAL_ELIGIBILITY;
    }
  }

  async checkPayLaterEligibility() {
    if (this.abilities.cannot('create pay later transfer in financing')) {
      return null;
    }

    try {
      return await this.financing.checkPayLaterEligibility();
    } catch (error) {
      if (this.errors.shouldFlash(error)) {
        this.toastFlashMessages.toastError(this.errors.messageForStatus(error));
      }
      return null;
    }
  }
}
