export const memberEditScope = {
  steps: {
    scope: {
      componentName: 'member/scope-selection',
      nextStepId: ({ member }) => {
        let { scope } = member.customPermissions;
        return scope === 'team' ? 'account-management-team' : 'account-management-organization';
      },
    },
    'account-management-team': {
      componentName: 'member/account-management/team-scope',
      nextStepId: ({ hasAllowedBankAccountsError }) => {
        if (hasAllowedBankAccountsError) {
          return 'edit-allowed-bank-accounts-error';
        }
        return 'expense-permissions';
      },
    },
    'account-management-organization': {
      componentName: 'member/account-management/organization-scope',
      nextStepId: ({ hasAllowedBankAccountsError }) => {
        if (hasAllowedBankAccountsError) {
          return 'edit-allowed-bank-accounts-error';
        }
        return 'expense-permissions';
      },
    },
    'expense-permissions': {
      componentName: 'member/expense-permissions',
      nextStepId: 'other-permissions',
    },
    'other-permissions': {
      componentName: 'member/other-permissions',
      nextStepId: ({ hasAllowedBankAccountsError }) => {
        if (hasAllowedBankAccountsError) {
          return 'edit-allowed-bank-accounts-error';
        }

        return 'edit-scope-success';
      },
    },
    'edit-scope-success': {
      componentName: 'member/success/edit-scope',
    },
    'edit-allowed-bank-accounts-error': {
      componentName: 'member/error/allowed-bank-accounts',
    },
  },
  options: {
    enablePersistence: true,
  },
};
