import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class CapitalLegalOwnerRoute extends Route {
  @service capitalIncreaseManager;
  @service menu;
  @service store;

  queryParams = ['step'];

  activate() {
    this.capitalIncreaseManager.hideCapitalStepper();
    if (this.menu.isVisible) {
      this.menu.hide();
    }
  }

  model() {
    let { id } = this.paramsFor('capital.shareholders.owner');

    let owner = this.store.peekRecord('stakeholder', id);
    let legalEntity = this.store.createRecord('legal-entity', {
      organization: this.capitalIncreaseManager.organization,
      stakeholder: owner,
    });

    return legalEntity;
  }

  deactivate() {
    this.capitalIncreaseManager.showCapitalStepper();
    this.menu.show();
  }
}
