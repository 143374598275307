import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { dropTask, restartableTask, timeout } from 'ember-concurrency';

import { DEBOUNCE_MS } from 'qonto/constants/timers';
import { filterParams } from 'qonto/utils/compute-query-params';
import { ErrorInfo } from 'qonto/utils/error-info';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

export default class ChecksIndexRoute extends Route {
  @service intl;
  @service organizationManager;
  @service sentry;
  @service store;
  @service toastFlashMessages;

  queryParams = {
    highlight: {
      refreshModel: false,
    },
    page: { refreshModel: true },
    per_page: { refreshModel: true },
    sort_by: { refreshModel: true },
    bankAccounts: { refreshModel: true },
  };

  model(params) {
    this.fetchDataTask.perform(params, false).catch(ignoreCancelation);
    this.fetchMandateTask.perform().catch(ignoreCancelation);

    return { fetchChecksTask: this.fetchDataTask, fetchMandateTask: this.fetchMandateTask };
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.highlight = '';
      controller.page = 1;
      controller.per_page = 25;
      controller.sort_by = 'created_at:desc';
      controller.bankAccounts = '';
    }
  }

  fetchDataTask = restartableTask(async params => {
    await timeout(DEBOUNCE_MS);

    let { organization } = this.organizationManager;
    let { bankAccounts } = params;

    let bankAccountIds = bankAccounts ? bankAccounts.split(',') : {};

    let nonNullParams = filterParams({
      ...params,
      filters: { bank_account_ids: bankAccountIds },
    });

    try {
      return await this.store.query('check', {
        organization_id: organization.id,
        ...nonNullParams,
      });
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('errors.internal_server_error'));

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  fetchMandateTask = dropTask(async () => {
    let { organization } = this.organizationManager;

    try {
      await this.store.adapterFor('check').getMandate(organization.id);
      return true;
    } catch {
      return false;
    }
  });
}
