/* eslint-disable @qonto/no-import-roles-constants */
import Controller, { inject as controller } from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';

import { isTesting, macroCondition } from '@embroider/macros';
import dayjs from 'dayjs';
import { dropTask, timeout } from 'ember-concurrency';
import { bool, reads } from 'macro-decorators';

import { ROLES } from 'qonto/constants/membership';
import { DISCLAIMER_TYPES } from 'qonto/constants/transfers';
import scrollIntoView from 'qonto/utils/scroll-into-view';
import { prepareLimitsData } from 'qonto/utils/transfers';
export default class TransfersMultiReviewController extends Controller {
  @service intl;
  @service organizationManager;
  @service segment;
  @service router;

  @controller('transfers.multi') parentController;

  @reads('model.transfers') transfers;

  @bool('model.errors.length') hasMultiTransferError;

  get disableConfirmButton() {
    return (
      this.confirmFlightTask.isRunning ||
      this.parentController.uploadTask.isRunning ||
      this.hasInsufficientFunds ||
      this.model.transfersAmount <= 0
    );
  }

  get insufficientFundsMessage() {
    return this.organizationManager.membership.role === ROLES.MANAGER
      ? this.intl.t('transfers.errors.manager.multi_insufficient_funds')
      : this.intl.t('transfers.errors.multi_insufficient_funds');
  }

  get hasInsufficientFunds() {
    let { transfersAmount, bankAccount } = this.model;
    return transfersAmount > bankAccount?.authorizedBalance;
  }

  @action
  handleGoBack() {
    this.router.transitionTo('transfers.landing');
  }

  confirmFlightTask = dropTask(async () => {
    let multiTransfer = this.model;

    // If the transfers are scheduled and the selected date is not tomorrow or beyond, we cancel the confirmation
    let tomorrow = dayjs().add(1, 'day');
    if (multiTransfer.scheduled && dayjs(multiTransfer.scheduledDate).isBefore(tomorrow, 'day')) {
      return;
    }

    let invalidTransfers = await this._validateTransfers();

    if (invalidTransfers.get('length') > 0) {
      // Scroll view to the first element with error state
      let delay = macroCondition(isTesting()) ? 0 : 500;
      await timeout(delay);

      scrollIntoView('[data-has-error]');
      return;
    }

    this.set('model.confirmWarnings', null);

    multiTransfer.addIdempotencyHeader();
    let response;

    try {
      response = await multiTransfer
        .confirm()
        .finally(() => multiTransfer.removeIdempotencyHeader());
    } catch (error) {
      return error;
    }

    this.segment.track('transfer_bulk_create_page_type', {
      file_extension: multiTransfer.file.extension,
    });

    this._trackMultiTransferOptions(multiTransfer);

    let confirmWarnings = this._getConfirmDisclaimers({
      warnings: response.warnings,
      fees: response.estimated_price,
    });

    let limitsData = prepareLimitsData(response.warnings, response.spend_limits);

    let confirmErrors = Array.isArray(response.errors) ? response.errors : [];

    this.set('model.confirmWarnings', confirmWarnings);
    this.set('model.confirmErrors', confirmErrors);
    this.set('model.limitsData', limitsData);
    this.router.transitionTo('transfers.multi.review.confirm', this.model);
  });

  _trackMultiTransferOptions(multiTransfer) {
    if (multiTransfer.scheduled) this.segment.track('transfer_toggle_schedule');

    let selectedTransfers = multiTransfer.selectedTransfers;

    let emailNotificationRequested = selectedTransfers.some(
      transfer => transfer.notifyByEmail === true
    );
    if (emailNotificationRequested) this.segment.track('transfer_toggle_notify');

    let attachmentProvided = selectedTransfers.some(transfer => transfer.attachment !== undefined);
    if (attachmentProvided) this.segment.track('transfer_toggle_attachment');
  }

  async _validateTransfers() {
    let invalidTransfers = [];

    for (let i = 0; i < this.model.selectedTransfers.length; i++) {
      let transfer = this.model.selectedTransfers[i];
      transfer.set('didValidate', false);
      let { validations: transferValidation } = await transfer.validate();

      transfer.set('didValidate', true);

      if (!transferValidation.isValid) {
        invalidTransfers.push(transfer);
      }
    }

    return invalidTransfers;
  }

  _getConfirmDisclaimers({ warnings = [], fees }) {
    let disclaimerTypes = [
      ...warnings,
      ...(fees && fees.amount > 0 ? [DISCLAIMER_TYPES.COST] : []),
    ];
    let disclaimers = this._getConfirmDisclaimersList({ fees });

    let selectDisclaimer = disclaimer =>
      disclaimerTypes.find(type => type === disclaimer.warningType);
    return disclaimers.filter(selectDisclaimer);
  }

  _getConfirmDisclaimersList({ fees }) {
    return [
      {
        warningType: DISCLAIMER_TYPES.COST,
        warningLevel: 'warning',
        warningMessage: this.intl.t('transfers.warnings.cost', {
          computed_amount: this.intl.formatMoney(fees.amount, {
            currency: fees.amount_currency,
          }),
        }),
      },
    ];
  }
}
