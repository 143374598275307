import { action } from '@ember/object';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { ParsingError, PrismicError } from '@prismicio/client';

import { INDEX_ROUTE, INSURANCE_CONTRACT_STATUS } from 'qonto/constants/insurance-hub';

export default class InsuranceHubProductRoute extends Route {
  @service organizationManager;
  @service prismic;
  @service router;
  @service store;

  previousRoute;
  previousModels;

  model({ uid }) {
    return this.getInsuranceOffer(uid);
  }

  @action
  error(error) {
    if (error.message === 'not-found') {
      this.router.replaceWith('/404');
    } else {
      return true;
    }
  }

  @action
  loading(transition) {
    let previousRoute = transition.from;

    this.previousRoute = previousRoute?.name || INDEX_ROUTE;
    this.previousModels = [
      this.organizationManager.organization.slug,
      ...(previousRoute?.params.category_slug ? [previousRoute.params.category_slug] : []),
    ];

    return true;
  }

  async getInsuranceOffer(uid) {
    let insuranceOffer;

    try {
      insuranceOffer = await this.prismic.getByUID('insurance_offer', uid, {
        fetchLinks: ['insurance_risk.name', 'insurance_provider.logo'],
      });

      let insuranceContracts = await this.store.findAll('insurance-contract');
      let hasActiveContract = insuranceContracts.find(
        ({ type, status }) =>
          type === insuranceOffer.data.type && status !== INSURANCE_CONTRACT_STATUS.EXPIRED
      );
      return { uid, ...insuranceOffer.data, hasActiveContract: Boolean(hasActiveContract) };
    } catch (error) {
      if (
        error instanceof ParsingError ||
        error.message === 'An invalid API response was returned'
      ) {
        throw new Error('bad-request');
      }

      if (error instanceof PrismicError) {
        throw new Error('not-found');
      }

      throw error;
    }
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    controller.set('previousRoute', this.previousRoute);
    controller.set('previousModels', this.previousModels);
  }
}
