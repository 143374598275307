export const createBudget = {
  steps: {
    intro: {
      componentName: 'budgets/create-budget/intro',
      nextStepId: 'team-supervisors-selection',
    },
    'team-selection': {
      componentName: 'budgets/create-budget/team-selection',
      nextStepId: ({ skipInviteManagerStep }) => {
        if (skipInviteManagerStep) {
          return 'time-frame';
        }
        return 'invite-manager';
      },
    },
    'team-supervisors-selection': {
      componentName: 'budgets/create-budget/team-supervisors-selection',
      nextStepId: 'time-frame',
    },
    'invite-manager': {
      componentName: 'budgets/create-budget/invite-manager',
      nextStepId: 'time-frame',
    },
    'time-frame': {
      componentName: 'budgets/create-budget/time-frame',
      nextStepId: 'set-budget',
    },
    'set-budget': {
      componentName: 'budgets/create-budget/set-budget',
      nextStepId: 'success',
    },
    success: {
      componentName: 'budgets/create-budget/success',
    },
  },
  options: {
    enablePersistence: true,
  },
};
