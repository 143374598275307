import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

export default class ReimbursementsRoute extends Route {
  @service router;

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'spend-management');
  }
}
