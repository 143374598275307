import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { dropTask } from 'ember-concurrency';
import window from 'ember-window-mock';

import { apiBaseURL, apiNamespace } from 'qonto/constants/hosts';
import { ErrorInfo } from 'qonto/utils/error-info';

import ConnectionsController from '../controller';

export default class ConnectionsBanksController extends ConnectionsController {
  @service toastFlashMessages;
  @service intl;
  @service modals;
  @service abilities;
  @service segment;
  @service organizationManager;
  @service networkManager;

  @tracked banksConnections = this.model.banksConnectionsTask?.lastSuccessful?.value || [];
  @tracked sortBy = 'status:asc';
  @tracked highlight = '';

  queryParams = ['highlight', 'outcome', { sortBy: 'sort_by' }];
  closeSidebarId = 'close-sidebar';

  get sortedBankConnections() {
    let sorted = this.model.banksConnectionsTask?.lastSuccessful?.value || [];
    let [sortField, sortOrder] = this.sortBy.split(':');

    return [...sorted].sort((a, b) => {
      let order = 0;

      if (sortField === 'status') {
        // Sort by failing connections first, then by lastSuccessfulSyncAt date
        if (a.isFailedConnection || b.isFailedConnection) {
          if (a.isFailedConnection && !b.isFailedConnection) {
            order = -1;
          } else if (!a.isFailedConnection && b.isFailedConnection) {
            order = 1;
          } else {
            order = a.lastSuccessfulSyncAt >= b.lastSuccessfulSyncAt ? 1 : -1;
          }
        } else {
          // For non-failing connections, use expiresAt date
          // Connections without an expiration date are sorted last in ascending order
          // and first in descending order
          if (!a.expiresAt && !b.expiresAt) {
            order = 0;
          } else if (!a.expiresAt) {
            order = 1;
          } else if (!b.expiresAt) {
            order = -1;
          } else {
            order = a.expiresAt >= b.expiresAt ? 1 : -1;
          }
        }
      } else {
        // General sorting by other fields
        let fieldA = a.get(sortField);
        let fieldB = b.get(sortField);

        if (fieldA > fieldB) {
          order = 1;
        } else if (fieldA < fieldB) {
          order = -1;
        } else {
          order = 0;
        }
      }

      return sortOrder === 'asc' ? order : -order;
    });
  }

  get canDisconnect() {
    return this.abilities.can('delete connections external-account');
  }

  @action
  canReconnect(connection) {
    return this.abilities.can('import external-account') && connection?.isActionableConnection;
  }

  @action
  updateHighlightedItem(itemId) {
    this.highlight = itemId;
  }

  @action
  handleSortBy(sortField, sortOrder) {
    this.highlight = '';
    this.sortBy = `${sortField}:${sortOrder}`;
  }

  confirmDisconnectTask = dropTask(async connection => {
    let { name, accounts } = connection;
    let count = accounts.length;

    this.segment.track('active-connections_disconnect-cta_click', {
      bank: name,
    });
    await this.modals.open('connections/banks/confirmation-modal', {
      title: this.intl.t('settings.connections.disconnect-bank.modal.title', { count }),
      description: this.intl.t('settings.connections.disconnect-bank.modal.subtitle', { count }),
      cancel: this.intl.t('settings.connections.disconnect-bank.modal.cta-cancel'),
      confirm: this.intl.t('settings.connections.disconnect-bank.modal.cta-disconnect', { count }),
      confirmTask: this.disconnectTask,
      connection,
    });
  });

  disconnectTask = dropTask(async (closeModal, { connection }) => {
    let { name, accounts } = connection;
    let count = accounts.length;

    try {
      await connection.destroyRecord();

      this.banksConnections = this.banksConnections.filter(c => c.id !== connection.id);
      this.toastFlashMessages.toastSuccess(
        this.intl.t('settings.connections.disconnect-bank.toast.success', { count })
      );
      this.segment.track('active-connections_confirmation-modal_disconnect-button_click', {
        bank: name,
      });
    } catch (error) {
      if (error.status === 423) {
        // Bank connection is being synced and cannot be deleted
        this.toastFlashMessages.toastError(
          this.intl.t('settings.connections.disconnect-bank.syncing.toast-error', { count })
        );
      } else {
        this.toastFlashMessages.toastError(
          this.intl.t('settings.connections.disconnect-bank.toast.error', { count })
        );
      }
    } finally {
      closeModal();
    }
  });

  reconnectTask = dropTask(async connection => {
    let baseURL = this.router.urlFor(
      'settings.connections.banks',
      this.organizationManager.organization.slug
    );
    let successURL = `${baseURL}?highlight=${connection.id}&outcome=success`;
    let errorURL = `${baseURL}?highlight=${connection.id}&outcome=error`;

    let data = {
      connection_id: connection.id,
      success_url: `${window.location.origin}${successURL}`,
      error_url: `${window.location.origin}${errorURL}`,
    };

    try {
      let response = await this.networkManager.request(
        `${apiBaseURL}/${apiNamespace}/account_aggregation/accounts/reconnect`,
        { method: 'POST', data }
      );

      window.location.href = response.webview_url;
    } catch (error) {
      this.toastFlashMessages.toastError(this.intl.t('toasts.errors.generic'));

      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });
}
