import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

export default class TasksRoute extends Route {
  @service abilities;
  @service router;

  beforeModel() {
    Sentry.getCurrentScope().setTag('CFT', 'spend-management');

    if (this.abilities.cannot('navigate task')) {
      return this.router.replaceWith('transactions');
    }
  }
}
