import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class CapitalOwnerContributionRoute extends Route {
  @service capitalIncreaseManager;
  @service menu;
  @service store;

  queryParams = ['step'];

  activate() {
    this.capitalIncreaseManager.hideCapitalStepper();
    if (this.menu.isVisible) {
      this.menu.hide();
    }
  }

  model() {
    let { id } = this.paramsFor('capital.shareholders.owner');
    return this.store.peekRecord('stakeholder', id);
  }

  deactivate() {
    this.capitalIncreaseManager.showCapitalStepper();
    this.menu.show();
  }
}
