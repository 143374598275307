import Route from '@ember/routing/route';
import { service } from '@ember/service';

import { dropTask } from 'ember-concurrency';

import { ignore404 } from 'qonto/utils/ignore-error';

const MAX_PER_PAGE = 100;
const FLASH_CARD_REQUEST = 'flash_card';
const VIRTUAL_CARD_REQUEST = 'virtual_card';
const TRANSFER_REQUEST = 'transfer';
const MULTI_TRANSFER_REQUEST = 'multi_transfer';
const EXPENSE_REPORT = 'expense_report';

export default class RequestsIndexRoute extends Route {
  @service abilities;
  @service router;
  @service store;

  isTaskApprover() {
    let isApprover =
      (this.abilities.can('review transfer request') ||
        this.abilities.can('review card request')) &&
      this.abilities.can('navigate tasks');

    return isApprover;
  }

  async beforeModel(transition) {
    if (this.abilities.cannot('read request')) {
      return this.router.replaceWith('requests.upgrade-plan');
    }

    let {
      queryParams: { request_id, request_type },
    } = transition.to;

    let approverRequestsTypes = [
      FLASH_CARD_REQUEST,
      VIRTUAL_CARD_REQUEST,
      TRANSFER_REQUEST,
      EXPENSE_REPORT,
    ];

    if (request_id && approverRequestsTypes.includes(request_type)) {
      let record = await this.fetchRequestTask.perform({ request_id, request_type });
      return this.replaceRouteWithHighlightedRecord({ record });
    }

    if (MULTI_TRANSFER_REQUEST === request_type) {
      let record = await this.fetchRequestTask.perform({ request_id, request_type });

      if (!record) {
        return this.router.replaceWith('requests.pending');
      }

      let routeName = 'requests.pending.requester-multi-transfer-detail';

      this.router.replaceWith(routeName, request_id);
    } else {
      this.router.replaceWith('requests.pending');
    }
  }

  replaceRouteWithHighlightedRecord({ record }) {
    let baseRoute = this.isTaskApprover() ? 'tasks' : 'requests';

    if (!record) {
      this.router.replaceWith(`${baseRoute}.pending`);
    }

    if (record.status === 'pending') {
      this.router.replaceWith(`${baseRoute}.pending`, {
        queryParams: {
          highlight: record.id,
          per_page: MAX_PER_PAGE,
        },
      });
    } else {
      this.router.replaceWith(`${baseRoute}.past`, {
        queryParams: {
          highlight: record.id,
          per_page: MAX_PER_PAGE,
        },
      });
    }
  }

  fetchRequestTask = dropTask(async ({ request_id, request_type }) => {
    if (request_type === FLASH_CARD_REQUEST) {
      return await this.store.findRecord('request-flash-card', request_id).catch(ignore404);
    }

    if (request_type === VIRTUAL_CARD_REQUEST) {
      return await this.store.findRecord('request-virtual-card', request_id).catch(ignore404);
    }

    if (request_type === TRANSFER_REQUEST) {
      return await this.store.findRecord('request-transfer', request_id).catch(ignore404);
    }

    if (request_type === MULTI_TRANSFER_REQUEST) {
      return await this.store.findRecord('request-multi-transfer', request_id).catch(ignore404);
    }

    if (request_type === EXPENSE_REPORT) {
      return await this.store.findRecord('request-expense-report', request_id).catch(ignore404);
    }
  });
}
