export const profileKYC = {
  steps: {
    'document-selection': {
      componentName: 'kyc/document-selection',
      nextStepId: 'upload-documents',
    },
    'upload-documents': {
      componentName: 'kyc/upload-documents',
      nextStepId: 'user-info',
    },
    'user-info': {
      componentName: 'kyc/user-info',
      nextStepId: 'success',
    },
    success: {
      componentName: 'profile/kyc/success',
    },
  },
  options: {
    enablePersistence: true,
  },
};
