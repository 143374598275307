export const memberEditAccountPermissions = {
  steps: {
    'account-management-team': {
      componentName: 'member/account-management/team-scope',
      nextStepId: ({ hasAllowedBankAccountsError }) => {
        if (hasAllowedBankAccountsError) {
          return 'edit-allowed-bank-accounts-error';
        }
        return 'edit-role-success';
      },
    },
    'account-management-organization': {
      componentName: 'member/account-management/organization-scope',
      nextStepId: ({ hasAllowedBankAccountsError }) => {
        if (hasAllowedBankAccountsError) {
          return 'edit-allowed-bank-accounts-error';
        }
        return 'edit-role-success';
      },
    },
    'edit-role-success': {
      componentName: 'member/success/edit-role',
    },
    'edit-allowed-bank-accounts-error': {
      componentName: 'member/error/allowed-bank-accounts',
    },
  },
  options: {
    enablePersistence: true,
  },
};
