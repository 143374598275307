import { action } from '@ember/object';
import { service } from '@ember/service';
import { underscore } from '@ember/string';

import * as Sentry from '@sentry/ember';

import { CATEGORIES, STAKEHOLDERS } from 'qonto/constants/connect';
import { FlowSetup } from 'qonto/routes/flows/setup/internals';

export const REDIRECTION_OPTIONS = {
  INVITATION: 'invitation',
  INTEGRATIONS: 'integrations',
};
export default class AccountingDiscoveryFlowSetup extends FlowSetup {
  @service organizationManager;
  @service router;
  @service segment;

  dataContext = {
    isAccountingDiscoveryFlow: true,
    selectedOption: null,
  };

  beforeFlow() {
    Sentry.getCurrentScope().setTag('CFT', 'bookkeeping');
  }

  onAbort(dataContext, { id } = {}) {
    this.router.transitionTo('overview');

    if (['landing', 'personal-info', 'review'].includes(id)) {
      this.segment.track('accounting-discovery_flow_aborted', {
        step_id: underscore(id),
      });
    }
  }

  @action
  onComplete({ selectedOption, primaryActionTriggered, member }) {
    if (selectedOption === REDIRECTION_OPTIONS.INVITATION) {
      if (primaryActionTriggered) {
        this.router.transitionTo('guests.guest', member.recipientId);
      } else {
        this.onAbort();
      }
    } else if (selectedOption === REDIRECTION_OPTIONS.INTEGRATIONS) {
      this.router.transitionTo(
        'settings.connect-hub.categories',
        this.organizationManager.organization.slug,
        CATEGORIES.ACCOUNTING,
        {
          queryParams: {
            stakeholderSlug: STAKEHOLDERS.FREELANCERS,
          },
        }
      );
    } else {
      throw new Error('Redirection option missing');
    }
  }
}
