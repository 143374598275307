import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class PasswordsResetRoute extends Route {
  @service segment;
  @service store;

  email = null;

  queryParams = {
    mfa_enabled: {
      refreshModel: true,
    },
    mfa_method: {
      refreshModel: true,
    },
    reset_password_token: {
      refreshModel: true,
    },
    set_new: {
      refreshModel: true,
    },
  };

  // Destructure query params from reset password URL user received by email
  model({ mfa_enabled, reset_password_token, mfa_method, set_new }) {
    this.segment.track('reset_password_started');

    return this.store.createRecord('users/password', {
      mfaEnabled: mfa_enabled === 'true',
      mfaMethod: mfa_method,
      resetPasswordToken: reset_password_token,
      hasPassword: set_new !== 'true',
    });
  }
}
