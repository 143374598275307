export default {
  "container": "qWB",
  "wrapper": "qWE",
  "grey-header-block": "qWd",
  "connect-app-header": "qWI",
  "connect-app-header-link": "qWt",
  "connect-app-header-content": "qWM",
  "connect-app-header-content-default": "qWP",
  "connect-app-header-content-left": "qWl",
  "button-block": "qWX",
  "connect-app-body": "qWC",
  "connect-app-body-left-content": "qWk",
  "connect-app-body-sidebar": "qWJ"
};
