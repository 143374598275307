import { NotFoundError } from '@ember-data/adapter/error';
import Controller from '@ember/controller';
import { service } from '@ember/service';

import { all, dropTask } from 'ember-concurrency';

export default class CapitalFinishController extends Controller {
  @service organizationManager;
  @service subscriptionManager;
  @service intl;
  @service toastFlashMessages;
  @service sentry;

  finishCapitalDepositTask = dropTask(async () => {
    // ideally these calls would be done here
    // https://gitlab.qonto.co/qonto/qonto-js/blob/13798a1c72b7bf0d55f560aa8c8ad3623b938ee1/app/organizations/show/route.js#L47
    // but when transitioning from capital.finish to organizations.show
    // afterModel hook is not executed
    // hence these requests
    try {
      this.organizationManager.organization.set('underRegistration', false);
      await all([
        this.organizationManager.updateBankAccounts(),
        this.subscriptionManager.refresh(),
      ]);
      this.transitionToRoute('organizations.show');
    } catch (error) {
      // Ignore failure on GET /subscriptions
      // it might not be created yet
      if (error instanceof NotFoundError) {
        this.transitionToRoute('organizations.show');
        return;
      }

      this.organizationManager.organization.set('underRegistration', true);
      this.sentry.captureException(error);
      let message = this.intl.t('errors.internal_server_error');
      this.toastFlashMessages.toastError(message);
    }
  });
}
