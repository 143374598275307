export default {
  "container": "qyd",
  "form-container": "qyI",
  "preview-container": "qyt",
  "credit-note-preview-container": "qyM",
  "credit-note-preview": "qyP",
  "content": "qyl",
  "title": "qyX",
  "tabs-container": "qyC",
  "close-button": "qyk btn btn--icon-only btn--tertiary btn--large",
  "header": "qyJ",
  "without-tabs": "qys"
};
