import Route from '@ember/routing/route';
import { service } from '@ember/service';

import * as Sentry from '@sentry/ember';

export default class CardAcquirerPaymentsRoute extends Route {
  @service abilities;
  @service homePage;
  @service router;

  beforeModel(transition) {
    Sentry.getCurrentScope().setTag('CFT', 'cards');
    if (this.abilities.cannot('read card-acquirer-payment')) {
      let queryParams = transition.to.queryParams;
      delete queryParams.query;
      this.homePage.visitDefaultPage({ queryParams });
    }
  }
}
